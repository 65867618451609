import stateCoords from "../input/state_coords.json";
import propertyTaxes from "../input/state_property_taxes.json";
import counties from "../input/all_counties_fips.json";
import cities from "../input/summarized_cities_smaller.json";
import states from "../input/allstates.json";
import { scaleQuantile } from "d3-scale";

export const HOUSING_HEATMAP_NAME = "housing_heatmap"
export const HIDDEN_HOUSING_NAME = "hidden_housing"

let cityMap = null
let fipsMap = null
let countyMap = null
let taxMap = null
let stateMap = null

let countyLinkMap = null // filled by loadcountymap
let cityLinkMap = null // filled by loadcitymap

cityMap = loadCityMap()
fipsMap = loadFipsMap()
countyMap = loadCountyMap()
stateMap = loadStateMap()
taxMap = loadPropertyTax()

export function getAnalyticsName() {
  if (window.location.origin.indexOf("housingheatmap") > -1) {
    return HOUSING_HEATMAP_NAME
  } else if (window.location.origin.indexOf("hiddenhousing") > -1) {
    return HIDDEN_HOUSING_NAME
  } else if (window.location.origin.indexOf("localhost") > -1) {
    return HOUSING_HEATMAP_NAME
  }
}

export const STATE_ABBREV_TO_FULL = {"AZ":"Arizona","AL":"Alabama","AK":"Alaska","AR":"Arkansas","CA":"California","CO":"Colorado","CT":"Connecticut","DC":"District of Columbia","DE":"Delaware","FL":"Florida","GA":"Georgia","HI":"Hawaii","ID":"Idaho","IL":"Illinois","IN":"Indiana","IA":"Iowa","KS":"Kansas","KY":"Kentucky","LA":"Louisiana","ME":"Maine","MD":"Maryland","MA":"Massachusetts","MI":"Michigan","MN":"Minnesota","MS":"Mississippi","MO":"Missouri","MT":"Montana","NE":"Nebraska","NV":"Nevada","NH":"New Hampshire","NJ":"New Jersey","NM":"New Mexico","NY":"New York","NC":"North Carolina","ND":"North Dakota","OH":"Ohio","OK":"Oklahoma","OR":"Oregon","PA":"Pennsylvania","RI":"Rhode Island","SC":"South Carolina","SD":"South Dakota","TN":"Tennessee","TX":"Texas","UT":"Utah","VT":"Vermont","VA":"Virginia","WA":"Washington","WV":"West Virginia","WI":"Wisconsin","WY":"Wyoming"};

export const STATE_FULL_TO_ABBREV = {"arizona":"AZ","alabama":"AL","alaska":"AK","arkansas":"AR","california":"CA","colorado":"CO","connecticut":"CT","districtofcolumbia":"DC","delaware":"DE","florida":"FL","georgia":"GA","hawaii":"HI","idaho":"ID","illinois":"IL","indiana":"IN","iowa":"IA","kansas":"KS","kentucky":"KY","louisiana":"LA","maine":"ME","maryland":"MD","massachusetts":"MA","michigan":"MI","minnesota":"MN","mississippi":"MS","missouri":"MO","montana":"MT","nebraska":"NE","nevada":"NV","newhampshire":"NH","newjersey":"NJ","newmexico":"NM","newyork":"NY","northcarolina":"NC","northdakota":"ND","ohio":"OH","oklahoma":"OK","oregon":"OR","pennsylvania":"PA","rhodeisland":"RI","southcarolina":"SC","southdakota":"SD","tennessee":"TN","texas":"TX","utah":"UT","vermont":"VT","virginia":"VA","washington":"WA","westvirginia":"WV","wisconsin":"WI","wyoming":"WY"}

export const DEFAULT_MAP_KEY = {
  "inverted": "N",
  "mapType": "default",
  "geoMode": "national",
  "upper": " Best", "upperMid": " Good", "bottomMid": " Okay", "bottom": " Bad",
  "upper_color": "#228B22", "upperMid_color": "#FFFF00", "bottomMid_color": "#FF9000", "bottom_color": "#BF0000"
}

export const CHART_COLORS = {
  redColor: "rgba(255,0,0,1)",
  redTransparent: "rgba(255,0,0,0.4)",

  blueColor:"rgba(0,0,255,1)",
  blueTransparent: "rgba(0,0,255,.4)",

  darkRedColor: "rgba(139,0,0,1)",
  darkRedTransparent: "rgba(139,0,0,.4)",

  darkBlueColor: "rgba(0,0,139,1)",
  darkBlueTransparent: "rgba(0,0,139,.4)",

  lightRedColor: "rgba(255,114,118,.8)",
  lightRedTransparent: "rgba(255,114,118,.4)",

  lightBlueColor: "rgba(137, 207, 240,.8)",
  lightBlueTransparent: "rgba(137, 207, 240,.4)",

  greenColor: "rgba(0, 128, 0, 1)",
  greenTransparent: "rgba(0, 128, 0, .4)",

  darkGreenColor: "rgba(0,100,0,1)",
  darkGreenTransparent: "rgba(0,100,0,.4)",

  lightGreenColor: "rgba(144,238,144,1)",
  lightGreenTransparent: "rgba(144,238,144,.4)",

  whiteColor: "rgba(255, 255, 255, 1)",
  whiteColorTransparent: "rgba(255, 255, 255, .4)",

  blackColor: "rgba(0, 0, 0, 1)",
  blackColorTransparent: "rgba(0, 0, 0, .4)",

  grayColor: "rgba(105, 105, 105, 1)",
  grayColorTransparent: "rgba(105, 105, 105, .4)",

  purpleColor: "rgba(119, 0, 255, 1)",
  publicColorTransparent: "rgba(119, 0, 255, .4)",
}

function loadStateMap() {
  if (stateMap == null) {
    let map = {}
    let entries = Object.entries(states)
    for (let key in Object.keys(entries)) {
      let values = entries[key][1]
      map[values["id"]] = values["val"]
    }
    return map
  } else {
    return stateMap
  }
}

function loadCityMap() {
  if (cityMap == null) {
    let map = {}
    let linkMap = {}
    let alreadyAdded = {}
    let entries = Object.entries(cities)
    for (let key in Object.keys(entries)) {
      let values = entries[key][1]
      map[values["city_id"]] = values

      // linkMap (used for SEO)
      if (alreadyAdded[values["city_id"]] !== undefined){
        continue
      }
      alreadyAdded[values["city_id"]] = "Y";
      let state = values["state"]
      if (linkMap[state] == undefined) {
        linkMap[state] = []
      }
      //console.log('values: ' + JSON.stringify(values))
      let link = convertToLink("city", values, values["city_id"], values["state"])
      //console.log('city link: ' + link)
      linkMap[state].push(link)
    }


    cityLinkMap = {}

    for (let s in linkMap) {
      let stateCounties = linkMap[s]
      stateCounties.sort()
      cityLinkMap[s] = stateCounties
    }

    return map
  } else {
    return cityMap
  }
}

function loadPropertyTax() {
  if (taxMap == null) {
    let map = {}
    let entries = Object.entries(propertyTaxes)
    for (let key in Object.keys(entries)) {
      let values = entries[key][1]
      map[values["State"]] = values["Real Estate Tax Rate"]
    }
    return map
  } else {
    return taxMap
  }
}

function convertToLink(geoModeP, obj, key, state) {
  let geoKey = obj[geoModeP]
  if (geoModeP == "county") {
    geoKey = geoKey.replaceAll(' ', '_')
    geoKey = state + "/" + key + "/" + geoKey
  } else if (geoModeP == "city") {
    let cityName = obj["city_name"];
    cityName = cityName.replaceAll(' ', '_')
    geoKey = obj["city_id"] +"/" + cityName
  }
  let link = window.location.origin + "/map/" + "national/" + geoModeP + "/active/median_price/" + geoKey
  return link
}

function loadCountyMap() {
  if (countyMap == null) {
    let map = {}
    let linkMap = {}
    let entries = Object.entries(counties)
    for (let key in Object.keys(entries)) {
      let values = entries[key][1]
      values["county"] = values["county"].replace('-', ' ')
      map[values["county_id"]] = values
      
      // linkMap (used for SEO)
      let state = values["state"]
      if (linkMap[state] == undefined) {
        linkMap[state] = []
      }
      let link = convertToLink("county", values, values["county_id"], values["state"])
      linkMap[state].push(link)
    }

    countyLinkMap = {}

    for (let s in linkMap) {
      let stateCounties = linkMap[s]
      stateCounties.sort()
      countyLinkMap[s] = stateCounties
    }


    return map
  } else {
    return countyMap
  }
}

function loadFipsMap() {
  if (fipsMap == null) {
    let map = {}
    let entries = Object.entries(counties)
    for (let key in Object.keys(entries)) {
      let values = entries[key][1]
      values["county"] = values["county"].replace('-', ' ')
      map[values["fips"]] = values
    }
    return map
  } else {
    return fipsMap
  }
}

export function shouldHideHeader(index) {
  let hideHeader = index !== 0
  if (hideHeader) {
    hideHeader = (isMobile() ? false : hideHeader)
  }
  return hideHeader
}

export function isMobile() {
    return window.innerWidth <= 768;
}

export const CATEGORY_SUBSET_MAP = {
  "active": {
    "all": "All",
    "bed_0": "0 Bed",
    "bed_1": "1 Bed",
    "bed_2": "2 Bed",
    "bed_3": "3 Bed",
    "bed_4": "4 Bed",
    "bed_5+": "5+ Bed",
    "bath_1": "1 Bath",
    "bath_2": "2 Bath",
    "bath_3": "3 Bath",
    "bath_4+": "4+ Bath",
    "sqft_<=1000": "< 1000 sqft",
    "sqft_>1000": "> 1000 sqft",
    "sqft_>1500": "> 1500 sqft",
    "sqft_>2000": "> 2000 sqft",
    "sqft_>2500": "> 2500 sqft",
    "sqft_>=3000": "> 3000 sqft",
  },
  "sold": {
    "all": "All",
    "bed_0": "0 Bed",
    "bed_1": "1 Bed",
    "bed_2": "2 Bed",
    "bed_3": "3 Bed",
    "bed_4": "4 Bed",
    "bed_5+": "5+ Bed",
    "bath_1": "1 Bath",
    "bath_2": "2 Bath",
    "bath_3": "3 Bath",
    "bath_4+": "4+ Bath",
    "sqft_<=1000": "< 1000 sqft",
    "sqft_>1000": "> 1000 sqft",
    "sqft_>1500": "> 1500 sqft",
    "sqft_>2000": "> 2000 sqft",
    "sqft_>2500": "> 2500 sqft",
    "sqft_>=3000": "> 3000 sqft",
  },
    "short_term_rental": {
      "all": "All",
      "bedrooms_0": "0 Bedrooms",
      "bedrooms_1": "1 Bedrooms",
      "bedrooms_2": "2 Bedrooms",
      "bedrooms_3": "3 Bedrooms",
      "bedrooms_4": "4 Bedrooms",
      "bedrooms_5": "5 Bedrooms",
      "bedrooms_6+": "6+ Bedrooms",
      "bed_0": "0 Bed",
      "bed_1": "1 Bed",
      "bed_2": "2 Bed",
      "bed_3": "3 Bed",
      "bed_4": "4 Bed",
      "bed_5": "5 Bed",
      "bed_6": "6 Bed",
      "bed_7": "7 Bed",
      "bed_8": "8 Bed",
      "sleeps_2": "Sleeps 2",
      "sleeps_3": "Sleeps 3",
      "sleeps_4": "Sleeps 4",
      "sleeps_5": "Sleeps 5",
      "sleeps_6": "Sleeps 6",
      "sleeps_7": "Sleeps 7",
      "sleeps_8": "Sleeps 8",
      "sleeps_9": "Sleeps 9",
      "sleeps_10": "Sleeps 10",
      "sleeps_11": "Sleeps 11",
      "sleeps_12+": "Sleeps 12+",
      "hot_tub": "Hot Tub",
      "pool": "Pool",
      "pets_allowed": "Pets Allowed",
    },
    "land": {
      "all": "All",
      "acres_1": "< 1 acre",
      "acres_3": "< 3 acres",
      "acres_5": "< 5 acres",
      "acres_10": "> 10 acres",
    },
    "rent": {
      "all": "All",
      "bed_0": "0 Bed",
      "bed_1": "1 Bed",
      "bed_2": "2 Bed",
      "bed_3": "3 Bed",
      "bed_4": "4 Bed",
      "bed_5+": "5+ Bed",
      "bath_1": "1 Bath",
      "bath_2": "2 Bath",
      "bath_3": "3 Bath",
      "bath_4+": "4+ Bath",
      "sqft_<=1000": "< 1000 sqft",
      "sqft_>1000": "> 1000 sqft",
      "sqft_>1500": "> 1500 sqft",
      "sqft_>2000": "> 2000 sqft",
      "sqft_>2500": "> 2500 sqft",
      "sqft_>=3000": "> 3000 sqft",
    }

  }

export const NATIONAL_COUNTIES_LIST = {
  "median_price": "Y",
  "avg_price": "Y",
  "sold_median_price": "Y",
  "all": "Y",
  "past_trend": "Y",
  "price_per_sqft": "Y",
  "avg_days_on_market": "Y",
  "median_days_on_market": "Y",
  "sold_median_days_on_market": "Y",
  "avg_rent": "Y",
  "median_rent": "Y",
  "rent_index": "Y",
  "listings_per_pop": "Y",
  "num_listings": "Y",
  "sold_listings_per_pop": "Y",
  "num_sales": "Y",
  "price_drops_per_pop": "Y",
  "num_price_drops": "Y",
  "avg_price_drop": "Y",
  "str_or": "Y",
  "est_rev": "Y",
  "cost_of_living": "Y",
  "violent_crime": "Y",
  "property_crime": "Y",
  "climate_risk_factors": "Y",
  "climate_fire": "Y",
  "climate_drought": "Y",
  "climate_heat": "Y",
  "climate_flood": "Y",
  "climate_storm": "Y",
  "sunny_days": "Y",
  "snow": "Y",
  "rain": "Y",
  "elevation": "Y",
  "land_avg_acres": "Y",
  "land_price_per_acre": "Y",
  "land_median_price": "Y",
  "land_avg_price": "Y",
  "land_num_listings": "Y",
  "land_listings_per_pop": "Y",
  "land_avg_price_drop": "Y",
  "land_num_price_drops": "Y",
  "land_price_drops_per_pop": "Y",
  "land_avg_price_change": "Y",
  "land_num_price_changes": "Y",
}

export const ALL_STATES_LIST = {
  "median_price": "Y",
  "avg_price": "Y",
  "sold_median_price": "Y",
  "all": "Y",
  "past_trend": "Y",
  "price_per_sqft": "Y",
  "avg_days_on_market": "Y",
  "median_days_on_market": "Y",
  "sold_median_days_on_market": "Y",
  "avg_rent": "Y",
  "median_rent": "Y",
  "listings_per_pop": "Y",
  "num_listings": "Y",
  "sold_listings_per_pop": "Y",
  "num_sales": "Y",
  "price_drops_per_pop": "Y",
  "num_price_drops": "Y",
  "avg_price_drop": "Y",
  "str_or": "Y",
  "est_rev": "Y",
  "lgbtq": "Y",
  "abortion": "Y",
  "weed": "Y"
}

export const SOLO_STATE_LIST = {
  "median_price": "Y",
  "avg_price": "Y",
  "sold_median_price": "Y",
  "all": "Y",
  "past_trend": "Y",
  "price_per_sqft": "Y",
  "avg_days_on_market": "Y",
  "median_days_on_market": "Y",
  "sold_median_days_on_market": "Y",
  "avg_rent": "Y",
  "median_rent": "Y",
  "rent_index": "Y",
  "listings_per_pop": "Y",
  "num_listings": "Y",
  "sold_listings_per_pop": "Y",
  "num_sales": "Y",
  "price_drops_per_pop": "Y",
  "num_price_drops": "Y",
  "avg_price_drop": "Y",
  "str_or": "Y",
  "est_rev": "Y",
  "cost_of_living": "Y",
  "violent_crime": "Y",
  "property_crime": "Y",
  "climate_risk_factors": "Y",
  "climate_fire": "Y",
  "climate_drought": "Y",
  "climate_heat": "Y",
  "climate_flood": "Y",
  "climate_storm": "Y",
  "sunny_days": "Y",
  "snow": "Y",
  "rain": "Y",
  "elevation": "Y",
  "land_avg_acres": "Y",
  "land_price_per_acre": "Y",
  "land_median_price": "Y",
  "land_avg_price": "Y",
  "land_num_listings": "Y",
  "land_listings_per_pop": "Y",
  "land_avg_price_drop": "Y",
  "land_num_price_drops": "Y",
  "land_price_drops_per_pop": "Y",
}

export const PAYWALLED = {
  "sold_median_price": "landlord",
  "sold_median_days_on_market": "landlord",
  "sold_listings_per_pop": "landlord",
  "num_sales": "landlord",
  "str_or": "landlord",
  "est_rev": "landlord"
}

export const PAYWALLED_CATEGORIES = {
  "sold": "landlord",
  "short_term_rental": "landlord",
}

export const CITY_LIST = {
  "median_price": "Y",
  "avg_price": "Y",
  "sold_median_price": "Y",
  "all": "Y",
  "past_trend": "Y",
  "price_per_sqft": "Y",
  "avg_days_on_market": "Y",
  "median_days_on_market": "Y",
  "sold_median_days_on_market": "Y",
  "listings_per_pop": "Y",
  "num_listings": "Y",
  "sold_listings_per_pop": "Y",
  "num_sales": "Y",
  "price_drops_per_pop": "Y",
  "num_price_drops": "Y",
  "avg_price_drop": "Y",
}

export const RANKINGS_LIST = {
  "median_price": "Y",
  "avg_price": "Y",
  "sold_median_price": "Y",
  "all": "Y",
  "past_trend": "Y",
  "price_per_sqft": "Y",
  "avg_days_on_market": "Y",
  "median_days_on_market": "Y",
  "sold_median_days_on_market": "Y",
  "avg_rent": "Y",
  "median_rent": "Y",
  "rent_index": "Y",
  "listings_per_pop": "Y",
  "num_listings": "Y",
  "sold_listings_per_pop": "Y",
  "num_sales": "Y",
  "price_drops_per_pop": "Y",
  "num_price_drops": "Y",
  "avg_price_drop": "Y",
  "str_or": "Y",
  "est_rev": "Y",
  "climate_v2_total": "Y",
  "climate_v2_heat": "Y",
  "climate_v2_drought": "Y",
  "climate_v2_fire": "Y",
  "climate_v2_flood": "Y",
  "climate_v2_storm": "Y",
  "land_avg_acres": "Y",
  "land_price_per_acre": "Y",
  "land_median_price": "Y",
  "land_avg_price": "Y",
  "land_num_listings": "Y",
  "land_listings_per_pop": "Y",
  "land_avg_price_drop": "Y",
  "land_num_price_drops": "Y",
  "land_price_drops_per_pop": "Y",
}

export const CATEGORY_SUBTYPES = {
  "active": [
    "median_price", "avg_price", "all", "price_per_sqft", "avg_days_on_market", "median_days_on_market", "listings_per_pop", "num_listings",
    "price_drops_per_pop", "num_price_drops", "avg_price_drop"
  ],
  "sold": [
    "sold_median_price", "sold_median_days_on_market", "sold_listings_per_pop", "num_sales"
  ],
  "past": [
    "median_price", "past_trend", "avg_days_on_market", "avg_price_drop", "listings_per_pop", "price_drops_per_pop", "num_listings", "avg_price_change"
  ],
  "short_term_rental": [
    "str_or", "est_rev"
  ],
  "land": [
    "land_price_per_acre", "land_avg_acres", "land_avg_price", "land_median_price", "land_num_listings", "land_listings_per_pop",
    "land_num_price_drops", "land_price_drops_per_pop"
  ],
  "rent":[
    "avg_rent", "median_rent", "rent_index"
  ],
  "climate":[
    "climate_risk_factors", "climate_fire", "climate_drought", "climate_heat", "climate_storm", "climate_flood", "sunny_days", "snow", "rain", "elevation"
  ],
  "community": [
    "cost_of_living", "violent_crime", "property_crime", "lgbtq", "abortion", "weed"
  ]
}

export const GEO_MAP_TYPES = {
  "national": "national",
  "state": "state"
}

export const GEO_MODE_TYPES = {
  "county": "county",
  "city": "city"
}

export const MAP_MODES_TO_LIST = {
  "rankings": RANKINGS_LIST,
  "city": CITY_LIST,
  "solo_state": SOLO_STATE_LIST,
  "states": ALL_STATES_LIST,
  "national": NATIONAL_COUNTIES_LIST
}

export const CATEGORIES_PER_TYPE = {
  "national": ["active", "sold", "past", "land", "short_term_rental", "rent", "climate", "community"],
  "states": [ "active", "sold", "short_term_rental", "rent", "community"],
  "solo_state": [ "active", "sold", "past", "land", "short_term_rental", "rent", "climate", "community"],
  "city": ["active", "sold", "past"],
  "rankings": ["active", "sold", "past", "land", "short_term_rental", "rent"]
}

export const MAP_TYPES = {
    all: "all",
    avg_days_on_market: "avg_days_on_market",
    median_days_on_market: "median_days_on_market",
    avg_price: "avg_price",
    median_price: "median_price",
    num_listings: "num_listings",
    num_sales: "num_sales",
    num_price_changes: "num_price_changes",
    str_or: "str_or",
    est_rev: "est_rev",
    num_price_drops: "num_price_drops",
    avg_price_drop: "avg_price_drop",
    listings_per_pop: "listings_per_pop",
    price_drops_per_pop: "price_drops_per_pop",
    sold_listings_per_pop: "sold_listings_per_pop",
    climate_risk_factors: "climate_risk_factors",
    avg_rent: "avg_rent",
    median_rent: "median_rent",
    avg_price_change: "avg_price_change",
    past_trend: "past_trend",
    cost_of_living: "cost_of_living",
    sunny_days: "sunny_days",
    snow: "snow",
    rain: "rain",
    violent_crime: "violent_crime",
    property_crime: "property_crime",
    elevation: "elevation",
    sold_median_price: "sold_median_price",
    sold_median_days_on_market: "sold_median_days_on_market",
    price_per_sqft: "price_per_sqft"
}

export const TABLES = {
    county_prop_sums_total: "county_prop_sums_total",
    county_sold_sums_total: "county_sold_sums_total",
    str_county_booking_summary: "str_county_booking_summary"}

export const BENCHMARK_TYPES = {
    benchmark_active: "benchmark_active",
    benchmark_sold: "benchmark_sold",
    benchmark_str: "benchmark_str",
    benchmark_rent: "benchmark_rent",
    benchmark_climate: "benchmark_climate",
    state_benchmark_active: "state_benchmark_active",
    state_benchmark_sold: "state_benchmark_sold",
    state_benchmark_str: "state_benchmark_str"
}

export function getBenchmarkType(mapType) {
    switch (mapType) {
        case SCORES_TYPES.sold_listings_per_pop_score:
        case SCORES_TYPES.num_sales_score:
            return BENCHMARK_TYPES.benchmark_sold
        case SCORES_TYPES.est_rev_score:
        case SCORES_TYPES.str_or_score:
            return BENCHMARK_TYPES.benchmark_str
        default:
            return BENCHMARK_TYPES.benchmark_active
    }
}

export function numberWithCommas(x) {
    if (x !== undefined) {
        if (x == "0" || x == "0.0" || x == 0 || x == 0.0) {
          return "-"
        } else {
          let xFloat = parseFloat(x).toFixed(2);
          return xFloat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}

export function grabPopulation(popMap, fips) {
  if (fips !== undefined && popMap !== undefined) {
    
    if (popMap[fips] !== undefined) { 
      return popMap[fips]; 
    }
    else {
      if (fips.indexOf('0') === 0) {
        fips = fips.substring(1, fips.length)
        return popMap[fips]
      }
    }
  }
}

export function getAttrLabelSold(attr) {
  let attrLabels = {
    "avg_price": "Avg Price",
    "median_price": "Median Price",
    "median_sold_price": "Median Sold Price",
    "avg_days_on_market": "Avg Days on Market",
    "median_days_on_market": "Median Days on Market",
    "num_listings": "Sold Listings",
    "num_sold": "Sold Listings",
    "listings_per_pop": "Sold Listings (per 100k)",
    "sold_listings_per_pop": "Sold Listings (per 100k)",
    "num_price_drops": "Price Drops",
    "avg_price_drop": "Avg Price Drop",
    "price_drops_per_pop": "Price Drops (per 100k)",
    "occupancy_rate": "Occupancy Rate (% booked)",
    "avg_price_per_night": "Avg Price Per Night",
    "est_revenue": "Est. Yearly Revenue (based on Occupancy Rate)",
    "possible_rev": "Maximum Yearly Revenue (booked 100%)",
    "winter_score": "Winter Score",
    "summer_score": "Summer Score",
    "snowfall": "Annual Snowfall",
    "rainfall": "Annual Rain",
    "avg_winter_low": "Winter Low",
    "avg_summer_high": "Summer High",
    "precipitation_days": "Rainy/Snowy Days",
    "sunny_days": "Sunny Days",
    "uv_index": "UV Index",
    "elevation": "Elevation",
    "income_tax": "Income Tax",
    "sales_taxes": "Sales Tax",
    "household_income": "Median Household Income",
    "income_per_cap": "Income per capita",
    "recent_job_growth": "Recent Job Growth (2021)",
    "future_job_growth": "Future Job Growth",
    "family_median_income": "Median Family Income",
    "unemployment_rate": "Unemployment rate (2021)",
    "price_per_sqft": "Price per Sqft"
  }
  return attrLabels[attr]
}

export function getAttrLabelShort(attr) {
  let attrLabels = {
    "median_price": "Median Price",
    "num_listings": "Listings",
    "num_price_drops": "Price Drops",
  }
  return attrLabels[attr]
}

export function getAttrLabel(attr) {
  let attrLabels = {
    "avg_price": "Avg Price",
    "median_price": "Median Price",
    "median_sold_price": "Median Sold Price",
    "avg_days_on_market": "Avg Days on Market",
    "median_days_on_market": "Median Days on Market",
    "num_listings": "Active Listings",
    "num_sold": "Sold Listings",
    "listings_per_pop": "Active Listings (per 100k)",
    "sold_listings_per_pop": "Sold Listings (per 100k)",
    "num_price_drops": "Price Drops",
    "price_drops_per_pop": "Price Drops (per 100k)",
    "avg_price_drop": "Avg Price Drop",
    "occupancy_rate": "Occupancy Rate (% booked)",
    "avg_price_per_night": "Avg Price Per Night",
    "est_revenue": "Est. Yearly Revenue (based on Occupancy Rate)",
    "possible_rev": "Maximum Yearly Revenue (100% booked)",
    "winter_score": "Winter Score",
    "summer_score": "Summer Score",
    "snowfall": "Annual Snowfall",
    "rainfall": "Annual Rain",
    "avg_winter_low": "Winter Low",
    "avg_summer_high": "Summer High",
    "precipitation_days": "Rainy/Snowy Days",
    "sunny_days": "Sunny Days",
    "uv_index": "UV Index",
    "elevation": "Elevation",
    "income_tax": "Income Tax",
    "sales_taxes": "Sales Tax",
    "household_income": "Median Household Income",
    "income_per_cap": "Income per capita",
    "recent_job_growth": "Recent Job Growth (2021)",
    "future_job_growth": "Future Job Growth",
    "family_median_income": "Median Family Income",
    "unemployment_rate": "Unemployment rate (2021)",
    "price_per_sqft": "Price per Sqft",
    "land_median_price": "Median Price",
    "land_avg_price": "Avg Price",
    "land_avg_price_drop": "Avg Price Drop",
    "land_num_price_drops": "Price Drops",
    "land_price_drops_per_pop": "Price Drops (per 100k)",
    "land_avg_acres": "Avg Acres per Listing",
    "land_price_per_acre": "Price per Acre",
    "land_num_listings": "Land Listings",
    "land_listings_per_pop": "Land Listings (per 100k)"
  }
  return attrLabels[attr]
}

export function getAttrDecoration(attr) {
  let prefixLabels = {
    "all": "",
    "avg_price": "$",
    "median_price": "$",
    "sold_median_price": "$",
    "avg_days_on_market": "",
    "median_days_on_market": "",
    "num_listings": "",
    "num_sold": "",
    "listings_per_pop": "",
    "sold_listings_per_pop": "",
    "num_price_drops": "",
    "price_drops_per_pop": "",
    "avg_price_drop": "$",
    "occupancy_rate": "",
    "avg_price_per_night": "$",
    "est_revenue": "$",
    "possible_rev": "$",
    "winter_score": "",
    "summer_score": "",
    "snowfall": "",
    "rainfall": "",
    "avg_winter_low": "",
    "avg_summer_high": "",
    "precipitation_days": "",
    "sunny_days": "",
    "uv_index": "",
    "elevation": "",
    "price_per_sqft": "$"
  }
  let response = prefixLabels[attr]
  if (response == undefined) {
    return ""
  } else {
    return response
  }
}

export function replaceHyphens(x) {
    if (x !== undefined) {
        return x.toString().replaceAll('-', " ")
    }
}

export const WEED_MAP_COLORS = [
  "#808080",
  "#0eff00",
  "#089000",
  "#063b00"
  ]

  export const ABORTION_MAP_COLORS = [
    "#000000",
    "#ffc2cd",
    "#ff93ac",
    "#ff6289",
    "#fc3468",
    "#ff084a"
    ]

export const LGBTQ_MAP_COLORS = [
  "#ffc2cd",
  "#ff93ac",
  "#ff6289",
  "#fc3468",
  "#ff084a"
  ]

export const COLORBLIND_INVERSE_MAP_COLORS = [
  '#7b0000',
  '#b10000',
  "#7b0000",
  '#FF9000',
  "#FF0000",
  '#FF0500',
  '#FF9A98',
  '#80c3d8',
  "#83aff0",
  "#4779c4",
  "#2c456b",
  "#00008B",
  ]

export const COLORBLIND_MAP_COLORS = [
  "#00008B",
  "#2c456b",
  "#4779c4",
  "#83aff0",
  '#80c3d8',
  '#FF9A98',
  '#FF0500',
  "#FF0000",
  '#FF9000',
  "#7b0000",
  '#b10000',
  '#7b0000'
  ]

  export const TWO_TONE_MAP_COLORS = [
    "#a93245",
    "#c0656c",
    "#d49396",
    "#e4c2c2",
    "#f1f1f1",
    "#c4d3e2",
    "#97b6d3",
    "#659ac5",
    "#1b7fb6"
  ]

  export const TWO_TONE_INVERSE_MAP_COLORS = [
    "#1b7fb6",
    "#659ac5",
    "#97b6d3",
    "#c4d3e2",
    "#f1f1f1",
    "#e4c2c2",
    "#d49396",
    "#c0656c",
    "#a93245",
  ]

  export const GAY_MAP_COLORS = [
    "#c73b52",
    "#d76d77",
    "#e49a9d",
    "#edc5c6",
    "#f1f1f1",
    "#c9bfe4",
    "#9f8fd6",
    "#7162c7",
    "#3537b8",
  ]

  export const GAY_INVERSE_MAP_COLORS = [
    "#002539",
    "#7162c7",
    "#9f8fd6",
    "#c9bfe4",
    "#f1f1f1",
    "#edc5c6",
    "#e49a9d",
    "#d76d77",
    "#c73b52",
  ]

  export const SINGLE_MAP_COLORS = [
    "#b7e8ff",
    "#a1d3ec",
    "#8cbed9",
    "#77aac6",
    "#6396b3",
    "#4e83a1",
    "#397090",
    "#225e7e",
    "#002539"
  ]

  export const SINGLE_INVERSE_MAP_COLORS = [
    "#004c6d",
    "#225e7e",
    "#397090",
    "#4e83a1",
    "#6396b3",
    "#77aac6",
    "#8cbed9",
    "#a1d3ec",
    "#b7e8ff"
  ]

  export const CYBERPUNK_MAP_COLORS = [
    "#ffd700",
    "#ffb14e",
    "#fa8775",
    "#ea5f94",
    "#cd34b5",
    "#9d02d7",
    "#0000ff"
  ]

  export const CYBERPUNK_INVERSE_MAP_COLORS = [
    "#0000ff",
    "#9d02d7",
    "#cd34b5",
    "#ea5f94",
    "#fa8775",
    "#ffb14e",
    "#ffd700",
  ]
/*
export const MAP_COLORS = [
    "#BF0000",
    "#FF0000",
    "#FF5000",
    "#FF7000",
    "#FF8000",
    "#D0FF00",
    "#B0FF00",
    "#10FF00",
    "#2FBE2F",
    "#228B22",
  ]
export const INVERSE_MAP_COLORS = [
  "#228B22",
  "#2FBE2F",
  "#10FF00",
  "#B0FF00",
  "#D0FF00",
  "#FF8000",
  "#FF7000",
  "#FF5000",
  "#FF0000",
  "#BF0000",
  ]*/

export const HEATMAP_MAP_COLORS = [
  "#BF0000",
  "#FF0000",
  "#FF5000",
  "#FF7000",
  "#FF8000",
  "#FF9000",
  "#FFA000",
  "#FFB000",
  "#FFC000",
  "#FFD000",
  "#FFF000",
  "#FFFF00",
  "#F0FF00",
  "#D0FF00",
  "#B0FF00",
  "#10FF00",
  "#2FBE2F",
  "#228B22",
    ]

export const HEATMAP_INVERSE_MAP_COLORS = [
  "#228B22",
  "#2FBE2F",
  "#10FF00",
  "#B0FF00",
  "#D0FF00",
  "#F0FF00",
  "#FFFF00",
  "#FFF000",
  "#FFD000",
  "#FFC000",
  "#FFB000",
  "#FFA000",
  "#FF9000",
  "#FF8000",
  "#FF7000",
  "#FF5000",
  "#FF0000",
  "#BF0000"
    ]

  export const ASC = "asc"
  export const DESC = "desc"

  export const SORT_ORDER =  
    {"all": DESC,
     "past_trend":DESC,
    "avg_price": ASC,
    "median_price": ASC,
    "sold_median_price": ASC ,
    "price_per_sqft": ASC ,
    "avg_days_on_market": DESC,
    "median_days_on_market": DESC,
    "sold_median_days_on_market": DESC,
    "avg_rent": ASC,
    "median_rent": ASC,
    "rent_index": ASC,
    "listings_per_pop": DESC,
    "num_listings": DESC ,
    "sold_listings_per_pop": DESC,
    "num_sales": DESC,
    "price_drops_per_pop": DESC ,
    "num_price_drops": DESC ,
    "avg_price_drop": DESC ,
    "str_or": DESC ,
    "est_rev": DESC,
    "climate_v2_flood": ASC,
    "climate_v2_fire": ASC,
    "climate_v2_drought": ASC,
    "climate_v2_heat": ASC,
    "climate_v2_storm": ASC,
    "climate_v2_total": ASC,
    "land_avg_acres": DESC,
    "land_price_per_acre": ASC,
    "land_median_price": ASC,
    "land_avg_price": ASC,
    "land_num_listings": DESC,
    "land_listings_per_pop": DESC,
    "land_avg_price_drop": DESC,
    "land_num_price_drops": DESC,
    "land_price_drops_per_pop": DESC,
    }

  export function determineSortOrder(mapType) {
    let sort = SORT_ORDER[mapType]
    return sort
  }

  export function sortOrderOpposite(mapType) {
    let sortOrder = SORT_ORDER[mapType]
    if (sortOrder == ASC) {
      return DESC
    } else if (sortOrder == DESC) {
      return ASC
    } else {
      return None
    }
  }

  export const RENT_COLS = {
    "avg_price_score": "avg_price_score",
    "median_price_score": "median_price_score",
    "num_listings_score": "num_listings_score",
    "date": "date",
    "type": "type"
  }

  export const STR_COLS = {
    "possible_rev_3m": "possible_rev_3m",
    "num_listings_3m": "num_listings_3m",
    "est_revenue_3m": "est_revenue_3m",
    "occupancy_rate_3m": "occupancy_rate_3m",
    "avg_price_per_night_3m": "avg_price_per_night_3m",
    "occupancy_rate_12m": "occupancy_rate_12m",
    "num_listings_12m": "num_listings_12m",
    "avg_price_per_night_12m": "avg_price_per_night_12m",
    "possible_rev_12m": "possible_rev_12m",
    "est_revenue_12m": "est_revenue_12m",
    "filter_by": "filter_by",
    "date": "date",
    "type": "type"
  }

  export const LAND_COLS = {
    "land_avg_acres": "land_avg_acres",
    "land_price_per_acre": "land_price_per_acre",
    "land_median_price": "land_median_price",
    "land_avg_price": "land_avg_price",
    "land_num_listings": "land_num_listings",
    "land_listings_per_pop": "land_listings_per_pop",
    "land_avg_price_drop": "land_avg_price_drop",
    "land_num_price_drops": "land_num_price_drops",
    "land_price_drops_per_pop": "land_price_drops_per_pop",
    "land_avg_price_change": "land_avg_price_change",
    "land_num_price_changes": "land_num_price_changes",
    "filter_by": "filter_by",
    "date": "date",
    "type": "type"
  }

  export const SCORES_MAP = {
    "all": "all_score",
    "avg_days_on_market": "avg_days_on_market_score",
    "median_days_on_market": "median_days_on_market_score",
    "avg_price": "avg_price_score",
    "median_price": "median_price_score",
    "price_per_sqft": "price_per_sqft_score",
    "num_listings": "num_listings_score",
    "num_sales": "num_sales_score",
    "num_price_changes": "num_price_changes_score",
    "str_or": "str_or_score",
    "est_rev": "est_rev_score",
    "num_price_drops": "num_price_drops_score",
    "avg_price_drop": "avg_price_drop_score",
    "listings_per_pop": "listings_per_pop_score",
    "price_drops_per_pop": "price_drops_per_pop_score",
    "sold_listings_per_pop": "sold_listings_per_pop_score",
    "rent_index": "rent_index_score",
    "avg_rent": "avg_rent_score",
    "median_rent": "median_rent_score",
    "climate_risk_factors": "climate_risk_factors_score",
    "climate_heat": "climate_risk_factors_score",
    "climate_drought": "climate_risk_factors_score",
    "climate_fire": "climate_risk_factors_score",
    "climate_storm": "climate_risk_factors_score",
    "climate_flood": "climate_risk_factors_score",
    "avg_price_change": "avg_price_change_score",
    "past_trend": "past_trend_score",
    "past_diff": "past_diff_score",
    "state": "state_scores",
    "sold_median_price": "sold_median_price_score",
    "sold_median_days_on_market": "sold_median_days_on_market_score",
    "cost_of_living": "cost_of_living_score",
    "sunny_days": "weather_score",
    "snow": "weather_score",
    "rain": "weather_score",
    "elevation": "weather_score",
    "violent_crime": "crime_score",
    "property_crime": "crime_score",
    "est_monthly_payment": "est_monthly_payment",
    "lgbtq": "lgbtq_score",
    "abortion": "abortion_score",
    "weed": "weed_score",
    "land_avg_acres": "land_avg_acres_score",
    "land_price_per_acre": "land_price_per_acre_score",
    "land_median_price": "land_median_price_score",
    "land_avg_price": "land_avg_price_score",
    "land_num_listings": "land_num_listings_score",
    "land_listings_per_pop": "land_listings_per_pop_score",
    "land_avg_price_drop": "land_avg_price_drop_score",
    "land_num_price_drops": "land_num_price_drops_score",
    "land_price_drops_per_pop": "land_price_drops_per_pop_score",
    "land_avg_price_change": "land_avg_price_change_score",
    "land_num_price_changes": "land_num_price_changes_score",
  }

  export const STATE_SCORES_ORDER = ["all", "avg_price", "median_price", "avg_days_on_market", "median_days_on_market", "num_price_changes",
  "avg_price_change", "num_sales", "num_listings", "str_or", "est_rev", "num_price_drops", "avg_price_drop",
  "price_drops_per_pop", "listings_per_pop", "sold_listings_per_pop", "avg_rent", "median_rent", "climate_risk_factors",
  "past_trend"]

  export const SCORES_TYPES = {
    all_score: "all_score",
    avg_days_on_market_score: "avg_days_on_market_score",
    median_days_on_market_score: "median_days_on_market_score",
    avg_price_score: "avg_price_score",
    median_price_score: "median_price_score",
    num_listings_score: "num_listings_score",
    num_sales_score: "num_sales_score",
    num_price_changes_score: "num_price_changes_score",
    str_or_score: "str_or_score",
    est_rev_score: "est_rev_score",
    num_price_drops_score: "num_price_drops_score",
    avg_price_drop_score: "avg_price_drop_score",
    listings_per_pop_score: "listings_per_pop_score",
    price_drops_per_pop_score: "price_drops_per_pop_score",
    sold_listings_per_pop_score: "sold_listings_per_pop_score",
    avg_rent_score: "avg_rent_score",
    median_rent_score: "median_rent_score",
    climate_risk_factors_score: "climate_risk_factors_score",
    state_scores: "state_scores",
    avg_price_change_score: "avg_price_change_score",
    past_trend_score: "past_trend_score",
    price_per_sqft: "price_per_sqft_score",
    median_sold_price: "median_sold_price_score",
    sold_median_days_on_market: "sold_median_days_on_market_score"
  }

export function formatDateWithoutDay (date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance')
}

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')

  return `${year}-${month}`
}

export function formatDate (date) {
    if (!(date instanceof Date)) {
        throw new Error('Invalid "date" argument. You must pass a date instance')
    }

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

function convertClimateType(mapType) {
  if (mapType == "climate_fire") {
    return "v2_fire"
  } else if (mapType == "climate_heat") {
  return "v2_heat"
  } else if (mapType == "climate_drought") {
    return "v2_drought"
  } else if (mapType == "climate_storm") {
    return "v2_storm"
  }else if (mapType == "climate_flood") {
    return "v2_flood"
  } else {
    return "v2_total"
  }
}

export function parseClimateDataV2Type(oldString, type) {
  if (oldString === undefined) {
      return undefined
  }
  let converted = convertClimateType(type)
  if (oldString !== "" && converted !== "")  {
    return parseInt(oldString[converted])
  }
}

export function parseClimateDataV2(oldString) {
  if (oldString === undefined) {
      return undefined
  }

  if (oldString !== "")  {
    return parseInt(oldString['v2_total'])
  }
}

export function parseClimateData(oldString) {
    if (oldString === undefined) {
        return undefined
    }
    let removeDecimal = oldString.replaceAll('Decimal(', '');
    let removeParen = removeDecimal.replaceAll(')', '');
    let fixed = removeParen.replaceAll(/'/g, '"');
    if (fixed !== "")  {
      return parseInt(obj['total'])
    }
}

export function removeScore(oldName) {
    return oldName.replace("_score", "")
}
export function convertToUserFacing(oldName) {
    if (oldName === MAP_TYPES.avg_price_change || oldName === SCORES_TYPES.avg_price_change_score) {
        return "Average Price Change of Active Listings"
    }
    if (oldName === MAP_TYPES.median_rent || oldName === SCORES_TYPES.median_rent_score) {
        return "Median Rent of Active Listings"
    }
    if (oldName === MAP_TYPES.avg_rent || oldName === SCORES_TYPES.avg_rent_score) {
        return "Average Rent of Active Listings"
    }
    if (oldName === MAP_TYPES.listings_per_pop || oldName === SCORES_TYPES.listings_per_pop_score) {
        return "Number of Listings per 100k (last 3 months)"
    }
    if (oldName === MAP_TYPES.climate_risk_factors || oldName === SCORES_TYPES.climate_risk_factors_score) {
        return "Climate Risk Factors"
    }
    if (oldName === MAP_TYPES.listings_per_pop || oldName === SCORES_TYPES.listings_per_pop_score) {
        return "Number of Listings per 100k (last 3 months)"
    }
    if (oldName === MAP_TYPES.price_drops_per_pop || oldName === SCORES_TYPES.price_drops_per_pop_score) {
        return "Number of Price Drops per 100k people (last 3 months)"
    }
    if (oldName === MAP_TYPES.sold_listings_per_pop || oldName === SCORES_TYPES.sold_listings_per_pop_score) {
        return "Number of Sold listings per 100k people (last 3 months)"
    }
    if (oldName === MAP_TYPES.price_per_sqft || oldName === SCORES_TYPES.price_per_sqft) {
      return "Price Per Sqft"
    }
    if (oldName === MAP_TYPES.sold_median_price || oldName === SCORES_TYPES.sold_median_price) {
      return "Sold Median Price"
    }
    if (oldName === MAP_TYPES.sold_median_days_on_market || oldName === SCORES_TYPES.sold_median_days_on_market) {
      return "Sold Median Days on Market"
    }
    if (oldName === SCORES_TYPES.avg_price_score) {
        return "Average Price of Active Listings"
    }
    if (oldName == SCORES_TYPES.median_price_score) {
        return "Median Price of Active Listings"
    }
    if (oldName == SCORES_TYPES.median_days_on_market_score) {
        return "Median Days on Market of Active Listings"
    }
    if (oldName == SCORES_TYPES.avg_days_on_market_score) {
        return "Average Days on Market of Active Listings"
    }
    if (oldName == SCORES_TYPES.all_score) {
        return "Housing Score of Active Listings"
    }
    if (oldName === MAP_TYPES.all) {
        return "Total Score"
    }
    if (oldName === MAP_TYPES.avg_days_on_market) {
        return "Average Days on Market"
    }
    if (oldName === MAP_TYPES.median_days_on_market) {
        return "Median Days on Market"
    }
    if (oldName === MAP_TYPES.avg_price) {
        return "Average Price"
    }
    if (oldName === MAP_TYPES.median_price) {
        return "Median Price"
    }
    if (oldName === MAP_TYPES.num_listings) {
        return "Number of Active Listings"
    }
    if (oldName === MAP_TYPES.num_sales) {
        return "Number of Sold Listings (last 3 months)"
    }
    if (oldName === MAP_TYPES.num_price_changes) {
        return "Number of Price Changes (last 3 months)"
    }
    if (oldName === MAP_TYPES.str_or) {
        return "Short Term Rental Occupancy Rates"
    }
    if (oldName === MAP_TYPES.est_rev) {
        return "Short Term Rental Annual Estimate Revenue"
    }
    if (oldName === MAP_TYPES.num_price_drops) {
        return "Number of Price Drops (last 3 months)"
    }
    if (oldName === MAP_TYPES.avg_price_drop) {
        return "Average Price Drop Amount (last 3 month)"
    }
    if (oldName === TABLES.county_prop_sums_total) {
        return "Active Listings"
    }
    if (oldName === TABLES.county_sold_sums_total) {
        return "Sold Listings (last 3 months)"
    }
    if (oldName === TABLES.str_county_booking_summary) {
        return "Airbnb Data"
    }
    if (oldName === BENCHMARK_TYPES.benchmark_active) {
        return "National Average (Active Listings)"
    }
    if (oldName === BENCHMARK_TYPES.benchmark_sold) {
        return "National Average (Sold Listings)"
    }
    if (oldName === BENCHMARK_TYPES.benchmark_str) {
        return "National Average (Short Term Listings)"
    }
    if (oldName === BENCHMARK_TYPES.state_benchmark_active) {
        return "State Average (Active Listings)"
    }
    if (oldName === BENCHMARK_TYPES.state_benchmark_sold) {
        return "State Average (Sold Listings)"
    }
    if (oldName === BENCHMARK_TYPES.state_benchmark_str) {
        return "State Average (Short Term Listings)"
    }
    if (oldName === MAP_TYPES.past_trend) {
      return "Past Total Score (Buyer's market)"
    }

    if (oldName === "rent_index") {
      return "Price to Rent Ratio"
    }

    if (oldName === SCORES_TYPES.rent_index) {
      return "Price to Rent Ratio"
    }

    if (oldName === "climate_v2_total") {
      return "Total Climate Risk (max 400)"
    }
    if (oldName === "climate_v2_fire") {
      return "Fire Climate Risk (max 100)"
    }
    if (oldName === "climate_v2_heat") {
      return "Heat Climate Risk (max 100)"
    }
    if (oldName === "climate_v2_drought") {
      return "Drought Climate Risk (max 100)"
    }
    if (oldName === "climate_v2_storm") {
      return "Storm Climate Risk (max 100)"
    }
    if (oldName === "climate_v2_flood") {
      return "Flood Climate Risk (max 100)"
    }

    if (oldName === "climate_risk_factors") {
      return "Total Climate Risk"
    }
    if (oldName === "climate_fire") {
      return "Fire Climate Risk"
    }
    if (oldName === "climate_heat") {
      return "Heat Climate Risk"
    }
    if (oldName === "climate_drought") {
      return "Drought Climate Risk"
    }
    if (oldName === "climate_storm") {
      return "Storm Climate Risk"
    }
    if (oldName === "climate_flood") {
      return "Flood Climate Risk"
    }
    if (oldName === "sunny_days") {
      return "Annual Sunny Days"
    }
    if (oldName === "snow") {
      return "Annual Snow"
    }
    if (oldName === "elevation") {
      return "Elevation"
    }
    if (oldName === "rain") {
      return "Annual Rainfall"
    }
    if (oldName == "cost_of_living") {
      return "Cost of Living"
    }
    if (oldName == "violent_crime") {
      return "Violent Crime Rate"
    }
    if (oldName == "property_crime") {
      return "Property Crime Rate"
    }
    if (oldName == "active") {
      return "Latest Housing Data"
    }
    if (oldName == "active") {
      return "Latest Housing Data"
    }
    if (oldName == "sold") {
      return "Sold"
    }
    if (oldName == "past") {
      return "Past"
    }
    if (oldName == "short_term_rental") {
      return "Short Term Rentals"
    }
    if (oldName == "rent") {
      return "Rent"
    }
    if (oldName == "climate") {
      return "Climate & Weather"
    }
    if (oldName == "community") {
      return "Community"
    }
    if (oldName == "weed") {
      return "Weed Access"
    }
    if (oldName == "abortion") {
      return "Abortion Access"
    }
    if (oldName == "lgbtq") {
      return "LGBTQ Friendliness"
    }
    if (oldName == "land") {
      return "Land"
    }
    if (oldName == "land_avg_acres") {
      return "Avg Acres"
    }

    if (oldName == "land_price_per_acre") {
      return "Price per Acre"
    }
    if (oldName == "land_avg_price_change") {
      return "Avg Price Change"
    }
    if (oldName == "land_avg_price_drop") {
      return "Avg Price Drop"
    }
    if (oldName == "land_avg_price") {
      return "Avg Price"
    }
    if (oldName == "land_median_price") {
      return "Median Price"
    }
    if (oldName == "land_num_listings") {
      return "Number of Listings"
    }
    if (oldName == "land_listings_per_pop") {
      return "Number of Listings (per 100k)"
    }
    if (oldName == "land_num_price_changes") {
      return "Number of Price Changes"
    }
    if (oldName == "land_num_price_drops") {
      return "Number of Price Drops"
    }
    if (oldName == "land_price_drops_per_pop") {
      return "Number of Price Drops (per 100k)"
    }
    if (oldName == "1m" || oldName == "3m" || oldName == "6m" || oldName == "12m") {
      let numMonths = oldName.substring(0, oldName.indexOf("m"))
      if (numMonths == "1") {
        return numMonths + " Month"
      }
      return numMonths + " Months"
    }
    if (oldName == "avg") {
      return "Average"
    }
    if (oldName == "county") {
      return "County"
    }
    if (oldName == "city") {
      return "City"
    }
}

export function generateRankingTitle(geoMode, mapCategory, mapType, numMonths) {
  let start = (geoMode === "county"? "Counties ": "Cities ")
  if (mapCategory == "past" && mapType !== "past_trend") {
    return start + " with the Biggest " + convertToUserFacing(numMonths) + " Change in "
  } else {
    return start + "with the Best "
  }
}

export function replaceUnderscores(x) {
  if (x !== undefined) {
    let replaced = x.toString().replace('_', " ")
    //if (replaced == "bath 0" || replaced == "bed 0") {
    //  replaced = "Land (" + replaced + ")" 
    //}
    return replaced
  }
}

export function getStateCoords(abbrev) {
    if (stateCoords[abbrev] === undefined) {
        return null
    }
    return stateCoords[abbrev]
}

export function findCoords(geography) {
    const coordsArray = geography.geometry.coordinates
    const abbrev = geography.properties.abbr
      const len0 = coordsArray.length
      const len1 = coordsArray[0].length
      const len2 = coordsArray[0][0].length
      const len3 = coordsArray[0][0][0].length

      const lengthArray = [len1, len2, len3]
      const arrays = [coordsArray[0], coordsArray[0][0], coordsArray[0][0][0]]
      let arrayToUse = []
      let currLongest = 0
      for (let i in lengthArray) {
        let length = lengthArray[i]
        if (length > currLongest) {
          arrayToUse = arrays[i]
          currLongest = length
        }
        i = i + 1
      }

      let xSum = 0
      let ySum = 0
      for (let i in arrayToUse) {
        let coords = arrayToUse[i]
        let currX = coords[0]
        let currY = coords[1]
        xSum += currX
        ySum += currY
      }

      let x = xSum / arrayToUse.length
      let y = ySum / arrayToUse.length
      let z = 4
      if (abbrev == "CA") {
        y = y + 5
      } else if (abbrev == "TX") {
        y = y + 2
      } else if (abbrev == "FL") {
        x = x - 3
        y = y + 2
      } else if (abbrev == "NY") {
        x = x - 3
      } else if (abbrev == "MT") {
        x = x + 1
      } else if (abbrev == "NV") {
        y = y + 1
      } else if (abbrev == "HI" || abbrev == "SD" || abbrev == "ND" || abbrev == "NE" || abbrev == "KS") {
        x = x - 2
      } else if (abbrev == "LA") {
        x = x  - 3
      } else if (abbrev == "MI" || abbrev == "OH") {
        y = y - 1
      } else if (abbrev == "MS") {
        y = y + 2
        x = x - 1
      } else if (abbrev == "AL") {
        y = y + 2
        x = x + 1
      } else if (abbrev == "NC" || abbrev == "VA") {
        x = x - 3
        y = y - 1
      } else if (abbrev == "WI") {
        x = x - 2
        y = y - 1
      }

      if (abbrev == "CA") {
        z = 2
      } else if (abbrev == "TX") {
        z = 3
      } else if (abbrev == "AR" || abbrev == "SC" || abbrev == "LA" || abbrev == "PA" || abbrev == "ME" || abbrev == "SC" || 
        abbrev == "WI" || abbrev == "NC" || abbrev == "VA" || abbrev == "AL" || abbrev == "MS" || abbrev == "OH" || abbrev == "MD") {
        z = 5
      } else if (abbrev == "VT" || abbrev == "NH" || abbrev == "MA" || abbrev == "NJ") {
        z = 6
      } else if (abbrev == "RI" || abbrev == "CT" || abbrev == "DE") {
        z = 8
      }

      const obj = { state: abbrev, zoom: z, x: x, y: y}
      return obj
  }

  export function shadeColor(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
  }

  export function isHexLight(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}

  export function getFontColor(curr, bgColor) {
    const score = curr.score
    const value = curr.value

    if (bgColor !== undefined) {
      if (isHexLight(bgColor)) {
        return "#1e1e1e"
      } else {
        return "white"
      }
    }

    if (score !== undefined && value !== undefined) {
      return "#1e1e1e"
    } else {
      return "white"
    }
  }

  export function getColorMapsToUse(appCtx) {
    let mapToUse = HEATMAP_MAP_COLORS
    let invertedMapToUse = HEATMAP_INVERSE_MAP_COLORS

    if (appCtx.colors == "heatmap") {
      mapToUse = HEATMAP_MAP_COLORS
      invertedMapToUse = HEATMAP_INVERSE_MAP_COLORS
    } else if (appCtx.colors == "colorblind") {
      mapToUse = COLORBLIND_MAP_COLORS
      invertedMapToUse = COLORBLIND_INVERSE_MAP_COLORS
    } else if (appCtx.colors == "gay") {
      mapToUse = GAY_MAP_COLORS
      invertedMapToUse = GAY_INVERSE_MAP_COLORS
    } else if (appCtx.colors == "two-tone") {
      mapToUse = TWO_TONE_MAP_COLORS
      invertedMapToUse = TWO_TONE_INVERSE_MAP_COLORS
    } else if (appCtx.colors == "one-tone") {
      mapToUse = SINGLE_MAP_COLORS
      invertedMapToUse = SINGLE_INVERSE_MAP_COLORS
    } else if (appCtx.colors == "cyberpunk") {
      mapToUse = CYBERPUNK_MAP_COLORS
      invertedMapToUse = CYBERPUNK_INVERSE_MAP_COLORS
    }

    return { "normal": mapToUse, "inverse": invertedMapToUse }
  }

  export function getAllColorScale(appCtx, upper, bottom, inverted) {

    let maps = getColorMapsToUse(appCtx)

    let useMap = maps["normal"]

    if (inverted) {
      useMap = maps["inverse"]
    }

    return scaleQuantile()
      .domain([bottom, upper])
      .range(useMap)
  }

  export function getColorScale(curr, mapType, appCtx, geoModeTab, mapCategory) {
    let score = curr.score
    let value = curr.value

    let colorMaps = getColorMapsToUse(appCtx)

    let mapToUse = colorMaps["normal"]
    let invertedMapToUse = colorMaps["inverse"]

    let careAboutScore = (mapType == undefined || mapType == "all" || mapType == "cost_of_living" || mapType == "crime" || mapType == "sunny_days" 
    || mapType == "snow" || mapType == "elevation" || mapType == "rain" || mapType == "property_crime" || mapType == "violent_crime")

    if (careAboutScore && score == 0 || careAboutScore && score == undefined) {
      return "#D3D3D3"
    }

    if (!careAboutScore && value == 0) {
      return "#D3D3D3"
    }

    let landPricePerAcreScale = scaleQuantile()
     .domain([5000, 100000])
     .range(invertedMapToUse)

    let landAvgAcresScale = scaleQuantile()
     .domain([0, 30])
     .range(mapToUse)

    let landAvgPriceChangeScale = scaleQuantile()
     .domain([10000, 300000])
     .range(mapToUse)

     let landAvgPriceDropScale = scaleQuantile()
     .domain([10000, 300000])
     .range(mapToUse)

     let landAvgPriceScale = scaleQuantile()
     .domain([10000, 300000])
     .range(invertedMapToUse)

     let landMedianPriceScale = scaleQuantile()
     .domain([10000, 300000])
     .range(invertedMapToUse)

     let landNumListingsScale = scaleQuantile()
     .domain([0, 1000])
     .range(mapToUse)

     let landListingsPerPopScale = scaleQuantile()
     .domain([0, 1000])
     .range(mapToUse)

     let landNumPriceChangesScale = scaleQuantile()
     .domain([0, 30])
     .range(mapToUse)

     let landNumPriceDropsScale = scaleQuantile()
     .domain([0, 30])
     .range(mapToUse)

     let landPriceDropsPerPopScale = scaleQuantile()
     .domain([0, 30])
     .range(mapToUse)

    let pastDiffScale = scaleQuantile()
      .domain([-25, 25])
      .range(mapToUse)

    let pastDiffScaleInverse = scaleQuantile()
      .domain([-25, 25])
      .range(invertedMapToUse)

    let estRevScale = scaleQuantile()
      .domain([5000, 40000])
      .range(mapToUse)

    let domScale = scaleQuantile()
      .domain([20, 175])
      .range(mapToUse)

    let medianPriceScale = scaleQuantile()
      .domain([100000,500000])
      .range(invertedMapToUse)

    let orScale = scaleQuantile()
      .domain([0, 80])
      .range(mapToUse)

    let priceChangeScale = scaleQuantile()
      .domain([10000,80000])
      .range(mapToUse)

    let numSoldScale = scaleQuantile()
      .domain([10, 500])
      .range(mapToUse)

    let listingsPerPopScale = scaleQuantile()
      .domain([10, 750])
      .range(mapToUse)
    
    let numListingsScale = scaleQuantile()
      .domain([10, 1000])
      .range(mapToUse)

    let climateScale = scaleQuantile()
      .domain([100, 300])
      .range(invertedMapToUse)

    let climateSoloScale = scaleQuantile()
      .domain([0, 100])
      .range(invertedMapToUse)

    let pastTrendScale = scaleQuantile()
      .domain([0, 35])
      .range(mapToUse)

    let colorScale = scaleQuantile()
      .domain([10, 100])
      .range(mapToUse)

    let costOfLivingScale = scaleQuantile()
      .domain([70, 130])
      .range(invertedMapToUse)

    let violentCrimeScale = scaleQuantile()
      .domain([5, 40])
      .range(invertedMapToUse)

    let propertyCrimeScale = scaleQuantile()
      .domain([10, 60])
      .range(invertedMapToUse)

    let sunnyDaysScale = scaleQuantile()
      .domain([150, 300])
      .range(mapToUse)

    let snowScale = scaleQuantile()
      .domain([0, 100])
      .range(mapToUse)

    let rainScale = scaleQuantile()
      .domain([0, 60])
      .range(mapToUse)

    let elevationScale = scaleQuantile()
      .domain([500, 8000])
      .range(mapToUse)

    let avgRentScale = scaleQuantile()
      .domain([500, 3000])
      .range(invertedMapToUse)

    let medianRentScale = scaleQuantile()
      .domain([500, 3000])
      .range(invertedMapToUse)
    
    let medianSoldScale = scaleQuantile()
      .domain([50000,400000])
      .range(invertedMapToUse)

    let rentIndexScale = scaleQuantile()
      .domain([10,25])
      .range(invertedMapToUse)

    let pricePerSqftScale = scaleQuantile()
      .domain([75,500])
      .range(invertedMapToUse)

    let priceDropsPerPopScale = scaleQuantile()
      .domain([50, 400])
      .range(mapToUse)

    let priceDropsScale = scaleQuantile()
      .domain([0, 200])
      .range(mapToUse)

    let totalScoreScale = scaleQuantile()
      .domain([10, 100])
      .range(mapToUse)

    let scale = null
    let returnValue = ""

    if (mapCategory == "past" && mapType !== "past_trend") {
      let scoreObj = curr["score"]
      if (scoreObj[mapType] != undefined) {
        let score = scoreObj[mapType]
        if (mapType == "median_price") {
          scale = pastDiffScaleInverse
          returnValue = pastDiffScaleInverse(score)
        } else {
          scale = pastDiffScale
          returnValue = pastDiffScale(score)
        }
      } else {
        return "#D3D3D3"
      }
    }

    else if (mapType === "avg_price_drop") {
      if (value > 300000) {
        returnValue = "#10FF00"
      }
      scale = priceChangeScale
      returnValue = priceChangeScale(value)
    } else if (mapType === "price_drops_per_pop") {
      scale = priceDropsPerPopScale
      returnValue = priceDropsPerPopScale(value)
    } else if (mapType === "num_price_drops") {
      scale = priceDropsScale
      returnValue = priceDropsScale(value)
    } else if (mapType == "avg_rent") {
      scale = avgRentScale
      returnValue = avgRentScale(value)
    } else if (mapType == "median_rent") {
      scale = medianRentScale
      returnValue = medianRentScale(value)
    } else if (mapType == "sold_median_price") {
      scale = medianSoldScale
      returnValue = medianSoldScale(value)
    } else if (mapType == "median_price" || mapType == "avg_price") {
      scale = medianPriceScale
      returnValue = medianPriceScale(value)
    } else if (mapType == "str_or") {
      scale = orScale
      returnValue = orScale(value*100)
    } else if (mapType == "num_listings") {
      scale = numListingsScale
      returnValue = numListingsScale(value)
    } else if (mapType == "num_sales") {
      scale = numSoldScale
      returnValue = numSoldScale(value)
    } else if (mapType == "sold_listings_per_pop" || mapType == "listings_per_pop") {
      scale = listingsPerPopScale
      returnValue = listingsPerPopScale(value)
    } else if (mapType == "median_days_on_market" || mapType == "avg_days_on_market" || mapType == "sold_median_days_on_market") {
      scale = domScale
      returnValue = domScale(value)
    } else if (mapType == "est_rev") {
      scale = estRevScale
      returnValue = estRevScale(value)
    } else if (mapType == "past_trend") {
      scale = pastTrendScale
      returnValue = pastTrendScale(score)
    } else if (mapType.indexOf("climate") > -1) {
      if (mapType == "climate_risk_factors") {
        const climateScore = parseClimateDataV2(curr["value"])
        scale = climateScale
        returnValue = climateScale(climateScore)
      } else {
        const climateScore = parseClimateDataV2Type(curr["value"], mapType)
        scale = climateSoloScale
        returnValue = climateSoloScale(climateScore)
      }
    } else if (mapType == "cost_of_living") {
      scale = costOfLivingScale
      returnValue = costOfLivingScale(score["overall"])
    } else if (mapType == "violent_crime") {
      scale = violentCrimeScale
      returnValue = violentCrimeScale(score["violent_crime_rate"])
    } else if (mapType == "property_crime") {
      scale = propertyCrimeScale
      returnValue = propertyCrimeScale(score["property_crime_rate"])
    } else if (mapType == "sunny_days") {
      scale = sunnyDaysScale
      returnValue = sunnyDaysScale(score["sunny_days"])
    } else if (mapType == "snow") {
      scale = snowScale
      returnValue = snowScale(score["snowfall"])
    } else if (mapType == "rain") {
      scale = rainScale
      returnValue = rainScale(score["rainfall"])
    } else if (mapType == "elevation") {
      scale = elevationScale
      returnValue = elevationScale(score["elevation"])
    } else if (mapType == "rent_index") {
      scale = rentIndexScale
      returnValue = rentIndexScale(score)

    } else if (mapType == "price_per_sqft") {
      scale = pricePerSqftScale
      returnValue = pricePerSqftScale(value)
    } else if (mapType == "all") {
      scale = totalScoreScale
      returnValue = totalScoreScale(score)

    } else if (mapType == "land_price_per_acre") {
      scale = landPricePerAcreScale
      returnValue = landPricePerAcreScale(value)
    } else if (mapType == "land_avg_acres") {
      scale = landAvgAcresScale
      returnValue = landAvgAcresScale(value)
    } else if (mapType == "land_avg_price_change") {
      scale = landAvgPriceChangeScale
      returnValue = landAvgPriceChangeScale(value)
    } else if (mapType == "land_avg_price_drop") {
      scale = landAvgPriceDropScale
      returnValue = landAvgPriceDropScale(value)
    } else if (mapType == "land_avg_price") {
      scale = landAvgPriceScale
      returnValue = landAvgPriceScale(value)
    } else if (mapType == "land_median_price") {
      scale = landMedianPriceScale
      returnValue = landMedianPriceScale(value)
    } else if (mapType == "land_num_listings") {
      scale = landNumListingsScale
      returnValue = landNumListingsScale(value)
    } else if (mapType == "land_listings_per_pop") {
      scale = landListingsPerPopScale
      returnValue = landListingsPerPopScale(value)
    } else if (mapType == "land_num_price_changes") {
      scale = landNumPriceChangesScale
      returnValue = landNumPriceChangesScale(value)
    } else if (mapType == "land_num_price_drops") {
      scale = landNumPriceDropsScale
      returnValue = landNumPriceDropsScale(value)
    } else if (mapType == "land_price_drops_per_pop") {
      scale = landPriceDropsPerPopScale
      returnValue = landPriceDropsPerPopScale(value)
    } else {
      scale = colorScale
      returnValue = colorScale(value)
    }

    if (geoModeTab == undefined || (appCtx.mapKeyRange["mapType"] == mapType && appCtx.mapKeyRange["geoMode"] == geoModeTab)) {
      return returnValue
    }

    if (mapCategory == "past" && mapType !== "past_trend") {
      mapType = "past_diff"
    }

    try {
      let range = getKeyFromDomain(appCtx, mapType, scale, geoModeTab, mapCategory)
      
      if (appCtx.mapKeyRange["upperMid"] !== range["upperMid"]) {
        appCtx.setMapKeyRange(range)
      }
    } catch (error) {
      console.log('error caught: '  + error.message)
      if (appCtx.mapKeyRange["upperMid"] !== "Good") {
        appCtx.setMapKeyRange(DEFAULT_MAP_KEY)
      }
    }

    return returnValue
  }

  export function setupMapKey(appCtx, mapType, upper, bottom, geoModeTab, mapCategory) {
    let sort = determineSortOrder(mapType)
    let inverted = (sort == ASC)

    let difference = Math.abs(upper - bottom)

    let increment = Math.round((difference / 5), 2)
    bottom = increment
    let bottomMid = increment * 2
    let upperMid = increment * 3
    upper = increment * 4

    let scale = getAllColorScale(appCtx, upper, bottom, inverted)

    let rangeKey = {}
    if (inverted) {
      rangeKey = { 
        "mapType": mapType,
        "geoMode": geoModeTab,
        "upper": upper, "bottomMid": bottomMid, "upperMid": upperMid, "bottom": bottom,
        "upper_color": scale(upper), "bottomMid_color": scale(bottomMid), "upperMid_color": scale(upperMid), "bottom_color": scale(bottom) 
      }
    } else {
      rangeKey = { 
        "mapType": mapType,
        "geoMode": geoModeTab,
        "upper": bottom, "bottomMid": upperMid, "upperMid": bottomMid, "bottom": upper,
        "upper_color": scale(bottom), "bottomMid_color": scale(upperMid), "upperMid_color": scale(bottomMid), "bottom_color": scale(upper)
      }
    }

    rangeKey = addDecoration(rangeKey, mapType, mapCategory)

    return rangeKey
  }

  function getKeyFromDomain(appCtx, mapType, scale, geoModeTab, mapCategory) {
    let domain = scale.domain()
    let range = scale.range()
    let inversionObj = isMapInverted(appCtx, range)

    let bottom = parseFloat(domain[0])
    let upper = parseFloat(domain[1])
    let difference = Math.abs(upper - bottom)
    let bottomMid = Math.round((difference / 3),2) + bottom
    let upperMid = Math.round((difference / 3) * 2, 2) + bottom

    let rangeKey = {}
    if (inversionObj == "inverted") {
      rangeKey = {
        "inverted": "Y",
        "mapType": mapType,
        "geoMode": geoModeTab,
        "upper": upper, "bottomMid": bottomMid, "upperMid": upperMid, "bottom": bottom,
        "upper_color": scale(upper), "bottomMid_color": scale(bottomMid), "upperMid_color": scale(upperMid), "bottom_color": scale(bottom) 
      }
    } else {
      rangeKey = { 
        "inverted": "N",
        "mapType": mapType,
        "geoMode": geoModeTab,
        "upper": bottom, "bottomMid": upperMid, "upperMid": bottomMid, "bottom": upper,
        "upper_color": scale(bottom), "bottomMid_color": scale(upperMid), "upperMid_color": scale(bottomMid), "bottom_color": scale(upper)
      }
    }

    rangeKey = addDecoration(rangeKey, mapType, mapCategory)

    return rangeKey
  }

  function addDecoration(rangeKey, mapType, mapCategory) {
    for (let key in rangeKey) {
      if (key == "mapType" || key == "geoMode") {
        continue
      }
      if (key.indexOf("color") > -1) {
        continue
      }

      //let indicator = " < "
      //if (inversionObj == "inverted") {
      //  indicator = " > "
      //}
      let suffix = ""
      if (mapType == "str_or") {
        suffix = "%"
      }
      if (mapType == "weed") {
        suffix = " form"
      }
      if (mapCategory ==  "past" && mapType != "past_trend") {
        suffix = "%"
      }
      let indicator = " "
      let value = rangeKey[key]
      let prefix = getAttrDecoration(mapType)
      if (mapCategory == "past") {
        prefix = ""
      }
      let formatted = numberWithCommas(value)
      if (formatted == "-") {
        formatted = "0"
      }
      let newValue = indicator + prefix + formatted + suffix
      rangeKey[key] = newValue
    }
    return rangeKey
  }

  function isMapInverted(appCtx, range) {
    let mapColors = getColorMapsToUse(appCtx)
    let mapToUse = mapColors["normal"]
    let invertedMapToUse = mapColors["inverse"]

    if (JSON.stringify(range) == JSON.stringify(invertedMapToUse)) {
      return "normal"
    } else if (JSON.stringify(range) == JSON.stringify(mapToUse)) {
      return "inverted"
    }
  }


  export function getStateColorScale(curr, mapType, appCtx, geoModeTab, mapCategory) {
    const score = curr.score
    const actual = curr.value
    const currCountyId = curr.county_id

    let mapColors = getColorMapsToUse(appCtx)

    let mapToUse = mapColors["normal"]
    let invertedMapToUse = mapColors["inverse"]

    let careAboutScore = (mapType == "all" || mapType == "cost_of_living" || mapType == "crime" || mapType == "sunny_days" 
    || mapType == "snow" || mapType == "elevation" || mapType == "rain" || mapType == "property_crime" || mapType == "violent_crime")

    if (careAboutScore && score == 0) {
      return "#D3D3D3"
    }

    if (!careAboutScore && actual == 0) {
      return "#D3D3D3"
    }

    let pastDiffScale = scaleQuantile()
      .domain([-25, 25])
      .range(mapToUse)

    let pastDiffScaleInverse = scaleQuantile()
      .domain([-25, 25])
      .range(invertedMapToUse)

    let priceDropsPerPopScale = scaleQuantile()
      .domain([50, 400])
      .range(mapToUse)

    let priceDropsScale = scaleQuantile()
      .domain([0, 200])
      .range(mapToUse)

    let perPopScale = scaleQuantile()
    .domain([0, 50])
    .range(mapToUse)

    let allScoreScale = scaleQuantile()
      .domain([0, 20])
      .range(mapToUse)

    let avgDomScale = scaleQuantile()
      .domain([20, 150])
      .range(mapToUse)

    let medianSoldScale = scaleQuantile()
      .domain([100000, 400000])
      .range(invertedMapToUse)

    let avgPriceScale = scaleQuantile()
      .domain([0, 30])
      .range(mapToUse)

    let medianPriceScale = scaleQuantile()
      .domain([200000, 500000])
      .range(invertedMapToUse)

      let orScale = scaleQuantile()
      .domain([20, 50])
      .range(mapToUse)

    let estRevScale = scaleQuantile()
      .domain([5000, 30000])
      .range(mapToUse)

    let numListingsScale = scaleQuantile()
      .domain([10, 1000])
      .range(mapToUse)

    let numSoldScale = scaleQuantile()
      .domain([10, 500])
      .range(invertedMapToUse)

    let rentMedianScale = scaleQuantile()
      .domain([500, 3000])
      .range(invertedMapToUse)

    let bigNumbersScale = scaleQuantile()
      .domain([20000, 70000])
      .range(mapToUse)

    let climateScale = scaleQuantile()
      .domain([50, 300])
      .range(invertedMapToUse)

    let climateSoloScale = scaleQuantile()
    .domain([0, 100])
    .range(invertedMapToUse)

    let costOfLivingScale = scaleQuantile()
    .domain([50, 150])
    .range(invertedMapToUse)

    let violentCrimeScale = scaleQuantile()
      .domain([0, 40])
      .range(invertedMapToUse)

    let propertyCrimeScale = scaleQuantile()
      .domain([0, 60])
      .range(invertedMapToUse)

    let sunnyDaysScale = scaleQuantile()
      .domain([150, 300])
      .range(mapToUse)

    let snowScale = scaleQuantile()
      .domain([0, 100])
      .range(mapToUse)

    let rainScale = scaleQuantile()
      .domain([0, 60])
      .range(mapToUse)

    let elevationScale = scaleQuantile()
      .domain([500, 8000])
      .range(mapToUse)

    let pricePerSqftScale = scaleQuantile()
      .domain([75,500])
      .range(mapToUse)

    let weedScale = scaleQuantile()
      .domain([0,3])
      .range(WEED_MAP_COLORS)

    let abortionScale = scaleQuantile()
      .domain([0,25])
      .range(ABORTION_MAP_COLORS)

    let lgbtqScale = scaleQuantile()
      .domain([60,90])
      .range(LGBTQ_MAP_COLORS)

    let listingsPerPopScale = scaleQuantile()
      .domain([10, 750])
      .range(mapToUse)

    let scale = null
    let returnValue = ""

    if (mapCategory == "past" && mapType !== "past_trend") {
      let scoreObj = curr["score"]
      if (scoreObj[mapType] != undefined) {
        let score = scoreObj[mapType]
        if (mapType == "median_price") {
          scale = pastDiffScaleInverse
          returnValue = pastDiffScaleInverse(score)
        } else {
          scale = pastDiffScale
          returnValue = pastDiffScale(score)
        }
      } else {
        return "#D3D3D3"
      }
    }

    else if (mapType === "all") {
      scale = allScoreScale
      returnValue = allScoreScale(score)
    } else if (mapType == "sold_median_price") {
      scale = medianSoldScale
      returnValue = medianSoldScale(actual)
    } else if (mapType == "median_price" || mapType == "avg_price") {
      scale = medianPriceScale
      returnValue = medianPriceScale(actual)
    } else if (mapType == "str_or") {
      scale = orScale
      returnValue = orScale(actual*100)
    } else if (mapType == "median_days_on_market" || mapType == "avg_days_on_market" || mapType == "sold_median_days_on_market") {
      scale = avgDomScale
      returnValue = avgDomScale(actual)
    } else if (mapType === "price_drops_per_pop") {
        scale = priceDropsPerPopScale
        returnValue = priceDropsPerPopScale(actual)
    } else if (mapType === "num_price_drops") {
        scale = priceDropsScale
        returnValue = priceDropsScale(actual)
    } else if (mapType === "est_rev") {
        scale = estRevScale
        returnValue = estRevScale(actual)
    } else if (mapType === "num_listings") {
        scale = numListingsScale
        returnValue = numListingsScale(actual)
    } else if (mapType === "num_sales") {
      scale = numSoldScale
      returnValue = numSoldScale(actual)
    } else if (mapType == "sold_listings_per_pop" || mapType == "listings_per_pop") {
      scale = listingsPerPopScale
      returnValue = listingsPerPopScale(actual)
    } else if (mapType === "avg_price_drop") {
        scale = bigNumbersScale
        returnValue = bigNumbersScale(actual)
    } else if (mapType === "median_rent" || mapType === "avg_rent") {
        scale = rentMedianScale
        returnValue = rentMedianScale(actual)
    } else if (mapType.indexOf("climate") > -1) {
      if (mapType == "climate_risk_factors") {
        const climateScore = parseClimateDataV2(curr["value"])
        scale = climateScale
        returnValue = climateScale(climateScore)
      } else {
        const climateScore = parseClimateDataV2Type(curr["value"], mapType)
        scale = climateSoloScale
        returnValue = climateSoloScale(climateScore)
      }
    } else if (mapType == "cost_of_living") {
      scale = costOfLivingScale
      returnValue = costOfLivingScale(score["overall"])
    } else if (mapType == "violent_crime") {
      scale = violentCrimeScale
      returnValue = violentCrimeScale(score["violent_crime_rate"])
    } else if (mapType == "property_crime") {
      scale = propertyCrimeScale
      returnValue = propertyCrimeScale(score["property_crime_rate"])
    } else if (mapType == "sunny_days") {
      scale = sunnyDaysScale
      returnValue = sunnyDaysScale(score["sunny_days"])
    } else if (mapType == "snow") {
      scale = snowScale
      returnValue = snowScale(removeNonNumericExceptDot(score["snowfall"]))
    } else if (mapType == "rain") {
      scale = rainScale
      returnValue = rainScale(score["rainfall"])
    } else if (mapType == "elevation") {
      scale = rainScale
      returnValue = elevationScale(score["elevation"])
    } else if (mapType == "past_trend") {
      scale = avgPriceScale
      returnValue = avgPriceScale(score)
    } else if (mapType == "price_per_sqft") {
      scale = pricePerSqftScale
      returnValue = pricePerSqftScale(actual)
    } else if (mapType == "weed") {
      scale = weedScale
      returnValue = weedScale(actual)
    } else if (mapType == "abortion") {
      scale = abortionScale
      returnValue = abortionScale(actual)
    } else if (mapType == "lgbtq") {
      scale = lgbtqScale
      returnValue = lgbtqScale(actual)
    } else {
      scale = avgPriceScale
      returnValue = avgPriceScale(actual)
    }

    // already done mapkey shit, only need to do it once
    if ( (appCtx.mapKeyRange["mapType"] == mapType && appCtx.mapKeyRange["geoMode"] == geoModeTab)) {
      return returnValue
    }

    if (mapCategory == "past" && mapType !== "past_trend") {
      mapType = "past_diff"
    }

    try {

      let range = getKeyFromDomain(appCtx, mapType, scale, geoModeTab, mapCategory)
      if (appCtx.mapKeyRange["upperMid"] !== range["upperMid"]) {
        appCtx.setMapKeyRange(range)
      }
    } catch (error) {
      if (appCtx.mapKeyRange["upperMid"] !== "Good") {
        appCtx.setMapKeyRange(DEFAULT_MAP_KEY)
      }
    }

    return returnValue

  }

  export function getCardTitle(geoMode, selectedGeo) {
    let title = ""
    if (selectedGeo === null || selectedGeo === undefined) {
      return ""
    }
  
    if (geoMode == "county") {
      title += selectedGeo.county
    } else if (geoMode == "city") {
      title += selectedGeo.city_name
    }
    // if we switched modes just fallback on last one
    if (title == undefined) {
      if (geoMode == "city") {
        title += selectedGeo.county
      } else if (geoMode == "county") {
        title += selectedGeo.city_name
      }
    }
    title += ", " + selectedGeo.state
    if (title.indexOf('-') > -1){
        title = replaceHyphens(title)
    }
    return title
  }

  export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

  export function getCityNameFromId(cityId) {
    if (cityId === null || cityId === undefined || cityId === "") {
      return cityId
    }
    let underscore = cityId.indexOf('_')
    let stateAbbrev = cityId.substring(0, underscore)
    let cityName = cityId.substring(underscore+1)
    return cityName + ", " + stateAbbrev
  }

  export function getGeoTitle(geoId) {
    if (isGeoIdCity(geoId)) {
      return getCityNameFromId(geoId)
    } else {
      return countyIdToName(geoId)

    }
  }

  function checkSpecialCities(cityId) {
    if (cityId == "ID_Coeur d'Alene") {
      return "ID_Coeur D Alene"
    }
    if (cityId == "ID_Boise City") {
      return "ID_Boise"
    }
    if (cityId == "WV_Charleston") {
      return "WV_Charles Town"
    }
    return cityId
  }

  export function isGeoIdCity(geoId) {
    if (geoId === undefined || geoId == null) {
      return false
    }
    let index = geoId.indexOf('_')
    if (index > -1) {
      return true
    } else {
      return false
    }
  }

  export function cityIdToCountyInfo(cityId) {
    cityId = checkSpecialCities(cityId)
    return cityMap[cityId]
  }

  export function getStateId(label) {
    return stateMap[label]
  }

  export function fipsToCountyId(fips) {
    if (fips !== undefined && fips !== null && isMapNonEmpty(fipsMap)) {
      if (fipsMap[fips] !== undefined) { return fipsMap[fips]; }
      else {
        if (fips.indexOf('0') === 0) {
          fips = fips.substring(1, fips.length)
          return fipsMap[fips]
        }
      }
    }
  }

  export function currentlyLoggedIn(appCtx) {
    return appCtx.user !== null && appCtx.user.username !== null
  }

  export function getUsername(appCtx) {
    if (appCtx.user == null || appCtx.user.username == null) {
      return "Guest"
    } else {
      return appCtx.user.username
    }
  }

  export function isMapNonEmpty(map) {
    if (map !== undefined && map !== null && Object.keys(map).length > 0) {
      return true
    } else {
      return false
    }
  }

  export function getPropertyTax(state) {
    if (state === null || state === undefined) {
      return null
    }

    if (taxMap[state] !== undefined && taxMap[state]) {
      let taxStr = taxMap[state];
      if (taxStr.indexOf('%')) {
        taxStr = taxStr.replace('%', '')
      }
      return taxStr
    } else {
      return ""
    }
  }

  export function countyIdToFips(countyId) {
    if (countyId === null || countyId === undefined) {
      return null
    }

    if (countyMap[countyId] !== undefined && countyMap[countyId]) { 
      return countyMap[countyId];
    }
      else {
        if (countyId.indexOf('0') === 0) {
          countyId = countyId.substring(1, countyId.length)
          return countyMap[countyId]
        }
      }
  }

  export function getCountyInfoFromGeo(geoId) {
    if (geoId == undefined) {
      return null
    }
    if (isGeoIdCity(geoId)) {
      return cityIdToCountyInfo(geoId)
    } else {
      return countyIdToFips(geoId)
    }
  }

  export function countyIdToName(countyId) {
    let countyInfo = countyIdToFips(countyId)
    if (countyInfo == undefined) {
      return ""
    }
    let name = countyInfo["county"]
    
    name = replaceHyphens(name)
    let title = name + ", " + countyInfo.state
    return title
  }

  export function countyIdToProp(countyId, prop) {
    let countyInfo = countyIdToFips(countyId)
    let name = countyInfo[prop]
    return name
  }

  export function setupUrlCheckpoint(page, geoModeTab, mapModeTab, mapCategory, mapType, geoContent, state) {
    //http://localhost:3000/map/national/county/active/median_price/state/120/County-Name
    let domain = window.location.host
    let id = ""
    let name = ""
    if (isMapNonEmpty(geoContent)) {
      id = geoContent["county_id"]
      name = geoContent["county"]
      if (geoModeTab == "city") {
        id = geoContent["city_id"]
        if (id !== undefined) {
          id = id.replaceAll(" ", "-");
        }

        name = geoContent["city_name"]
      }
    }

    if (name !== undefined) {
      name = name.replaceAll(" ", "-");
    }

    let newUrl = '/'+ page +'/'+mapModeTab + '/' + geoModeTab + '/' + mapCategory + '/' + mapType

    if (isMapNonEmpty(state) && state.label !== undefined && state.label !== null && state.label !== "") {
      newUrl = newUrl + '/' + state.label
    }

    if (id != "" && id !== undefined) {
      newUrl = newUrl + '/' + id
    }
    if (name != "" && name !== undefined) {
      newUrl = newUrl + "/" + name
    }
    return newUrl
  }

  export function grabScoreOrValue(score, label, mapType) {
    if (score !== undefined && score !== "" && score !== "0") {

      if (mapType === "climate_risk_factors") {
        if (label == "Score: ") {
          return
        }
        return label + score["v2_total"] + ' (max 400)'
      }

      if (mapType == "median_rent" || mapType == "avg_rent") {
        if (label == "Score: ") {
          return
        }
      }

      if (mapType == "rent_index") {
        if (label == "Value: ") {
          return
        }
      }

      if (mapType === "property_crime") {
        return score["property_crime_rate"] + ' crimes per 100k people'
      }

      if (mapType === "violent_crime") {
        return score["violent_crime_rate"] + ' crimes per 100k people'
      }

      if (mapType === "cost_of_living") {
        return label + score['overall'] + ' (100 is avg)'
      }

      if (mapType === "sunny_days") {
        return "Annual Sunny Days: " + " " + score["sunny_days"]
      }
      if (mapType === "rain") {
        return "Annual Rain: " + " " + score["rainfall"] + " in"
      }
      if (mapType === "snow") {
        return "Annual Snow: " + " " + score["snowfall"] + " in"
      }
      if (mapType === "elevation") {
        return "Elevation: " + numberWithCommas(score["elevation"]) + " ft"
      }
      return label + numberWithCommas(score)
    }
  }

  export function safelyGrab(source, col) {
    if (source === undefined) {
      return
    }
    if (source[col] === undefined) {
      return
    } else {
      return numberWithCommas(source[col])
    }
  }

  export function getScaledValue(value, sourceRangeMin, sourceRangeMax, targetRangeMin, targetRangeMax) {
    var targetRange = targetRangeMax - targetRangeMin;
    var sourceRange = sourceRangeMax - sourceRangeMin;
    return (value - sourceRangeMin) * targetRange / sourceRange + targetRangeMin;
}

export function removeNonNumeric(myString) {
  if (myString === undefined || myString === null || myString === "") {
    return myString
  }
  return myString.replace(/\D/g,'');
}

export function removeNonNumericDefault(myString) {
  let defaultV = "1"
  if (myString === undefined || myString === null || myString === "") {
    return defaultV
  }
  return myString.replace(/\D/g,'');
}

export function removeNonNumericExceptDot(myString) {
  let defaultV = "1"
  if (myString === undefined || myString === null || myString === "") {
    return defaultV
  }
  // remove any extra dots not attached to the number
  if (myString.indexOf(" ") > -1) {
    myString = myString.substring(0, myString.indexOf(" "))
  }

  return myString.replace(/[^0-9.]/g, '');
}

export function removeNonNumericExceptDotMinus(myString) {
  let defaultV = "1"
  if (myString === undefined || myString === null || myString === "") {
    return defaultV
  }
  // remove any extra dots not attached to the number
  if (myString.indexOf(" ") > -1) {
    myString = myString.substring(0, myString.indexOf(" "))
  }
  return myString.replace(/[^0-9.-]/g, '');
}

export function grabJWTBody(token) {
  let tokens = token.split(".");
  return JSON.parse(atob(tokens[1]));
}

export function convertStrToFloat(orig) {
  let newStr = removeNonNumericExceptDot(orig)
  return parseFloat(newStr)
}

export const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));

export function randomColor(transparency) {
  let a = 1
  if (transparency !== null) {
    a = transparency
  }
  const r = randomBetween(0, 255);
  const g = randomBetween(0, 255);
  const b = randomBetween(0, 255);
  const rgb = `rgba(${r},${g},${b},${a})`;
  const rgb2 = `rgba(${r},${g},${b},1)`;

  return { "transparent": rgb, "opaque": rgb2 }
}

export function isInScope(appCtx, desired) {
  let scope = appCtx.scope
  if (scope == "tycoon") {
    if (desired == "landlord") {
      return true
    }
  }
  return appCtx.scope == desired
}

export function isCategeoryPaywalled(appCtx, category) {
  let scope = appCtx.scope
  if (scope == "tycoon" || scope == "landlord") {
    return false
  }

  if (PAYWALLED_CATEGORIES[category] != undefined) {
    return true
  }
  return false
}

export function isMapTypeInExistingList(currList, mapType) {
  if (mapType == undefined) {
    return true
  }

  for (let i in currList) {
    let currItem = currList[i]

    if (mapType == currItem.type) {
      return true
    }
  }
  return false

}

export function getProperMapMode(mapModeTab, geoModeTab, typedState) {
  let mapMode = mapModeTab
  if (mapModeTab === "state") {
    if (typedState == undefined || typedState == null || typedState.label == undefined
      || typedState.label == null || typedState.label === "USA" || typedState.label == "") {
        mapMode = "states"
    } else {
      mapMode = "solo_state"
    }
  }
  if (geoModeTab !== undefined && geoModeTab == "city") {
    mapMode = geoModeTab
  }
  return mapMode
}

export function mapTypeExistsForGeoMode(geoMode, mapType) {
  let list = CATEGORIES_PER_TYPE[geoMode]
  return isMapTypeInExistingList(list, mapType)
}

export function getUrlPrefix() {
  // http://localhost:3000/USA-Maps
  const local = "http://localhost:3000"
  const phone = "http://192.168.0.12:3000"
  let url = window.location.href

  let suffix  = "v1/thermostat/"

  if (url.includes(local)) {
      return "http://127.0.0.1:8000/v1/thermostat/"
  } else if (url.includes(phone)) {
      return "http://localhost:3000/v1/thermostat/"
  } else {

      // get rest of the garbage out of there
    if (url.indexOf('.com') > -1) {
      url = url.substring(0, url.indexOf('com')+3)
    } else if (url.indexOf('.org')) {
      url = url.substring(0, url.indexOf('org')+3)
    }

    if (url.charAt(url.length-1) != '/') {
      url = url + '/'
    }

      url = url.replace('www', 'api')
      url = url + suffix
      return url
  }
}


export function calculateMonthlyPayment(cost, length, rate) {
  if (cost == "" || length == "" || rate == "") {
    return 0
  }
  const totalCost = convertStrToFloat(""+cost)
  const defaultDown = .2 * totalCost // can customize this later
  const borrowedMoney = totalCost - defaultDown
  const lengthOfLoan = convertStrToFloat(""+length) * 12.0
  const interestRate = convertStrToFloat(""+rate)
  const calculedInterest = interestRate / 100
  const interestReady = calculedInterest / 12

  const percentage = interestReady
  const percentagePlusOne = interestReady + 1
  const exponentiationOperator = (percentagePlusOne ** lengthOfLoan)
  const firstDividend = percentage * exponentiationOperator
  const secondDividend = exponentiationOperator - 1
  const division = firstDividend / secondDividend
  const mortgage = borrowedMoney
  const quotas = mortgage * division

  const finalOutput = parseInt(quotas)
  return numberWithCommas(finalOutput)
}


export function calculateWeedScore(status, medical, decriminalized) {
  let score = 0
    let both = false
    if (status == "fully legal") {
      score = 3
      status = "Fully Legal"
    } else if (status == "mixed") {
      status = "Mixed: "
      if (decriminalized == "yes") {
        score += 1
        status += "Decriminalized"
        both = true
      }
      if (medical == "yes") {

        score += 1
        if (both) {
          status += " & "
        }
        both = true
        status += "Medical"
      }
      if (!both) {
        status = "Illegal"
      }
    }
    return { "status": status, "score": score }
}

export function doesMapTypeMatch(dataHasArrived, mapType) {
  if ((dataHasArrived == "elevation" || dataHasArrived == "rain"  || dataHasArrived == "snow" || dataHasArrived == "sunny_days")
    && (mapType == "elevation" || mapType == "rain" || mapType == "snow" || mapType == "sunny_days")) {
      return true

    } else if ((dataHasArrived == "property_crime" || dataHasArrived == "violent_crime")
     && (mapType == "property_crime" || mapType == "violent_crime")) {
      return true
    } else if ((dataHasArrived.indexOf("climate") > -1) && (mapType.indexOf("climate") > -1)) {
       return true
  } else {
    return dataHasArrived == mapType
  }
}

export function getMapColors(selected, curr, mapType, dataHasArrived, appCtx, geoModeTab, mapCategory) {
  if (!doesMapTypeMatch(dataHasArrived, mapType)) {
    return "#D3D3D3"
  } else if (selected) {
    return "black"
  } else if (curr) {
      return getColorScale(curr, mapType, appCtx, geoModeTab, mapCategory)
  } else {
    return "#D3D3D3"
  }
}

export function getCountyMap() {
  return countyMap
}

export function getCityMap() {
  return cityMap
}

export function getCountyLinkMap() {
  return countyLinkMap
}

export function getCityLinkMap() {
  return cityLinkMap
}