import React, { useState, useEffect, useContext, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from "@mui/material";
import AppContext from '../../state/app-context';
import { CATEGORIES_PER_TYPE, convertToUserFacing, isMapTypeInExistingList, getProperMapMode } from "../../shared/common";
import ReactGA from "react-ga4"

const MapCategoryCombobox = (props) => {
  const appCtx = useContext(AppContext);
  const [mapItems, setMapItems] = useState(setupComboboxItems(props.mapModeTab));
  const [currList, setCurrList] = useState(CATEGORIES_PER_TYPE[props.mapModeTab])
  const [mapModeTab, setMapModeTab] = useState(null)
  const [typedState, setTypedState] = useState({"label": null})
  const [selectedItem, setSelectedItem] = useState(findSelectedItem(props.currItem))
  const [state, setState] = useState({ "mapModeTab": "", "geoModeTab": "", "typedState": typedState})

  const onChanged = (newValue) => {
    if (newValue !== null) {
      props.setMapCategory(newValue.type)
      setSelectedItem(newValue)

      ReactGA.event({
        category: 'CategoryCombobox.Changed',
        action: 'MapTypeCombobox Typed ',
        label: 'maptypecombobox.typed.'+newValue.state + '.' +newValue.label
      });
    }
  };

  function findSelectedItem(currItem) {

    if (currItem == undefined) {
      return mapItems[0]
    }

    for (let i in mapItems) {
      let item = mapItems[i]
      if (item.type == currItem) {
        return mapItems[i]
      }
    }
    return mapItems[0]
  }

  function setupComboboxItems(mapMode) {
    if (mapMode == undefined) {
      return true
    }

    mapMode = getProperMapMode(props.mapModeTab, props.geoModeTab, props.typedState)

    let items = CATEGORIES_PER_TYPE[mapMode]
    let comboItems = []
    for (let i in items) {
      let item = items[i]
      let newItem = {
        "type": item, "label": convertToUserFacing(item)
      }
      comboItems.push(newItem)
    }
    return comboItems
  }

  useEffect(() => {

    if (props.mapModeTab !== undefined && props.mapModeTab !== null) {
      let mapMode = props.mapModeTab

      // if state mode, subdivide into either national state (states) or state solo
      mapMode = getProperMapMode(props.mapModeTab, props.geoModeTab, props.typedState)

      let resetList = false
        if (state.mapModeTab !== props.mapModeTab) {
          state["mapModeTab"] = props.mapModeTab
          resetList = true
        }

        if (state.geoModeTab !== props.geoModeTab) {
          state["geoModeTab"] = props.geoModeTab
          resetList = true
        }

        if (state.typedState.label !== props.typedState.label && props.typedState.label !== "USA" && props.typedState.label !== "") {
          state["typedState"] = props.typedState
          resetList = true
        }

        if (props.mapModeTab == "state" && mapMode == "states") {
          resetList = true
        }

        // city trumps all
        if (props.geoModeTab !== undefined && props.geoModeTab == "city") {
          mapMode = props.geoModeTab
        }

        if (resetList) {

          let comboItems = setupComboboxItems(mapMode)
          setMapItems(comboItems)

          let list = CATEGORIES_PER_TYPE[mapMode]
          setCurrList(list)

          if (!isCategoryInList(list, selectedItem.type)) {
            setSelectedItem(comboItems[0])
            props.setMapCategory(comboItems[0].type)
          }
      }

    }


  }, [props.mapModeTab, props.typedState, props.geoModeTab]);

  function isCategoryInList(list, category) {
    for (let c in list){
      let cat = list[c]
      if (cat == category) {
        return true
      }
    }
    return false
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-map"
      value={selectedItem.label}
      className="combobox-dark"
      options={mapItems}
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white" }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" InputProps={{ style: { fontSize: 8 } }} {...params} label="Category" size="small" />}
    />
  );
}

export default memo(MapCategoryCombobox);