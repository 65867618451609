import { getUrlPrefix } from '../shared/common';


export async function createSession(deviceId) {
    console.log('inside createSession: ' + deviceId)

    try {
        const url = getUrlPrefix() + 'session/create'
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': deviceId
            },
          })
        const jsonResp = await response.json();
        
        return jsonResp

    } catch(error) {
        return [];
    }
}

export async function getSession(token, deviceId, username) {
    let headObj = {
        'Content-Type': 'application/json',
        'hh-device-id': deviceId,
        'hh-access-token': token,
    }
    if (username != null) {
        headObj["hh-username"]
    }

    try {
        const url = getUrlPrefix() + 'session/check'
        const response = await fetch(url, {
            method: 'GET',
            headers: headObj,
          })
          const jsonResp = await response.json();

        return jsonResp
    } catch(error) {
        return [];
    }
}

export async function signUpUser(token, deviceId, username, password) {
    console.log('inside signUpUser: ' + username)

    try {
        const url = getUrlPrefix() + 'user/signup'
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': deviceId,
                        'hh-access-token': token,
                        'hh-username': username,
                        'hh-password': password
            },
          })
          const jsonResp = await response.json();
          console.log('user signup response: ' + JSON.stringify(jsonResp))

        return jsonResp
    } catch(error) {
        return [];
    }
}

export async function loginUser(token, deviceId, username, password) {
    console.log('inside loginUser: ' + username + ' device_id: ' + deviceId)

    try {
        const url = getUrlPrefix() + 'user/login'
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': deviceId,
                        'hh-access-token': token,
                        'hh-username': username,
                        'hh-password': password
            },
          })
          const jsonResp = await response.json();
          console.log('user login response: ' + JSON.stringify(jsonResp))

        return jsonResp
    } catch(error) {
        return [];
    }
}

export async function unsubscribeUser(token, deviceId, username) {
    console.log('inside unsubscribeUser: ' + username + ' device_id: ' + deviceId)

    try {
        const url = getUrlPrefix() + 'user/unsubscribe'
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': deviceId,
                        'hh-access-token': token,
                        'hh-username': username,
            },
          })
          const jsonResp = await response.json();
          console.log('user unsubscribe response: ' + JSON.stringify(jsonResp))

        return jsonResp
    } catch(error) {
        return [];
    }
}

export async function deleteUser(token, deviceId, username) {
    console.log('inside deleteUser: ' + username + ' device_id: ' + deviceId)

    try {
        const url = getUrlPrefix() + 'user/delete'
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': deviceId,
                        'hh-access-token': token,
                        'hh-username': username,
            },
          })
          const jsonResp = await response.json();
          console.log('user delete response: ' + JSON.stringify(jsonResp))

        return jsonResp
    } catch(error) {
        return [];
    }
}

export async function checkRefresh(refresh, deviceId, username, token) {
    console.log('inside checkRefresh: ' + username + ' refresh: ' + refresh)

    try {
        const url = getUrlPrefix() + 'session/validate'
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': deviceId,
                        'hh-access-token': token,
                        'hh-refresh-token': refresh,
                        'hh-username': username
            },
          })
          const jsonResp = await response.json();
          console.log('user validate session response: ' + JSON.stringify(jsonResp))

        return jsonResp
    } catch(error) {
        return [];
    }
}