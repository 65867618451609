
import { createContext, useState } from "react";
import { v4 as uuid } from 'uuid'; 
import { DEFAULT_MAP_KEY } from "../shared/common";

const AppContext = createContext({
  isAuthenticated: false,
  user: null,
  token: null,
  deviceId: null,
  refreshToken: null,
  scope: null,
  subUrl: null,
  selectedGeo: null,
  watchlistItems: null,
  refreshElement: null,
  colors: null,
  mapKeyRange: DEFAULT_MAP_KEY,
  setIsAuthenticated: (auth) => {},
  setUser: (username) => {},
  setToken: (token) => {},
  setDeviceId: (deviceId) => {},
  setRefreshToken: (refresh) => {},
  setScope: (scope) => {},
  setSubUrl: (subUrl) => {},
  setSelectedGeo: (selectedGeo) => {},
  setWatchlistItems: (watchlistItems) => {},
  setRefreshElement: (refreshElement) => {},
  setColors: (colors) => {},
  setMapKeyRange: (mapKeyRange) => {}
});


export default AppContext;
