import React from 'react'
import AppNav from './AppNav';
import SideNav from './SideNav';
import { createSession, getSession, checkRefresh } from '../service/sessionservice'

import './MainLayout.css'
import { useContext, useState, useEffect } from 'react';
import AppContext from '../state/app-context';
import { formatDate, isMobile, grabJWTBody, isMapNonEmpty } from '../shared/common';
import { TroubleshootRounded } from '@mui/icons-material';
import { getWatchlistGeos } from '../service/thermostatservice';
import { SocialIcon } from 'react-social-icons'


function MainLayout(props) {
  const appCtx = useContext(AppContext);
  const [locked, setLocked] = useState(false);
  const [tries, setTries] = useState(0);
  const [width, setWidth] = useState(window.innerWidth)
  const { v4: uuidv4 } = require('uuid'); 
  const [watchlistItems, setWatchlistItems] = useState(null)

  useEffect(() => {
    setWidth(window.innerWidth)

  }, [window.innerWidth]);

  useEffect(() => {
    if (isMapNonEmpty(appCtx.user) && appCtx.isAuthenticated || (appCtx.refreshElement == "watchlist" && isMapNonEmpty(appCtx.user))) {
      fetchWatchlist(appCtx.refreshElement == "watchlist")
      appCtx.setRefreshElement(null)
    }

  }), [appCtx.isAuthenticated, appCtx.refreshElement]

  useEffect(() => {
    // after logout clear watchlist
    if (appCtx.watchlistItems == null) {
      setWatchlistItems(null)
    }

  }), [appCtx.watchlistItems]

  if (!appCtx.isAuthenticated && locked == false) {
    setLocked(true)
    setupTokens()
  }

  function setupTokens() {
    setLocked(true)
    let token = localStorage.getItem("hh-access-token");
    let deviceId = grabOrCreateDeviceId()
    let username = localStorage.getItem('hh-username')
    let refreshToken = localStorage.getItem('hh-user-rt')

    console.log('existing username')

    // if we have a token and username cached we are probably logged in, lets check
    if (token !== null && username != null) {
      console.log('username and token found, attempting login')
      let scope = localStorage.getItem('hh-scope')

       // set temporarily while we try auth in background (assume we are good til we arent)
       // this will be unset in validateTokens if meta != 200
      appCtx.setUser({"username": username})
      appCtx.setScope(scope)

      // check if token is valid, if it isnt then we need to make a new one
      // before checking on backend if its legit should first check if its expired here, save us a call TODO
      validateTokens(token, refreshToken, deviceId, username)
    } else {
      console.log('no username or token existing, creating guest pass')
      // there is no refresh, set up a temporary guest access-token
      createGuestAccessToken(deviceId)
    }
  }

  async function createGuestAccessToken(deviceId) {
    let response = await createSession(deviceId)
    if (response["meta"] === 200 && response["data"] !== undefined && response["data"]["token"] !== undefined) {
      const newToken = response["data"]["token"]
      appCtx.setToken(newToken)
      appCtx.setIsAuthenticated(true)
      appCtx.setUser(null)
      appCtx.setScope("guest")
      appCtx.setDeviceId(deviceId)
      appCtx.setRefreshToken(null)

      localStorage.setItem("hh-access-token", newToken)
      localStorage.setItem('hh-scope', "guest")

      // created a valid token
      console.log('guest authenticated, created a new token and saved it locally')

    } else {
      // unable to create a token, increment tries and it will try again automatically outside of fetchToken
      appCtx.setIsAuthenticated(false)
    }

  }

  async function validateTokens(accessToken, refreshToken, deviceId, username) {
    let response = await getSession(accessToken, deviceId, username)
    if (response["meta"] === 200 && response["data"] !== undefined && response["data"]["token"] !== undefined) {
      console.log('access token still valid, logged in')
      const newToken = response["data"]["token"]
      const scope = response["data"]["scope"]
      let subUrl = response["data"]["sub_url"]
      appCtx.setSubUrl(subUrl)
      appCtx.setToken(newToken)
      appCtx.setIsAuthenticated(true)
      appCtx.setUser({"username": username})
      appCtx.setScope(scope)
      appCtx.setDeviceId(deviceId)

      localStorage.setItem("hh-access-token", newToken)

      // created a valid token
      console.log('authenticated, created a new token and saved it locally')
      setLocked(false)

    } else {
      console.log('access token expired, validating refresh instead')
      // unable to validate access token (expired)
      appCtx.setIsAuthenticated(false)
      //appCtx.setUser(null)

      // check if we have a valid refresh token, and create a new access token from that
      if (refreshToken != null && username != null) {
        // we are logged in validate refresh token and grab the user from it
        validateRefresh(refreshToken, deviceId, username, accessToken)
      }
    }
  }

  async function validateRefresh(refreshToken, deviceId, username, token) {
    let response = await checkRefresh(refreshToken, deviceId, username, token)
    if (response["meta"] === 200 && response["data"] !== undefined && response["data"]["token"] !== undefined) {
      const newToken = response["data"]["token"]
      const scope = response["data"]["scope"]
      appCtx.setToken(newToken)
      appCtx.setIsAuthenticated(true)
      appCtx.setUser({"username": username})
      appCtx.setScope(scope)
      appCtx.setDeviceId(deviceId)
      appCtx.setRefreshToken(refreshToken)

      localStorage.setItem("hh-access-token", newToken)
      localStorage.setItem('hh-scope', scope)
      
      // created a valid token
      console.log('authenticated, validated refresh token')

    } else {
      // unable to create a token, increment tries and it will try again automatically outside of fetchToken
      localStorage.removeItem('hh-user-rt',)
      localStorage.removeItem('hh-username')
      localStorage.removeItem('hh-access-token')
      appCtx.setIsAuthenticated(false)
      appCtx.setToken(null)
      appCtx.setUser(null)
      appCtx.setRefreshToken(null)
      appCtx.setScope("guest")
      createGuestAccessToken(deviceId)
    }

  }

  function grabTokenIfExists() {
    let token = localStorage.getItem('hh-access-token')
    if (token != null) {
      return token
    } else {
      console.log('no token found')
      return null
    }
  }

  function grabOrCreateDeviceId() {
  const existingId = localStorage.getItem('hh-device-id');

  if (existingId == undefined || existingId == null) {
    const uniqueId = uuidv4();
    localStorage.setItem("hh-device-id", uniqueId)
    appCtx.setDeviceId(uniqueId)
    console.log('created new id: ' + uniqueId)
    return uniqueId
  } else {
    appCtx.setDeviceId(existingId)
    return existingId
  }
}

function fetchWatchlist(override) {
  if (watchlistItems == null || override) {
    setWatchlistItems({})
    fetchWatchlistItems()
  }
}

async function fetchWatchlistItems() {
  // fetch geos
  let watchlistResponse = await getWatchlistGeos(appCtx)
  if (watchlistResponse["meta"] == 200 && isMapNonEmpty(watchlistResponse["data"])) {
    let watchlist = watchlistResponse["data"]
    setWatchlistItems(watchlist)

    let watchlistMap = {}
    for (let i in watchlist) {
      let item = watchlist[i]
      let geoId = item["geo_id"]

      if (watchlistMap[geoId] == undefined) {
        watchlistMap[geoId] = item
      }
    }
    appCtx.setWatchlistItems(watchlistMap)
  } else {
    setWatchlistItems([{"geo_id": "dummy"}])
    appCtx.setWatchlistItems({})
  }
}

function createLink(geoType) {
  let domain = window.location.origin;
  return domain + "/links/USA/" + geoType;
}

  return (
    <React.Fragment>
      <div>
        <AppNav />
      </div>
      
      {/* Only show the SideNav if the screen is large. */}
      <div className='container-fluid ps-0'>
        <div className='row'>
          <div className="col-xs-0 col-sm-0 col-md-0 col-lg-2 watchlist-outer">
            <SideNav watchlistItems={watchlistItems}/>
          </div>

          <div hidden={appCtx.isAuthenticated === false} key={"main-body"} className="col-xs-12 col-sm-12 col-md-12 col-lg-10 main-layout">
            {props.children}
          </div>
        </div>
      </div>
      <div className="social-footers">
        <SocialIcon target="_blank" className="social-icon" href="https://www.tiktok.com/@hiddenhousing" url="https://tiktok.com" />
        <SocialIcon target="_blank" className="social-icon" href="https://www.instagram.com/hiddenhousing" url="https://instagram.com" />
        <SocialIcon target="_blank" className="social-icon" href="https://www.youtube.com/@hiddenhousing" url="https://youtube.com" />
        <SocialIcon target="_blank" className="social-icon" href="mailto:hiddenhousing@outlook.com" url="email" />
        <a class="footer-link-2" href={createLink("county")}>Browse All Counties Home Prices</a>
        <a class="footer-link-2" href={createLink("city")}>Browse All Cities Home Prices</a>
      </div>
    </React.Fragment>
  );
}

export default MainLayout;
