import { useState, useEffect, useContext, memo} from 'react';
import AppContext from '../../state/app-context';
import * as React from 'react';
import {getColorScale, numberWithCommas, getAttrLabel, getCardTitle, grabPopulation, CHART_COLORS, shouldHideHeader, removeNonNumeric, isMapNonEmpty, getFontColor} from '../../shared/common';
import { getCountySubsets } from "../../service/thermostatservice"
import { Bubble } from "react-chartjs-2"; 
import Chart from 'chart.js/auto';

import { Col, Container, Row } from 'react-bootstrap';
import ChartvsTableToggle from '../ChartvsTableToggle';
import { Box } from '@mui/material';
import Indicator from './Indicator';

let sampleCountyData = { "county_rent_sums_total": { "avg_price": "", "median_price": "", "num_listings": "", "listings_per_pop": ""}, "county_sold_sums_total": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}
let sampleStateData = { "state": "", "data": { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}}
let sampleNationalData = { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}

let tinyHomesUrl = "https://www.thegoodtrade.com/features/where-to-buy-tiny-houses/"
let homeCostsUrl = "https://todayshomeowner.com/home-finances/guides/cost-of-building-a-home-by-state/"
let landForSaleUrlPrefix = "https://www.homes.com/"
let landForSaleUrlSuffix = "/land-for-sale/"

let options = {
  responsive: true,
  scales: {
    y: {  
      ticks: {
        color: "white",
      }
    },
    x: {  
      ticks: {
        color: "white",
      }
    }
  },
  plugins: {
    legend: {
      labels: {
        color: "white", 
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: 'Rent Bed/Bath Crosstabs',
      padding: {
        left: 10,
        right: 5,
        bottom: 10,
        top: 5
      },
      color: "white",
      font: {
        size: 14,
        weight: 'bold',
      },
      align: "center"
    }
  }
}

let options2 = {
  responsive: true,
  scales: {
    y: {  
      ticks: {
        color: "white",
      }
    },
    x: {  
      ticks: {
        color: "white",
      }
    }
  },
  plugins: {
    legend: {
      labels: {
        color: "white", 
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: 'Rent Sqft Crosstabs',
      padding: {
        left: 10,
        right: 5,
        bottom: 10,
        top: 5
      },
      color: "white",
      font: {
        size: 14,
        weight: 'bold',
      },
      align: "center"
    }
  }
}

function LandCards(props) {
  const appCtx = useContext(AppContext);
  const [benchmarks, setBenchmarks] = useState(sampleNationalData)
  const [stateBenchmarks, setStateBenchmarks] = useState(sampleStateData)
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});
  const [landData, setLandData] = useState(sampleCountyData);
  const [selectedTab, setSelectedTab] = useState("table")
  const [rentSubsetData, setRentSubsetData] = useState({})
  const [selectedRentSubsetData, setSelectedRentSubsetData] = useState({})
  const [population, setPopulation] = useState("")
  const [populationFips, setPopulationFips] = useState("")
  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {

    if (isMapNonEmpty(props.selectedCounty) && props.selectedCounty.county_id !== selectedCounty.county_id) {
        setSelectedCounty(props.selectedCounty)
        setLoadingData(true)
    }

  }, [props.selectedCounty]);

  useEffect(() => {

    if (isMapNonEmpty(props.landData) && isMapNonEmpty(props.landData["land"])) {
        setLoadingData(false)
        setLandData(props.landData["land"])
    }

  }, [props.landData]);

  useEffect(() => {
    if (isMapNonEmpty(props.benchmarks)) {
        setBenchmarks(props.benchmarks)
    }

  }, [props.benchmarks]);

  useEffect(() => {
    if (isMapNonEmpty(props.stateBenchmarks)) {
        setStateBenchmarks(props.stateBenchmarks)
    }

  }, [props.stateBenchmarks]);


function getAttrValues(label, attr, from) {
  if (from === undefined) {
    return
  }
  let response = ""
  let responseBare = ""
  if (from[attr] !== undefined || from[label] !== undefined) {

    if (label == "land_median_price" || label == "land_avg_price") {
      responseBare = parseInt(from[attr])
      response = "$" + numberWithCommas(responseBare)

    }
    if (label == "land_num_listings" || label == "land_listings_per_pop" || label == "land_avg_acres"
     || label == "land_price_drops_per_pop" || label == "land_num_price_drops") {
      responseBare = parseInt(from[attr])
      response = numberWithCommas(responseBare)
    }

    if (label == "land_price_per_acre") {
      responseBare = parseFloat(from[attr])
      response = "$" + numberWithCommas(responseBare)
    }
  }

  return { str: response, num: responseBare}
}

function StatBubble(props) {

  let index = props.index
  let hideHeader = shouldHideHeader(index)
  let attr1 = props.attr1
  let attr2 = props.attr2
  let attr3 = props.attr3
  let attr4 = props.attr4

  let attr1Label = getAttrLabel(props.attr1)
  let attr2Label = getAttrLabel(props.attr2)
  let attr3Label = getAttrLabel(props.attr3)
  let attr4Label = getAttrLabel(props.attr4)

  let countyValue1 = getAttrValues(props.attr1, attr1, landData)
  let countyValue2 = getAttrValues(props.attr2, attr2, landData)
  let countyValue3 = getAttrValues(props.attr3, attr3, landData)
  let countyValue4 = getAttrValues(props.attr4, attr4, landData)

  let stateValue1 = getAttrValues(props.attr1, attr1, stateBenchmarks.data.benchmark_land)
  let stateValue2 = getAttrValues(props.attr2, attr2, stateBenchmarks.data.benchmark_land)
  let stateValue3 = getAttrValues(props.attr3, attr3, stateBenchmarks.data.benchmark_land)
  let stateValue4 = getAttrValues(props.attr4, attr4, stateBenchmarks.data.benchmark_land)

  let nationalValue1 = getAttrValues(props.attr1, attr1, benchmarks.benchmark_land)
  let nationalValue2 = getAttrValues(props.attr2, attr2, benchmarks.benchmark_land)
  let nationalValue3 = getAttrValues(props.attr3, attr3, benchmarks.benchmark_land)
  let nationalValue4 = getAttrValues(props.attr4, attr4, benchmarks.benchmark_land)

  let firstClass = "card card-background mb-3"
  let secondClass = "card card-background-alt mb-3"

  if (nationalValue1 === undefined || nationalValue2 === undefined || stateValue1 === undefined || stateValue2 == undefined
    || countyValue1 === undefined || countyValue2 === undefined) {
    return
  }

  let desired2 = props.desired
  if (props.attr2 == "land_avg_acres") {
    desired2 = "higher"
  }

  if (selectedCounty.county !== null && isMapNonEmpty(landData)) {
    let bgColor = undefined
    if (isMapNonEmpty(selectedCounty.county)) {
      bgColor = getColorScale(selectedCounty, props.mapType, appCtx)
    }
    return (
      <Container>
        <Row className="mt-2">
          <Col md={6} lg={4}>
            <div className={firstClass}>
              <div style={{color:getFontColor(selectedCounty, bgColor), backgroundColor:bgColor}} hidden={hideHeader} className="card-header">{getCardTitle("county", selectedCounty)}      ({props.sliderDate})</div>
              <div className="card-body">
                <Row>
                  <div hidden={hideHeader}><h5  className="card-subheader text-centered">Land</h5>
                  <hr className="separator" /></div>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{countyValue1.str}
                      <Indicator desired={props.desired} number={countyValue1.num} stateAvg={stateValue1.num} nationalAvg={nationalValue1.num} ></Indicator>
                    </p>
                    </Col>
                    <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{countyValue2.str}
                      <Indicator desired={desired2} number={countyValue2.num} stateAvg={stateValue2.num} nationalAvg={nationalValue2.num} ></Indicator>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} className="spacer-no-bottom">
                    <h6 className="card-title">{attr3Label}</h6>
                    <p className="card-text">{countyValue3.str}
                      <Indicator desired={props.desired} number={countyValue3.num} stateAvg={stateValue3.num} nationalAvg={nationalValue3.num} ></Indicator>
                    </p>
                    </Col>
                    <Col md={6} lg={6} className="spacer-no-bottom">
                    <h6 className="card-title">{attr4Label}</h6>
                    <p className="card-text">{countyValue4.str}
                      <Indicator desired={props.desired} number={countyValue4.num} stateAvg={stateValue4.num} nationalAvg={nationalValue4.num} ></Indicator>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className={secondClass}>
              <div hidden={hideHeader} className="card-header ch-alt">State Averages: {stateBenchmarks.state}      ({props.sliderDate})</div>
              <div className="card-body">
                <Row>
                  <div hidden={hideHeader}><h5  className="card-subheader text-centered">Land</h5>
                  <hr className="separator" /></div>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{stateValue1.str}</p>
                  </Col>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{stateValue2.str}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} className="spacer-no-bottom">
                    <h6 className="card-title">{attr3Label}</h6>
                    <p className="card-text">{stateValue3.str}</p>
                  </Col>
                  <Col md={6} lg={6} className="spacer-no-bottom">
                    <h6 className="card-title">{attr4Label}</h6>
                    <p className="card-text">{stateValue4.str}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <div className={firstClass}>
              <div hidden={hideHeader} className="card-header ch-normal">National Averages      ({props.sliderDate})</div>
              <div className="card-body">
              <Row>
              <div hidden={hideHeader}><h5  className="card-subheader text-centered">Land</h5>
                <hr className="separator" /></div>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{nationalValue1.str}
                    </p>
                  </Col>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{nationalValue2.str}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} className="spacer-no-bottom">
                    <h6 className="card-title">{attr3Label}</h6>
                    <p className="card-text">{nationalValue3.str}
                    </p>
                  </Col>
                  <Col md={6} lg={6} className="spacer-no-bottom">
                    <h6 className="card-title">{attr4Label}</h6>
                    <p className="card-text">{nationalValue4.str}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

  return (
    <Container key={"rents-"+JSON.stringify(selectedCounty)} hidden={selectedCounty == null || selectedCounty.county == null}>
      <div hidden={loadingData == true}>
        <Row>
          <Col md={12} lg={12}>
            <Col hidden={selectedTab === "chart"}>
            <StatBubble mapType={props.mapType} sliderDate={props.sliderDate} desired={"lower"} score={props.score} attr1={"land_price_per_acre"} attr2={"land_avg_acres"} attr3={"land_median_price"} attr4={"land_avg_price"} index={0}></StatBubble>
              <StatBubble mapType={props.mapType} sliderDate={props.sliderDate} desired={"higher"} score={props.score} attr1={"land_num_listings"} attr2={"land_listings_per_pop"} attr3={"land_num_price_drops"} attr4={"land_price_drops_per_pop"} index={1}></StatBubble>
            </Col>
        </Col>
      </Row>
      <Container>
      <Row>
      <Col md={4} lg={4}>
          <div className={"card card-background card-stats"}>
            <a target="_blank" className="card-body text-centered" href={landForSaleUrlPrefix+selectedCounty.county+'-'+selectedCounty.state+landForSaleUrlSuffix}> Land for sale</a>
          </div>
        </Col>
        <Col md={4} lg={4}>
          <div className={"card card-background-alt"}>
            <a target="_blank" className="card-body text-centered" href={tinyHomesUrl}> Put a tiny house on it</a>
          </div>
        </Col>
        <Col md={4} lg={4}>
          <div className={"card card-background card-stats"}>
            <a target="_blank" className="card-body text-centered" href={homeCostsUrl}> Costs to build</a>
          </div>
        </Col>
      </Row>
      </Container>
      </div>
      <Row hidden={loadingData == false}>
        <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
      </Row>

      </Container>
  );
}

export default memo(LandCards);