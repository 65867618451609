import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {createFilterOptions} from '@mui/material/Autocomplete';
import ReactGA from "react-ga4"

//import cities from "../../input/all_cities.json";
import citiesMap from "../../input/USA_Major_Cities.json"
import { cityIdToCountyInfo, fipsToCountyId, isMapNonEmpty } from "../../shared/common";
import { Paper } from "@mui/material";
import { createSiteMap } from "../../shared/sitemap_gen";

const filterOptions = createFilterOptions({
  limit: 10
});

const CityComboBox = (props) => {
  const [comboboxItems, setComboboxItems] = useState([]);
  const [selected, setSelected] = useState("")
  const [sitemap, setSitemap] = useState(false)

  const onChanged = (newValue) => {
    if (newValue != null) {
      if (newValue.county === undefined) {
        let countyInfo = cityIdToCountyInfo(newValue.city_id)
          if (countyInfo !== undefined) {
            newValue.county = countyInfo.county
            newValue.state = countyInfo.state
            newValue.county_id = countyInfo.county_id
            newValue.fips = countyInfo.fips
          }
      }

      setSelected(newValue.city_name + ", " + newValue.state)

      props.setTypedCity(newValue)

      ReactGA.event({
        category: 'Combobox.Changed',
        action: 'CityCombobox Typed ',
        label: 'citycombobox.typed.'+newValue.state + '.' +newValue.city_name
      });
    }
  };

  function setupCitiesList(citiesList) {
    if (citiesList !== undefined && citiesList.length > 0) {
      setComboboxItems(citiesList)
    }
  }

  useEffect(() => {
    // only need to do the first time it updates
    
    if (props.citiesList.length > 0) {
      setupCitiesList(props.citiesList)

      // remove when done testing
      //if (sitemap == false) {
        //setSitemap(true)
        //createSiteMap(props.citiesList)
      //}
    }

  }, [props.citiesList]);

  useEffect(() => {
    if (isMapNonEmpty(props.geoContent)) {
        setSelected(props.geoContent.city_name + ", " + props.geoContent.state)
    }

  }, [props.geoContent]);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-cities"
      options={comboboxItems}
      value={{label : selected}}
      filterOptions={filterOptions}
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white" }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField InputProps={{ style: { fontSize: 8 } }} {...params} label="City" size="small" />}
    />
  );
}

export default memo(CityComboBox);