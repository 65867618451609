import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import {CHART_COLORS, numberWithCommas, removeNonNumericExceptDot, randomColor, getCardTitle, isMapNonEmpty, calculateWeedScore} from '../../shared/common';

import { Col, Container, Row } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import GaugeChart from 'react-gauge-chart'

const LGBTQ_URL = "https://www.equaldex.com/equality-index/united-states"

function getBarOptions(newLabel) {

  let options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    responsive: true,
    scales: {
      y: {  
        ticks: {
          color: "white",
        }
      },
      x: {  
        ticks: {
          color: "white",
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: newLabel,
        padding: {
          left: 10,
          right: 5,
          bottom: 10,
          top: 5
        },
        color: "white",
        font: {
          size: 14,
          weight: 'bold',
        },
        align: "center"
      }
    }
  }
  return options
}

const sampleBarChartData = {
  labels: ["Sample Violent Crime", "State", "National"],
  datasets: [
    {
      data: ['100', '200', "150"],
      backgroundColor: [CHART_COLORS.blueColor, CHART_COLORS.lightBlueColor, CHART_COLORS.darkBlueColor],
    }
  ]
};


function getOptions(newLabel) {

  let options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: "white", 
          font: {
            size: 12
          }
        }
      },
      title: {
        display: true,
        text: newLabel,
        padding: {
          left: 10,
          right: 5,
          bottom: 10,
          top: 5
        },
        color: "white",
        font: {
          size: 14,
          weight: 'bold',
        },
        align: "center"
      }
    }
  }

  return options
}

const sampleGaugeData = 
  {"num": .5, "str": "50", "color": "#90ee90", "details": "" }

const sampleLGBTQGaugeData = {
  "equality_rating":  sampleGaugeData,
  "legal_rating":  sampleGaugeData,
  "public_opinion":  sampleGaugeData
}

const sampleChartData = {
  labels: ["Men", "Women"],
  datasets: [
    {
      data: ['50', '50'],
      backgroundColor: [CHART_COLORS.blueColor, CHART_COLORS.redColor],
    }
  ]
};

const graphs = {
  "gender": sampleChartData,
  "marriage": sampleChartData,
  "politics": sampleChartData,
  "geography": sampleChartData,
  "diversity": sampleChartData,
  "violent_crime_rate": sampleBarChartData,
  "property_crime_rate": sampleBarChartData,
  "lgbtq": sampleLGBTQGaugeData,
  "abortion": sampleGaugeData,
  "weed": sampleGaugeData,
  
}

let firstState ={"state": false, "national": false, "county": false}

function CommunityCards(props) {
  const [communityData, setCommunityData] = useState(null)
  const [benchmarks, setBenchmarks] = useState(null)
  const [stateBenchmarks, setStateBenchmarks] = useState(null)
  const [selectedCounty, setSelectedCounty] = useState({"county": null, "state": null})
  const [chartData, setChartData] = useState(graphs)
  const [colors, setColors] = useState(null)
  const [updateKey, setUpdateKey] = useState({"key": "", "county": ""})
  const [dataState, setDataState] = useState(firstState)
  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {
    
    if (isMapNonEmpty(props.selectedCounty) && isMapNonEmpty(props.selectedCounty["county"]) && props.selectedCounty.county !== selectedCounty.county) {
      setLoadingData(true)
      setSelectedCounty(props.selectedCounty)

      if (JSON.stringify(chartData) != JSON.stringify(graphs)) {
        setDataState(firstState)
      }
    }

  }, [props.selectedCounty]);

  
  useEffect(() => {
   
    if (isMapNonEmpty(props.countyData) && isMapNonEmpty(props.countyData["people"]) && isMapNonEmpty(props.countyData["crime"]) && isMapNonEmpty(props.countyData["politics"])) {
       // we only care about the weather type so lets only save that part
       let countyMap = {}
       setLoadingData(false)
       countyMap = props.countyData["people"]
       countyMap["politics"] = props.countyData["politics"]
       countyMap["crime"] = props.countyData["crime"]
       countyMap["lgbtq"] = props.countyData["lgbtq"]
       countyMap["weed"] = props.countyData["weed"]
       countyMap["abortion"] = props.countyData["abortion"]
       props.setCountyBio(props.countyData["misc"])

       setCommunityData(countyMap)
       dataState["county"] = true

       if (dataState["county"]) {
        setupCharts(countyMap, stateBenchmarks, benchmarks)
       }
       
    }
 
  }, [props.countyData]);
 
  useEffect(() => {
    
    if (isMapNonEmpty(props.benchmarks) && isMapNonEmpty(props.benchmarks.benchmark_community) && isMapNonEmpty(props.benchmarks.benchmark_community) && isMapNonEmpty(props.benchmarks.benchmark_community["people"])) {
       // we only care about the people type so lets only save that part
       let cData = props.benchmarks.benchmark_community
       let benchData = cData.people
       benchData["politics"] = cData["politics"]
       benchData["crime"] = cData["crime"]

       setBenchmarks(benchData)
       dataState["national"] = true

       if (dataState["county"] && dataState["state"] && dataState["national"]) {
        setupCharts(communityData, stateBenchmarks, benchData)
       }
    }
 
  }, [props.benchmarks]);
 
  useEffect(() => {

    if (isMapNonEmpty(props.stateBenchmarks) && isMapNonEmpty(props.stateBenchmarks.data) && isMapNonEmpty(props.stateBenchmarks.data.benchmark_community)) {
       // we only care about the people type so lets only save that part
       if (Object.keys(props.stateBenchmarks.data.benchmark_community.people).length > 0) {
          let stateCommunity = props.stateBenchmarks.data.benchmark_community
          let stateData = stateCommunity.people
          stateData.state = props.stateBenchmarks.data["state"]
          stateData["politics"] = stateCommunity["politics"]
          stateData["crime"] = stateCommunity["crime"]

          setStateBenchmarks(stateData)
          dataState["state"] = true

          if (dataState["county"] && dataState["state"] && dataState["national"]) {
            setupCharts(communityData, stateData, benchmarks)
           }
       }
    }
 
  }, [props.stateBenchmarks]);

  function getAttrValues(attr, from) {

    if (from === undefined || from == null) {
      return
    }
    let response = ""

    if (attr == "land_area" || attr == "water_area" || attr == "population_density") {
      return removeNonNumericExceptDot(from[attr])
    }

    if (attr == "male_population" || attr == "female_population" || attr == "single_population" || attr == "married_population") {
      // remove %
      return removeNonNumericExceptDot(from[attr])
    }

    if (attr == "summary") {
      return from[attr].replace('.', '')
    }

    if ((String(from[attr])).indexOf('%') > -1) {
      return removeNonNumericExceptDot(from[attr])
    }

    if (from[attr] !== undefined && from[attr] !== null) {
      response = from[attr]
    }

  
    return response
  }

  function getGaugeValue(startValue, type) {
    if (type == "lgbtq") {
      return parseFloat(parseFloat(startValue) / 100.0).toFixed(2);
    }
    if (type == "abortion") {
      let access = parseFloat(parseFloat(startValue) / 24.0).toFixed(2);
      if (access > 1.0){
        access = 1.0
      }

      return access
    }
    if (type == "weed") {
      let access = parseFloat(parseFloat(startValue) / 3.0).toFixed(2);
      return access
    }
  }
 
  function getNeedleColor(value, type) {
    let color = ""
    value = parseInt(value)

    if (type == "lgbtq") {
      if (value > 70) {
        color = "#008000"
      } else if (value < 40) {
        color = "#FF0000"
      } else {
        color = "#FFE000"
      }
      return color
    }

    if (type == "abortion") {
      if (value > 16) {
        color = "#c90076"
      } else if (value < 12) {
        color = "#FFFFFF"
      } else {
        color = "#d5a6bd"
      }
      return color
    }

    if (type == "weed") {
      if (value > 2) {
        color = "#137413"
      } else if (value < 1) {
        color = "#FFFFFF"
      } else {
        color = "#afeaaf"
      }
      return color
    }
  }

  function setupBarChartData(attr, countyData, stateBenchmarks, benchmarks) {
    let borders = [CHART_COLORS.redColor, CHART_COLORS.lightRedColor, CHART_COLORS.darkRedColor]
    let backgrounds = [CHART_COLORS.redTransparent, CHART_COLORS.lightRedTransparent, CHART_COLORS.darkRedTransparent]
    if (attr === "property_crime_rate") {
      borders = [CHART_COLORS.blueColor, CHART_COLORS.lightBlueColor, CHART_COLORS.darkBlueColor]
      backgrounds = [CHART_COLORS.blueTransparent, CHART_COLORS.lightBlueTransparent, CHART_COLORS.darkBlueTransparent]
    }
    const data = {
      labels: ["County Avg", "State Avg", "National Avg"],
      datasets: [
        {
          data: [getAttrValues(attr, countyData), getAttrValues(attr, stateBenchmarks), getAttrValues(attr, benchmarks)],
          borderColor: borders,
          backgroundColor: backgrounds
        }
      ]
    };
    return data
  }

  function setupChartData(attr, dataToUse) {

    if (attr === "gender" && dataToUse[attr] !== undefined) {
      let boys = getAttrValues("male_population", dataToUse[attr])
      let girls = getAttrValues("female_population", dataToUse[attr])

      const data = {
        labels: ["Men", "Women"],
        datasets: [
          {
            data: [boys, girls],
            borderColor: [CHART_COLORS.lightBlueColor, CHART_COLORS.lightRedColor],
            backgroundColor: [CHART_COLORS.lightBlueTransparent, CHART_COLORS.lightRedTransparent]
          }
        ]
      };
      return data
    }

    if (attr === "marriage" && dataToUse[attr] !== undefined) {
      let single = getAttrValues("single_population", dataToUse[attr])
      let married = getAttrValues("married_population", dataToUse[attr])

      const data = {
        labels: ["Married", "Single"],
        datasets: [
          {
            data: [married, single],
            borderColor: [CHART_COLORS.whiteColor, CHART_COLORS.lightGreenColor],
            backgroundColor: [CHART_COLORS.whiteColorTransparent, CHART_COLORS.lightGreenTransparent]
          }
        ]
      };
      return data
    }

    if (attr === "geography" && dataToUse[attr] !== undefined) {
      let water = getAttrValues("water_area", dataToUse[attr])
      let land = getAttrValues("land_area", dataToUse[attr])

      const data = {
        labels: ["Water", "Land"],
        datasets: [
          {
            data: [water, land],
            borderColor: [CHART_COLORS.blueColor, CHART_COLORS.greenColor],
            backgroundColor: [CHART_COLORS.blueTransparent, CHART_COLORS.greenTransparent]
          }
        ]
      };
      return data
    }

    if (attr === "politics" && dataToUse[attr] !== undefined) {
      let democrat = getAttrValues("democrat", dataToUse[attr])
      let republican = getAttrValues("gop", dataToUse[attr])
      let independent = getAttrValues("independent", dataToUse[attr])

      const data = {
        labels: ["Independent", "Democrat", "Republican"],
        datasets: [
          {
            data: [independent, democrat, republican],
            borderColor: [CHART_COLORS.purpleColor, CHART_COLORS.blueColor, CHART_COLORS.redColor],
            backgroundColor: [CHART_COLORS.publicColorTransparent, CHART_COLORS.blueTransparent, CHART_COLORS.redTransparent]
          }
        ]
      };
      return data
    }

    if (attr === "diversity" && dataToUse[attr] !== undefined) {
      let diversity = dataToUse[attr]
      let races = Object.keys(diversity)
      let raceArray = []
      let borders = []
      let backgrounds = []
      let colorObj = {}
      for (let item in races) {
        let race = races[item]
        let value = getAttrValues(race, dataToUse[attr])
        raceArray.push(value)
        if (colors === null) {
          let randomColors = randomColor(.4)
          borders.push(randomColors.opaque)
          backgrounds.push(randomColors.transparent)
        }
      }

      if (colors === null) {
        colorObj["backgrounds"] = backgrounds
        colorObj["borders"] = borders
        colorObj["county"] = selectedCounty.county
        setColors(colorObj)

      } else {
        colorObj = colors
      }

      const data = {
        labels: races,
        datasets: [
          {
            data: raceArray,
            borderColor: colorObj["borders"],
            backgroundColor: colorObj["backgrounds"]
          }
        ]
      };
      return data
    }
    if ((attr === "lgbtq") && dataToUse["lgbtq"] !== undefined) {
      let lgtbq_er = getAttrValues('equality_index', dataToUse["lgbtq"])
      let lgtbq_lr = getAttrValues('legal_index', dataToUse["lgbtq"])
      let lgbtq_or = getAttrValues('public_opinion_index', dataToUse['lgbtq'])

      return {
         "equality_rating": { "num": getGaugeValue(lgtbq_er, "lgbtq"), "str": lgtbq_er, "color": getNeedleColor(lgtbq_er, "lgbtq") },
         "legal_rating": { "num": getGaugeValue(lgtbq_lr, "lgbtq"), "str": lgtbq_lr, "color": getNeedleColor(lgtbq_lr, "lgbtq") },
         "public_opinion": { "num": getGaugeValue(lgbtq_or, "lgbtq"), "str": lgbtq_or, "color": getNeedleColor(lgbtq_or, "lgbtq") },
      }
   }
   if ((attr === "abortion") && dataToUse["abortion"] !== undefined) {
      let numWeeks = getAttrValues('num_weeks', dataToUse["abortion"])
      let legalUntil = getAttrValues('legal_until', dataToUse["abortion"])
      let details = getAttrValues('details', dataToUse["abortion"])
      let status = getAttrValues('status', dataToUse["abortion"])

      if (legalUntil == "never") {
        legalUntil = "Illegal"
      }
      if (legalUntil == "no gestational limit") {
        legalUntil = "No Limits"
      }
      if (legalUntil == "gestational limit") {
        legalUntil = "Legal with limits"
      }

    let result = {
      "num": getGaugeValue(numWeeks, "abortion"), "str": legalUntil, "color": getNeedleColor(numWeeks, "abortion"), "status": status, "details": details
    }
    return result
  }
  if ((attr === "weed") && dataToUse["weed"] !== undefined) {
    let status = getAttrValues('status', dataToUse["weed"])
    let medical = getAttrValues('medical', dataToUse["weed"])
    let decriminalized = getAttrValues('decriminalized', dataToUse["weed"])
    let link = getAttrValues('link', dataToUse["weed"])

    let weedScore = calculateWeedScore(status, medical, decriminalized)
    status = weedScore["status"]
    let score = weedScore["score"]

    let result = { "num": getGaugeValue(score, "weed"), "str": status, "color": getNeedleColor(score, "weed"), "link": link }
    return result
  }
}

  function checkAndSet(toCheck, toSet, attr, backup) {
    if (toCheck == undefined || toCheck == null) {
      toSet[attr] = backup
    } else {
      toSet[attr] = toCheck
    }
    return toSet
  }

  function setupCharts(countyData, stateBenchmarks, nationalBenchmarks) {
    if (isMapNonEmpty(countyData)
        && isMapNonEmpty(stateBenchmarks)
        && isMapNonEmpty(nationalBenchmarks)
      && isMapNonEmpty(selectedCounty) && selectedCounty.county !== null) {

      let gender = setupChartData("gender", countyData)
      let marriage = setupChartData("marriage", countyData)
      let politics = setupChartData("politics", countyData)
      let diversity = setupChartData("diversity", countyData)
      let geography = setupChartData("geography", countyData)
      let lgbtq = setupChartData("lgbtq", countyData)
      let weed = setupChartData("weed", countyData)
      let abortion = setupChartData("abortion", countyData)
      let violentCrime = setupBarChartData("violent_crime_rate", getAttrValues("crime", countyData), getAttrValues("crime", stateBenchmarks), getAttrValues("crime", nationalBenchmarks))
      let propertyCrime = setupBarChartData("property_crime_rate", getAttrValues("crime", countyData), getAttrValues("crime", stateBenchmarks), getAttrValues("crime", nationalBenchmarks))

      let allData = {}
      allData = checkAndSet(gender, allData, "gender", sampleChartData)
      allData = checkAndSet(marriage, allData, "marriage", sampleChartData)
      allData = checkAndSet(politics, allData, "politics", sampleChartData)
      allData = checkAndSet(diversity, allData, "diversity", sampleChartData)
      allData = checkAndSet(geography, allData, "geography", sampleChartData)
      allData = checkAndSet(lgbtq, allData, "lgbtq", sampleLGBTQGaugeData)
      allData = checkAndSet(weed, allData, "weed", sampleGaugeData)
      allData = checkAndSet(abortion, allData, "abortion", sampleGaugeData)
      allData = checkAndSet(violentCrime, allData, "violent_crime_rate", sampleBarChartData)
      allData = checkAndSet(propertyCrime, allData, "property_crime_rate", sampleBarChartData)

      setChartData(allData)
      //if (updateKey.county !== selectedCounty.county) {
      setUpdateKey({"key":Date.now(), "county": selectedCounty.county })
     //  }
    }
  }

  return (
      <Container key={'community-card-'+updateKey.key} hidden={selectedCounty == null || selectedCounty.county == null}>
        <div hidden={loadingData == true}>
          <Col className="mx-auto" md={12} lg={11}>
            <div className="card card-background card-stats card-key mb-3">
              <Row>
                <Col md={6} lg={4}>
                  <div className="text-centered bold">{getCardTitle("county", props.selectedCounty)}</div>
                </Col>
                <Col md={6} lg={4}>
                  <div className="text-centered">{"Median Age: " + getAttrValues("median age", communityData)}</div>
                </Col>
                <Col md={6} lg={4}>
                  <div className="text-centered">{"Population Density: " + getAttrValues("population_density", getAttrValues("geography", communityData)) + " people per sq mile"}</div>
                </Col>
                </Row>
            </div>
          </Col>

        <Row className="mt-2">
          <Col xs={6} sm={6} md={6} lg={3} className="people-pies">
            <Doughnut className="chart-centered" key={"gender-pie"} data={chartData["gender"]} options={getOptions("Gender")}></Doughnut>
            </Col>
          <Col xs={6} sm={6} md={6} lg={3} className="people-pies">
            <Doughnut className="chart-centered" key={"marriage-pie"} data={chartData["marriage"]} options={getOptions("Marriage")}></Doughnut>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3} className="people-pies">
            <Doughnut className="chart-centered" key={"politics-pie"} data={chartData["politics"]} options={getOptions("Politics: " + getAttrValues("summary", getAttrValues("politics", communityData)))}></Doughnut>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3} className="people-pies">
            <Doughnut className="chart-centered" key={"geography-pie"} data={chartData["geography"]} options={getOptions("Geography (sq miles)")}></Doughnut>
          </Col>
        </Row>
        <Row className="mt-4" key={'social-issues-'+updateKey.key}>
            <Col xs={0} sm={0} md={1} lg={1}></Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <h5 className="text-centered gauge-text mb-2">LGBT+ Friendliness</h5>
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <GaugeChart key={'gauge11-'+updateKey.key} id="gauge-chart11"
                        nrOfLevels={3}
                        colors={["#FF0000", "#FFE000", "#008000"]}
                        needleColor={getAttrValues("color", chartData["lgbtq"]["equality_rating"])}
                        hideText={true}
                        percent={getAttrValues("num", chartData["lgbtq"]["equality_rating"])}  />
                        <p className="text-centered gauge-text">Equality Rating</p>
                        <p className="text-centered">
                          <a target="_blank" className="gauge-link" href={LGBTQ_URL}>{getAttrValues("str", chartData["lgbtq"]["equality_rating"])} / 100</a>
                       </p>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <GaugeChart key={'gauge12-'+updateKey.key} id="gauge-chart12"
                        nrOfLevels={3}
                        colors={["#FF0000", "#FFE000", "#008000"]}
                        hideText={true}
                        needleColor={getAttrValues("color", chartData["lgbtq"]["legal_rating"])}
                        percent={getAttrValues("num", chartData["lgbtq"]["legal_rating"])}  />
                        <p className="text-centered gauge-text">Laws Rating</p>
                        <p className="text-centered">
                          <a target="_blank" className="gauge-link" href={LGBTQ_URL}>{getAttrValues("str", chartData["lgbtq"]["legal_rating"])} / 100</a>
                       </p>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <GaugeChart key={'gauge13-'+updateKey.key} id="gauge-chart13"
                        nrOfLevels={3}
                        colors={["#FF0000", "#FFE000", "#008000"]}
                        needleColor={getAttrValues("color", chartData["lgbtq"]["public_opinion"])}
                        hideText={true}
                        percent={getAttrValues("num", chartData["lgbtq"]["public_opinion"])}  />
                        <p className="text-centered gauge-text">Public Opinion </p>
                        <p className="text-centered">
                          <a target="_blank" className="gauge-link" href={LGBTQ_URL}>{getAttrValues("str", chartData["lgbtq"]["public_opinion"])} / 100</a>
                       </p>
                        
                  </Col>
                </Row>
              </Col>
                <Col xs={6} sm={6} md={6} lg={2}>
                <h5 className="text-centered gauge-text mb-2">Abortion Access</h5>
                <div className="tooltip-element">
                  <GaugeChart key={'gauge14-'+updateKey.key} id="gauge-chart14"
                      nrOfLevels={3}
                      colors={["#FFFFFF", "#d5a6bd","#c90076"]}
                      needleColor={getAttrValues("color", chartData["abortion"])}
                      hideText={true}
                      percent={getAttrValues("num", chartData["abortion"])}/>
                      <p className="text-centered">{getAttrValues("str", chartData["abortion"])}</p>
                        <span className="tooltip">{getAttrValues("details", chartData["abortion"])}</span>
                      </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={2}>
                <h5 className="text-centered gauge-text mb-2">Marijuana Access</h5>
                  <GaugeChart key={'gauge15-'+updateKey.key} id="gauge-chart15"
                      nrOfLevels={3}
                      colors={["#FFFFFF", "#afeaaf", "#137413"]}
                      hideText={true}
                      needleColor={getAttrValues("color", chartData["weed"])}
                      percent={getAttrValues("num", chartData["weed"])}  />
                      <p className="text-centered">
                        <a target="_blank" className="gauge-link" href={getAttrValues("link", chartData["weed"])}>{getAttrValues("str", chartData["weed"])}</a>
                      </p>
                </Col>
            </Row>
        <Row className="chart-row">
          <Col xs={12} sm={12} md={12} lg={5} className="people-pies">
            <Doughnut className="chart-centered" key={"diversity-pie"} data={chartData["diversity"]} options={getOptions("Diversity")}></Doughnut>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1}></Col>
          <Col xs={12} sm={12} md={12} lg={5} className="people-pies">
            <Row>
              <Bar className="community-chart-centered" key={"vc-bar"} data={chartData["violent_crime_rate"]} options={getBarOptions("Violent Crime (reports per 1k people)")}></Bar>
            </Row>
            <Row>
              <Bar className="community-chart-centered" key={"pc-bar"} data={chartData["property_crime_rate"]} options={getBarOptions("Property Crime (reports per 1k people")}></Bar>
            </Row>
          </Col>
        </Row>
        </div>
        <Row hidden={loadingData == false}>
          <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
        </Row>

      </Container>
  );
}

export default CommunityCards;