import React, { useState, useEffect, useContext, memo } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ReactGA from "react-ga4"
import AppContext from '../../state/app-context';
import { convertToUserFacing, isInScope } from "../../shared/common";
import './Controls.css';

const MonthRadioButtons = (props) => {
  function setDefaultItem() {
    if (props.numMonths != undefined) {
      return props.numMonths
    }

    if (props.mapCategory == "short_term_rental") {
      if (props.isRankings) {
        return "6m"
      } else {
        return "avg"
      }
    } else {
      return "3m"
    }
  }

    const [selectedItem, setSelectedItem] = useState(setDefaultItem())
    const appCtx = useContext(AppContext);


    const onChanged = (newValue) => {
        if (newValue !== null) {
          props.setNumMonths(newValue)
          setSelectedItem(newValue)
    
          ReactGA.event({
            category: 'MonthRadioButtons.Changed',
            action: 'MonthRadioButtonsChanged',
            label: newValue
          });
        }
      };

      function getHeaderText() {
        if (props.mapCategory == "past") {
          return "% Change in last "+convertToUserFacing(selectedItem)
        } else if (props.mapCategory == "short_term_rental") {
          if (selectedItem == "avg") {
            return "Seasonality:  all quarter's averaged together"
          } else {
            return "Seasonality: calculated for the next "+convertToUserFacing(selectedItem)
          }
        }
      }

  return (
    <FormControl className="date-radios-container">
      <FormLabel className="date-radios-label">{getHeaderText()}</FormLabel>
      <RadioGroup
        row
        defaultValue={selectedItem}
        value={selectedItem}
        onChange={(event, newValue) => {
            onChanged(newValue);
          }}
      >
        <FormControlLabel hidden={!(props.mapCategory == "past")} value="1m" control={<Radio />} label="1 Month" />
        <FormControlLabel value="3m" control={<Radio />} disabled={!isInScope(appCtx, "landlord")} label="3 Months" />
        <FormControlLabel value="6m" control={<Radio />} disabled={!isInScope(appCtx, "landlord")} label="6 Months" />
        <FormControlLabel hidden={!(props.mapCategory == "short_term_rental") || props.isRankings} value="9m" control={<Radio />} disabled={!isInScope(appCtx, "landlord")} label="9 Months" />
        <FormControlLabel value="12m" control={<Radio />} disabled={!isInScope(appCtx, "landlord")} label="12 Months" />
        <FormControlLabel hidden={!(props.mapCategory == "short_term_rental")} value="avg" control={<Radio />} disabled={!isInScope(appCtx, "landlord")} label="Average" />
      </RadioGroup>
      <p className='slider-text' hidden={isInScope(appCtx, "landlord")}>all dates unlocked with subscription</p>
    </FormControl>
  );
}

export default memo(MonthRadioButtons);