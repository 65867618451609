import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import {getGeoTitle, isMapNonEmpty, numberWithCommas} from '../../shared/common';

import { Col, Container, Row } from 'react-bootstrap';

let BASE_URL = "https://www.google.com/search?tbm=isch&q="
BASE_URL = "https://www.bing.com/images/search?q="
BASE_URL = "https://www.bing.com/chat?q=Bing+AI&FORM=hpcodx"

function AICards(props) {
  const [selectedGeo, setSelectedGeo] = useState({});
  const [url, setUrl] = useState("")
  const [underText, setUnderText] = useState("")
  // https://www.google.com/search?tbm=isch&q=hennepin-county-mn-landscapes

  useEffect(() => {
    if (isMapNonEmpty(props.selectedGeo) && props.selectedGeo.county_id !== undefined) {
      
      if (props.geoModeTab === "county") {
        setSelectedGeo(props.selectedGeo)
        let under = 'Try typing: "What is ' + getGeoTitle(""+props.selectedGeo.county_id) + ' like?"'
        //let urlSuffix = "What+is+Hennepin+County+MN+known+for"// +props.selectedGeo.county + '-' + props.selectedGeo.state +"+known+for?"
        setUnderText(under)
        //let url = BASE_URL + urlSuffix + SUFFIX
        setUrl(BASE_URL)
      } else if (isMapNonEmpty(props.selectedGeo) && props.geoModeTab === "city") {
        setSelectedGeo(props.selectedGeo)
        let under = 'Try typing: "What is ' + getGeoTitle(""+props.selectedGeo.city_id) + ' like?"'
        setUnderText(under)
        setUrl(BASE_URL)
      }
    }

  }, [props.selectedGeo]);


  return (
    <div>
    <div key={'photo-iframe-'+JSON.stringify(selectedGeo)} className="iframe-outer-container" hidden={props.selectedGeo == null || (selectedGeo.county == null && selectedGeo.city_name == null)}>
    <div className="iframe-container">
      <iframe className={"iframe-actual-photos"} width={"90%"} height="900px" src={url}></iframe>
      </div>
      </div>
      <p className="ai-under-text">{underText}</p>
      </div>
      
  );
}

export default memo(AICards);