
import React, { useState, memo, useEffect, useContext } from 'react';
import ReactGA from "react-ga4"
import AppContext from '../state/app-context';
import "./RankingsPage.css"

import { getLeaderboard } from "../service/thermostatservice"
import LeaderboardTable from '../componets/LeaderboardTable';
import MapTypeComboBox from '../componets/Controls/MapTypeComboBox';
import StateComboBox from '../componets/Controls/StateComboBox';
import { isMapNonEmpty, CHART_COLORS, countyIdToName, randomBetween, convertToUserFacing, getCityNameFromId, ASC, DESC, determineSortOrder, isCategeoryPaywalled, isInScope, sortOrderOpposite, setupUrlCheckpoint, getCountyInfoFromGeo, getStateId, generateRankingTitle } from '../shared/common'
import { Bar } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';
import NationalvsStateToggle from '../componets/Controls/NationalvsStateToggle';
import CountyVsCityToggle from '../componets/Controls/CountyVsCityToggle';
import CardTabs from '../componets/Cards/CardTabs';
import SortButton from '../componets/Controls/SortButton';
import MapCategoryComboBox from '../componets/Controls/MapCategoryComboBox';
import MonthRadioButtons from '../componets/Controls/MonthRadioButtons';
import HelmetTags from '../state/helmet';

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import { Button } from '@mui/material';

const sampleChartData = {
  labels: ["Median Price"],
  datasets: [
    {
      data: ['100', '200', '150', '250'],
      backgroundColor: [CHART_COLORS.blueColor, CHART_COLORS.redColor, CHART_COLORS.lightBlueColor, CHART_COLORS.lightRedColor],
    }
  ]
};

const PAGE = "rankings"

const sampleChartDataOuter = {[ASC]: sampleChartData, [DESC]: sampleChartData }
const emptySample = { [ASC]: {}, [DESC]: {}}

const graphs = {
  "all": sampleChartData,
  "avg_price": sampleChartData,
  "median_price": sampleChartData,
  "avg_dom": sampleChartData,
  "median_dom": sampleChartData,
  "num_listings": sampleChartData,
  "num_listings_normalized": sampleChartData,
  "num_price_drops": sampleChartData,
  "num_price_drops_normalized": sampleChartData
}


const RankingsPage = (props) => {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();
  let { mapModeP, geoModeP, mapCategoryP, mapTypeP, stateP, geoIdP, geoNameP } = useParams();

  const [paramGeoId, setParamGeoId] = useState(geoIdP !== undefined? geoIdP: null)
  const [mapType, setMapType] = useState(mapTypeP !== undefined? mapTypeP: "median_price");
  const [mapCategory, setMapCategory] = useState(mapCategoryP !== undefined? mapCategoryP: "active");
  const [selectedGeo, setSelectedGeo] = useState(isMapNonEmpty(appCtx.selectedGeo)? appCtx.selectedGeo: {})
  const [typedState, setTypedState] = useState(stateP !== undefined? { label: stateP, val: getStateId(stateP) }: {"label": "USA", "val": ""})
  const [mapModeTab, setMapModeTab] = useState(mapModeP !== undefined? mapModeP: "national")
  const [geoMode, setGeoMode] = useState(geoModeP !== undefined? geoModeP: "county")

  const [chartMap, setChartMap] = useState({ "county": { "USA": {}}, "city": { "USA": {}}})
  const [chartData, setChartData] = useState(sampleChartDataOuter)
  const [rawDataMap, setRawDataMap] = useState({ "county": { "USA": {}}, "city": { "USA": {}}})
  const [tableData, setTableData] = useState(emptySample)
  const [sliderDate, setSliderDate] = useState("today")
  const [sortOrder, setSortOrder] = useState(ASC)
  const [numMonths, setNumMonths] = useState(mapCategory == "short_term_rental"? "avg": "1m")
  const [loadingData, setLoadingData] = useState(null)

  function getOptions(newLabel, rotate) {
  let minRoto = 0
  if (rotate) {
    minRoto = 20
  }

  let options = {
    aspectRatio: 2,
    maintainAspectRatio: false,
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    /*options: {
      scales: {
          xAxes: [{
              ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
              }
          }]
      }
  }*/
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    responsive: true,
    scales: {
      y: {  
        ticks: {
          stepSize: 1,
          color: "white",
        }
      },
      x: {  
        ticks: {
          color: "white",
          maxRotation: 90,
          minRotation: minRoto
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: newLabel,
        padding: {
          left: 10,
          right: 5,
          bottom: 10,
          top: 5
        },
        color: "white",
        font: {
          size: 14,
        },
        align: "center"
      }
    }
  }
  return options
}

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);

  useEffect(() => {

    if (mapType !== null && mapType !== undefined && mapType !== "" 
      && geoMode !== undefined && geoMode !== null
      && typedState !== undefined && typedState !== null 
      && sortOrder !== null && sortOrder !== undefined && sortOrder !== "") {

      // reset that shit
      if (mapModeTab === "national" && typedState !== "USA") {
        setTypedState("USA")
      }

        setSortOrder(determineSortOrder(mapType))

        if (!appCtx.isAuthenticated) {
          return
        }

        setLoadingData(true)
        fetchRanking(mapType, geoMode, sliderDate)

        let newUrl = setupUrlCheckpoint(PAGE, geoMode, mapModeTab, mapCategory, mapType, selectedGeo, typedState)
        navigate(newUrl, { replace: true });
    }
  }, [mapType, geoMode, typedState, mapModeTab, numMonths, mapCategory]);

  useEffect(() => {
    if (selectedGeo != null && appCtx.isAuthenticated) {
      // fetch geo sum for selected geo, so we can display it in some cards

      let newUrl = setupUrlCheckpoint(PAGE, geoMode, mapModeTab, mapCategory, mapType, selectedGeo, typedState)
      navigate(newUrl, { replace: true });
    }
  }, [selectedGeo]);


  useEffect(() => {
    if (appCtx.isAuthenticated) {
      // fetch geo sum for selected geo, so we can display it in some cards

      setSelectedGeo(appCtx.selectedGeo)
      fetchRanking(mapType, geoMode, sliderDate)
    }
  }, [appCtx.isAuthenticated]);

  useEffect(() => {
    let countyObj;
    if ((geoIdP !== undefined && (appCtx.selectedGeo == null || appCtx.selectedGeo != null && appCtx.selectedGeo["geo_id"] !== geoIdP)) ||
      (geoIdP !== undefined && !isMapNonEmpty(appCtx.selectedGeo))) {
      countyObj = getCountyInfoFromGeo(geoIdP)
      if (countyObj !== null && countyObj !== undefined) {
        countyObj["geo_id"] = geoIdP
          appCtx.setSelectedGeo(countyObj)
      }
    }  

  }, [paramGeoId]);

  function dissectData(rawData, currType, sort, setChartD) {
    if (rawData === undefined || rawData === null) {
      return
    }

    const xAxis = []
    const yAxis = []

    // dont flash graph is data is the same

    let selectedIndex = -1
    for (let i = 0; i < rawData.length; i++) {
      if (i == 100) {
        break;
      }
      const curr = rawData[i]
      let geoInfo = ""
      if (geoMode === "county") {
        geoInfo = countyIdToName(curr.unit_id)
      } else if (geoMode == "city") {
        geoInfo = getCityNameFromId(curr.unit_id)
      }
      const amount = curr.value
      if (curr.value == 0.0 || curr.value == 0 || curr.value == "0.0" || curr.value == "0") {
        continue;
      }
      xAxis.push(geoInfo)
      yAxis.push(amount)
    }

    let colors1 = []
    let colors2 = []
    let colors4 = []
    for (let y=0;  y < yAxis.length; y++) {
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const rgb2 = `rgba(${r},${g},${b},0.4)`; // Collect all to a css color string
        const rgb4 = `rgba(${r},${g},${b},0.8)`; // Collect all to a css color string
        const rgb1 = `rgba(${r},${g},${b},0.4)`; // Collect all to a css color string
        colors1.push(rgb1)
        colors2.push(rgb2)
        colors4.push(rgb4)
    }

    const data = {
      labels: xAxis,
      datasets: [
        {
          label: convertToUserFacing(mapType),
          backgroundColor: colors2,
          borderColor: colors1,
          hoverBackgroundColor: colors4,
          hoverBorderColor: colors1,
          data: yAxis,
        },
      ],
    };
    if (chartMap[geoMode][typedState] == undefined) {
      chartMap[geoMode][typedState] = {}
    }

    //if (mapCategory == "past" && mapType !== "past_trend") {
    //  mapType = mapType + "_past_" + numMonths
    //}

    if (chartMap[geoMode][typedState][currType] == undefined) {
      chartMap[geoMode][typedState][currType] = {}
    }

    chartMap[geoMode][typedState][currType][sort] = data
    if (setChartD) {
      chartData[sort] = data
      //setChartData(data)
      //setChartData(chartData)
    }
  }

  async function fetchRanking(currType, geoMode, sliderDate) {
    // put state in format AL_type
    // just use today for the first call
    let dateToUse = sliderDate

    dateToUse = "today"

    if (mapCategory == "past" && currType !== "past_trend") {
      currType = "past_" + mapType + "_" + numMonths
    }

    // dont fetch if paywalled
    if (isCategeoryPaywalled(appCtx, mapCategory)) {
      return
    }

    //let foundInCache = false
    /*try {
      if (rawDataMap[geoMode][typedState][currType][ASC] !== undefined) {
        let toSet = rawDataMap[geoMode][typedState][currType]
        let sorted = determineSortOrder(mapType)
        let reversed = sortOrderOpposite(mapType)
        
        setTableData(toSet)
        dissectData(toSet[sorted], currType, DESC, true)
        dissectData(data[reversed], currType, ASC, true)
        foundInCache = true
        console.log('found in cache')
        setLoadingData(false)
      }

    } catch (error) {
      foundInCache = false
      console.log('cache not existant for that value, looking up instead: ' + error)
    }*/

    //if (!foundInCache) {

    // find specific month
    if (mapCategory == "short_term_rental" && numMonths !== "avg") {
      currType = mapType + "_" + numMonths
    }

      ReactGA.event({
        category: 'Rankings.Fetch',
        action: 'Rankings.' +  geoMode + "." + currType + '.', typedState,
        label: 'Rankings.'+geoMode + "." + currType + '.', typedState
      });

      getLeaderboard(appCtx, currType, geoMode, typedState, dateToUse, mapModeTab).then((responseObj) => {
        const data = responseObj.data
        if (data !== undefined && responseObj.meta === 200) {

          if (rawDataMap !== undefined && rawDataMap[geoMode] === undefined) {
            rawDataMap[geoMode] = {}
          }
      
          if (rawDataMap[geoMode] !== undefined && typedState !== undefined && rawDataMap[geoMode][typedState] === undefined) {
            rawDataMap[geoMode][typedState] = {}
          }
      
          if (rawDataMap[geoMode] !== undefined && typedState !== undefined && mapType !== undefined && rawDataMap[geoMode][typedState] !== undefined && rawDataMap[geoMode][typedState][currType] === undefined) {
            rawDataMap[geoMode][typedState][currType] = {}
          }

          if (ASC == determineSortOrder(mapType)) {
            rawDataMap[geoMode][typedState][currType][ASC] = data["ranked"]
            rawDataMap[geoMode][typedState][currType][DESC] = data["reverse"]
            let toSet = {
              [ASC]: data["ranked"],
              [DESC]: data["reverse"]
            }
            setTableData(toSet)
            dissectData(data["ranked"], mapType,ASC, true)
            dissectData(data["reverse"], mapType,DESC, true)
          } else {
            rawDataMap[geoMode][typedState][currType][DESC] = data["ranked"]
            rawDataMap[geoMode][typedState][currType][ASC] = data["reverse"]
            let toSet = {
              [DESC]: data["ranked"],
              [ASC]: data["reverse"]
            }
            setTableData(toSet)
            dissectData(data["ranked"], mapType, DESC, true)
            dissectData(data["reverse"], mapType, ASC, true)
          }

          setLoadingData(false)
        }
      })
    //}
  }

  function LeaderboardChart(props) {
    if (isCategeoryPaywalled(appCtx, mapCategory)) {
      return (
      <div className="placeholder-ranking">
        <Button className="profile-subset-button" variant="contained" color="success" onClick={()=> viewSubOptions()}>
            Alright fine I'll look but no promises
        </Button>
      </div>
      )
    } else {
        return (
          <div className="leaderboard-chart-spacer" style={{minHeight: "800px", height:'100%',width:'100%'}}>
            <Bar className="chart-centered" key={"avg_price-bar"} data={props.chartData[sortOrder]} options={getOptions(props.chartType, true)}></Bar>
          </div>
        )
    }
  }

  function viewSubOptions() {
    return (
      navigate("/premium", { replace: false })
    )
  }

  function LeaderboardTableWrapper(props) {
    if (isCategeoryPaywalled(appCtx, mapCategory)) {
      return (
      <div className="placeholder-ranking">
        <p className='slider-text'>all rankings unlocked with subscription</p>
      </div>
      )
    } else {
        return (
          <LeaderboardTable key={+"ranking-table-"+mapType+sortOrder} setSelectedGeo={props.setSelectedGeo} leaderboardData={props.leaderboardData} mapType={mapType} mapCategory={mapCategory} leaderboardType={props.leaderboardType} ></LeaderboardTable>
        )
    }
  }

  function createMetaTitle() {
    let state = typedState.label == undefined ? "the United States": typedState.label
    return generateRankingTitle(geoMode, mapCategory, mapType, numMonths) + convertToUserFacing(mapType) + " in " + state
  }

  function createMetaDesc() {
    let geo = (geoMode === "county"? "Counties ": "Cities ")
    let state = typedState.label == undefined ? "the United States": typedState.label
    let title = generateRankingTitle(geoMode, mapCategory, mapType, numMonths) + convertToUserFacing(mapType) + " when compared to all other " + geo + "in " + state

    return title
  }

  return (
    <div>
      <HelmetTags key={'ranking-'+mapType + "-" + geoMode} title={createMetaTitle()} description={createMetaDesc()}></HelmetTags>
    <div className="leaderboard-spacer">
      <Row className="spacer-rankings">
          <Col lg={1} md={1} sm={1} xs={1} className="leaderboard-col-first">
            <SortButton className={"sort-button-p"} setSortOrder={setSortOrder} mapType={mapType}></SortButton>
          </Col>
          <Col lg={11} md={11} sm={10} xs={10} className="centered-items">
            <Row>
            <Col lg={3} md={5} sm={5} xs={10} className="leaderboard-col">
              <MapCategoryComboBox className="rankings-maptype" mapType={mapType} typedState={typedState} currItem={mapCategory} mapModeTab={"rankings"} geoModeTab={geoMode} setMapCategory={setMapCategory}></MapCategoryComboBox>
            </Col>

            <Col lg={3} md={5} sm={6} xs={10} className="leaderboard-col">
              <MapTypeComboBox className="rankings-maptype" mapCategory={mapCategory} mapType={mapType} typedState={typedState} mapModeTab={"rankings"} geoModeTab={geoMode} setMapType={setMapType}></MapTypeComboBox>
            </Col>

          <Col lg={3} md={5} sm={5} xs={6} className="leaderboard-col">
            <CountyVsCityToggle geoModeTab={geoMode} setGeoModeTab={setGeoMode}></CountyVsCityToggle>
          </Col>
          <Col lg={3} md={5} sm={6} xs={6} className="leaderboard-col">
            <NationalvsStateToggle startToggle={"national"} setMapModeTab={setMapModeTab} mapModeTab={mapModeTab}></NationalvsStateToggle>
          </Col>
          <Col lg={3} md={5} sm={6} xs={10} className="leaderboard-col leaderboard-state-col" hidden={mapModeTab == "national"}>
            <StateComboBox bgcolor="white" display="inline" typedState={typedState} setTypedState={setTypedState}></StateComboBox>
          </Col>
          <Col lg={7} md={7} sm={12} xs={12} className="leaderboard-col leaderboard-state-col" hidden={mapCategory != "past" && mapCategory != "short_term_rental"}>
            <MonthRadioButtons isRankings={true} numMonths={numMonths} mapType={mapType} numMonths={numMonths} setNumMonths={setNumMonths} mapCategory={mapCategory}></MonthRadioButtons>
          </Col>
          </Row>
          </Col>
       </Row>

       <Row>
        <h2 className="text-centered dark-primary mt-4">{generateRankingTitle(geoMode, mapCategory, mapType, numMonths)}<span className='text-centered ranking-title'>{convertToUserFacing(mapType)}</span></h2>
      </Row>

      <Row hidden={loadingData}>
        <Col lg={6} md={6} sm={12} xs={12}>
          <LeaderboardTableWrapper setSelectedGeo={setSelectedGeo} leaderboardData={tableData[sortOrder]} leaderboardType={mapType+"_"+sortOrder} ></LeaderboardTableWrapper>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <LeaderboardChart key={'ranking-chart-'} chartData={chartData} chartType={convertToUserFacing(mapType)}></LeaderboardChart>
        </Col>
      </Row>
      <Row hidden={loadingData == false}>
        <div key={"loader-rankings"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
      </Row>
      <div className="cards-container">
        <CardTabs sliderDate={sliderDate} mapModeTab={mapModeTab} mapType={mapType} score={null} geoModeTab={geoMode} geoContent={selectedGeo} mortgageRates={{"rate15": "", "rate30": ""}}></CardTabs>
      </div>

    </div>
  </div>
  );
};
  
export default memo(RankingsPage);