import React, { useState, useEffect, memo, useContext } from 'react';

import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Button as Button2, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import './ProfilePage.css';
import AccountState from './AccountState';
import AppContext from '../state/app-context';
import StripeFlow from '../componets/Subscriptions/StripeFlow';
import HypeFlow from '../componets/Subscriptions/HypeFlow';

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";
import { deleteUser, unsubscribeUser } from '../service/sessionservice';
import SubscriptionOptions from '../componets/Subscriptions/SubscriptionOptions';
import { isInScope, getAnalyticsName, getColorScale } from '../shared/common';
import ReactGA from "react-ga4"
import MapKey from '../componets/Map/MapKey';

function ProfilePage() {
  const appCtx = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState("profile")
  const [selectedColor, setSelectedColor] = useState((appCtx.colors == null || appCtx.colors == "default") ? "heatmap": appCtx.colors)
  const navigate = useNavigate();
  const [onDeckAction, setOnDeckAction] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);

  function logoutUser() {
    if (appCtx.isAuthenticated) {
      // logout
        ReactGA.event({
          category: 'Account.Logout',
          action: 'Account Logout',
          label: 'account.logout.'+appCtx.deviceId
        });

        localStorage.removeItem('hh-user-rt',)
        localStorage.removeItem('hh-username')
        localStorage.removeItem('hh-access-token')
        localStorage.removeItem('hh-scope')
        appCtx.setIsAuthenticated(false)
        appCtx.setToken(null)
        appCtx.setUser(null)
        appCtx.setRefreshToken(null)
        console.log('logged out user')

    } else {
      // do nothing, already not logged in, shouldnt be here
    }
  }

  function undoIt() {
    setOnDeckAction(null)
  }

  function doIt() {
    if (onDeckAction == null || !appCtx.isAuthenticated) {
      return
    }
    if (onDeckAction == "delete") {
      setOnDeckAction(null)
      callDeleteAccount()
    }
    if (onDeckAction == "unsubscribe") {
      setOnDeckAction(null)
      callUnsubscribeAccount()
    }
  }

  function deleteAccount() {
    setOnDeckAction("delete")
  }

  function unsubscribeAccount() {
    setOnDeckAction("unsubscribe")
  }

  async function callUnsubscribeAccount() {
    setShowLoader(true)
    let response = await unsubscribeUser(appCtx.token, appCtx.deviceId, appCtx.user.username)
    if (response["meta"] == 200 && response["data"] !== undefined) {
      let endDate = response["data"]["end_date"]
      setEndDate(endDate)

      ReactGA.event({
        category: 'Account.Unsubscribe',
        action: 'Account Unsubscribe',
        label: 'account.unsubscribe.'+appCtx.saved
      });

      /*let newScope = response["data"]["scope"]
      let newToken = response["data"]["access_token"]
      let newRefresh = response["data"]["refresh_token"]

      appCtx.setScope(newScope)
      appCtx.setToken(newToken)
      appCtx.setRefreshToken(newRefresh)

      localStorage.removeItem('hh-scope')
      localStorage.removeItem('hh-access-token')
      localStorage.removeItem('hh-rt')

      localStorage.setItem('hh-token', newToken)
      localStorage.setItem('hh-scope', newScope)
      localStorage.setItem('hh-rt', newRefresh)*/
    }
    setShowLoader(false)
  }

  async function callDeleteAccount() {
    setShowLoader(true)
    let response = await deleteUser(appCtx.token, appCtx.deviceId, appCtx.user.username)
    if (response["meta"] == 200) {
      ReactGA.event({
        category: 'Account.Delete',
        action: 'Account Delete',
        label: 'account.delete.'+appCtx.saved
      });

      appCtx.setIsAuthenticated(false)
      appCtx.setToken(null)
      appCtx.setUser(null)
      appCtx.setRefreshToken(null)
      appCtx.setScope(null)
      appCtx.setSelectedGeo(null)
      appCtx.setWatchlistItems(null)
      localStorage.removeItem('hh-user-rt')
      localStorage.removeItem('hh-username')
      localStorage.removeItem('hh-access-token')
      localStorage.removeItem('hh-scope')
      console.log('deleted user')
      setShowLoader(false)

      return (
        navigate("/", { replace: true })
      )
    }
    setShowLoader(false)
  }

  function viewSubOptions() {
    return (
      navigate("/premium", { replace: false })
    )
  }

  function hideUrgentMessage() {
    if (appCtx.scope.indexOf("inactive") > -1) {
      return false
    } else {
      return true
    }
  }

  const handleColorsChange = (newValue) => {
    if (newValue !== null) {
      
      setSelectedColor(newValue)
      appCtx.setColors(newValue)
      localStorage.setItem('colors', newValue)
      
      ReactGA.event({
        category: 'Map.ColorChange.'+newValue,
        action: 'Map ColorChange',
        label: 'map.colorchange.'+newValue
      });
    }
  };

  if (appCtx.isAuthenticated && appCtx.user != null) {
    return (
    <div>
      <Tabs
          className="tabs-margin"
          defaultActiveKey="profile"
          onSelect={(k) => setSelectedTab(k)}
        >
        <Tab eventKey="profile" title="Profile">
          <Row className="profile-header">
            <Col className="profile-info" xs={12} sm={12} md={12} lg={12}>
              <Row>
                <Col lg={4} md={4} sm={4} xs={5}>
                  <h5 className="profile-text">Username: </h5>
                </Col>
                <Col lg={8} md={8} sm={8} xs={7}>
                <h5 className="profile-text">{appCtx.user.username}</h5>
                </Col>
              </Row>
              <Row hidden={hideUrgentMessage()}>
                <Col lg={4} md={4} sm={4} xs={5}>
                  <h5 className="profile-text-urgent">Action required: </h5>
                </Col>
                <Col lg={8} md={8} sm={8} xs={7}>
                <h5 className="profile-text-urgent">Subscription requires update </h5>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={4} xs={5}>
                  <h5 className="profile-text">Subscription: </h5>
                </Col>
                <Col lg={8} md={8} sm={8} xs={7}>
                <h5 className="profile-text">{appCtx.scope}</h5>
                </Col>
              </Row>
              <Row className="profile-buttons">
                <Col lg={3} md={6} sm={6} xs={6}>
                  <Button 
                  className="vertical-center prof-button" 
                  onClick={()=> logoutUser()}>Logout
                </Button>
              </Col>
              <Col lg={3} md={6} sm={6} xs={6}>
                  <Button hidden={isInScope(appCtx, "serf") || endDate !== null}
                    target='_blank'
                    href={appCtx.subUrl}
                    className="prof-button"><p className="vertical-center-special">Update Subscription</p>
                </Button>
              </Col>
              <Col lg={3} md={6} sm={6} xs={6}>
                <Button hidden={isInScope(appCtx, "serf") || endDate !== null}
                  className="vertical-center prof-button" 
                  onClick={()=> unsubscribeAccount()}>Cancel Subscription
                </Button>
              </Col>
              <Col lg={3} md={6} sm={6} xs={6}>
                <Button 
                  className="vertical-center prof-button" 
                  onClick={()=> deleteAccount()}>Delete Account
                </Button>
                </Col>
              </Row>
              <Row className="profile-buttons" hidden={onDeckAction == null}>
                <span>
                  <p>Are you sure? There's no going back. Your subscription features remain unlocked until end of billing cycle</p>
                  <Row className='areyousure'>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Button 
                        className="prof-button"
                        onClick={()=> doIt()}>Do It
                      </Button>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Button 
                        className="prof-button"
                        onClick={()=> undoIt()}>Nevermind
                      </Button>
                      </Col>
                    </Row>
                </span>
              </Row>
              <Row className="profile-buttons" hidden={!showLoader}>
                <div className="lds-ellipsis middle"><div></div><div></div><div></div><div></div></div>
              </Row>
              <Row className="profile-buttons" hidden={endDate == null}>
                <p>{"Billing cycle ends: " + endDate}</p>
              </Row>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="payment" title="Subscription">
          <SubscriptionOptions profile={appCtx.scope}></SubscriptionOptions>
        </Tab>
        <Tab eventKey="settings" title="Settings">
          <Row className="color-settings">
          <Col lg={6} md={6} sm={6} xs={12}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Map Colors</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedColor}
                onChange={(event, newValue) => {
                  handleColorsChange(newValue);
                }}
              >
                <FormControlLabel checked={selectedColor == "heatmap"} value="heatmap" control={<Radio />} label="Heatmap - Red to Orange to Yellow to Green" />
                <FormControlLabel checked={selectedColor == "gay"} value="gay" control={<Radio />} label="Bisexual Lighting - Purple and Pink" />
                <FormControlLabel checked={selectedColor == "one-tone"} value="one-tone" control={<Radio />} label="One tone - blue" />
                <FormControlLabel checked={selectedColor == "two-tone"} value="two-tone" control={<Radio />} label="Two tones - light blue to light red" />
                <FormControlLabel checked={selectedColor == "cyberpunk"} value="cyberpunk" control={<Radio />} label="Cyber punk - neon" />
                <FormControlLabel checked={selectedColor == "colorblind"} value="colorblind" control={<Radio />} label="Colorblind Accessible - Blue to Red" />
              </RadioGroup>
            </FormControl>
          </Col>
          <Col lg={5} md={5} sm={5} xs={11} className="map-key-wrapper-prof-logged-in mt-4">
            <MapKey key={'mapkey-color-'+selectedColor} override={true}></MapKey>
          </Col>
          </Row>
        </Tab>
        </Tabs>
    </div>
    )
  } else {
    return (
    <div>
          <Row className="profile-page-as">
            <Col lg={3} md={12} sm={12} xs={12}>
                <AccountState></AccountState>
                <Button2 className="profile-sub-button" variant="contained" color="success" onClick={()=> viewSubOptions()}>
                What does an Account get me?
              </Button2>
            </Col>
            <Col lg={9} md={12} sm={12} xs={12}>
              <h3 className='dark-primary centered mt-4'>Settings (no account required)</h3>
              <div className="logged-out-color-settings">
                <h5 className='dark-primary logged-out-text'>Change the color of the map</h5>
                <Row className="color-settings">
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">Map Colors</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedColor}
                        onChange={(event, newValue) => {
                          handleColorsChange(newValue);
                        }}
                      >
                        <FormControlLabel checked={selectedColor == "heatmap"} value="heatmap" control={<Radio />} label="Heatmap - Red to Orange to Yellow to Green" />
                        <FormControlLabel checked={selectedColor == "gay"} value="gay" control={<Radio />} label="Bisexual Lighting - Purple and Pink" />
                        <FormControlLabel checked={selectedColor == "one-tone"} value="one-tone" control={<Radio />} label="One tone - blue" />
                        <FormControlLabel checked={selectedColor == "two-tone"} value="two-tone" control={<Radio />} label="Two tones - light blue to light red" />
                        <FormControlLabel checked={selectedColor == "cyberpunk"} value="cyberpunk" control={<Radio />} label="Cyber punk - neon" />
                        <FormControlLabel checked={selectedColor == "colorblind"} value="colorblind" control={<Radio />} label="Colorblind Accessible - Blue to Red" />
                      </RadioGroup>
                    </FormControl>
                  </Col>
                  <Col lg={5} md={5} sm={5} xs={11}>
                    <div className='map-key-wrapper-prof'>
                    <MapKey key={'mapkey-color-'+selectedColor} override={true}></MapKey>
                    </div>
                  </Col>
              </Row>
          </div>
            </Col>
          </Row>
          <Row width={"100%"}>
          <Col lg={12} md={12} sm={12} xs={12}>
              <HypeFlow scope={"demo"}></HypeFlow>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <HypeFlow scope={"tycoon"}></HypeFlow>
            </Col>
          </Row>
        </div>
    )
  }
}

export default ProfilePage;