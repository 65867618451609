import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { height } from '@mui/system';

export default function SubsetToggle(props) {
  const [active, setActive] = React.useState(props.selectedTab);

  const handleChange = (event, newValue) => {
    setActive(newValue)
    props.setSelectedTab(newValue);
  };

  return (
    <ToggleButtonGroup
      className='control'
      aria-label="Small sizes"
      size="small"
      value={active}
      exclusive={true}
      onChange={handleChange}
    >
      <ToggleButton value="active">Active</ToggleButton>
      <ToggleButton value="sold">Sold</ToggleButton>
      <ToggleButton value="land">Land</ToggleButton>
      <ToggleButton value="rent">Rent</ToggleButton>
      <ToggleButton value="str">Short Term Rental</ToggleButton>
    </ToggleButtonGroup>
  );
}