import { useState, useEffect, useContext, memo} from 'react';
import * as React from 'react';
import {getColorScale, getCardTitle, numberWithCommas, CHART_COLORS, getAttrLabel, grabPopulation, grabScoreOrValue, shouldHideHeader, convertToUserFacing, getAttrLabelSold, calculateMonthlyPayment, isMapNonEmpty, getFontColor } from '../../shared/common';
import "./styles.css";
import AppContext from '../../state/app-context';

import { Col, Container, Row } from 'react-bootstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { Box } from '@mui/material';
import ChartvsTableToggle from '../ChartvsTableToggle';
import Indicator from './Indicator';
import AddToWatchlist from '../Watchlist/AddToWatchlist';

const redirectSuffix = "/housing-market"
const redirectPrefix = "https://www.redfin.com/county/"

let sampleGeoData = { "geo_prop_sums": { "avg_price": "", "median_price": ""}, "geo_sold_sums": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}
let sampleStateData = { "state": "", "data": { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}}
let sampleNationalData = { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}


function getOptions(newLabel, rotate) {
  let minRoto = 0
  if (rotate) {
    minRoto = 20
  }

  let options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    /*options: {
      scales: {
          xAxes: [{
              ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
              }
          }]
      }
  }*/
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    responsive: true,
    scales: {
      y: {  
        ticks: {
          color: "white",
        }
      },
      x: {  
        ticks: {
          color: "white",
          maxRotation: 90,
          minRotation: minRoto
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: newLabel,
        padding: {
          left: 10,
          right: 5,
          bottom: 10,
          top: 5
        },
        color: "white",
        font: {
          size: 14,
          weight: 'bold',
        },
        align: "center"
      }
    }
  }
  return options
}

const sampleChartData = {
  labels: ["Active", "Sold", "WA" + " Active", "WA" + " Sold"],
  datasets: [
    {
      data: ['100', '200', '150', '250'],
      backgroundColor: [CHART_COLORS.blueColor, CHART_COLORS.redColor, CHART_COLORS.lightBlueColor, CHART_COLORS.lightRedColor],
    }
  ]
};

const graphs = {
  "avg_price": sampleChartData,
  "median_price": sampleChartData,
  "avg_dom": sampleChartData,
  "median_dom": sampleChartData,
  "num_listings": sampleChartData,
  "num_listings_normalized": sampleChartData,
  "num_price_drops": sampleChartData,
  "num_price_drops_normalized": sampleChartData
}

function StatCards(props) {
  const appCtx = useContext(AppContext);
  const [benchmarks, setBenchmarks] = useState(sampleNationalData)
  const [stateBenchmarks, setStateBenchmarks] = useState(sampleStateData)
  const [selectedGeo, setSelectedGeo] = useState({ city_id: null, city: null, county: null, county_id: null, state: null, fips: null});
  const [geoData, setGeoData] = useState(sampleGeoData);
  const [selectedTab, setSelectedTab] = useState("table")
  const [chartData, setChartData] = useState(graphs)
  const [population, setPopulation] = useState("")
  const [monthlyPayment, setMonthlyPayment] = useState(null)
  const [loadingData, setLoadingData] = useState(false)

  function setupChartData(attr, geoData, stateBenchmarks, benchmarks) {
    let prop_sums = geoData.geo_prop_sums
    let sold_sums = geoData.geo_sold_sums
    const data = {
      labels: ["County Active", selectedGeo.state + " Active", "National Active", "County Sold", selectedGeo.state + " Sold", "National Sold"],
      datasets: [
        {
          data: [prop_sums[attr], stateBenchmarks.data.benchmark_active[attr], benchmarks.benchmark_active[attr], 
          sold_sums[attr], stateBenchmarks.data.benchmark_sold[attr], benchmarks.benchmark_sold[attr]],
          borderColor: [CHART_COLORS.blueColor, CHART_COLORS.lightBlueColor, CHART_COLORS.darkBlueColor, CHART_COLORS.redColor, CHART_COLORS.lightRedColor, CHART_COLORS.darkRedColor],
          backgroundColor: [CHART_COLORS.blueTransparent, CHART_COLORS.lightBlueTransparent, CHART_COLORS.darkBlueTransparent, CHART_COLORS.redTransparent, CHART_COLORS.lightRedTransparent, CHART_COLORS.darkRedTransparent]
        }
      ]
    };
    return data
  }

  function setupCharts(geoData, stateBenchmarks, benchmarks) {
    if (geoData != null && geoData != undefined && geoData !== sampleGeoData
      && stateBenchmarks !== undefined && stateBenchmarks !== sampleStateData
      && benchmarks !== undefined && benchmarks !== sampleNationalData) {

      let avgPriceData = setupChartData("avg_price", geoData, stateBenchmarks, benchmarks)
      let medianPriceData = setupChartData("median_price", geoData, stateBenchmarks, benchmarks)
      let avgDomData = setupChartData("avg_days_on_market", geoData, stateBenchmarks, benchmarks)
      let medianDomData = setupChartData("median_days_on_market", geoData, stateBenchmarks, benchmarks)
      let numListingsData = setupChartData("num_listings", geoData, stateBenchmarks, benchmarks)
      let numListingsNormalizedData = setupChartData("listings_per_pop", geoData, stateBenchmarks, benchmarks)
      let numPriceDrops = setupChartData("num_price_drops", geoData, stateBenchmarks, benchmarks)
      let numPriceDropsNormalized = setupChartData("price_drops_per_pop", geoData, stateBenchmarks, benchmarks)
      
      let allData = {}
      allData["avg_price"] = avgPriceData
      allData["median_price"] = medianPriceData
      allData["avg_dom"] = avgDomData
      allData["median_dom"] = medianDomData
      allData["num_listings"] = numListingsData
      allData["num_listings_normalized"] = numListingsNormalizedData
      allData["num_price_drops"] = numPriceDrops
      allData["num_price_drops_normalized"] = numPriceDropsNormalized

      setChartData(allData)
    }
  }

  function calculateMortgage(houseCost) {

    let pay = calculateMonthlyPayment(houseCost, 30, props.mortgageRates["rate30"])
    setMonthlyPayment("Est. Monthly Payment: $" + pay)
  }

  function displayCountyBio(attr) {
    if (attr !== undefined && attr !== null && attr !== "" && isMapNonEmpty(props.countyBio)) {
      let valueArray = props.countyBio[attr]
      let start = "Pros: "
      if (attr == "cons") {
        start = "Cons: "
      }
      let finalString = start
      for (let v in valueArray) {
        finalString += valueArray[v] + ", "
      }
      finalString = finalString.substring(0, finalString.length-2)
      finalString = finalString.charAt(0).toUpperCase()+ finalString.slice(1)
      return finalString
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (props.geoData !== null && props.geoData !== undefined && props.geoData.geo_prop_sums !== undefined) {
      let dataToSet = props.geoData
      if (props.geoData.geo_prop_sums === null) {
        dataToSet.geo_prop_sums = sampleGeoData.geo_prop_sums
      }
      if (props.geoData.geo_sold_sums === null) {
        dataToSet.geo_sold_sums = sampleGeoData.geo_sold_sums
      }
      setGeoData(dataToSet)
      setLoadingData(false)
      calculateMortgage(dataToSet.geo_prop_sums["median_price"])
      setupCharts(props.geoData, stateBenchmarks, benchmarks)
  
    }
  }, [props.geoData]);

  useEffect(() => {
    if (props.stateBenchmarks !== undefined && props.stateBenchmarks !== null && props.stateBenchmarks.data !== undefined && props.stateBenchmarks.data.benchmark_active !== undefined) {
      setStateBenchmarks(props.stateBenchmarks)
      setupCharts(geoData, props.stateBenchmarks, benchmarks)
    }
  }, [props.stateBenchmarks]);

  useEffect(() => {
    if (props.benchmarks !== undefined && props.benchmarks !== null && props.benchmarks.benchmark_active !== undefined) {
      setBenchmarks(props.benchmarks)
      setupCharts(geoData, stateBenchmarks, benchmarks)
    }
  }, [props.benchmarks]);

  useEffect(() => {
    if (isMapNonEmpty(props.selectedGeo) && props.selectedGeo.fips !== null && props.selectedGeo.fips !== undefined && props.selectedGeo.fips !== selectedGeo.fips) {
      props.setCountyBio(null)
      setLoadingData(true)
      setMonthlyPayment(null)
      setSelectedGeo(props.selectedGeo)
      setupCharts()
      let pop = grabPopulation(props.populationMap, props.selectedGeo.fips)
      setPopulation(pop)
    }
  }, [props.selectedGeo]);

function StatBubble(props) {
  let attr1 = props.attr1
  let attr2 = props.attr2
  let attr3 = props.attr3
  let index = props.index
  let decoration = props.decoration
  let hideHeader = shouldHideHeader(index)

  let firstClass = "card card-background mb-3"
  let secondClass = "card card-background-alt mb-3"

  if (selectedGeo.county !== null && geoData !== null && geoData.geo_prop_sums !== undefined) {
    let bgColor = undefined
    if (isMapNonEmpty(selectedGeo.county)) {
      bgColor = getColorScale(selectedGeo, props.mapType, appCtx)
    }
    return (
      <div>
        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={4}>
            <div className={firstClass}>
              <div style={{color:getFontColor(selectedGeo, bgColor), backgroundColor:bgColor}} hidden={hideHeader} className="card-header">{getCardTitle(props.geoModeTab, selectedGeo)}      ({props.sliderDate})</div>
              <div className="card-body">
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} className="spacer-no-bottom">
                  <div hidden={hideHeader}><h5  className="card-subheader">Active Listings</h5>
                  <hr className="separator" /></div>
                  <h6 className="card-title">{getAttrLabel(attr1)}</h6>
                  <p className="card-text">{decoration[0]}{numberWithCommas(geoData.geo_prop_sums[attr1])}
                    <Indicator desired={props.desired} number={geoData.geo_prop_sums[attr1]} stateAvg={stateBenchmarks.data.benchmark_active[attr1]} nationalAvg={benchmarks.benchmark_active[attr1]} ></Indicator>
                  </p>
                  <h6 className="card-title">{getAttrLabel(attr2)}</h6>
                  <p className="card-text">{decoration[1]}{numberWithCommas(geoData.geo_prop_sums[attr2])}
                  <Indicator desired={props.desired} number={geoData.geo_prop_sums[attr2]} stateAvg={stateBenchmarks.data.benchmark_active[attr2]} nationalAvg={benchmarks.benchmark_active[attr2]} ></Indicator>
                  </p>
                  <span hidden={attr3 == null}>
                    <h6 className="card-title">{getAttrLabel(attr3)}</h6>
                      <p className="card-text">{decoration[2]}{numberWithCommas(geoData.geo_prop_sums[attr3])}
                        <Indicator desired={props.desired} number={geoData.geo_prop_sums[attr3]} stateAvg={stateBenchmarks.data.benchmark_active[attr3]} nationalAvg={benchmarks.benchmark_active[attr3]} ></Indicator>
                      </p>
                  </span>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div hidden={hideHeader}><h5  className="card-subheader">Sold Listings</h5>
                    <hr className="separator" /></div>
                    <h6 className="card-title">{getAttrLabelSold(attr1)}</h6>
                    <p className="card-text">{decoration[0]}{numberWithCommas(geoData.geo_sold_sums[attr1])}
                    <Indicator desired={props.desired2} number={geoData.geo_sold_sums[attr1]} stateAvg={stateBenchmarks.data.benchmark_sold[attr1]} nationalAvg={benchmarks.benchmark_sold[attr1]} ></Indicator>
                    </p>
                    <h6 className="card-title">{getAttrLabelSold(attr2)}</h6>
                    <p className="card-text">{decoration[1]}{numberWithCommas(geoData.geo_sold_sums[attr2])}
                    <Indicator desired={props.desired2} number={geoData.geo_sold_sums[attr2]} stateAvg={stateBenchmarks.data.benchmark_sold[attr2]} nationalAvg={benchmarks.benchmark_sold[attr2]} ></Indicator>
                    </p>
                    <span hidden={attr3 == null}>
                      <h6 className="card-title">{getAttrLabel(attr3)}</h6>
                        <p className="card-text">{decoration[2]}{numberWithCommas(geoData.geo_sold_sums[attr3])}
                          <Indicator desired={props.desired} number={geoData.geo_sold_sums[attr3]} stateAvg={stateBenchmarks.data.benchmark_sold[attr3]} nationalAvg={benchmarks.benchmark_sold[attr3]} ></Indicator>
                        </p>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
            <div className={secondClass}>
              <div hidden={hideHeader} className="card-header ch-alt">State Averages: {stateBenchmarks.state}      ({props.sliderDate})</div>
              <div className="card-body">
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} className="spacer-no-bottom">
                <div hidden={hideHeader}><h5  className="card-subheader">Active Listings</h5>
                  <hr className="separator" /></div>
                  <h6 className="card-title">{getAttrLabel(attr1)}</h6>
                  <p className="card-text">{decoration[0]}{numberWithCommas(stateBenchmarks.data.benchmark_active[attr1])}</p>
                  <h6 className="card-title">{getAttrLabel(attr2)}</h6>
                  <p className="card-text">{decoration[1]}{numberWithCommas(stateBenchmarks.data.benchmark_active[attr2])}</p>
                  <span hidden={attr3 == null}>
                    <h6 className="card-title">{getAttrLabel(attr3)}</h6>
                    <p className="card-text">{decoration[2]}{numberWithCommas(stateBenchmarks.data.benchmark_active[attr3])}</p>
                  </span>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                <div hidden={hideHeader}><h5  className="card-subheader">Sold Listings</h5>
                  <hr className="separator" /></div>
                  <h6 className="card-title">{getAttrLabelSold(attr1)}</h6>
                  <p className="card-text">{decoration[0]}{numberWithCommas(stateBenchmarks.data.benchmark_sold[attr1])}</p>
                  <h6 className="card-title">{getAttrLabelSold(attr2)}</h6>
                  <p className="card-text">{decoration[1]}{numberWithCommas(stateBenchmarks.data.benchmark_sold[attr2])}</p>
                  <span hidden={attr3 == null}>
                    <h6 className="card-title">{getAttrLabelSold(attr3)}</h6>
                    <p className="card-text">{decoration[2]}{numberWithCommas(stateBenchmarks.data.benchmark_sold[attr3])}</p>
                  </span>
                </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
            <div className={firstClass}>
              <div hidden={hideHeader} className="card-header ch-normal">National Averages      ({props.sliderDate})</div>
              <div className="card-body">
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} className="spacer-no-bottom">
                <div hidden={hideHeader}><h5  className="card-subheader">Active Listings</h5>
                  <hr className="separator" /></div>
                  <h6 className="card-title">{getAttrLabel(attr1)}</h6>
                  <p className="card-text">{decoration[0]}{numberWithCommas(benchmarks.benchmark_active[attr1])}</p>
                  <h6 className="card-title">{getAttrLabel(attr2)}</h6>
                  <p className="card-text">{decoration[1]}{numberWithCommas(benchmarks.benchmark_active[attr2])}</p>
                  <span hidden={attr3 == null}>
                    <h6 className="card-title">{getAttrLabel(attr3)}</h6>
                    <p className="card-text">{decoration[2]}{numberWithCommas(benchmarks.benchmark_active[attr3])}</p>
                  </span>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                <div hidden={hideHeader}><h5  className="card-subheader">Sold Listings</h5>
                  <hr className="separator" /></div>
                  <h6 className="card-title">{getAttrLabelSold(attr1)}</h6>
                  <p className="card-text">{decoration[0]}{numberWithCommas(benchmarks.benchmark_sold[attr1])}</p>
                  <h6 className="card-title">{getAttrLabelSold(attr2)}</h6>
                  <p className="card-text">{decoration[1]}{numberWithCommas(benchmarks.benchmark_sold[attr2])}</p>
                  <span hidden={attr3 == null}>
                    <h6 className="card-title">{getAttrLabelSold(attr3)}</h6>
                    <p className="card-text">{decoration[2]}{numberWithCommas(benchmarks.benchmark_sold[attr3])}</p>
                  </span>
                </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
  
  return (
    <Container >
      <Container hidden={selectedGeo.state != null}>Click on any county or city to see all of its housing data</Container>
      <Container hidden={selectedGeo.state == null}>
        <div hidden={loadingData == true}>
          <Row xs={12} sm={12} md={12} lg={12}>

            <Col xs={12} sm={12} md={7} lg={4}>
              <Row>
                <Col className="tab-background tab-buttons" xs={4} sm={3} md={3} lg={3}>
                  <Row>
                    <Col xs={3} sm={3} md={3} lg={3}>
                      <AddToWatchlist currGeo={selectedGeo}></AddToWatchlist>
                    </Col>
                    <Col xs={9} sm={9} md={9} lg={9}>
                      <ChartvsTableToggle selectedTab={selectedTab} setSelectedTab={setSelectedTab}></ChartvsTableToggle>
                    </Col>
                  </Row>
                </Col>
                <Col xs={8} sm={9} md={9} lg={9}>
                  <div className="card card-background card-stats card-key">
                    <Box display="block-inline">
                      <div className="text-centered bold">{getCardTitle(props.geoModeTab, selectedGeo)}</div>
                    </Box>
                  </div>
                </Col>
              </Row>
              <Row>
              <Col xs={4} sm={3} md={0} lg={1}></Col>
                <Col xs={8} sm={9} md={12} lg={11}>
                  <div hidden={monthlyPayment === null} className="card card-background card-stats card-key card-mortgage mb-3">
                    <div className="text-centered">{monthlyPayment}</div>
                  </div>
                </Col>
            </Row>
            </Col>
            
            <Col xs={9} sm={9} md={5} lg={4} className="center-card">
              <div className="card card-background card-stats card-key mb-3">
                <Box display="block-inline">
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <div className="text-centered">Population: {numberWithCommas(population.population)}</div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <div className="text-centered">{grabScoreOrValue(selectedGeo.score, "Score: ", props.mapType)}</div>
                      <div className="text-centered">{grabScoreOrValue(selectedGeo.value, "Value: ", props.mapType)}</div>
                    </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="text-centered pros-text">{displayCountyBio("pros")}</div>
                        <div className="text-centered pros-text">{displayCountyBio("cons")}</div>
                      </Col>
                    </Row>
                  </Box>
              </div>
            </Col>
            <Col xs={9} sm={9} md={6} lg={4} className="center-card">
              <div className="card card-stats card-key mb-3">
                <span>
                  <i className="bi good-indicators bi-arrow-up-circle-fill strong-indicator"></i>
                  <i className="bi good-indicators bi-arrow-down-circle-fill strong-indicator"/>
                  <i className="bi good-indicators strong-indicator"> Better than state & national</i>
                </span>
                <span>
                  <i className="bi good-indicators bi-arrow-up-circle-fill weak-indicator"></i>
                  <i className="bi good-indicators bi-arrow-down-circle-fill weak-indicator"/>
                  <i className="bi good-indicators weak-indicator"> Better than state or national</i>
                </span>
              </div>
            </Col>

          </Row>
          <Row>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"avg_price-bar"} data={chartData["avg_price"]} options={getOptions("Avg Price", true)}></Bar>
            </Col>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"median_price-bar"} data={chartData["median_price"]} options={getOptions("Median Price", true)}></Bar>
            </Col>
          </Row>
          <Row>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"avg_dom-bar"} data={chartData["avg_dom"]} options={getOptions("Avg Days on Market", false)}></Bar>
            </Col>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"median_dom-bar"} data={chartData["median_dom"]} options={getOptions("Median Days on Market", false)}></Bar>
            </Col>
          </Row>
          <Row>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"num_listings-bar"} data={chartData["num_listings"]} options={getOptions("Number of Listings", false)}></Bar>
            </Col>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"num_listings_normalized-bar"} data={chartData["num_listings_normalized"]} options={getOptions("Number of Listings Normalized", false)}></Bar>
            </Col>
          </Row>
          <Row>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"num_price_drops-bar"} data={chartData["num_price_drops"]} options={getOptions("Number of Price Drops", false)}></Bar>
            </Col>
            <Col hidden={selectedTab === "table"} md={12} lg={6}>
              <Bar className="chart-centered" key={"num_price_drops_normalized-bar"} data={chartData["num_price_drops_normalized"]} options={getOptions("Number of Price Drops Normalized", false)}></Bar>
            </Col>
          </Row>
          <Col hidden={selectedTab === "chart"}>
            <StatBubble mapType={props.mapType} desired={"lower"} desired2={"lower"} sliderDate={props.sliderDate} geoModeTab={props.geoModeTab} score={props.score} attr1={"avg_price"} attr2={"median_price"} attr3={"price_per_sqft"} decoration={["$","$","$"]} index={0}></StatBubble>
            <StatBubble mapType={props.mapType} desired={"higher"} desired2={"higher"} sliderDate={props.sliderDate} geoModeTab={props.geoModeTab} score={props.score} attr1={"avg_days_on_market"} attr2={"median_days_on_market"} decoration={["","",""]} attr3={null} index={1}></StatBubble>
            <StatBubble mapType={props.mapType} desired={"higher"} desired2={"lower"} sliderDate={props.sliderDate} geoModeTab={props.geoModeTab} score={props.score} attr1={"num_listings"} attr2={"listings_per_pop"} attr3={null} decoration={["","",""]} index={2}></StatBubble>
            <StatBubble mapType={props.mapType} desired={"higher"} desired2={"higher"} sliderDate={props.sliderDate} geoModeTab={props.geoModeTab} score={props.score} attr1={"num_price_drops"} attr2={"price_drops_per_pop"} attr3={"avg_price_drop"} decoration={["","","$"]} index={3}></StatBubble>
          </Col>
          </div>
          <Row hidden={loadingData == false}>
            <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
          </Row>
        </Container>
      </Container>
  );
}

export default memo(StatCards);


      /*<CurrCards sliderDate={props.sliderDate} geoModeTab={props.geoModeTab} score={props.score}></CurrCards>*/
