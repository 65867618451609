import React, { useContext } from 'react';
import AppContext from '../state/app-context';

import SideNav from './SideNav';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './MainLayout.css'
import logo from './../assets/hiddenhousinglogo.png'

import { HouseCheck, Buildings, Grid, BarChart, Speedometer2, PersonAdd, CloudDownload, StarFill, Award, QuestionCircle } from 'react-bootstrap-icons';
import { getUsername, isMobile } from '../shared/common';

// Nav is the container for both the side and top nav, and coordinates
// where to display the pages.
function AppNav() {
  const appCtx = useContext(AppContext);

  return (
    <React.Fragment>
     <Navbar expand="lg" className="bg-body-tertiary dark-main-color navbar-top" data-bs-theme="dark">
      <Navbar.Brand className="align-items-start ps-3" href="/">
      <img
          src={logo}
          width="25"
          height="25"
          className="d-inline-block align-top"
        />
        <span className="ms-2">Hidden Housing</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className='center-obj'>
        <Nav className="me-auto d-none d-xs-none d-sm-none d-md-none d-lg-block motto">
          <Navbar.Text className="navbar-text">The Best Places to Buy a House.</Navbar.Text>
          <Navbar.Text className="white-text navbar-text">Right Now.</Navbar.Text>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto d-block d-sm-block d-md-block d-lg-none navbar-bg pb-2">
          <Nav.Link href="/"><Speedometer2 className="mx-2" size={16} />Dashboard</Nav.Link>
          <Nav.Link href="/rankings"><Award className="mx-2" size={16} />Rankings</Nav.Link>
          <Nav.Link href="/profile"><PersonAdd className="mx-2" size={16} />Profile</Nav.Link>
          <Nav.Link href="/watchlist"><StarFill className="mx-2" size={16} />Watchlist</Nav.Link>
          <Nav.Link href="/downloads"><CloudDownload className="mx-2" size={16} />Downloads</Nav.Link>
          <Nav.Link href="/premium"><Buildings className="mx-2" size={16} />Premium</Nav.Link>
          <Nav.Link href="/about"><QuestionCircle size={16} className="mx-2" />About</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      { appCtx.isAuthenticated && (<Navbar.Collapse className="justify-content-end pe-3 navbar-bg login-text">
          <Navbar.Text className="p-2">
            Signed in as: <a className="p-2" href="/profile">{getUsername(appCtx)}</a>
          </Navbar.Text>
        </Navbar.Collapse>)
      }
      { !appCtx.isAuthenticated && (<Navbar.Collapse className="justify-content-end pe-3 navbar-bg login-text">
          <Navbar.Text>
            <a href="/profile">Sign in</a>
          </Navbar.Text>
        </Navbar.Collapse>)
      }
    </Navbar>
    </React.Fragment>
  );
}

export default AppNav;