import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/material';
import ReactGA from "react-ga4"

export default function NationalvsStateToggle(props) {
  const [active, setActive] = React.useState(props.startToggle);

  const handleChange = (event, newValue) => {
    setActive(newValue)
    props.setMapModeTab(newValue);

    ReactGA.event({
      category: 'Toggle.Changed',
      action: 'NationalVsStateToggle Changed ',
      label: 'nationalvsstatetoggle.changed.'+newValue
    });
  };

  return (
    <Box 
    display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center">
      <ToggleButtonGroup
        fullWidth={true}
        style={{textAlign:"center"}}
        aria-label="Small sizes"
        size="small"
        value={active}
        exclusive={true}
        onChange={handleChange}>
        <ToggleButton exclusive="true" value="national">National Avgs</ToggleButton>
        <ToggleButton exclusive="true" value="state">State Avgs</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}