import React, { useState, useEffect, memo } from 'react';
import { Container } from 'react-bootstrap';
import MapChart from './MapChart';
import StateMapChart from './StateMapChart';
import ReactTooltip from 'react-tooltip'
import { isMobile } from '../../shared/common';


const CurrentMap = (props) => {
  const [tooltipContent, setTooltipContent] = useState({"label": "", "data": {}});

  useEffect(() => {
  }, [tooltipContent, props.mapData]);

    if (props.mapModeTab === "national") {
        return (
          <div>
            <MapChart className="nationalmapchart" popStop={props.popStop} setCitiesList={props.setCitiesList}
              geoModeTab={props.geoModeTab} mapData={props.mapData} setTooltipContent={setTooltipContent} 
              mapType={props.mapType} geoContent={props.geoContent} setGeoContent={props.setGeoContent} 
              mapCategory={props.mapCategory} dataHasArrived={props.dataHasArrived}/>
            <div hidden={isMobile()}>
              <ReactTooltip>{tooltipContent.label}</ReactTooltip>
            </div>
          </div>
        );
    } else {
      return (
        <div>
          <StateMapChart className={"statemapchart"} key={'statemap'} popStop={props.popStop} mapData={props.mapData}
            geoModeTab={props.geoModeTab} sliderDate={props.sliderDate} setTypedState={props.setTypedState}
            typedState={props.typedState} mapType={props.mapType} setTooltipContent={setTooltipContent}
            geoContent={props.geoContent} setGeoContent={props.setGeoContent} mapCategory={props.mapCategory}
            dataHasArrived={props.dataHasArrived} numMonths={props.numMonths}>
             </StateMapChart>
          <div hidden={isMobile()}>
            <ReactTooltip>{tooltipContent.label}</ReactTooltip>
          </div>
        </div>
      );
    }
    
  }

export default memo(CurrentMap);