import React, { useState, useEffect, useContext } from "react";
import './Stripe.css';
import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";
import { getStripeSession } from "../../service/stripeservice";
import AppContext from '../../state/app-context'
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import crosstabGif from '../../assets/crosstabs_gif.gif'
import sliderGif from '../../assets/date_slider_gif.gif'
import mapTypesGif from '../../assets/map_types_gif.gif'
import downloadsGif from '../../assets/download_gif_1.gif'
import downloadsGif2 from '../../assets/download_gif_2.gif'
import homeScreen from '../../assets/home_screen.png'
import categoriesScreen from '../../assets/categories.png'
import rankingsScreen from '../../assets/rankings_screen.png'
import pastChangesScreen from '../../assets/past_changes.png'
import sliderScreen from '../../assets/slider.png'

import freeTypes from '../../assets/free_types_1.png'
import strTypes from '../../assets/str_types.png'
import pastTypes from '../../assets/past_types.png'
import soldTypes from '../../assets/sold_types.png'
import rentTypes from '../../assets/rent_types.png'
import climateTypes from '../../assets/climate_types.png'
import communityTypes from '../../assets/community_types.png'

import ReactGA from "react-ga4"
import CrossTabHype from "./CrossTabHype";

const HypeFlow = (props) => {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();
  const [scope, setScope] = useState('tycoon')

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + '/paywall' });
  }, []);

  function landlordScope() {
    if (props.profile !== undefined && props.profile !== "serf") {
      return
    }
    if (props.scope == "landlord" || props.scope == "tycoon") {
      return (
        <Container>
          <div className="card aboutme-card">
              <div className="aboutme-header">
                <h3>What do you get with a Landlord Subscription?</h3>
              </div>
            </div>
              <CrossTabHype></CrossTabHype>
            <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
              <Col className="stripe-return-box" xs={12} sm={12} md={12} lg={6}>
                <h5 className="stripe-text">
                  Travel Back in Time
                </h5>
                <p className="bullets-left">View all map types from any month your heart desires</p>
                <p className="bullets-left">Access to All Past Trends (monthly % changes in price, days on market, price drops etc.)</p>
                <p className="bullets-left">All County and City subset data, but in the past</p>
                <p className="bullets-left">All housing data trends at your fingertips. Feel the raw power </p>
                <p className="bullets-left">Works for cities and counties alike!</p>
                <p className="bullets-left">Works for national and state averages</p>
                <p className="bullets-left">Map data begins on 04/2023</p>
                <p className="bullets-left">Date Slider unlocked for ALL available map types</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <p className="stripe-text centered"> % Changes to Median Price over Time</p>
                <img className="thumbnail" src={pastChangesScreen} alt="loading..." />
                <img className="thumbnail" src={sliderScreen} alt="loading..." />
              </Col>
            </Row>
            <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
                <h5 className="stripe-text hype-header">
                  Access ALL Map Types
                </h5>
                <Row>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">All Categories</h3>
                  <img className="thumbnail" src={categoriesScreen} alt="loading..." />
                </Col>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">Active Maps (free)</h3>
                  <img className="thumbnail" src={freeTypes} alt="loading..." />
                </Col>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">Past Maps (1m, 3m, 6m, 12m)</h3>
                  <img className="thumbnail" src={pastTypes} alt="loading..." />
                </Col>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">Short Term Listing Maps (airbnb/vrbo)</h3>
                  <img className="thumbnail" src={strTypes} alt="loading..." />
                </Col>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">Sold Maps</h3>
                  <img className="thumbnail" src={soldTypes} alt="loading..." />
                </Col>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">Rent Maps</h3>
                  <img className="thumbnail" src={rentTypes} alt="loading..." />
                </Col>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">Community Maps (free)</h3>
                  <img className="thumbnail" src={communityTypes} alt="loading..." />
                </Col>
                <Col lg={3} sm={6} md={3} xs={12}>
                  <h3 className="bullets-left-no-space hype-color">Climate Maps (free)</h3>
                  <img className="thumbnail" src={climateTypes} alt="loading..." />
                </Col>
                </Row>
            </Row>
          </Container>
      )
    }
  }

  function tycoonScope() {
    if (props.profile !== undefined && props.profile !== "serf") {
      return
    }
    if (props.scope == "tycoon") {
      return (
        <Container>
          <div className="card aboutme-card">
              <div className="aboutme-header">
                <h3>What do you get with a Tycoon Subscription?</h3>
              </div>
            </div>
          <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
              <Col className="stripe-return-box" xs={12} sm={12} md={12} lg={6}>
                <h5 className="stripe-text">
                  Download and Become One with the Data </h5>
                  <p className="bullets-left">Data is updated daily!</p>
                  <p className="bullets-left">Everything you see on the site is downloadable</p>
                  <p className="bullets-left">With this subscription you are licensed to do whatever you want with the data, as long as you credit us.</p>
                  <p className="bullets-left">Download .csv quick and easy</p>
                  <p className="bullets-left">Cities and counties alike</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <p className="stripe-text centered"> National Data Download </p>
                <img className="thumbnail" src={downloadsGif} alt="loading..." />
              </Col>
            </Row>
            <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
              <Col className="stripe-return-box" xs={12} sm={12} md={12} lg={6}>
                <h5 className="stripe-text">
                  Download Targeted Data </h5>
                <p className="bullets-left">Personalize your needs by only downloading a state's data</p>
                <p className="bullets-left">National Data, State Data, State Averages and everything between</p>
                <p className="bullets-left">State Averages allow you to find the best deals within a state</p>
                <p className="bullets-left">With State Averages, county/city averages are computed against ONLY other counties/cities within the state</p>

              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <p className="stripe-text centered"> State Data Download </p>
                <img className="thumbnail" src={downloadsGif2} alt="loading..." />
              </Col>
            </Row>
          </Container>
      )
    }
  }

  function demoScope() {
    if (props.profile !== undefined && props.profile !== "serf") {
      return
    }

    if (props.scope == "demo") {
      return (
        <Container>
          <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
              <Col className="stripe-return-box" xs={12} sm={12} md={12} lg={6}>
                <h5 className="stripe-text">
                  Welcome to Hidden Housing </h5>
                  <p className="bullets-left">We scrape ALL the housing data, summarize and make it pretty.</p>
                  <p className="bullets-left">Our sources: Major MLS sites (you can guess them), and the major short term rental sites</p>
                  <p className="bullets-left">How does it work? We scan all the listings every single day, average and compare them for every county and city</p>
                  <p className="bullets-left">We find you the best housing deals in the country. Every single day.</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <p className="stripe-text centered"> Maps Galore </p>
                <img className="thumbnail" src={homeScreen} alt="loading..." />
              </Col>
            </Row>
            <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
              <Col className="stripe-return-box" xs={12} sm={12} md={12} lg={6}>
                <h5 className="stripe-text">Every datapoint. Ranked.</h5>
                  <p className="bullets-left">Each datapoint is ranked daily by county, city and state.</p>
                  <p className="bullets-left">Ex. Easily find the cities/counties with the biggest price declines in the last 6 months</p>
                  <p className="bullets-left">Ex. Find the cities/counties that have the largest housing inventory</p>
                  <p className="bullets-left">Reverse all rankings to find the inverse (most expensive/fewest houses)</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <p className="stripe-text centered"> County Data </p>
                <img className="thumbnail" src={rankingsScreen} alt="loading..." />
              </Col>
            </Row>
            
          </Container>
      )
    }
  }

    return (
      <Container>
        {demoScope()}
        {landlordScope()}
        {tycoonScope()}
      </Container>
    )
}


export default HypeFlow;