import { useState, useEffect, useContext, memo} from 'react';
import AppContext from '../../state/app-context';
import { BsStar, BsStarFill } from 'react-icons/bs';
import "./watchlist.css";
import { Button } from 'react-bootstrap';
import { updateWatchlist } from '../../service/thermostatservice';
import { isMapNonEmpty } from '../../shared/common';
import ReactGA from "react-ga4"

function AddToWatchlist(props) {
  const appCtx = useContext(AppContext);
  const [currGeo, setCurrGeo] = useState(null);
  const [state, setState] = useState(null)

  const handleChange = (event) => {
    addToWatchlist(currGeo)
  };

  async function addToWatchlist(currGeo) {
    let state = null
    let action = null
    if (isOnWatchlist()) {
      state = "unwatched"
      action = "remove"

      ReactGA.event({
        category: 'Watchlist.Remove',
        action: 'Watchlist Remove ',
        label: 'watchlist.remove.'+currGeo["state"] + '.' +getGeoId(currGeo)
      });
      appCtx.watchlistItems[(getGeoId(currGeo))] = undefined
    } else {
      state = "watched"
      action = "add"

      ReactGA.event({
        category: 'Watchlist.Add',
        action: 'Watchlist Add ',
        label: 'watchlist.add.'+currGeo["state"] + '.' +getGeoId(currGeo)
      });
    }
    setState(state)
    let watchlistResponse = await updateWatchlist(appCtx, action, currGeo)

    if (watchlistResponse["meta"] == 200) {
      appCtx.setRefreshElement("watchlist")
    }
  }

  function getGeoId(currGeo){
    let geoId = (currGeo["county_id"] !== undefined  || currGeo["county_id"] !== null) ? currGeo["county_id"] : currGeo["city_id"]
    return geoId
  }

  function isOnWatchlist() {
    // only depend on actually watchlist for state before state is set, once state is set we go on that

    if (state == null && currGeo !== null && isMapNonEmpty(appCtx.watchlistItems)) {
      let geoId = getGeoId(currGeo)
      if (appCtx.watchlistItems[geoId] !== undefined) {
        setState("watched")
        return true
      }
    } else if (state == null) {
      return false
    } else {

      if (state == "unwatched") {
        return false
      } else if (state == "watched") {
        return true
      }
    }
  }

  useEffect(() => {

    if (props.currGeo !== undefined && props.currGeo !== null) {
      setCurrGeo(props.currGeo)
      setState(null)
    }
  }, [props.currGeo]);

  return (
    <div>
      <Button
        className="dark-font-color watchlist-button"
        aria-label="Small sizes"
        size="small"
        onClick={handleChange}
      >
        <BsStarFill className="watchlist-icon" hidden={!isOnWatchlist()}></BsStarFill>
        <BsStar className="watchlist-icon" hidden={isOnWatchlist()}></BsStar>
      </Button>
      </div>
  );
}

export default memo(AddToWatchlist);