import React from 'react';
    import { Helmet } from 'react-helmet-async';

    function HelmetTags(props) {
      return (
        <div>
          <Helmet prioritizeSeoTags>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
        </div>
      );
    }
    
    export default HelmetTags;