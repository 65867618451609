import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import App from './App';
import MainLayout from './layout/MainLayout';
import reportWebVitals from './reportWebVitals';
import AppContext from './state/app-context';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { checkRefresh, getSession, createSession } from './service/sessionservice'

import ReactGA from "react-ga4"
  const HOUSING_HEATMAP_TRACKER = "G-Z9THN93YRC"
  const HIDDEN_HOUSING_TRACKER = "G-LMLX8NVQ68"

// Pages
import ErrorPage from './pages/ErrorPage';
import DashboardPage from './pages/DashboardPage';
import AccountState from './pages/AccountState';
import AboutPage from './pages/AboutPage';
import { useState, useContext, useEffect } from 'react';
import DownloadsPage from './pages/DownloadsPage';
import ProfilePage from './pages/ProfilePage';
import StripeReturn from './componets/Subscriptions/StripeReturn';
import StripeCheckoutForm from './componets/Subscriptions/StripeCheckoutForm';
import StripeFlow from './componets/Subscriptions/StripeFlow';
import SubscriptionOptions from './componets/Subscriptions/SubscriptionOptions';
import WatchlistPage from './pages/WatchlistPage';
import { DEFAULT_MAP_KEY, HIDDEN_HOUSING_NAME, HOUSING_HEATMAP_NAME } from './shared/common';
import RankingsPage from './pages/RankingsPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import LinksPage from './pages/LinksPage';
ReactGA.initialize(HIDDEN_HOUSING_TRACKER)


const HousingApp = () => {
  const appCtx = useContext(AppContext);
  const [refreshToken, setRefreshToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [scope, setScope] = useState(null)
  const [subUrl, setSubUrl] = useState(null)
  const [selectedGeo, setSelectedGeo] = useState(null)
  const [watchlistItems, setWatchlistItems] = useState(null)
  const [refreshElement, setRefreshElement] = useState(null)
  const [colors, setColors] = useState(null)
  const [mapKeyRange, setMapKeyRange] = useState(DEFAULT_MAP_KEY)

  // hide login if we already have a username saved, we do this elsewhere but its better to do it at the top
  if (user == null) {
    let username = localStorage.getItem('hh-username')
    if (username !== null) {
      setUser({"username": username})
    }
  }

  if (colors == null) {
    let colorPref = localStorage.getItem("colors")
    if (colorPref !== null) {
      setColors(colorPref)
    } else {
      localStorage.setItem('colors', 'default')
      setColors('default')
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <DashboardPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/links/:stateP/:geoModeP",
      element: <LinksPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/map/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP/:stateP/:geoIdP/:geoNameP",
      element: <DashboardPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/map/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP/:geoIdP/:geoNameP",
      element: <DashboardPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/map/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP/:stateP",
      element: <DashboardPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/map/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP",
      element: <DashboardPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/rankings",
      element: <RankingsPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/rankings/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP/:stateP/:geoIdP/:geoNameP",
      element: <RankingsPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/rankings/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP/:geoIdP/:geoNameP",
      element: <RankingsPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/rankings/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP/:stateP",
      element: <RankingsPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/rankings/:mapModeP/:geoModeP/:mapCategoryP/:mapTypeP",
      element: <RankingsPage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/about",
      element: <AboutPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/tos",
      element: <TermsOfServicePage />,
      errorElement: <TermsOfServicePage />,
    },
    {
      path: "/login",
      element: <AccountState />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/downloads",
      element: <DownloadsPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/watchlist",
      element: <WatchlistPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/premium",
      element: <SubscriptionOptions />,
      errorElement: <SubscriptionOptions />,
    },
    {
      path: "/stripe-checkout",
      element: <StripeFlow />,
      errorElement: <StripeFlow />,
    },
    {
      path: "/return",
      element: <StripeReturn />,
      errorElement: <StripeReturn />,
    },

  ]);

  return (
    
    <HelmetProvider>
      <AppContext.Provider 
        value={{ 
          isAuthenticated, setIsAuthenticated,
          token, setToken, 
          deviceId, setDeviceId,
          user, setUser,
          refreshToken, setRefreshToken,
          scope, setScope, 
          subUrl, setSubUrl,
          selectedGeo, setSelectedGeo,
          watchlistItems, setWatchlistItems,
          refreshElement, setRefreshElement,
          colors, setColors,
          mapKeyRange, setMapKeyRange
          }}>
        <MainLayout className="wrapper">
          <RouterProvider router={router} />
        </MainLayout>
      </AppContext.Provider>
    </HelmetProvider>
  );
  }

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<HousingApp />)
  //ReactDOM.render(<App/>, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
