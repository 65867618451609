import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import {numberWithCommas, grabPopulation, getAttrLabel, shouldHideHeader, getCardTitle, removeNonNumericExceptDot, isMapNonEmpty} from '../../shared/common';

import { Col, Container, Row } from 'react-bootstrap';
import Indicator from './Indicator';
import { Box } from '@mui/material';

function WeatherCards(props) {
  const [weatherData, setWeatherData] = useState(null)
  const [benchmarks, setBenchmarks] = useState(null)
  const [stateBenchmarks, setStateBenchmarks] = useState(null)
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});
  const [loadingData, setLoadingData] = useState(null)

  useEffect(() => {
   
   if (props.countyData !== undefined && props.countyData["weather"] !== undefined) {
      // we only care about the weather type so lets only save that part
      setLoadingData(false)
      setWeatherData(props.countyData["weather"])
   }

 }, [props.countyData]);

 useEffect(() => {
   if (isMapNonEmpty(props.selectedCounty) && props.selectedCounty.county_id !== selectedCounty.county_id) { 
      setLoadingData(true)
      setSelectedCounty(props.selectedCounty)
   }

 }, [props.selectedCounty]);

 useEffect(() => {
   
   if (props.benchmarks !== undefined && props.benchmarks.benchmark_community !== undefined) {
      // we only care about the weather type so lets only save that part
      setBenchmarks(props.benchmarks.benchmark_community.weather)
   }

 }, [props.benchmarks]);

 useEffect(() => {
   
   if (props.stateBenchmarks !== undefined && props.stateBenchmarks.data !== undefined && props.stateBenchmarks.data.benchmark_community !== undefined) {
      // we only care about the weather type so lets only save that part
      if (Object.keys(props.stateBenchmarks.data.benchmark_community.weather).length > 0) {
         let stateData = props.stateBenchmarks.data.benchmark_community.weather
         stateData.state = props.stateBenchmarks.data["state"]
         setStateBenchmarks(stateData)
      }
   }

 }, [props.stateBenchmarks]);

  
function getAttrValues(attr, from) {
   if (from === undefined || from == null) {
     return
   }
   let response = ""
   let responseBare = ""
   if (from[attr] !== undefined && from[attr] !== null) {
 
     if (attr == "winter_score" || attr == "summer_score") {
       responseBare = parseInt(from[attr])
       response = numberWithCommas(responseBare)
 
     }
     if (attr == "snowfall" || attr == "rainfall") {
      response = removeNonNumericExceptDot(from[attr]) + " in."
      responseBare = parseFloat(removeNonNumericExceptDot(from[attr]))
     }
     if (attr == "avg_winter_low" || attr == "avg_summer_high") {
      response = removeNonNumericExceptDot(from[attr]) + "°F"
      responseBare = parseFloat(removeNonNumericExceptDot(from[attr]))
     }
     if (attr == "sunny_days" || attr == "precipitation_days") {
      response = removeNonNumericExceptDot(from[attr])
      responseBare = parseFloat(removeNonNumericExceptDot(from[attr]))
     }
     if (attr == "uv_index") {
      response = removeNonNumericExceptDot(from[attr])
      responseBare = parseFloat(removeNonNumericExceptDot(from[attr]))
     }
     if (attr == "elevation") {
      response = removeNonNumericExceptDot(from[attr])
      if (response.lastIndexOf('.') == response.length-1) {
         response = response.substring(0, response.lastIndexOf('.'))
      }
      response = numberWithCommas(response) + " ft."
      responseBare = parseFloat(removeNonNumericExceptDot(from[attr]))
     }
     if (attr == "total_score") {
      return from[attr]
     }
   }
 
   return { str: response, num: responseBare}
 }

 function getAttrImage(attr) {
   if (attr == "winter_score") {
      return "bi-snow3"
    }
    if (attr == "summer_score") {
      return "bi-emoji-sunglasses-fill"
    }
    if (attr == "snowfall") {
     return "bi-cloud-snow-fill"
    }
    if (attr == "rainfall") {
      return "bi-cloud-drizzle-fill"
    }
    if (attr == "avg_winter_low") {
      return "bi-thermometer-snow"
    }
    if (attr == "avg_summer_high") {
      return "bi-thermometer-sun"
    }
    if (attr == "sunny_days") {
      return "bi-brightness-high-fill"
    }
    if (attr == "precipitation_days") {
      return "bi-cloud-drizzle-fill"
    }
    if (attr == "uv_index") {
      return "bi-brightness-alt-high-fill"
    }
    if (attr == "elevation") {
      return "bi-signpost-split-fill"
    }
 }

 function getAttrDesired(attr) {
   if (attr == "winter_score") {
      return "higher"
    }
    if (attr == "summer_score") {
      return "higher"
    }
    if (attr == "snowfall") {
     return "higher"
    }
    if (attr == "rainfall") {
      return "higher"
    }
    if (attr == "avg_winter_low") {
      return "higher"
    }
    if (attr == "avg_summer_high") {
      return "lower"
    }
    if (attr == "sunny_days") {
      return "higher"
    }
    if (attr == "precipitation_days") {
      return "lower"
    }
    if (attr == "elevation") {
      return "higher"
    }
    if (attr == "uv_index") {
      return "lower"
    }
 }

  
function StatBubble(props) {
   if (weatherData === null || weatherData === undefined) {
      return
   }

   let index = props.index
   let hideHeader = shouldHideHeader(index)
   let attr1Array = props.attr1
   let attr2Array = props.attr2

   let attrs = []

   for (let i=0; i< attr1Array.length; i++) {
      let attr = attr1Array[i]
      let newObj = {}
      let bigObj = {}
      newObj["county"] = getAttrValues(attr, weatherData)
      newObj["label"] = getAttrLabel(attr)
      newObj["state"] = getAttrValues(attr, stateBenchmarks)
      newObj["national"] = getAttrValues(attr, benchmarks)
      newObj["image"] = getAttrImage(attr)
      newObj["desired"] = getAttrDesired(attr)

      bigObj["attr1"] = newObj

      let newObj2 = {}
      attr = attr2Array[i]
      newObj2["county"] = getAttrValues(attr, weatherData)
      newObj2["label"] = getAttrLabel(attr)
      newObj2["state"] = getAttrValues(attr, stateBenchmarks)
      newObj2["national"] = getAttrValues(attr, benchmarks)
      newObj2["image"] = getAttrImage(attr)
      newObj2["desired"] = getAttrDesired(attr)

      bigObj["attr2"] = newObj2

      attrs.push(bigObj)
   }

   let firstClass = "card card-background-flood mb-3"
   let secondClass = "card card-background mb-3"

   if (selectedCounty.county !== null && weatherData !== null && stateBenchmarks !== null && benchmarks !== null) {
     return (
       <div>
         <Row className="mt-2">
           <Col md={12} lg={4}>
             <div className={firstClass}>
               <div hidden={hideHeader} className="card-header card-header-flood">{getCardTitle("county", selectedCounty)}</div>
               <div className="card-body">
                 <Row>
                  <Col xs={6} sm={6} md={6} lg={6} className="spacer">
                     <div hidden={hideHeader}><h5  className="card-subheader">Winter</h5>
                     <hr className="separator" /></div>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                     <div hidden={hideHeader}><h5  className="card-subheader">Summer</h5>
                     <hr className="separator" /></div>
                  </Col>
                  </Row>
                  {attrs.map((row) => (
                  <Row>
                     <Col xs={6} sm={6} md={6} lg={6} className="spacer">
                        <h6 className="card-title">{row["attr1"]["label"]}</h6>
                        <p className="card-text">{row["attr1"]["county"].str}
                           <Indicator image={row["attr1"]["image"]} desired={row["attr1"]["desired"]} number={row["attr1"]["county"].num} stateAvg={row["attr1"]["state"].num} nationalAvg={row["attr1"]["national"].num} ></Indicator>
                        </p>
                     </Col>
                     <Col xs={6} sm={6} md={6} lg={6}>
                        <h6 className="card-title">{row["attr2"]["label"]}</h6>
                        <p className="card-text">{row["attr2"]["county"].str}
                           <Indicator image={row["attr2"]["image"]} desired={row["attr2"]["desired"]} number={row["attr2"]["county"].num} stateAvg={row["attr2"]["state"].num} nationalAvg={row["attr2"]["national"].num} ></Indicator>
                        </p>
                     </Col>
                  </Row>
                 ))}
               </div>
             </div>
           </Col>
           <Col xs={12} sm={12} md={12} lg={4}>
             <div className={secondClass}>
               <div hidden={hideHeader} className="card-header">State Averages: {selectedCounty.state}</div>
                  <div className="card-body">
                     <Row>
                        <Col xs={6} sm={6} md={6} lg={6} className="spacer">
                           <div hidden={hideHeader}><h5  className="card-subheader">Winter</h5>
                           <hr className="separator" /></div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                           <div hidden={hideHeader}><h5  className="card-subheader">Summer</h5>
                           <hr className="separator" /></div>
                        </Col>
                        </Row>
                        {attrs.map((row) => (
                        <Row>
                           <Col xs={6} sm={6} md={6} lg={6} className="spacer">
                              <h6 className="card-title">{row["attr1"]["label"]}</h6>
                              <p className="card-text">{row["attr1"]["state"].str}</p>
                           </Col>
                           <Col xs={6} sm={6} md={6} lg={6}>
                              <h6 className="card-title">{row["attr2"]["label"]}</h6>
                              <p className="card-text">{row["attr2"]["state"].str}</p>
                           </Col>
                        </Row>
                     ))}
                  </div>
               </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
             <div className={firstClass}>
               <div hidden={hideHeader} className="card-header card-header-flood">National Averages </div>
                  <div className="card-body">
                     <Row>
                        <Col xs={6} sm={6} md={6} lg={6} className="spacer">
                           <div hidden={hideHeader}><h5  className="card-subheader">Winter</h5>
                           <hr className="separator" /></div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                           <div hidden={hideHeader}><h5  className="card-subheader">Summer</h5>
                           <hr className="separator" /></div>
                        </Col>
                        </Row>
                        {attrs.map((row) => (
                        <Row>
                           <Col xs={6} sm={6} md={6} lg={6} className="spacer">
                              <h6 className="card-title">{row["attr1"]["label"]}</h6>
                              <p className="card-text">{row["attr1"]["national"].str}</p>
                           </Col>
                           <Col xs={6} sm={6} md={6} lg={6}>
                              <h6 className="card-title">{row["attr2"]["label"]}</h6>
                              <p className="card-text">{row["attr2"]["national"].str}</p>
                           </Col>
                        </Row>
                     ))}
                  </div>
               </div>
            </Col>
         </Row>
       </div>
     )
   }
 }

  return (
   <Container key={"weather-"+JSON.stringify(selectedCounty)} hidden={selectedCounty == null || selectedCounty.county == null} >
      <div hidden={loadingData == true}>
      <Col md={12} lg={11} className="mx-auto">
         <Row>
            <Col md={6} lg={4}>
               <div className="card card-background card-stats card-key mb-3">
               <Box display="block-inline">
                  <Row>
                  <Col md={6} lg={6}>
                     <div className="text-centered bold">{getCardTitle(props.geoModeTab, selectedCounty)}</div>
                  </Col>
                  <Col md={6} lg={6}>
                     <div className="text-centered">{"Weather Score: " + getAttrValues("total_score", weatherData)}</div>
                     </Col>
                     </Row>
                  </Box>
               </div>
            </Col>
            <Col md={6} lg={7}>
               <div className="card card-stats card-key mb-3">
                  <Row>
                     <Col md={6} lg={6}>
                        <span> 
                           <i className="bi good-indicators bi-snow3 strong-indicator"></i>
                           <i className="bi good-indicators bi-brightness-high-fill strong-indicator"/>
                           <i className="bi good-indicators strong-indicator"> Better than state AND national</i>
                        </span>
                     </Col>
                     <Col md={6} lg={6}>
                        <span>
                           <i className="bi good-indicators bi-cloud-snow-fill weak-indicator"></i>
                           <i className="bi good-indicators bi-emoji-sunglasses-fill weak-indicator"/>
                           <i className="bi good-indicators weak-indicator"> Better than state OR national</i>
                        </span>
                     </Col>
                  </Row>
               </div>
            </Col>
         </Row>
         </Col>
      <Row>
         <Col>
            <StatBubble score={props.score} attr1={["winter_score", "snowfall", "avg_winter_low"]} attr2={["summer_score", "rainfall", "avg_summer_high"]} index={0}></StatBubble>
            <StatBubble score={props.score} attr1={["sunny_days"]} attr2={["precipitation_days"]} index={1}></StatBubble>
            <StatBubble score={props.score} attr1={["elevation"]} attr2={["uv_index"]} index={3}></StatBubble>
         </Col>
      </Row>
      </div>
      <Row hidden={loadingData == false}>
      <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
    </Row>
 </Container>
  );
}

export default memo(WeatherCards);