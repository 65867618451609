import React, { useState, useEffect, useContext } from "react";
import {loadStripe} from '@stripe/stripe-js';
import './Stripe.css';
import AppContext from '../../state/app-context';
import { Col, Row } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import { Button } from "@mui/material";

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";
import { currentlyLoggedIn, getUsername, isInScope } from "../../shared/common";
import HypeFlow from "./HypeFlow";


const SubscriptionOptions = (props) => {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();

  function subscribeUser(subscriptionLevel) {
    console.log('clicked subscribe: ' + subscriptionLevel)

    if (appCtx.isAuthenticated && appCtx.user !== undefined && appCtx.user !== null &&
       appCtx.user.username !== undefined && appCtx.user.username !== null) {
      navigate("/stripe-checkout#"+subscriptionLevel, { replace: false })
    } else {
      navigate("/profile", { replace: false })
    }
  }

  function loginOrCreateAccount() {
    console.log('clicked account create')
    return (
      navigate("/profile", { replace: false })
    )
  }

  return (
    <div className="subscription-options">
      <Row>
        <Col xs={12} sm={12} md={4} lg={4}>
          <div className="card serf-card-background mb-3">
            <div className="card-body">
              <Row>
                <div>
                  <h5  className="card-subheader text-centered">Serf (Free)</h5>
                  <hr className="sub-separator" />
                </div>
                <span hidden={currentlyLoggedIn(appCtx)}>
                  <Button className="sub-button" variant="contained" color="success" onClick={()=> loginOrCreateAccount()}>
                    Create an Account or Login
                  </Button>
                </span>
                <span hidden={!currentlyLoggedIn(appCtx)}>
                  <div className="account-status"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p">{getUsername(appCtx)} has access</p></span></div>
                </span>
                <Col md={12} lg={12} className="sub-spacer">
                  <h6 className="card-title">Averages, stats and data included: </h6>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Most maps</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Rankings for all data types (except short term listings and sold)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Counties</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Cities</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Sold Listings</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Active Listings</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Land Listings </p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Rentals</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Short Term Rentals (Airbnb/VRBO)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Past Averages/Trends (1m and score)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Community Data and Maps (crime, diversity, politics, lgbtq)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Economic Data and Maps </p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Weather and Climate Data and Maps</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Cost of Living Data</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Watchlist</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> AI - Ask questions about any place</p></span></div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <div className="card serf-card-background mb-3">
            <div className="card-body">
              <Row>
                <div>
                  <h5  className="card-subheader text-centered">Landlord ($25 / month)</h5>
                  <hr className="sub-separator" />
                </div>
                <span hidden={isInScope(appCtx, "landlord")}>
                  <Button className="sub-button" variant="contained" color="success" onClick={()=> subscribeUser("landlord")}>
                    Sign Up
                  </Button>
                </span>
                <span hidden={!isInScope(appCtx, "landlord")}>
                  <div className="account-status"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p">{getUsername(appCtx)} has access</p></span></div>
                </span>
                <Col md={12} lg={12} className="sub-spacer">
                  <h6 className="card-title">Averages, stats and data included: </h6>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Everything in free tier</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Date Slider Unlocked - See past year of maps</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Short Term Rental Maps (occupancy rates, estimated annual revenue)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Sold Data Maps and Rankings (sold median price, days on market, number of listings etc.)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> All Past Maps - See the biggest % increases/decreases in last 3, 6, and 12 months</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Watchlist Deals - Find houses that are underpriced and undervalued</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Short Term Rental Rankings (National and State)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Active Listing Crosstab Data by beds/baths/sqft</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Sold Listing Crosstab Data by beds/baths/sqft</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Land Crosstab Data (by number of acres)</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Rental Crosstab Data by beds/baths/sqft</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Short Term Listing (Airbnb/VRBO) Crosstab Data by beds/baths/sqft</p></span></div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <Row>
          <div className="card serf-card-background mb-3">
            <div className="card-body">
              <Row>
                <div>
                  <h5  className="card-subheader text-centered">Tycoon ($250 / month)</h5>
                  <hr className="sub-separator" />
                </div>
                <span hidden={isInScope(appCtx, "tycoon")}>
                  <Button className="sub-button" variant="contained" color="success" onClick={()=> subscribeUser("tycoon")}>
                    Sign Up
                  </Button>
                </span>
                <span hidden={!isInScope(appCtx, "tycoon")}>
                  <div className="account-status"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p">{getUsername(appCtx)} has access</p></span></div>
                </span>
                <Col md={12} lg={12} className="sub-spacer">
                  <h6 className="card-title">Averages, stats and data included: </h6>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Everything in Serf (free) Tier</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Everything in Landlord Tier</p></span></div>
                  <div className="bullets"><span><CheckCircleFill className="map-color-best"/><p className="card-text sub-p"> Download current and past data as CSV. Do whatever you want with it.</p></span></div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="card serf-card-background mb-3">
            <div className="card-body">
              <Row>
                <div>
                  <h5  className="card-subheader text-centered">API Access (Contact me)</h5>
                  <hr className="sub-separator" />
                </div>
                <Col md={12} lg={12} className="sub-spacer">
                  <h6 className="card-title">Email: hiddenhousing@outlook.com </h6>
                </Col>
              </Row>
            </div>
          </div>
          </Row>
        </Col>
      </Row>
      <HypeFlow profile={props.profile} scope={"tycoon"}></HypeFlow>
    </div>
  )
}

export default SubscriptionOptions;