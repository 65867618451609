import { useState, useEffect, useContext, memo} from 'react';
import { isMapNonEmpty, countyIdToName, currentlyLoggedIn, numberWithCommas, isGeoIdCity, fipsToCountyId, isInScope } from '../../shared/common';
import "./watchlist.css";
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import WatchlistSortCombobox from './WatchlistSortCombobox';
import AppContext from '../../state/app-context';
import { getListingDeals } from '../../service/thermostatservice';
import ListingDealsCombobox from './ListingDealsCombobox';
import { Button } from '@mui/material';
import watchlistGif from '../../assets/watchlist_deals.gif'

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";
import ReactGA from "react-ga4"

//const BASE_URL = "https://www.bing.com/images/search?q="
//const BASE_REDFIN = "http://www.redfin.com"
const BASE_URL = "https://www.bing.com/search?q="

const ListingDeals = (props) => {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();
  const [currSort, setCurrSort] = useState("all");
  const [lastSort, setLastSort] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [listingArray, setListingArray] = useState([])
  const [selectedListing, setSelectedListing] = useState(null)
  const [subsetMedian, setSubsetMedian] = useState("")

  useEffect(() => {
    if (isMapNonEmpty(props.selectedGeo) && props.selectedGeo["geo_id"] !== selectedItem) {
      setSelectedItem(props.selectedGeo)
      setSubsetMedian("")
      setListingArray([])
      fetchListingDeals(props.selectedGeo, currSort)
    
    }
  }, [props.selectedGeo])

  useEffect(() => {
    if (isMapNonEmpty(props.selectedGeo) && currSort !== lastSort) {
      setLastSort(currSort)
      setListingArray([])
      setSelectedListing(null)
      fetchListingDeals(selectedItem, currSort)
    
    }
  }, [currSort])

  function viewSubOptions() {
    return (
      navigate("/premium", { replace: false })
    )
  }

  function hideLoader() {
    if (!currentlyLoggedIn(appCtx)) {
      return true
    }
    if (isMapNonEmpty(selectedItem)) {
      // if a geo is clicked, but listingArry is empty, display
      if (listingArray.length == 0) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  function listingItemClicked(row, detail) {

    setSelectedListing(row)
    ReactGA.event({
      category: 'Watchlist.Deals.Click',
      action: 'Watchlist Deals Listing Click',
      label: 'watchlist.deals.listing.click.'+ detail,
    });
  }

  function createLink(row) {
    let base = "http://redfin.com" + row["link"]
    return base
  }

  async function fetchListingDeals(selected, sort) {
    if (selected == null) {
      return
    }

    let geoId = selected["geo_id"]

    // for cities just show listings in the county who gives a shit
    if (isGeoIdCity(selected["geo_id"])) {
      let countyInfo = fipsToCountyId(selected["fips"])
      if (countyInfo !== null && countyInfo !== undefined) {
        geoId = countyInfo["county_id"]
      }
    }
  
    let listingDealsResp = await getListingDeals(appCtx, geoId, sort)

    if (listingDealsResp["meta"] == 200 && isMapNonEmpty(listingDealsResp["data"])) {
      let listings = listingDealsResp["data"]["listings"]
      let medianPrice = listingDealsResp["data"]["median_price"]
      setSubsetMedian(medianPrice)
      setListingArray(listings)
    }
  }

  function getImageLink(row) {
    if (row == null) {
      return ""
    }
    // 220+N+Michigan+St%2C+Argos%2C+IN+redfin&qs=n&form=QBRE
    let link = BASE_URL + row["address"] + ' redfin&qs=n&form=QBRE'
    link = link.replaceAll(' ', '+')
    return link
  }

  function getGeoClasses(geoId) {
    if (selectedListing === null) {
      return 'listing-row'
    }

    if (selectedListing["listing_id"] == geoId) {
      return 'listing-row listing-row-selected'
    }
    return 'listing-row'
  }

  function generateKey() {
    if (isMapNonEmpty(props.selectedGeo)) {
      return props.selectedGeo["geo_id"]
    } else {
      
    }
  }

  function ShowPayWall(props) {
    if (isInScope(props.appCtx, "landlord")) {
      return
    } else {
      return (
        <Container>
          <Row className="paywall-subset">
            <Col xs={12} sm={12} md={6} lg={6}>
              <p className="dark-primary text-centered"> Landlord subscription is required for Watchlist Deals. Its quite powerful.</p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Button className="profile-subset-button" variant="contained" color="success" onClick={()=> viewSubOptions()}>
                What will it cost me?
              </Button>
              </Col>
          </Row>
          <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
          <Col xs={12} sm={12} md={12} lg={6}>
              <p className="stripe-text centered"> Watchlist Deals</p>
              <img className="thumbnail" src={watchlistGif} alt="loading..." />
            </Col>
            <Col className="stripe-return-box" xs={12} sm={12} md={6} lg={6}>
              <h5 className="stripe-text">
                Quickly view best value listings from your watched locations </h5>
              <p className="bullets-left">Sort by number of beds, baths or square feet</p>
              <p className="bullets-left">See listings that are undervalued and underpriced</p>
              <p className="bullets-left">Listings are updated daily</p>
              <p className="bullets-left">Be the first to find the best deals on the most affordable homes.</p>
            </Col>
          </Row>
        </Container>
      )
    }
  }

  if (currentlyLoggedIn(appCtx)) {

      return (
        <div>
        <Container className="listings-container">
          <Row className="listings-header">
            <div className='sidebar-title'>
            <p className="watchlist-header-txt">{"Best Deals"}</p>
            </div>
            <hr className='separator-watchlist'></hr>
            <Col xs={6} sm={6} md={6} lg={6} className="listing-combobox">
              <ListingDealsCombobox currSort={currSort} setCurrSort={setCurrSort}></ListingDealsCombobox>
            </Col>
            <Col hidden={subsetMedian == ""} xs={6} sm={6} md={6} lg={6} className="listing-median-price">
              <p>{"Median Price: $" + numberWithCommas(subsetMedian)}</p>
            </Col>
          </Row>

          <ShowPayWall appCtx={appCtx}></ShowPayWall>
          
          <Row className="listing-viewer" hidden={!isInScope(appCtx, "landlord")}>
            <div key={"loader-"+listingArray.length} hidden={hideLoader()} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
            <Row hidden={!isMapNonEmpty(selectedItem) || !hideLoader()} xs={12} sm={12} md={12} lg={12} className="listing-deals-viewer">
                  <div className="iframe-outer-container-watchlist" key={"listings-"+generateKey()} hidden={selectedListing == null}>
                    <div className="iframe-container-watchlist">
                      <iframe className={"iframe-actual-watchlist"} width={"100px"} height={"100px"} src={getImageLink(selectedListing)}></iframe>
                    </div>
                  </div>
                  <Row hidden={selectedListing != null && selectedItem != null} className='watchlist-listing'>
                    <p>Click on a listing to see what it looks like</p>
                  </Row>
              </Row>
            <Row hidden={isMapNonEmpty(props.selectedGeo)} className='watchlist-listing'>
              <p>Click on a watchlist item to view best deals in that region</p>
            </Row>
              <Row hidden={!hideLoader()} xs={12} sm={12} md={12} lg={12} className="listing-deals-container">
                {listingArray.map((row) => (
                  <Row className={getGeoClasses(row["listing_id"])}>
                    <Col xs={2} sm={2} md={2} lg={2}>
                      <div className='watchlist-link-wrapper'>
                        <a target="_blank" href={createLink(row)} onClick={ ()=> listingItemClicked(row, "redfin_link")}>
                          <p className='watchlist-link'>Link</p>
                        </a>
                      </div>
                    </Col>
                    <Col xs={10} sm={10} md={12} lg={10}>
                      <a className='watchlist-listing' onClick={ ()=> listingItemClicked(row, "row")}>
                        <Row>
                          <Col xs={3} sm={3} md={3} lg={3}>
                            <span className='watchlist-listing'>{"$"+numberWithCommas(row["price"])} </span>
                          </Col>
                          <Col xs={7} sm={7} md={7} lg={7}>
                            <span className='watchlist-listing'>{row["address"]} </span>
                          </Col>
                        </Row>
                      </a>
                    </Col>
                  </Row>
                ))}
              </Row>
          </Row>
          
        </Container>
        </div>
      );
  } else {
    return (
      <Container>
      <div className="paywall-spacer"></div>
        <ShowPayWall appCtx={appCtx}></ShowPayWall>
      </Container>
    );
  }
}

export default memo(ListingDeals);