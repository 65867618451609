import React, { useEffect, useState } from 'react';

import HeroBanner from "../componets/About/HeroBanner";
import { Col, Container, Row } from 'react-bootstrap';
import "../componets/About/styles.css";
import HypeFlow from '../componets/Subscriptions/HypeFlow';
import ReactGA from "react-ga4"

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";
import { Button } from '@mui/material';
import { getAnalyticsName } from '../shared/common';
import { createSiteMap } from '../shared/sitemap_gen';

function AboutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);

  function viewSubOptions() {
    return (
      navigate("/premium", { replace: false })
    )
  }

  return (
    <Container>
      <Row className="aboutme-section-first">
        <div className="aboutme-header">
          <h3>Have a Question, Comment or Idea?</h3>
          <span><h5 className="aboutme-subheader"> Email us at
            <a href="mailto:hiddenhousing@outlook.com"> hiddenhousing@outlook.com</a>
          </h5></span>
          <span><h5><a href="/tos">Terms of Service</a></h5></span>
        </div>
      </Row>
      <Row className="aboutme-section">
        <HeroBanner />
      </Row>
      <Row>
      <Col lg={12} md={12} sm={12} xs={12}>
      <div className="card aboutme-card aboutme-header">
        <Row>
        <Col lg={1} md={1} sm={0} xs={0}>
        </Col>
        <Col lg={6} md={6}>
          <div className="aboutme-header">
            <h3>Subscribe to gain access to ALL data</h3>
            <h5 className="aboutme-subheader">
              Become the most powerful person you know
            </h5>
          </div>
        </Col>
          <Col lg={3} md={3}>
            <Button className="profile-sub-button" variant="contained" color="success" onClick={()=> viewSubOptions()}>
              Call Me Subscription Curious
            </Button>
          </Col>
        </Row>
        
        </div>
        <HypeFlow scope={"tycoon"}></HypeFlow>
      </Col>
      </Row>
    </Container>
  );
}

export default AboutPage;