import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import './Stripe.css';
import StripeCheckoutForm from "./StripeCheckoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe("pk_live_51OKNguKetYwcQdZy7QTIY7OpsJWJcNIwqA314phKtI5jmtgQSvEhm1wpQ9jYXAE7qtnwCxCCcdnLkS8Il4Ocos21004WfRCcky");

const StripeFlow = () => {
  return (
    <div className="stripeflow">
      <StripeCheckoutForm stripePromise={stripePromise}></StripeCheckoutForm>
    </div>
  )
}

export default StripeFlow;