import React, { useEffect } from "react";
import { getAnalyticsName } from '../shared/common';
import ReactGA from "react-ga4"

function ErrorPage() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname + '/error' });
  }, []);

  return (
    <React.Fragment>
      <h1 className="h1">Error</h1>
    </React.Fragment>
  );
}

export default ErrorPage;
