import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import { Line } from "react-chartjs-2"; 
import Chart from 'chart.js/auto';

import { getCardTitle, grabPopulation, numberWithCommas, safelyGrab, CHART_COLORS, isMobile, shouldHideHeader, getAttrLabel, removeNonNumericExceptDot, isMapNonEmpty } from '../../shared/common';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import ChartvsTableToggle from '../ChartvsTableToggle'
import { Box, Grid } from '@mui/material';
import MapTypeComboBox from '../Controls/MapTypeComboBox';
import PastMapTypeComboBox from '../Controls/PastMapTypeComboBox';
import { RowingSharp } from '@mui/icons-material';
import Indicator from './Indicator';
import { lightBlue } from '@mui/material/colors';

const BATCH_MAP = { "1": "1 month ago", "2": "3 months ago", "3": "6 months ago", "4": "1 year ago" }

const options = {
  title: {
    display: true,
    text: "Price",
    fontSize: 20,
  },
  responsive: true,
  scales: {
    y: {  
      ticks: {
        color: "white",
        callback: function(value, index, values) {
          return Number((value / 1000).toString()) + 'K'; //pass tick values as a string into Number function
        }
      }
    },
    x: {  
      ticks: {
        color: "white",
      }
    }
  },
  plugins: {
    datalabels: {
      anchor: "end",
      align: "left",
      font: {
        color: "black"
      }
    },
    tickFormat: {
      notation: 'compact'
    },
    legend: {
      labels: {
        color: "white", 
        font: {
          size: 12
        }
      }
    }
  }
};

const chartLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

let samplePastData = {"county_prop_sums_total":{"batches":{"1":"2023-07-27","2":"2023-05-29"},"data":{"1":{"num_price_changes":"","avg_days_on_market":"","county_id":"","avg_price_change":"","price_change_sum":"","median_days_on_market":"","num_price_drops":"","median_price":"","price_drops_sum":"","price_sum":"","num_listings":"","date":"2023-07-27","listings_per_pop":"","days_on_market_sum":"","avg_price_drop":"","avg_price":"","price_drops_per_pop":""}}},
 "county_sold_sums_total": {"batches":{"1":"2023-07-27","2":"2023-05-29"},"data":{"1":{"num_price_changes":"","avg_days_on_market":"","county_id":"","avg_price_change":"","price_change_sum":"","median_days_on_market":"","num_price_drops":"","median_price":"","price_drops_sum":"","price_sum":"","num_listings":"","date":"2023-07-27","listings_per_pop":"","days_on_market_sum":"","avg_price_drop":"","avg_price":"","price_drops_per_pop":""}}}}
let sampleStateData = { "state": "", "data": { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}}
let sampleNationalData = { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}
let sampleChartData = {
  labels: chartLabels,
  datasets: [
    {
      label: "Median Active Listing Price",
      fill: false,
      lineTension: 0,
      backgroundColor: "rgba(131,138,133,0.4)",
      borderColor: "rgba(131,138,133,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(131,138,133,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(131,138,133,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [100, 80, 130, 125, 150, 200, 190, 210, 170, 190, 220, 200]
    }
  ]
}


function PastCards(props) {
  const [benchmarks, setBenchmarks] = useState(sampleNationalData)
  const [stateBenchmarks, setStateBenchmarks] = useState(sampleStateData)
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});
  const [pastData, setPastData] = useState(null);
  const [chartData, setChartData] = useState(sampleChartData)
  const [selectedTab, setSelectedTab] = useState(isMobile() ? "table": "chart")
  const [mapType, setMapType] = useState("median_price")
  const [geoData, setGeoData] = useState(null)
  const [loadingData, setLoadingData] = useState(false)

  function setupChart(backfillData, backfillBenchmarks, backfillStateBenchmarks) {

    // check mapType
    let redColor = CHART_COLORS.redColor
    let redTransparent = CHART_COLORS.redTransparent

    let blueColor = CHART_COLORS.blueColor
    let blueTransparent = CHART_COLORS.blueTransparent

    let darkRedColor = CHART_COLORS.darkRedColor
    let darkRedTransparent = CHART_COLORS.darkRedTransparent

    let darkBlueColor = CHART_COLORS.darkBlueColor
    let darkBlueTransparent = CHART_COLORS.darkBlueTransparent

    let lightRedColor = CHART_COLORS.lightRedColor
    let lightRedTransparent = CHART_COLORS.lightRedTransparent

    let lightBlueColor = CHART_COLORS.lightBlueColor
    let lightBlueTransparent = CHART_COLORS.lightBlueTransparent

    let greenColor = CHART_COLORS.blackColor
    let greenTransparent = CHART_COLORS.blackColorTransparent

    let lightGreenColor = CHART_COLORS.grayColor
    let lightGreenTransparent = CHART_COLORS.grayColorTransparent

    let chartDetails = {}
    let statePrefix = selectedCounty.state + " "
    if (mapType === "median_price") {
      let dataSets = []
      dataSets.push(backfillData.map(a => a.median_price))
      dataSets.push(backfillData.map(a => a.median_sold_price))
      dataSets.push(backfillStateBenchmarks.map(a => a.median_price))
      dataSets.push(backfillStateBenchmarks.map(a => a.median_sold_price))
      dataSets.push(backfillBenchmarks.map(a => a.median_price))
      dataSets.push(backfillBenchmarks.map(a => a.median_sold_price))
      chartDetails["datasets"] = dataSets
      chartDetails["num_datasets"] = 6
      chartDetails["colors"] = [blueColor, lightBlueColor, redColor, lightRedColor, greenColor, lightGreenColor]
      chartDetails["transparentColors"] = [blueTransparent, lightBlueTransparent, redTransparent, lightRedTransparent, greenTransparent, lightGreenTransparent]
      chartDetails["labels"] = backfillData.map(a => a.period)
      chartDetails["label"] = ["County Active Price", "County Sold Price", statePrefix + "Active", statePrefix + "Sold", "National Active", "National Sold"]
    } else if (mapType === "median_days_on_market") {
      let dataSets = []
      dataSets.push(backfillData.map(a => a.median_days_on_market))
      dataSets.push(backfillStateBenchmarks.map(a => a.median_days_on_market))
      dataSets.push(backfillBenchmarks.map(a => a.median_days_on_market))
      chartDetails["datasets"] = dataSets
      chartDetails["num_datasets"] = 3
      chartDetails["colors"] = [blueColor, redColor, greenColor]
      chartDetails["transparentColors"] = [blueTransparent, redTransparent, greenTransparent]
      chartDetails["labels"] = backfillData.map(a => a.period)
      chartDetails["label"] = ["County Median Days on Market", statePrefix + "Median Days on Market", "National Median Days on Market"]
    } else if (mapType === "num_listings") {
      let dataSets = []
      dataSets.push(backfillData.map(a => a.num_listings))
      dataSets.push(backfillData.map(a => a.num_sold))
      dataSets.push(backfillStateBenchmarks.map(a => a.num_listings))
      dataSets.push(backfillStateBenchmarks.map(a => a.num_sold))
      dataSets.push(backfillBenchmarks.map(a => a.num_listings))
      dataSets.push(backfillBenchmarks.map(a => a.num_sold))
      chartDetails["datasets"] = dataSets
      chartDetails["num_datasets"] = 6
      chartDetails["colors"] = [blueColor, lightBlueColor, redColor, lightRedColor, greenColor, lightGreenColor]
      chartDetails["transparentColors"] = [blueTransparent, lightBlueTransparent, redTransparent, lightRedTransparent, greenTransparent, lightGreenTransparent]
      chartDetails["labels"] = backfillData.map(a => a.period)
      chartDetails["label"] = ["County Active Listings", "County Sold Listings", statePrefix + "Active", statePrefix + "Sold", "National Active", "National Avg Sold"]
    } else if (mapType === "num_price_drops") {
      let dataSets = []
      dataSets.push(backfillData.map(a => a.num_price_drops))
      dataSets.push(backfillData.map(a => a.price_drops_per_pop))
      dataSets.push(backfillStateBenchmarks.map(a => a.num_price_drops))
      dataSets.push(backfillStateBenchmarks.map(a => a.price_drops_per_pop))
      dataSets.push(backfillBenchmarks.map(a => a.num_price_drops))
      dataSets.push(backfillBenchmarks.map(a => a.price_drops_per_pop))
      chartDetails["datasets"] = dataSets
      chartDetails["num_datasets"] = 6
      chartDetails["colors"] = [blueColor, lightBlueColor, darkRedColor, darkBlueColor, lightRedColor, lightBlueColor]
      chartDetails["transparentColors"] = [blueTransparent, lightBlueTransparent, redTransparent, lightRedTransparent, greenTransparent, lightGreenTransparent]
      chartDetails["labels"] = backfillData.map(a => a.period)
      chartDetails["label"] = ["Price Drops", "Price Drops Normalized", statePrefix + "Price Drops", statePrefix + "Price Drops Normalized", "National Price Drops", "National Price Drops Normalized"]
    } else if (mapType === "listings_per_pop") {
      let dataSets = []
      dataSets.push(backfillData.map(a => a.listings_per_pop))
      dataSets.push(backfillData.map(a => a.sold_listings_per_pop))
      dataSets.push(backfillStateBenchmarks.map(a => a.listings_per_pop))
      dataSets.push(backfillStateBenchmarks.map(a => a.sold_listings_per_pop))
      dataSets.push(backfillBenchmarks.map(a => a.listings_per_pop))
      dataSets.push(backfillBenchmarks.map(a => a.sold_listings_per_pop))
      chartDetails["datasets"] = dataSets
      chartDetails["num_datasets"] = 6
      chartDetails["colors"] = [blueColor, lightBlueColor, redColor, lightRedColor, greenColor, lightGreenColor]
      chartDetails["transparentColors"] = [blueTransparent, lightBlueTransparent, redTransparent, lightRedTransparent, greenTransparent, lightGreenTransparent]
      chartDetails["labels"] = backfillData.map(a => a.period)
      chartDetails["label"] = ["Active Listings Normalized", "Sold Listings Normalized", statePrefix + "Active Normalized", statePrefix + "Sold Normalized", "National Active Normalized", "National Sold Normalized"]
    }

    let datasetsObj = []

    for (let d=0; d < chartDetails["num_datasets"]; d++) {
      let color = chartDetails["colors"][d]

      let datasetData = {
        fill: false,
        lineTension: 0,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      }

      datasetData["label"] = chartDetails["label"][d]
      datasetData["data"] = chartDetails["datasets"][d]
      datasetData["backgroundColor"] = chartDetails["transparentColors"][d]
      datasetData["borderColor"] = color
      datasetData["pointHoverBackgroundColor"] = color
      datasetData["pointHoverBorderColor"] = color
      datasetData["pointBorderColor"] = color

      datasetsObj.push(datasetData)
    }


    const data = {
      labels: chartDetails["labels"],
      datasets: datasetsObj
    }

    setChartData(data)
}
  useEffect(() => {

  if (isMapNonEmpty(props.selectedCounty) && props.selectedCounty.county_id !== selectedCounty.county_id) {
    setLoadingData(true)
    setSelectedCounty(props.selectedCounty)
  }

  }, [props.selectedCounty]);


  useEffect(() => {

    if (isMapNonEmpty(props.pastData)) {
      setLoadingData(false)
      setPastData(props.pastData)
    }

  }, [props.pastData]);

  useEffect(() => {

    if (isMapNonEmpty(props.geoData)) {
      setLoadingData(false)
      setGeoData(props.geoData)
    }

  }, [props.geoData]);

  useEffect(() => {

    // only proceed if we have ALL of the data
    if (props.selectedCounty != null && props.selectedCounty != undefined) {
      if (props.backfillData !== undefined && props.backfillData !== null && Object.keys(props.backfillData).length > 0
      && props.backfillStateBenchmarks !== undefined && props.backfillStateBenchmarks !== null && Object.keys(props.backfillStateBenchmarks).length > 0
      && props.backfillBenchmarks !== undefined && props.backfillBenchmarks !== null && Object.keys(props.backfillBenchmarks).length > 0) {
        setupChart(props.backfillData, props.backfillBenchmarks, props.backfillStateBenchmarks)
      }
    }
  }, [props.backfillData, mapType, props.backfillBenchmarks, props.backfillStateBenchmarks]);

  function shortenPrice(price, skip) {
    if (skip || price == "" || price == null) {
      return price
    }
    let arr = price.split(',');
    let lastComma = arr.length;
    if (lastComma == 0) {
      return price
    }
    let lastInstance = price.indexOf(',', lastComma)
    let pre = price.substring(0, lastInstance)
    return pre + "k"
  }

function getAttrValuesSpecial(attr, from, attr2) {
  if (attr2 == null) {
    return getAttrValues(attr, from, null)
  }

  if (from === undefined || from == null || attr == null || attr == undefined) {
    return { str: "", num: 0}
  }

  if ((attr2 == "num_listings" && attr == "num_sold")) {
    let temp = removeNonNumericExceptDot(""+from[attr2])
    let responseBare = (parseInt(temp) / 3).toFixed(0) // sold is over 3 months, for comparison purposes we only want 1 month
    let response = numberWithCommas(responseBare)
    return { str: response, num: responseBare}

  } else if ((attr2 == "listings_per_pop" && attr == "sold_listings_per_pop")) {
    let pop = grabPopulation(props.populationMap, selectedCounty.fips)
      if (pop !== undefined && pop.population !== undefined) {
        let temp = removeNonNumericExceptDot(""+from["num_listings"]) / 3
        let responseBare = ((temp / pop.population) * 100000).toFixed(0)
        let response = numberWithCommas(responseBare)
        return { str: response, num: responseBare}
      } else {
        let temp = removeNonNumericExceptDot(""+from[attr2])
        let responseBare = parseInt(temp) / 3
        let response = numberWithCommas(responseBare)
        return { str: response, num: responseBare}
      }
  }
 
  else if ((attr2 == "median_price" && attr == "median_sold_price")) {
    let temp = removeNonNumericExceptDot(""+from[attr2])
    let responseBare = parseInt(temp)
    let response = "$" + numberWithCommas(responseBare)
    return { str: response, num: responseBare}
  }

  else {
    return getAttrValues(attr, from, null)
 }
}

function getAttrValues(attr, from, prefix) {
  if (from === undefined || from == null || attr == null || attr == undefined) {
    return { "str": "", "num": -1 }
  }

  if (from[attr] === undefined || from[attr] === null) {
        return { "str": "", "num": -1 }
  }

  if (attr == "color" || attr == "num" || attr == "str") {
     return from[attr]
  }

  if (prefix == "value") {
     return removeNonNumericExceptDot(from[attr])
  }

  let responseBare = 0
  let response = ""
  if (attr == "median_price" || attr == "median_sold_price") {
     let temp = removeNonNumericExceptDot(""+from[attr])
     responseBare = parseInt(temp)
     response = "$" + numberWithCommas(responseBare)
  }

  if (attr == "median_days_on_market" || attr == "num_price_drops" || 
    attr == "num_listings" || attr == "listings_per_pop" || attr == "num_sold" || attr == "sold_listings_per_pop") {
    let temp = removeNonNumericExceptDot(""+from[attr])
    responseBare = parseInt(temp)
    response = numberWithCommas(responseBare)
 }

  return { str: response, num: responseBare}
}

function getAttrDesired(attr) {
  if (attr == "median_price" || attr == "median_sold_price") {
     return "lower"
   }
   return "higher"
}

function StatBubble(props) {
  if (pastData === null || pastData === undefined || Object.keys(pastData).length < 1) {
     return
  }
  let skip = props.skipShorten

  let title = props.title

  let index = props.index
  let hideHeader = shouldHideHeader(index)
  let attr1Array = props.attr1
  let attr2Array = props.attr2

  let geoSumKeys = []
  for (let i=0; i < attr2Array.length; i++) {
    let curr = attr2Array[i]
    if (curr === null) {
      geoSumKeys.push("geo_prop_sums")
    } else {
      geoSumKeys.push("geo_sold_sums")
    }
  }

  let firstClass = "card card-background mb-3"

  if (selectedCounty.county !== null && pastData !== null && geoData !== null) {
    return (
      <Container>
        <Row className="mt-2">
           <div className={firstClass}>
           <div hidden={hideHeader} className="card-header">{title}</div>
           <div className="card-body">
              <Row>
              <Col xs={3} sm={3} md={3} lg={3} className="spacer">
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} className="spacer">
                 <div hidden={hideHeader} className="mx-auto"><h5  className={"card-subheader mx-auto centered"}>Now</h5>
                 <hr className="separator" /></div>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                 <div hidden={hideHeader}><h5  className="card-subheader">{isMobile()? "3": "3"}</h5>
                 <hr className="separator" /></div>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                 <div hidden={hideHeader}><h5  className="card-subheader">{isMobile()? "6": "6"}</h5>
                 <hr className="separator" /></div>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                 <div hidden={hideHeader}><h5  className="card-subheader">{isMobile()? "12": "12"}</h5>
                 <hr className="separator" /></div>
              </Col>

              </Row>
              <Row className="mt-2 mb-2">
                <Col xs={3} sm={3} md={3} lg={3}>
                    <h6 className="card-title">{getAttrLabel(attr1Array[0])}</h6>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} className="spacer">
                <p className="card-text">{shortenPrice(getAttrValuesSpecial(attr1Array[0], geoData[geoSumKeys[0]], attr2Array[0]).str, skip)}
                  <Indicator desired={getAttrDesired(attr1Array[0])} number={getAttrValuesSpecial(attr1Array[0], geoData[geoSumKeys[0]], attr2Array[0]).num} stateAvg={getAttrValues(attr1Array[0], pastData["three_months"]).num} nationalAvg={getAttrValues(attr1Array[0], pastData["twelve_months"]).num} ></Indicator>
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{shortenPrice(getAttrValues(attr1Array[0], pastData["three_months"]).str, skip)}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{shortenPrice(getAttrValues(attr1Array[0], pastData["six_months"]).str, skip)}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{shortenPrice(getAttrValues(attr1Array[0], pastData["twelve_months"]).str, skip)}
                    </p>
                </Col>
              </Row>
              <Row className="mt-2 mb-2">
                <Col xs={3} sm={3} md={3} lg={3}>
                    <h6 className="card-title">{getAttrLabel(attr1Array[1])}</h6>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} className="spacer">
                <p className="card-text">{shortenPrice(getAttrValuesSpecial(attr1Array[1], geoData[geoSumKeys[1]], attr2Array[1]).str, skip)}
                  <Indicator desired={getAttrDesired(attr1Array[1])} number={getAttrValuesSpecial(attr1Array[1], geoData[geoSumKeys[1]], attr2Array[1]).num} stateAvg={getAttrValues(attr1Array[1], pastData["three_months"]).num} nationalAvg={getAttrValues(attr1Array[1], pastData["twelve_months"]).num} ></Indicator>
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{shortenPrice(getAttrValues(attr1Array[1], pastData["three_months"]).str, skip)}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{shortenPrice(getAttrValues(attr1Array[1], pastData["six_months"]).str, skip)}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{shortenPrice(getAttrValues(attr1Array[1], pastData["twelve_months"]).str, skip)}
                    </p>
                </Col>
              </Row>
              <Row className="mt-2 mb-2">
                <Col xs={3} sm={3} md={3} lg={3}>
                    <h6 className="card-title">{getAttrLabel(attr1Array[2])}</h6>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} className="spacer">
                <p className="card-text">{getAttrValuesSpecial(attr1Array[2], geoData[geoSumKeys[2]], attr2Array[2]).str}
                  <Indicator desired={getAttrDesired(attr1Array[2])} number={getAttrValuesSpecial(attr1Array[2], geoData[geoSumKeys[2]], attr2Array[2]).num} stateAvg={getAttrValues(attr1Array[2], pastData["three_months"]).num} nationalAvg={getAttrValues(attr1Array[2], pastData["twelve_months"]).num} ></Indicator>
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{getAttrValues(attr1Array[2], pastData["three_months"]).str}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{getAttrValues(attr1Array[2], pastData["six_months"]).str}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{getAttrValues(attr1Array[2], pastData["twelve_months"]).str}
                    </p>
                </Col>
              </Row>
              <Row className="mt-2 mb-2">
                <Col xs={3} sm={3} md={3} lg={3}>
                    <h6 className="card-title">{getAttrLabel(attr1Array[3])}</h6>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} className="spacer">
                <p className="card-text">{getAttrValuesSpecial(attr1Array[3], geoData[geoSumKeys[3]], attr2Array[3]).str}
                  <Indicator desired={getAttrDesired(attr1Array[3])} number={getAttrValuesSpecial(attr1Array[3], geoData[geoSumKeys[3]], attr2Array[3]).num} stateAvg={getAttrValues(attr1Array[2], pastData["three_months"]).num} nationalAvg={getAttrValues(attr1Array[2], pastData["twelve_months"]).num} ></Indicator>
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{getAttrValues(attr1Array[3], pastData["three_months"]).str}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{getAttrValues(attr1Array[3], pastData["six_months"]).str}
                    </p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} className="spacer">
                    <p className="card-text">{getAttrValues(attr1Array[3], pastData["twelve_months"]).str}
                    </p>
                </Col>
              </Row>
           </div>
           </div>
        </Row>
      </Container>
    )
  }
}
  
  return (
    <Container key={"pasts-"+JSON.stringify(selectedCounty)} hidden={selectedCounty == null || selectedCounty.county == null}>
      <div hidden={loadingData == true}>
        <Row className='mb-2'>
          <Col xs={4} sm={2} md={2} lg={1}>
            <Box p={1} display="block-inline">
              <ChartvsTableToggle selectedTab={selectedTab} setSelectedTab={setSelectedTab}></ChartvsTableToggle>
            </Box>
          </Col>
          <Col xs={8} sm={10} md={4} lg={3}>
            <div className="card card-background card-stats card-key mb-3">
              <Box display="block-inline">
                  <div className="text-centered bold">{getCardTitle("county", selectedCounty)}</div>
                </Box>
              </div>
            </Col>
          <Col xs={12} sm={12} md={7} lg={7} hidden={selectedTab == "chart"}>
              <div className="card card-stats card-key mb-3">
                <span> 
                  <i className="bi good-indicators bi-arrow-up-circle-fill strong-indicator"></i>
                  <i className="bi good-indicators bi-arrow-down-circle-fill strong-indicator"/>
                  <i className="bi good-indicators strong-indicator"> Better than 1 month ago AND 1 year ago (% better than national)</i>
                </span>
                <span>
                  <i className="bi good-indicators bi-arrow-up-circle-fill weak-indicator"></i>
                  <i className="bi good-indicators bi-arrow-down-circle-fill weak-indicator"/>
                  <i className="bi good-indicators weak-indicator"> Better than 1 Month Ago OR 1 Year Ago (% whichever is better)</i>
                </span>
              </div>
              </Col>
          <Col xs={12} sm={12} md={3} lg={3} hidden={selectedTab === "table"} >
            <Grid minWidth="250px" maxWidth="250px" className="center-combobox">
              <PastMapTypeComboBox setMapType={setMapType}></PastMapTypeComboBox>
            </Grid>
          </Col >
        </Row>
        <Row>
          <Col hidden={selectedTab === "table"} md={12} lg={12}>
            <Line data={chartData} options={options}></Line>
          </Col>

          <Col hidden={selectedTab === "chart"}>
            <Row>
              <Col md={12} lg={6}>
                <StatBubble title="Annual Price Movement (Months Ago)" skipShorten={false} attr1={["median_price", "median_sold_price", "median_days_on_market", "num_price_drops"]} attr2={[null, "median_price", null, null]} index={0}></StatBubble>
              </Col>
              <Col md={12} lg={6}>
                <StatBubble title="Annual Inventory Movement (Months Ago)" skipShorten={true} attr1={["num_listings", "num_sold", "listings_per_pop", "sold_listings_per_pop"]} attr2={[null, "num_listings", null, "listings_per_pop"]} index={0}></StatBubble>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row hidden={loadingData == false}>
            <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
          </Row>
    </Container>
  );
}

export default memo(PastCards);