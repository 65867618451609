import { useState, useEffect, useContext, memo} from 'react';
import { isMapNonEmpty, countyIdToName, currentlyLoggedIn, numberWithCommas } from '../../shared/common';
import "./watchlist.css";
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import WatchlistSortCombobox from './WatchlistSortCombobox';
import AppContext from '../../state/app-context';
import AccountState from '../../pages/AccountState';
import { Button } from '@mui/material';
import ReactGA from "react-ga4"

let dummy = {
  "geo_id": "dummy"
}

const Watchlist = (props) => {
  const appCtx = useContext(AppContext);
  const [watchlistItems, setWatchlistItems] = useState([dummy]);
  const [pastCountyName, setPastCountyName] = useState("");
  const [currSort, setCurrSort] = useState("median_price");
  const [selectedItem, setSelectedItem] = useState(null);
  const [height, setHeight] = useState(window.innerHeight)
  const countyName = props.countyName

  let mode = props.mode

  useEffect(() => {
    setHeight(window.innerHeight)
  }, [window.innerHeight]);

  useEffect(() => {
    if (isMapNonEmpty(props.watchlistItems)) {
      setWatchlistItems(props.watchlistItems)
    }
  }, [props.watchlistItems]);

  function parseWatchlistValue(value, type) {
    let valueInt = 0.0
    if (value !== undefined && value !== "") {
      if (type == "trend") {
        valueInt = Math.round(parseFloat(value * 100))
      } else {
        valueInt = Math.round(parseFloat(value))
      }
    }
    return valueInt
  }

  function formatWatchlistValue(value, type) {
    let valueInt = parseWatchlistValue(value, type)
    if (valueInt > 0) {
      if (type == "trend") {
        return "+" + valueInt + "%"
      }
      if (type == "diff") {
        return "+" + numberWithCommas(valueInt)
      }
      if (type == "raw") {
        return "$" + numberWithCommas(valueInt)
      }
      return numberWithCommas(valueInt)
    } else if (valueInt < 0) {
      if (type == "trend") {
        return valueInt + "%"
      }
      if (type == "raw") {
        return "$" + numberWithCommas(valueInt)
      }
      return numberWithCommas(valueInt)
    } else {
      if (type == "trend") {
        return valueInt + "%"
      }
      if (type == "raw") {
        return "$" + numberWithCommas(valueInt)
      }
      return numberWithCommas(valueInt)
    }
  }

  function formatWatchlistNumber(sortName, value, type) {
    let valueInt = parseWatchlistValue(value, type)

    if (sortName == "median_price") {
      if (type == "raw") {
        return "watchlist-normal"
      }

      // lower is better
      if (valueInt < 0) {
        return "watchlist-good"
      } else if (valueInt > 0) {
        return "watchlist-bad"
      } else {
        return "watchlist-normal"
      }

    } else if (sortName == "num_price_drops") {
      // higher is better
      if (valueInt > 0) {
        return "watchlist-good"
      } else if (value < 0) {
        return "watchlist-bad"
      } else {
        return "watchlist-normal"
      }
    } else if (sortName == "num_listings") {
      // higher is better
      if (valueInt > 0) {
        return "watchlist-good"
      } else if (valueInt < 0) {
        return "watchlist-bad"
      } else {
        return "watchlist-normal"
      }
    }
  }

  function hideLoader() {
    if (!currentlyLoggedIn(appCtx)) {
      return true
    }
    return isMapNonEmpty(watchlistItems)
  }

  function watchlistItemClicked(obj, detail) {
    appCtx.setSelectedGeo({"geo_id": obj["geo_id"], "fips": obj["fips"]})
    setSelectedItem(obj["geo_id"])
    ReactGA.event({
      category: 'Watchlist.Click',
      action: 'Watchlist Item Click',
      label: 'watchlist.item.click.'+ detail,
    });
  }

  function getContainerClasses() {
    let classes = "watchlist-sidebar-container"
    return classes
  }

  function getGeoClasses(geoId, page) {
    let suffix = ""
    if (page) {
      suffix = "-page"
    }

    if (selectedItem === null) {
      return 'watchlist-row'+ suffix
    }
    if (selectedItem == geoId) {
      return 'watchlist-row'+suffix + ' watchlist-row'+suffix+'-selected'
    }
    return 'watchlist-row'+suffix
  }

  function formatWatchlistName(geoId) {
    if (geoId == undefined || geoId == "dummy") {
      return "click the star to add a county/city to watchlist"
    }

    // city mode
    let index = geoId.indexOf('_')
    if (index > -1) {
      let stateName = geoId.substring(0, index)
      let cityName = geoId.substring(index+1) + ", " + stateName
      return cityName
    } else {
      return countyIdToName(geoId)
    }
  }

  function getWindowHeight() {
    return height * .45;
  }

  if (pastCountyName !== countyName && countyName !== null && countyName !== undefined) {
    setPastCountyName(countyName)
  }

  if (currentlyLoggedIn(appCtx) && isMapNonEmpty(watchlistItems)) {

    if (mode == "sidebar"){
      return (
        <div>
          <div hidden={hideLoader()} className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <div className={getContainerClasses()}>
          <Row className="sidebar-header">
            <div className='sidebar-title'>
            <p className="watchlist-header-txt">Watchlist</p>
            </div>
            <hr className='separator-watchlist'></hr>
            
            <Col xs={8} sm={8} md={8} lg={8}>
              <WatchlistSortCombobox currSort={currSort} setCurrSort={setCurrSort}></WatchlistSortCombobox>
            </Col>
            <Col xs={4} sm={4} md={4}  lg={4}>
              <p className="watchlist-header-subtxt">% WoW</p>
            </Col>
          </Row>
        
          <div className="watchlist-body" style={{ maxHeight: "60%"}}>
            {watchlistItems.map((row) => (
              <div key={'watchlist0-'+row["geo_id"]}>
              <Row className={getGeoClasses(row["geo_id"], false)}>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <a onClick={ ()=> watchlistItemClicked(row, "sidebar")} className='watchlist-geo'>{formatWatchlistName(row["geo_id"])}</a>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <p className={"watchlist-value "+formatWatchlistNumber(currSort, row["trend_"+currSort], "trend")}>{formatWatchlistValue(row["trend_"+currSort], "trend")}</p>
                </Col>
              </Row>
            </div>
            ))}
          </div>
        </div>
        </div>
      );
    } else if (mode == "page") {
      return (
        <div>
          <div hidden={hideLoader()} className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <div className="watchlist-sidebar-page">
          <Row className="watchlist-page-header">
            <div className='watchlist-page-title'>
            <p className="watchlist-header-txt">Watchlist</p>
            </div>
            <hr className='separator-watchlist'></hr>
            
            <Col xs={4} sm={4} md={4} lg={4}>
              <WatchlistSortCombobox currSort={currSort} setCurrSort={setCurrSort}></WatchlistSortCombobox>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <p className="watchlist-header-subtxt">% Week over week</p>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <p className="watchlist-header-subtxt">Week over week</p>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <p className="watchlist-header-subtxt">Current</p>
            </Col>
          </Row>

          {watchlistItems.map((row) => (
            <div key={'watchlist1-'+row["geo_id"]}>
              <Row className={getGeoClasses(row["geo_id"], true)}>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <a onClick={ ()=> watchlistItemClicked(row, "watchlist_deals")} className='watchlist-geo'>{formatWatchlistName(row["geo_id"])}</a>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <p className={"watchlist-value "+formatWatchlistNumber(currSort, row["trend_"+currSort], "trend")}>{formatWatchlistValue(row["trend_"+currSort], "trend")}</p>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <p className={"watchlist-value "+formatWatchlistNumber(currSort, row["diff_"+currSort], "diff")}>{formatWatchlistValue(row["diff_"+currSort],"diff")}</p>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <p className={"watchlist-value "+formatWatchlistNumber(currSort, row[currSort], "raw")}>{formatWatchlistValue(row[currSort], "raw")}</p>
                </Col>
              </Row>
            </div>
          ))}
        </div>
        </div>
      );
    }
  } else if (mode == "page") {
    return (
    <Container>
      <Row className="mt-2 profile-page-as">
        <p className="intro-text">Login or make an Account to use this feature. Takes two clicks and we dont email you about bullshit</p>
        <Col lg={12} md={12} sm={12} xs={12}>
            <AccountState></AccountState>
        </Col>
      </Row>
        </Container>
    )
  } else {
    return
  }
}

export default memo(Watchlist);