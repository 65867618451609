import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from "@mui/material";
import { getAttrLabelShort } from "../../shared/common";
import "./watchlist.css";
import ReactGA from "react-ga4"

const ListingDealsComboBox = (props) => {
  const [mapItems, setMapItems] = useState([]);
  const [selected, setSelected] = useState("all")

  const dataArray = []
  const dataMap = {} 
  const SUBSET_MAP = {
    "bed_1": "1 Bed",
    "bed_2": "2 Bed",
    "bed_3": "3 Bed",
    "bed_4": "4 Bed",
    "bed_5+": "5+ Bed",
    "bath_1": "1 Bath",
    "bath_2": "2 Bath",
    "bath_3": "3 Bath",
    "bath_4+": "4+ Bath",
    "sqft_<=1000": "< 1000 sqft",
    "sqft_>1000": "> 1000 sqft",
    "sqft_>1500": "> 1500 sqft",
    "sqft_>2000": "> 2000 sqft",
    "sqft_>2500": "> 2500 sqft",
    "sqft_>=3000": "> 3000 sqft",
  }

  const SUBSET_TYPES = [
  { "type": "all", "name": "All" },
  { "type": "bath_0", "name": "Land" },
  { "type": "bed_1", "name": "1 Bed" },
  { "type": "bed_2", "name": "2 Bed" },
  { "type": "bed_3", "name": "3 Bed" },
  { "type": "bed_4", "name": "4 Bed" },
  { "type": "bed_5+", "name": "5+ Bed" },
  { "type": "bath_1", "name": "1 Bath" },
  { "type": "bath_2", "name": "2 Bath" },
  { "type": "bath_3", "name": "3 Bath" },
  { "type": "bath_4+", "name": "4+ Bath" },
  { "type": "sqft_<=1000", "name": "< 1000 sqft" },
  { "type": "sqft_>1000", "name": "> 1000 sqft" },
  { "type": "sqft_>1500", "name": "> 1500 sqft" },
  { "type": "sqft_>2000", "name": "> 2000 sqft" },
  { "type": "sqft_>2500", "name": "> 2500 sqft" },
  { "type": "sqft_>=3000", "name": "> 3000 sqft" },
  ]

  function getSubsetName(type) {
    return SUBSET_MAP[type]
  }


  const onChanged = (newValue) => {
    if (newValue !== null) {
      props.setCurrSort(newValue.type)
      setSelected(getSubsetName(newValue.type))
      ReactGA.event({
        category: 'Watchlist.CrossTabCombobox.Change',
        action: 'Watchlist CrossTab Combobox Change',
        label: 'watchlist.crossTabCombobox.change.'+getAttrLabelShort(newValue.type),
      });
    }
  };

  useEffect(() => {
    const loadValues = async (dataArray) => {

      for (let i =0; i < SUBSET_TYPES.length; i++) {
        const subtype = SUBSET_TYPES[i]
        const mapType = subtype["type"]
        const name = subtype["name"]
        
        if (name !== undefined && mapType !== undefined && dataMap[mapType] === undefined) {
          dataArray.push({ label: name, type: mapType})
          dataMap[mapType] = name
        }
      }

      if (dataArray.length > 0) {
        setMapItems(dataArray)
      }
    }

    if (mapItems.length < 1) {
      loadValues(dataArray)
      .catch(console.error);
    }
    

  }, []);

  return (
    <Autocomplete
      disablePortal
      size="small"
      id="combo-box-listings"
      value={{label : selected}}
      defaultValue={props.currSort}
      options={mapItems}
      disableClearable
      className="combobox-dark watchlist-combo"
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white", border: "0px", fontSize: 10 }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" {...params} InputProps={{ ...params.InputProps, style: { fontSize: 10} }} size="small" />}
    />
  );
}

export default memo(ListingDealsComboBox);