import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { GlobeAmericas } from 'react-bootstrap-icons';
import ReactGA from "react-ga4"
import './AccountState.css'
import AppContext from '../state/app-context';
import { signUpUser, loginUser } from '../service/sessionservice'
import Form from 'react-bootstrap/Form';
import { getAnalyticsName, currentlyLoggedIn } from '../shared/common';

function validateEmail(email) {
  let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
}

function validatePassword(pass1, pass2) {
  if (pass1 != pass2) {
    return false
  }
  let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,40}$/;
  return pass1.match(passw)
}

function AccountState(props) {
  const appCtx = useContext(AppContext);
  const [loginState, setLoginState] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);

  const clickSignUp = event => {
    setShowLoader(true)
    console.log('clickSignUp ran');
    if (event != null && event != undefined) {
      event.preventDefault()
    }

    console.log('clickSignUp called')
    if (email === undefined || password === undefined || password2 === undefined || password2 === "" || password === "" || email === "") {
      setErrorText("Please fill out all fields")
      return
    }

    let validEmail = validateEmail(email)
    let validPassword = validatePassword(password, password2)
    if (!validEmail) {
      setErrorText("Please enter a valid email address")
    }
    else if (!validPassword) {
      setErrorText("Please enter a password at least 6 characters long that contains least one numeric digit, one uppercase and one lowercase letter")
    }
    else {
      setErrorText("")
      createNewUser(appCtx.token, appCtx.deviceId, email, password)
    }
  }

  const clickLogin = event => {
    event.preventDefault()
    // code you want to do
    console.log('loginUser called')
    loginExistingUser(appCtx.token, appCtx.deviceId, email, password)
  }

  const logoutUser = event => {
    event.preventDefault()
    // code you want to do
    console.log('logoutUser called')
    logoutExistingUser()
  }

  function getButtonText() {
    if (loginState == "login") {
      return "Create an Account"
    } else if (loginState == "signup") {
      return "Log In"
    }
  }

  function getIntroText() {
    if (loginState == "login") {
      return "Login to your Account"
    } else if (loginState == "signup") {
      return "Create an Account"
    }
  }

  function toggleLoginState() {
    if (loginState == "signup") {
      setLoginState("login")
    } else if (loginState == "login") {
      setLoginState("signup")
    }
  }

  async function createNewUser(token, deviceId, email, password) {
    setShowLoader(true)
    let response = await signUpUser(token, deviceId, email, password)
    if (response["meta"] === 200 && response["data"] !== undefined && response["data"]["refresh_token"] !== undefined) {
      const refreshToken = response["data"]["refresh_token"]
      const accessToken = response["data"]["access_token"]
      const scope = response["data"]["scope"]
      console.log('user creation refresh_token received.. storing it locally')
      localStorage.setItem("hh-user-rt", refreshToken)
      localStorage.setItem('hh-access-token', accessToken)
      localStorage.setItem('hh-username', email)
      localStorage.setItem('hh-scope', scope)
      appCtx.setUser({ "username": email })
      appCtx.setIsAuthenticated(true)
      appCtx.setRefreshToken(refreshToken)
      appCtx.setScope(scope)
      appCtx.setToken(accessToken)
      setErrorText("")
      setShowLoader(false)

      ReactGA.event({
        category: 'Account.Create',
        action: 'Account Create',
        label: 'account.create.'+appCtx.deviceId
      });

    } else {
      setErrorText(response["message"])
      setShowLoader(false)
    }
  }

  async function loginExistingUser(token, deviceId, email, password) {
    setShowLoader(true)
    let response = await loginUser(token, deviceId, email, password)
    if (response["meta"] === 200 && response["data"] !== undefined && response["data"]["refresh_token"] !== undefined) {
      const refreshToken = response["data"]["refresh_token"]
      const accessToken = response["data"]["access_token"]
      const scope = response["data"]["scope"]
      const subUrl = response["data"]["sub_url"]
      console.log('user login refresh_token received, storing locally')
      localStorage.setItem("hh-user-rt", refreshToken)
      localStorage.setItem('hh-username', email)
      localStorage.setItem('hh-access-token', accessToken)
      localStorage.setItem('hh-scope', scope)
      appCtx.setIsAuthenticated(true)
      appCtx.setRefreshToken(refreshToken)
      appCtx.setUser({ "username": email })
      appCtx.setScope(scope)
      appCtx.setToken(accessToken)
      appCtx.setSubUrl(subUrl)
      appCtx.setRefreshElement("watchlist")
      setErrorText("")
      setShowLoader(false)

      ReactGA.event({
        category: 'Account.Login',
        action: 'Account Login',
        label: 'account.login.'+appCtx.deviceId
      });
    } else {
      setErrorText(response["message"])
      setShowLoader(false)
    }
  }

  function logoutExistingUser() {
    setShowLoader(true)
    localStorage.removeItem('hh-user-rt',)
    localStorage.removeItem('hh-username')
    localStorage.removeItem('hh-access-token')
    localStorage.removeItem('hh-scope')
    appCtx.setIsAuthenticated(false)
    appCtx.setToken(null)
    appCtx.setUser(null)
    appCtx.setScope(null)
    appCtx.setWatchlistItems(null)
    appCtx.setSelectedGeo(null)
    appCtx.setRefreshToken(null)
    console.log('logged out user')
    setShowLoader(false)

    ReactGA.event({
      category: 'Account.Logout',
      action: 'Account Logout',
      label: 'account.logout.'+appCtx.deviceId
    });
  }

  if (currentlyLoggedIn(appCtx)) {
    return
    /*
    return (
      <div className='dark-primary center'>
          <p className='sidebar-intro'>Logged into: {appCtx.user.username}</p>
          <Button className="center" onClick={logoutUser} variant="link">Log out</Button>
      </div>
    );*/
  } else {
    if (appCtx.user == null) {
      return (
        <div className='login-container'>
          <div className='dark-primary center'>
            <p className='sidebar-intro'>{getIntroText()}</p>
            <GlobeAmericas className='sidebar-logo' size={30} />
          </div>
          <div>
            <div key={"form-"+loginState} className="login-center" hidden={loginState === "login"} props={props}>
              <Form className="login-form-sidenav">
              <Form.Group className="mb-2" controlId="signup-username">
                <Form.Label className="dark-font-color">Email address</Form.Label>
                <Form.Control  type="email" placeholder="email" onChange={e => {
                  setEmail(e.target.value)
                  }} />
              </Form.Group>
              <Form.Group className="mb-2" controlId="signup-password">
                <Form.Label className="dark-font-color" >password</Form.Label>
                <Form.Control type="password" placeholder="password" onChange={e => {
                  setPassword(e.target.value)
                  }}/>
              </Form.Group>
              <Form.Group className="mb-2" controlId="password2">
                <Form.Label className="dark-font-color">retype password</Form.Label>
                <Form.Control type="password" 
                  placeholder="password" 
                  onKeyDown={e => e.key === 'Enter' ? createNewUser(appCtx.token, appCtx.deviceId, email, password): null}
                  onChange={e => {
                    setPassword2(e.target.value)
                  }}/>
            </Form.Group>
            <Button
              hidden={showLoader}
              className="center mt-2"
              onClick={clickSignUp}>Sign up</Button>
          </Form>
        </div>
          <div key={"form2-"+loginState} hidden={loginState === "signup"} className="login-center" props={props}>
            <Form className="login-form-sidenav center">
              <Form.Group className="mb-3 login-control" controlId="username">
                <Form.Label className="dark-font-color">Email address</Form.Label>
                <Form.Control type="email" placeholder="email" onChange={e => {
                  setEmail(e.target.value)
                  }} />
              </Form.Group>
              <Form.Group className="mb-3 login-control" controlId="password">
                <Form.Label className="dark-font-color">password</Form.Label>
                <Form.Control type="password" placeholder="password"
                  onKeyDown={e => e.key === 'Enter' ? loginExistingUser(appCtx.token, appCtx.deviceId, email, password): null}
                  onChange={e => {
                  setPassword(e.target.value)
                  }} />
              </Form.Group>
              <Button hidden={showLoader} className="center"
                onKeyDown={e => e.key === 'Enter' ? loginExistingUser(appCtx.token, appCtx.deviceId, email, password): null}
                onClick={clickLogin}>Log in</Button>
            </Form>
          </div>
            <div hidden={!showLoader} className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
          <p className="error-text">{errorText}</p>
          <Button 
            hidden={showLoader}
            className="center" 
            onClick={()=> toggleLoginState()}
            variant="link">{getButtonText()}
          </Button>
        </div>
      );
    }
  }
}

export default AccountState;