import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/material';
import ReactGA from "react-ga4"

export default function CountyVsCityToggle(props) {
  const [active, setActive] = React.useState(props.geoModeTab !== undefined ? props.geoModeTab : "county");

  const handleChange = (event, newValue) => {
    setActive(newValue)
    props.setGeoModeTab(newValue);

    ReactGA.event({
      category: 'Toggle.Changed',
      action: 'CountyVsCityToggle Changed ',
      label: 'countyvscitytoggle.changed.'+newValue
    });
  };

  return (
    <Box 
    display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center">
      <ToggleButtonGroup
        fullWidth={true}
        style={{textAlign:"center"}}
        className='dark-primary'
        aria-label="Small sizes"
        size="small"
        value={active}
        exclusive={true}
        color="primary"
        onChange={handleChange}>
        <ToggleButton value="county">Counties</ToggleButton>
        <ToggleButton value="city">Cities</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}