import { useState, useEffect, useContext, memo} from 'react';
import AppContext from '../../state/app-context';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StatCards from './StatCards';
import StrCards from './StrCards';
import populations from "../../input/county_pops.json";
import ReactGA from "react-ga4"
import { getGeoSums, getNationalBenchmarksBackfill, getStateBenchmarksBackfill, getNationalBenchmarks, getStateBenchmarks, getBackfillData } from "../../service/thermostatservice"
import { TABLES, formatDate, formatDateWithoutDay, fipsToCountyId, countyIdToFips, getAnalyticsName, isMapNonEmpty } from '../../shared/common';
import PastCards from './PastCards';
import RentCards from './RentCards';
import CommunityCards from './CommunityCards';
import { ConstructionOutlined } from '@mui/icons-material';
import SubsetCards from './SubsetCards';
import ClimateCards from './ClimateCards';
import { DatabaseDash } from 'react-bootstrap-icons';
import ListingsCards from './ListingsCards';
import WeatherCards from './WeatherCards';
import EconomyCards from './EconomyCards';
import PhotoCards from './PhotoCards';
import AICards from './AICards';
import LandCards from './LandCards';

let sampleGeoData = { "geo_prop_sums": { "avg_price": "", "median_price": ""}, "geo_sold_sums": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}
let sampleStateData = { "state": "", "data": { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}}
let sampleNationalData = { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}

const pastDateBatchMap = {}
const backfillDateBatchMap = {}
let backfillDates = []
backfillDates = getBackfillDates()

function getPastDates() {
  if (pastDates !== undefined) {
    return
  }

  let startDates = [30, 90, 180]
  let pastDates = []
  let today = new Date();

  for (let j=0; j < startDates.length; j++) {
    let anchorDate = startDates[j]
    let batchNum = j + 1
    for (let i=0; i < 5; i++) {
      let currDate = anchorDate + i
      let pastSumDate = new Date(new Date().setDate(today.getDate() - currDate));
      let dateStr = formatDate(pastSumDate)
      pastDates.push(dateStr)
      pastDateBatchMap[dateStr] = batchNum
    }
  }
  return pastDates
}

function getBackfillDates() {
  if (backfillDates !== undefined && backfillDates.length > 0) {
    return
  }

  let todaysDate = new Date();

  let result = []
  for (let j=1; j < 24; j++) {
    let dateToFind = new Date();
    dateToFind.setMonth(todaysDate.getMonth()-j);
    dateToFind = formatDateWithoutDay(dateToFind)
    result.push(dateToFind)
    backfillDateBatchMap[dateToFind] = j
  }
  return result
}

function CardTabs(props) {
  const appCtx = useContext(AppContext);
  const [benchmarks, setBenchmarks] = useState(sampleNationalData)
  const [stateBenchmarks, setStateBenchmarks] = useState(sampleStateData)
  const [benchmarkCache, setBenchmarkCache] = useState({})
  const [selectedGeo, setSelectedGeo] = useState({ county: null, county_id: null, state: null, fips: null});
  const [lastSelected, setLastSelected] = useState(null)
  const [lastDate , setLastDate] = useState(null)
  const [geoData, setGeoData] = useState({});
  const [climateRisks, setClimateRisks] = useState({"fire": "0", "heat": "0", "flood": "0", "wind": "0" })
  const [rent, setRent] = useState({})
  const [populationMap, setPopulationMap] = useState({})
  const [cityPopulationMap, setCityPopulationMap] = useState({})
  const [pastData, setPastData] = useState({})
  const [backfillData, setBackfillData] = useState({})
  const [backfillBenchmarks, setBackfillBenchmarks] = useState({})
  const [backfillStateBenchmarks, setBackfillStateBenchmarks] = useState({})
  const [selectedCard, setSelectedCard] = useState("current")
  const [validDate, setValidDate] = useState(null)
  const [todayDate, setTodayDate] = useState(null)
  const [countyBio, setCountyBio] = useState(null)

  loadPopulations()

  const fetchGeoData = async (geo, geoMode, sliderDate) => {

    if (Object.keys(benchmarkCache).length == 0) {
      benchmarkCache["today"] = {}
      benchmarkCache["today"]["national"] = {}
      benchmarkCache["today"]["states"] = {}
      benchmarkCache["today"]["county"] = {}
      benchmarkCache["today"]["city"] = {}
      benchmarkCache["past"] = {}
      benchmarkCache["backfill"] = {}
      benchmarkCache["backfill"]["national"] = {}
      benchmarkCache["backfill"]["states"] = {}
    }

    // new date request just dropped
    if (benchmarkCache[sliderDate] === undefined) {
      benchmarkCache[sliderDate] = {}
      benchmarkCache[sliderDate]["national"] = {}
      benchmarkCache[sliderDate]["states"] = {}
      benchmarkCache[sliderDate]["county"] = {}
      benchmarkCache[sliderDate]["city"] = {}
    }

    // fetch national benchmarks for geoMode if not cached already
    if (Object.keys(benchmarkCache[sliderDate]["national"]).length < 1) {
      console.log('fetching national benchmarks for first time: ' + sliderDate)
      fetchNationalBenchmarks(sliderDate)
    } else {
      console.log('retrieved national benchmarks from cache: ' + sliderDate)
      if (sliderDate == todayDate) {
        setBenchmarks(benchmarkCache["today"]["national"])
      } else {
        setBenchmarks(benchmarkCache[sliderDate]["national"])
      }
    }

    if (Object.keys(benchmarkCache[sliderDate]["states"]).length < 1 || benchmarkCache[sliderDate]["states"][geo.state] == undefined) {
      fetchStateBenchmarks(geo.state, sliderDate)
    } else {
      console.log('retrieved state benchmarks from cache: ' + geo.state)
      if (sliderDate == todayDate) {
        setStateBenchmarks(benchmarkCache["today"]["states"][geo.state])
      } else {
        setStateBenchmarks(benchmarkCache[sliderDate]["states"][geo.state])
      }
    }

    // fetch national benchmarks backfill if not already cached
    if (Object.keys(benchmarkCache["backfill"]["national"]).length < 1) {
      console.log('fetching national benchmark backfill data')
      fetchNationalBenchmarksBackfill(backfillDates)
    } else {
      console.log('retrieved national benchmarks backfill from cache')
      setBackfillBenchmarks(benchmarkCache["backfill"]["national"])
    }

    // fetch state benchmarks backfill if not already cached
    if (benchmarkCache["backfill"]["states"][geo.state] === undefined || Object.keys(benchmarkCache["backfill"]["states"][geo.state]).length < 1) {
      fetchStateBenchmarksBackfill(geo.state, backfillDates)
    } else {
      console.log('retrieved state benchmarks backfill from cache')
      setBackfillStateBenchmarks(benchmarkCache["backfill"]["states"][geo.state])
    }
    
    let geoId = ""
    let backupCountyId = ""
    let state = geo.state
    if (geoMode == "county") {
      geoId = geo.county_id
    } else if (geoMode == "city") {
      geoId = geo.city_id
      if (geo.county_id !== undefined) {
        backupCountyId = geo.county_id
      } else {
        let backupObj = fipsToCountyId(geo.fips)
        if (backupObj !== null && backupObj !== undefined) {
          backupCountyId = backupObj.county_id
        }
      }
    }

    let dateToUse = sliderDate
    if (sliderDate == todayDate) {
      dateToUse = "today"
    }

    // fetch county data
    if (geoId !== undefined && geoId !== "") {
      if (benchmarkCache[dateToUse][geoMode][geoId] === undefined) {
        let isValidDate = false
        if (validDate !== null) {
          isValidDate = validDate["is_valid"]
          // if slider date is today, and we have a valid date fetched already, use that
          if (validDate["replaces"] !== undefined && validDate["replaces"] === sliderDate) {
            dateToUse = validDate["valid_date"]
          }
        }

        // just use today for the first call
        if ((geoData !== undefined && Object.keys(geoData).length === 0) || sliderDate == todayDate) {
          dateToUse = "today"
        }

        getGeoSums(appCtx, geoId, state, geoMode, dateToUse, backupCountyId, isValidDate).then((responseObj) => {
          const data = responseObj.data
          if (data !== undefined && responseObj.meta === 200) {
            const data = responseObj.data

            // save whatever date we found, so next county lookup we dont have to waste time/data finding it
            if (data["valid_date"] === sliderDate) {
              setValidDate({ "valid_date": data["valid_date"], "is_valid": true })
            } else if (validDate === null || validDate["valid_date"] !== data["valid_date"]) {
              setValidDate({ "replaces": sliderDate, "valid_date": data["valid_date"], "is_valid": true })
            }

            if (data.str_county_booking_summary == null || data.str_county_booking_summary == undefined) {
              data.str_county_booking_summary = { }
            }

            setGeoData(data)
            setSelectedGeo(geo)
            setClimateRisks(data.county_climate_risks)
            setRent(data.county_rent_sums_total)
            benchmarkCache[sliderDate][geoMode][geoId] = data // cache for later
          }
        })

        // fetch backfill data if not cached
        if (benchmarkCache["backfill"][geoId] === undefined) {
          benchmarkCache["backfill"][geoId] = {} // placeholder so it doesnt dupe fetch
          getBackfillData(appCtx, geoId, backfillDates, geoMode).then((responseObj) => {
            const data = responseObj.data
            // combine this data with the map below.. maybe save it in an alternative map, 
            // so when something doesnt exist in the other one we grab it from backfill instead
            if (data !== undefined && responseObj.meta === 200) {

              let sortedData = dissectBackfillData(data)
              setBackfillData(sortedData)
              benchmarkCache["backfill"][geoId] = sortedData // cache for later
            }
          });
          // cached backfill
        } else {
          console.log('retrieved backfill geo from cache: ' + geoId + ' for date: ' + dateToUse)
          setBackfillData(benchmarkCache["backfill"][geoId]) // cached from last time
        }
      } else {
        console.log('retrieved geo from cache: ' + geoId + ' for date: ' + dateToUse)
        
        setGeoData(benchmarkCache[dateToUse][geoMode][geoId]) // cached from last time
      }
    }
  }

  function getFormattedDate(numMonths) {
    const DATE_AGO = new Date();
    DATE_AGO.setMonth(DATE_AGO.getMonth() - numMonths);
    return formatDateWithoutDay(DATE_AGO)
    //return DATE_AGO.getFullYear() + "-" + DATE_AGO.getMonth()
  }

  function dataGapWorkaround(d) {
    if (d == "2023-07") {
      d = "2023-06"
    }
    return d
  }

  function dissectBackfillData(data) {
      const sortedArray = data.sort(function(a,b) {
        return new Date(a.period) - new Date(b.period);
      });

      let pastMap = {}
      const THREE_MONTHS_AGO = dataGapWorkaround(getFormattedDate(3))
      const SIX_MONTHS_AGO = dataGapWorkaround(getFormattedDate(6))
      const TWELVE_MONTHS_AGO = dataGapWorkaround(getFormattedDate(12))

      const pastTabStops = {
        [THREE_MONTHS_AGO]: "three_months",
        [SIX_MONTHS_AGO]: "six_months",
        [TWELVE_MONTHS_AGO]: "twelve_months"
      }

      let existingCountyId = ""
      for (let i=0; i< sortedArray.length; i++) {
        let curr = sortedArray[i]
        let countyId = curr["county_id"]

        // TODO remove this once we conver this to city, workaround
        if (existingCountyId == "") {
          existingCountyId = countyId
        }

        let periodDate = new Date(curr.period)
        let periodFormatted = formatDateWithoutDay(periodDate)

        // if its 3/6/12 months then we throw that into pastData
        let tabStop = pastTabStops[periodFormatted.toString()]
        if (tabStop != undefined && tabStop != null) {
          pastMap[tabStop] = curr
        }

        // my values, already cleaned up
        if (countyId == undefined) {
          continue
        }

        // else - redfin's backfill values  
        let pop = 1
        if (props.geoModeTab == "city") {
          //pop = cityPopulationMap["city"]["population"]
          let fips = countyIdToFips(existingCountyId).fips
          pop = populationMap[fips]["population"]
           // need to change but not doing tonight

        } else if (props.geoModeTab == "county") {
          let fips = countyIdToFips(countyId).fips
          pop = populationMap[fips]["population"]
        }

        if (curr["listings_per_pop"] === undefined && curr["num_listings"] !== undefined) {
          let numListings = curr["num_listings"]
          let numSold = curr["num_sold"]
          let numDrops = curr["num_price_drops"]

          let listingsPerPop = (parseFloat(numListings) / pop) * 100000
          let soldPerPop = (parseFloat(numSold) / pop) * 100000
          let priceDropsPerPop = (parseFloat(numDrops) / pop) * 100000
          sortedArray[i]["listings_per_pop"] = String(listingsPerPop.toFixed(0))
          sortedArray[i]["sold_listings_per_pop"] = String(soldPerPop.toFixed(0))
          sortedArray[i]["price_drops_per_pop"] = String(priceDropsPerPop.toFixed(0))
        }
      }

      setPastData(pastMap)

      return sortedArray
  }

  const fetchStateBenchmarks = async (state, sliderDate) => {
    // just use today for the first call
    let dateToUse = sliderDate
    if ((stateBenchmarks !== undefined && stateBenchmarks == sampleStateData) || sliderDate == todayDate) {
      dateToUse = "today"
    }
    getStateBenchmarks(appCtx, state, dateToUse).then((responseObj) => {
      const data = responseObj.data
      if (data !== undefined && responseObj.meta === 200) {

        // if we have returned empty, default to first date we cached (todays) TODO: do this for city past dates.. as we only just started collecting city data
        if (Object.keys(data.data.benchmark_active).length > 0) {
          setStateBenchmarks({ data: data.data, state: data.state})
          benchmarkCache[dateToUse]["states"][state] = data
        } else {
          let existingDate = "today"
          console.log('state benchmark not found: ' + JSON.stringify(data))
          if (Object.keys(benchmarkCache[existingDate]["states"]).length > 0) {
            //console.log('falling back to default state benchmark: ' + JSON.stringify(benchmarkCache[existingDate]["states"][state]))
            setStateBenchmarks({data: benchmarkCache[existingDate]["states"][state]["data"], state: state})
          }
        }
      }
    })
  }

  const fetchNationalBenchmarks = async (sliderDate) => {
    // just use today for the first call
    let dateToUse = sliderDate
    if ((benchmarks !== undefined && benchmarks == sampleNationalData) || sliderDate == todayDate) {
      dateToUse = "today"
    }

    getNationalBenchmarks(appCtx, dateToUse).then((responseObj) => {
      const data = responseObj.data
      if (data !== undefined && responseObj.meta === 200) {
        setBenchmarks(data)
        benchmarkCache[dateToUse]["national"] = data
        setTodayDate(sliderDate)
      }
    })
  }

  const fetchNationalBenchmarksBackfill = async (backfillDates) => {
    getNationalBenchmarksBackfill(appCtx, backfillDates).then((responseObj) => {
      const data = responseObj.data
      if (data !== undefined && responseObj.meta === 200) {
        setBackfillBenchmarks(data)
        benchmarkCache["backfill"]["national"] = data
      }
    })
  }

  const fetchStateBenchmarksBackfill = async (state, backfillDates) => {
    getStateBenchmarksBackfill(appCtx, state, backfillDates).then((responseObj) => {
      const data = responseObj.data
      if (data !== undefined && responseObj.meta === 200) {
        setBackfillStateBenchmarks(data)
        benchmarkCache["backfill"]["states"][state] = data
      }
    })
  }

  function loadPopulations() {
    if (Object.keys(populationMap).length == 0) {
      let dataMap = {}
      for (let i in populations) {
        const pop = populations[i]
        dataMap[pop.fips] = { "fips": pop.fips, "population": pop.population }
      }
      setPopulationMap(dataMap)
    }

    // cities
    /*
    let list = cityPopulations["features"]
    if (Object.keys(cityPopulationMap).length == 0) {
      let dataMap = {}
      for (let i in list) {
        let item = list[i]
        const popObject = item["properties"]

        let name = popObject["NAME"]
        let population = popObject["POPULATION"]
        let state = popObject["ST"]
        let key = state + "_" + name

        //dataMap[key] = { "city_id": key, "population": population }
        dataMap[key] = { "city_id": key, "label": name +', ' + state , "city_name": name, "state": state, "population": population }

      }
      setCityPopulationMap(dataMap)
      console.log('city pops: ' + JSON.stringify(dataMap))
    }*/
  }

  useEffect(() => {
    if (selectedCard != null) {
    ReactGA.event({
      category: 'Card.Selected',
      action: selectedCard,
      label: 'card.selected.' + lastSelected,
    });
  }
  }, [selectedCard])

  useEffect(() => {
    if (isMapNonEmpty(props.geoContent) && appCtx.isAuthenticated) {
      if (props.geoModeTab == "county" && (lastSelected !== props.geoContent.county_id || lastDate !== props.sliderDate)) {
        setLastSelected(props.geoContent.county_id)
        setLastDate(props.sliderDate)

        fetchGeoData(props.geoContent, props.geoModeTab, props.sliderDate)
      } else if (props.geoModeTab == "city" && (lastSelected !== props.geoContent.city_id || lastDate !== props.sliderDate)) {
        setLastSelected(props.geoContent.city_id)

        setLastDate(props.sliderDate)
        fetchGeoData(props.geoContent, props.geoModeTab, props.sliderDate)
      }
    }

  }, [props.geoContent, props.sliderDate, appCtx.isAuthenticated]);


  return (
    <Tabs
      className="center tabs-margin"
      defaultActiveKey="current"
      id="uncontrolled-tab-example"
      onSelect={(k) => setSelectedCard(k)}
    >
      <Tab eventKey="current" title="Housing Stats">
        <StatCards mapType={props.mapType} sliderDate={props.sliderDate} populationMap={populationMap} geoModeTab={props.geoModeTab} selectedGeo={props.geoContent} geoData={geoData} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks} mortgageRates={props.mortgageRates} countyBio={countyBio} setCountyBio={setCountyBio}></StatCards>
      </Tab>
      <Tab eventKey="past" title="Trends">
        <PastCards geoData={geoData} mapType={props.mapType} populationMap={populationMap} geoModeTab={props.geoModeTab} selectedCounty={props.geoContent} backfillData={backfillData} pastData={pastData} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks} backfillBenchmarks={backfillBenchmarks} backfillStateBenchmarks={backfillStateBenchmarks}></PastCards>
      </Tab>
      <Tab eventKey="land" title="Land">
        <LandCards mapType={props.mapType} sliderDate={props.sliderDate} populationMap={populationMap} geoModeTab={props.geoModeTab} selectedCounty={props.geoContent} landData={geoData} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks}></LandCards>
      </Tab>
      <Tab eventKey="str" title="Short Term Rentals">
        <StrCards mapType={props.mapType} sliderDate={props.sliderDate} populationMap={populationMap} geoModeTab={props.geoModeTab} selectedCounty={props.geoContent} countyData={geoData} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks}></StrCards>
      </Tab>
      <Tab eventKey="rent" title="Rent">
        <RentCards mapType={props.mapType} sliderDate={props.sliderDate} populationMap={populationMap} geoModeTab={props.geoModeTab} selectedCounty={props.geoContent} rentData={geoData} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks}></RentCards>
      </Tab>
      <Tab eventKey="listings" title="Listings">
        <ListingsCards selectedGeo={props.geoContent} geoModeTab={props.geoModeTab}></ListingsCards>
      </Tab>
      <Tab eventKey="subsets" title="Bed/Bath/Sqft">
        <SubsetCards mapType={props.mapType} geoData={geoData} selectedCounty={props.geoContent} geoModeTab={props.geoModeTab} selectedCard={selectedCard} sliderDate={props.sliderDate} mortgageRates={props.mortgageRates}></SubsetCards>
      </Tab>
      <Tab eventKey="climate" title="Climate Risks">
        <ClimateCards selectedCounty={props.geoContent} climateRisks={climateRisks} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks}></ClimateCards>
      </Tab>
      <Tab eventKey="score" title="Weather">
        <WeatherCards geoModeTab={props.geoModeTab} selectedCounty={props.geoContent} countyData={geoData["community"]} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks}></WeatherCards>
      </Tab>
      <Tab eventKey="community" title="Community">
        <CommunityCards selectedCounty={props.geoContent} countyData={geoData["community"]} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks} setCountyBio={setCountyBio}></CommunityCards>
      </Tab>
      <Tab eventKey="economy" title="Economy">
        <EconomyCards selectedCounty={props.geoContent} countyData={geoData["community"]} stateBenchmarks={stateBenchmarks} benchmarks={benchmarks}></EconomyCards>
      </Tab>
      <Tab eventKey="photos" title="Photos">
        <PhotoCards selectedGeo={props.geoContent} geoModeTab={props.geoModeTab}></PhotoCards>
      </Tab>
    </Tabs>
  );
}

export default memo(CardTabs);