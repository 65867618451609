import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { csv } from "d3-fetch";
import { Paper } from "@mui/material";
import ReactGA from "react-ga4"

const PastMapTypeComboBox = ({setMapType}) => {
  const [mapItems, setMapItems] = useState([]);
  const dataArray = []
  const dataMap = {} 
  const SCORE_TYPES = [
  { "type": "median_price", "name": "Median Price" },
  { "type": "median_days_on_market", "name": "Median Days on Market"},
  { "type": "num_listings", "name": "Number of Listings" },
  { "type": "num_price_drops", "name": "Number of Price Drops" },
  { "type": "listings_per_pop", "name": "Number of Listings Normalized" },
]


  const onChanged = (newValue) => {
    if (newValue !== null) {
      setMapType(newValue.type)

      ReactGA.event({
        category: 'Combobox.Changed',
        action: 'PastMapTypeCombobox Changed',
        label: 'pastmaptypecombobox.changed.'+newValue.type
      });
    }
  };

  useEffect(() => {
    const loadValues = async (dataArray) => {

      for (let i =0; i < SCORE_TYPES.length; i++) {
        const subtype = SCORE_TYPES[i]
        const mapType = subtype["type"]
        const name = subtype["name"]
        
        if (name !== undefined && mapType !== undefined && dataMap[mapType] === undefined) {
          dataArray.push({ label: name, type: mapType})
          dataMap[mapType] = name
        }
      }

      if (dataArray.length > 0) {
        setMapItems(dataArray)
      }
    }

    if (mapItems.length < 1) {
      loadValues(dataArray)
      .catch(console.error);
    }
    

  }, []);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-map-past"
      defaultValue="Median Price"
      options={mapItems}
      className="combobox-dark"
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#2d2d30", color: "white" }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" InputProps={{ style: { fontSize: 6 } }} {...params} label="Compare" size="small" />}
    />
  );
}

export default memo(PastMapTypeComboBox);