import { useState, useEffect, useContext, memo} from 'react';
import AppContext from '../../state/app-context';
import * as React from 'react';
import {numberWithCommas, replaceUnderscores, isMobile, STR_COLS, RENT_COLS, safelyGrab, getCardTitle, isInScope, isMapNonEmpty, calculateMonthlyPayment, LAND_COLS, getAttrLabel} from '../../shared/common';
import { getCountySubsets } from "../../service/thermostatservice"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import CrossTabHype from '../Subscriptions/CrossTabHype';
import SubsetToggle from '../SubsetToggle';

import { Col, Container, Row } from 'react-bootstrap';
import Grid from '@mui/material/Unstable_Grid2';
import { SCORES_MAP } from '../../shared/common';

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1e1e1e',
    color: theme.palette.common.white,
    fontSize: 11,
    height: 10
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
  '&.headRow': {
    backgroundColor: '#1e1e1e',
    color: theme.palette.common.white,
    fontSize: 11,
    height: 10,
    fontWeight: 'bold'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#36363a",
    color: "white"
  },
  '&:nth-of-type(even)': {
    backgroundColor: "#2d2d30",
    color: "white"
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&.headRow': {
    backgroundColor: '#3e3e42',
    color: "white",
    fontSize: 11,
    height: 10,
    fontWeight: 'bold'
  },
}));

function SubsetCards(props) {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();
  const [selectedToggle, setSelectedToggle] = useState("active");
  const [subsetData, setSubsetData] = useState({})
  const [selectedData, setSelectedData] = useState([])
  const [loadingData, setLoadingData] = useState(null)
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});

  useEffect(() => {
    if (isMapNonEmpty(props.selectedCounty) && isInScope(appCtx, "landlord") && props.selectedCounty.county_id !== selectedCounty.county_id) {
      setSelectedCounty(props.selectedCounty)
      fetchCountySubsetData(props.selectedCounty, selectedToggle, props.sliderDate)
    }

  }, [props.selectedCounty, selectedToggle]);

  useEffect(() => {
    if (isMapNonEmpty(props.selectedCounty) && isInScope(appCtx, "landlord")) {
      fetchCountySubsetData(props.selectedCounty, selectedToggle, props.sliderDate)
    }

  }, [props.sliderDate, selectedToggle]);

function sortSubset(data) {
  let resortedBedroom = []
  let resortedSleeps = []
  let resortedBeds = []
  let doubleDigitsSleeps = []
  let leftovers = []
  for (let d in data) {
    let item = data[d]
    let filterBy = item["filter_by"]
    if (filterBy.indexOf('bedroom') > -1) {
      resortedBedroom.push(item)
    }
    else if (filterBy.indexOf('sleeps')> -1) {
      if (filterBy.indexOf("10") > -1 || filterBy.indexOf("11") > -1 || filterBy.indexOf("12") > -1) {
        doubleDigitsSleeps.push(item)
      } else {
        resortedSleeps.push(item)
      }
    }
    else if (filterBy.indexOf('beds')> -1) {
      resortedBeds.push(item)
    }
    else {
      leftovers.push(item)
    }
  }

  const all = [...resortedBedroom, ...resortedBeds, ...resortedSleeps, ...doubleDigitsSleeps, ...leftovers];
  return all
}

const fetchCountySubsetData = async (county, type, sliderDate) => {
  const countyId = county.county_id
  if (countyId === undefined) {
    return
  }

  // initialize
  if (subsetData[countyId] === undefined) {
    subsetData[countyId] = {}
  }
  
  if (subsetData[countyId][type] == undefined) {
    subsetData[countyId][type] = {}
  }

  // already cached
  if (isMapNonEmpty(subsetData[countyId][type][sliderDate]))  {
    setLoadingData(false)
    setSelectedData(subsetData[countyId][type][sliderDate])

  } else if (subsetData[countyId][type][sliderDate] === undefined) {
    // prevent double fetch
    subsetData[countyId][type][sliderDate] = {}
    setLoadingData(true)
    getCountySubsets(appCtx, countyId, type, sliderDate).then((responseObj) => {
      let data = responseObj.data
      if (data !== undefined && responseObj.meta === 200 && Object.keys(data).length !== 0) {
        // sort by type
        if (type == "str") {
        data = sortSubset(data)
        }

        subsetData[countyId][type][sliderDate] = data
        setLoadingData(false)
        setSelectedData(data)
      } else {
        setLoadingData(false)
      }
    })
  }
}

function shouldHideCell(toggle, type) {
  // configs

  // active & sold, show everything
  if (toggle === "active" || toggle === "sold") {
    if (type === "date" || type === "type") {
      return false
    }

    if (STR_COLS[type] !== undefined || LAND_COLS[type] !== undefined) {
      return true
    }

    return false

  } else if (toggle === "rent") {
    if (RENT_COLS[type] !== undefined) {
      return false
    } else {
      return true
    }

  } else if (toggle === "str") {
    if (STR_COLS[type] !== undefined) {
      return false
    } else {
      return true
    }
  } else if (toggle === "land") {
    if (LAND_COLS[type] !== undefined) {
      return false
    } else {
      return true
    }
  }
}

function computeOR(row, term) {
  if (term === "12m") {
    let unavailable = row["num_days_unavailable_12m"]
    let available = row["num_days_available_12m"]
    let total = parseFloat(unavailable) + parseFloat(available)
    return parseFloat((unavailable / total)*100).toFixed(2) + "%"

  } else if (term === "3m") {
    let unavailable = row["num_days_unavailable_3m"]
    let available = row["num_days_available_3m"]
    let total = parseFloat(unavailable) + parseFloat(available)
    return parseFloat((unavailable / total)*100).toFixed(2) + "%"
  }
  return
}

function viewSubOptions() {
  return (
    navigate("/premium", { replace: false })
  )
}

function calculateMortgage(houseCost) {
  let pay = calculateMonthlyPayment(houseCost, 30, props.mortgageRates["rate30"])
  return pay
}

function CurrCards(props) {

  if (props.selectedCounty !== null && props.selectedCounty.county !== null && selectedData !== undefined && !isMobile()) {
    return (
      <TableContainer component={Paper}>
      <Table key={"subset_table"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} size="small" aria-label="a dense table">
    <TableHead>
    <StyledTableRow key="county_sums_header" className='subsets-row'>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, "type")} align="center">Averages for Listings with:</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_price)} align="center">Avg Price</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.median_price)} align="center">Median Price</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.est_monthly_payment)} align="center">Est. Monthly Payment</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.price_per_sqft)} align="center">Price per Sqft</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_days_on_market)} align="center">Avg Days on Market</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.median_days_on_market)} align="center">Median Days on Market</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.num_listings)} align="center">Listings</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.listings_per_pop)} align="center">Listings per 100k</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.num_price_drops)} align="center">Price Drops (last 3 months)</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.price_drops_per_pop)} align="center">Price Drops per 100k</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_price_drop)} align="center">Avg Price Drop</StyledTableCell>
        
        <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.avg_price_per_night_3m)} align="center">Avg Price per Night (next 3 months)</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.avg_price_per_night_12m)} align="center">Avg Price per Night (next 12 months)</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.num_listings_12m)} align="center">Listings</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.occupancy_rate_3m)} align="center">Occupancy Rate (next 3 months)</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.occupancy_rate_12m)} align="center">Occupancy Rate (next 12 months)</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.est_revenue_12m)} align="center">Estimated Annual Revenue</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.possible_rev_12m)} align="center">Max Annual Revenue (occupancy=100%)</StyledTableCell>

        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_price_per_acre)} align="center">{getAttrLabel(LAND_COLS.land_price_per_acre)}</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_acres)} align="center">{getAttrLabel(LAND_COLS.land_avg_acres)}</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_median_price)} align="center">{getAttrLabel(LAND_COLS.land_median_price)}</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_price)} align="center">{getAttrLabel(LAND_COLS.land_avg_price)}</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_num_listings)} align="center">{getAttrLabel(LAND_COLS.land_num_listings)}</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_listings_per_pop)} align="center">{getAttrLabel(LAND_COLS.land_listings_per_pop)}</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_price_drop)} align="center">{getAttrLabel(LAND_COLS.land_avg_price_drop)}</StyledTableCell>
        <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_price_drops_per_pop)} align="center">{getAttrLabel(LAND_COLS.land_price_drops_per_pop)}</StyledTableCell>

        <StyledTableCell hidden={shouldHideCell(selectedToggle, "date")} align="center">Date</StyledTableCell>
      </StyledTableRow>
    </TableHead>
    <TableBody>
      {selectedData.map((row, index) => (

          <StyledTableRow key={"county_subsets_" + index} className="subsets-row">
          <StyledTableCell hidden={shouldHideCell(selectedToggle, "type")} align="center">{replaceUnderscores(row.filter_by)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_price)} align="center">{"$"+numberWithCommas(row.avg_price)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.median_price)} align="center">{"$"+numberWithCommas(row.median_price)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.est_monthly_payment)} align="center">{"$"+calculateMortgage(row.median_price)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.price_per_sqft)} align="center">{"$"+row.price_per_sqft}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_days_on_market)} align="center">{numberWithCommas(row.avg_days_on_market)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.median_days_on_market)} align="center">{numberWithCommas(row.median_days_on_market)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.num_listings)} align="center">{numberWithCommas(row.num_listings)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.listings_per_pop)} align="center">{numberWithCommas(row.listings_per_pop)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.num_price_drops)} align="center">{numberWithCommas(row.num_price_drops)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.price_drops_per_pop)} align="center">{numberWithCommas(row.price_drops_per_pop)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_price_drop)} align="center">{"$"+numberWithCommas(row.avg_price_drop)}</StyledTableCell>
          
          <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.avg_price_per_night_3m)} align="center">{"$"+safelyGrab(row, STR_COLS.avg_price_per_night_3m)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.avg_price_per_night_12m)} align="center">{"$"+safelyGrab(row, STR_COLS.avg_price_per_night_12m)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.num_listings_12m)} align="center">{safelyGrab(row, STR_COLS.num_listings_12m)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.occupancy_rate_3m)} align="center">{computeOR(row, "3m")}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.occupancy_rate_12m)} align="center">{computeOR(row, "12m")}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.est_revenue_12m)} align="center">{"$"+safelyGrab(row, STR_COLS.est_revenue_12m)}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, STR_COLS.possible_rev_12m)} align="center">{"$"+safelyGrab(row, STR_COLS.possible_rev_12m)}</StyledTableCell>

          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_price_per_acre)} align="center">{"$"+numberWithCommas(row[LAND_COLS.land_price_per_acre])}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_acres)} align="center">{numberWithCommas(row[LAND_COLS.land_avg_acres])}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_median_price)} align="center">{"$"+numberWithCommas(row[LAND_COLS.land_median_price])}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_price)} align="center">{"$"+numberWithCommas(row[LAND_COLS.land_avg_price])}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_num_listings)} align="center">{numberWithCommas(row[LAND_COLS.land_num_listings])}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_listings_per_pop)} align="center">{numberWithCommas(row[LAND_COLS.land_listings_per_pop])}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_price_drop)} align="center">{"$"+numberWithCommas(row[LAND_COLS.land_avg_price_drop])}</StyledTableCell>
          <StyledTableCell hidden={shouldHideCell(selectedToggle, LAND_COLS.land_price_drops_per_pop)} align="center">{numberWithCommas(row[LAND_COLS.land_price_drops_per_pop])}</StyledTableCell>
          
          <StyledTableCell hidden={shouldHideCell(selectedToggle, "date")} align="center">{row.date}</StyledTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  </Table>
  </TableContainer>
    );
  } else if (props.selectedCounty !== null && props.selectedCounty.county !== null && selectedData !== undefined && isMobile()) {
    return (
      <TableContainer component={Paper}>
      <Table key={"subset_table"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} size="small" aria-label="a dense table">
    
      {selectedData.map((row) => (
        <TableBody>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, "type")} className="headRow" key={"row_county_sums_filter_by"}>
            <StyledTableCell className="headRow" align="center">Averages for Listings with: </StyledTableCell>
            <StyledTableCell className="headRow" align="center">{replaceUnderscores(row.filter_by)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_price)} key={"row_county_sums_avg_price"}>
          <StyledTableCell align="center">Avg Price</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(row.avg_price)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.median_price)} key={"row_county_sums_median_price"}>
            <StyledTableCell align="center">Median Price</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(row.median_price)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.est_monthly_payment)} key={"county_est_monthly_mortgage"}>
            <StyledTableCell align="center">Est. Montly Payment</StyledTableCell>
            <StyledTableCell align="center">{"$"+calculateMortgage(row.median_price)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.est_monthly_payment)} key={"county_price_per_sqft"}>
            <StyledTableCell align="center">Price per Sqft</StyledTableCell>
            <StyledTableCell align="center">{"$"+row.price_per_sqft}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_days_on_market)} key={"county_sums_avg_days_on_market"}>
            <StyledTableCell align="center">Avg Days on Market</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(row.avg_days_on_market)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.median_days_on_market)} key={"county_sums_median_days_on_market"}>
            <StyledTableCell align="center">Median Days on Market</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(row.median_days_on_market)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.num_listings)} key={"county_sums_num_listings"}>
            <StyledTableCell align="center">Listings</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(row.num_listings)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.listings_per_pop)} key={"county_sums_listings_per_pop"}>
          <StyledTableCell  align="center">Listings per 100k</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(row.listings_per_pop)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.num_price_drops)} key={"county_sums_num_price_drops"}>
            <StyledTableCell align="center">Price Drops (last 3 months)</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(row.num_price_drops)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.price_drops_per_pop)} key={"county_sums_price_drops_per_pop"}>
            <StyledTableCell align="center">Price Drops per 100k</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(row.price_drops_per_pop)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, SCORES_MAP.avg_price_drop)} key={"county_sums_avg_price_drop"}>
            <StyledTableCell align="center">Avg Price Drop</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(row.avg_price_drop)}</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hidden={shouldHideCell(selectedToggle, "date")} key={"county_sums_date"}>
            <StyledTableCell align="center">Date</StyledTableCell>
            <StyledTableCell align="center">{row.date}</StyledTableCell>
          </StyledTableRow>


          <StyledTableRow hidden={shouldHideCell(selectedToggle, STR_COLS.avg_price_per_night_3m)} key={'col'+STR_COLS.avg_price_per_night_3m}>
            <StyledTableCell align="center">Avg Price Per Night</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(safelyGrab(row, STR_COLS.avg_price_per_night_3m))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, STR_COLS.occupancy_rate_3m)} key={'col'+STR_COLS.occupancy_rate_3m}>
            <StyledTableCell align="center">Occupancy Rate (3 months from now)</StyledTableCell>
            <StyledTableCell align="center">{computeOR(row, "3m")}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, STR_COLS.occupancy_rate_12m)} key={'col'+STR_COLS.occupancy_rate_12m}>
            <StyledTableCell align="center">Occupancy Rate (12 months from now)</StyledTableCell>
            <StyledTableCell align="center">{computeOR(row, "12m")}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, STR_COLS.est_revenue_12m)} key={'col'+STR_COLS.est_revenue_12m}>
            <StyledTableCell align="center">Estimated Annual Revenue</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(safelyGrab(row, STR_COLS.est_revenue_12m))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, STR_COLS.possible_rev_12m)} key={'col'+STR_COLS.possible_rev_12m}>
            <StyledTableCell align="center">Max Annual Revenue (occupancy=100%)</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(safelyGrab(row, STR_COLS.possible_rev_12m))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, STR_COLS.num_listings_12m)} key={'col'+STR_COLS.num_listings_12m}>
            <StyledTableCell align="center">Number of Listings</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(safelyGrab(row, STR_COLS.num_listings_12m))}</StyledTableCell>
          </StyledTableRow>


          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_price_per_acre)} key={'col'+LAND_COLS.land_price_per_acre}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_price_per_acre)}</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(safelyGrab(row, LAND_COLS.land_price_per_acre))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_acres)} key={'col'+LAND_COLS.land_avg_acres}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_avg_acres)}</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(safelyGrab(row, LAND_COLS.land_avg_acres))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_median_price)} key={'col'+LAND_COLS.land_median_price}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_median_price)}</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(safelyGrab(row, LAND_COLS.land_median_price))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_price)} key={'col'+LAND_COLS.land_avg_price}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_avg_price)}</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(safelyGrab(row, LAND_COLS.land_avg_price))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_num_listings)} key={'col'+LAND_COLS.land_num_listings}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_num_listings)}</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(safelyGrab(row, LAND_COLS.land_num_listings))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_listings_per_pop)} key={'col'+LAND_COLS.land_listings_per_pop}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_listings_per_pop)}</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(safelyGrab(row, LAND_COLS.land_listings_per_pop))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_avg_price_drop)} key={'col'+LAND_COLS.land_avg_price_drop}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_avg_price_drop)}</StyledTableCell>
            <StyledTableCell align="center">{"$"+numberWithCommas(safelyGrab(row, LAND_COLS.land_avg_price_drop))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow hidden={shouldHideCell(selectedToggle, LAND_COLS.land_price_drops_per_pop)} key={'col'+LAND_COLS.land_price_drops_per_pop}>
            <StyledTableCell align="center">{getAttrLabel(LAND_COLS.land_price_drops_per_pop)}</StyledTableCell>
            <StyledTableCell align="center">{numberWithCommas(safelyGrab(row, LAND_COLS.land_price_drops_per_pop))}</StyledTableCell>
          </StyledTableRow>

        </TableBody>
      ))}
    
  </Table>
  </TableContainer>
    );
  }
}



function ShowPayWall(props){
  if (isInScope(props.appCtx, "landlord")) {
    return
  } else {
    return (
      <Container>
        <Row className="paywall-subset">
          <Col xs={12} sm={12} md={6} lg={6}>
            <p className="dark-primary text-centered"> Landlord Subscription required to sort by beds/baths/sqft for Active/Sold/Land/Rent and Short Term Listings.</p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Button className="profile-subset-button" variant="contained" color="success" onClick={()=> viewSubOptions()}>
              How much we talkin here?
            </Button>
            </Col>
        </Row>
          <CrossTabHype></CrossTabHype>
      </Container>
    )
  }
}

  return (
    <Container key={"subsets-"+JSON.stringify(props.selectedCounty)} hidden={props.selectedCounty == null || props.selectedCounty.county == null}>
      <div hidden={!isInScope(appCtx, "landlord")}>
        <div hidden={loadingData == true}>
          <Row className="mb-3">
            <Col xs={12} sm={8} md={6} lg={5} className='text-centered small-spacer'>
              <SubsetToggle setSelectedTab={setSelectedToggle} selectedTab={selectedToggle}></SubsetToggle>
            </Col>
            <Col xs={12} sm={4} md={6} lg={4} className='text-centered'>
              <div className="bold text-centered dark-primary">{getCardTitle("county", props.selectedCounty) + " (" + props.sliderDate + ")"}</div>
            </Col>
          </Row>
          <Grid xs={1} sm={1} md={1} minWidth="300px" maxWidth="100%" >
            <CurrCards selectedData={props.selectedData} selectedCounty={props.selectedCounty} selectedToggle={selectedToggle}></CurrCards>
          </Grid>
        </div>
        <Row hidden={loadingData == false}>
          <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
        </Row>
      </div>
        <ShowPayWall appCtx={appCtx}></ShowPayWall>
      </Container>
  );
}

export default memo(SubsetCards);