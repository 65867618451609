import React from "react";
import { Row, Container, Col } from "react-bootstrap";

import communityImg from '../../assets/community_data_promo.png'
import climateImg from '../../assets/climate_risks_promo.png'
import heatmapImg from '../../assets/heatmap_promo.png'
import lgbtqImg from '../../assets/lgbtq_abotion_weed.png'
import economyImg from '../../assets/economy.png'
import jobsImg from '../../assets/jobs.png'
import costOfLivingImg from '../../assets/cost_of_living.png'

function HeroBanner() {
  return (
    <section id="home">
      <Container>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
            <div className="card aboutme-card">
              <div className="aboutme-header">
                <h3>Welcome to Hidden Housing</h3>
                <h5 className="aboutme-subheader">
                  You Can Afford a House. Somewhere.
                </h5>
              </div>
              <p className="aboutme-text">
                In an era of late stage capitalism, where housing costs have outpaced median wages for decades, we help level the playing field. We find secret coves where houses remain affordable and big money hasn't ruined it yet. You just may have to move to Arkansas.
              </p>
              <p className="aboutme-text">
                We scour the internet, tracking counties and cities that are undervalued. We collect treasure troves of housing data but we dont stop there. We also have data on each county/city's diversity, crime, politics, economy, cost of living, weather, climate risks, you name it. We show you all of the data, use it however you want.
              </p>
              <p className="aboutme-text">
                We summarize, weight and aggregate the data into a "total score". We synthesize new Heatmaps daily based on these scores. Green means its a great buying opportunity, Red means don't buy there right now.
              </p>
              <p className="aboutme-text">
                *Listings priced over 3 million dollars are not currently included. This was a design choice to serve the mission of the website: To help lower to upper middle class people find affordable housing in the United States. If you believe we should start including more expensive homes please email us.
              </p>
            </div>
          </Col>
          <Col lg={6}>
          <div className="img-wrapper-main">
            <img src={heatmapImg} className="section-image" alt="App Preview" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="card aboutme-card">
              <div className="aboutme-header">
                <h3>Where do I get the data?</h3>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12}>
          <div className="card aboutme-card">
              <h5 className="aboutme-subheader">
                Listing Sites
              </h5>
            <p className="aboutme-text">
              We actively compute occupancy rates, daily rental rates, projected revenue and listing information for short term rentals (Airbnbs/VRBOs)
            </p>
            <p className="aboutme-text">
              Compare occupancy rates from counties and cities throughout the country! No other site has this data for free like this. Go crazy.
            </p>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className="card aboutme-card">
              <h5 className="aboutme-subheader">
              Short Term Rental Sites
              </h5>
            <p className="aboutme-text">
              We check Active and Sold listings every day with MLS registered sites (zillow/redfin/houses.com etc).
            </p>
            <p className="aboutme-text">
            This allows us to find up to the day summaries of real estate conditions in ever county and city in the US. Every day.
            </p>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className="card aboutme-card">
              <h5 className="aboutme-subheader">
              Climate Risks
              </h5>
            <p className="aboutme-text">
            Our climate data is fetched from leading government agencies and non-profit's (ClimateCheck.com and Riskfactor.org)
            </p>
            <p className="aboutme-text">
            Each region is assessed by 5 major risk factors: Fire, Drought, Storm/Wind, Flood and Heat. These are all then averaged together into a total score (see climate risks map)
            </p>
          </div>
          <div className="card aboutme-card-jobs">
          <div className="img-wrapper">
            <img src={climateImg} className="section-image" alt="App Preview" />
          </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className="card aboutme-card">
              <h5 className="aboutme-subheader">
              Community Data
              </h5>
              <p className="aboutme-text">
            Browse a community's Diversity, Politics, Cost of Living, Crime, Weather, Number of Sunny Days a Year, Snow, Gender, Marrital Status. We have it all.
            </p>
            <p className="aboutme-text">
            Sources include but are not limited to: U.S. Census Bureau, American Community Survey, Current Population Survey, Federal Bureau of Investigation, Uniform Crime Reports, U.S. Geologic Service, Medicare and the U.S. Department of Health and Human Services, Environmental Protection Agency
            </p>
            
          </div>
          <div className="card aboutme-card-jobs">
          <div className="img-wrapper">
            <img src={communityImg} className="section-image" alt="App Preview" />
            <img src={lgbtqImg} className="section-image" alt="App Preview" />
          </div>
          </div>
        </Col>
        <Row>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className="card aboutme-card">
              <h5 className="aboutme-subheader">
              Economic Data
              </h5>
            <p className="aboutme-text">
            Our economic data is gotten from many public data sources, among them are:
            </p>
            <p className="aboutme-text">
            U.S. Census Bureau, Bureau of Labor Statistics, Consumer Expenditure Survey, Medicare and the U.S. Department of Health and Human Services, Department of Energy, Office of Housing Economic Oversight
            </p>
          </div>
          <div className="card aboutme-card-jobs">
        <div className="img-wrapper">
            <img src={jobsImg} className="section-image" alt="App Preview" />
          </div>
        </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
        <p className="aboutme-text">
            Example county economy data:
        </p>
        <div className="img-wrapper">
            <img src={economyImg} className="section-image" alt="App Preview" />
          </div>
          <div className="card aboutme-card-jobs">
        <div className="img-wrapper">
          <img src={costOfLivingImg} className="section-image" alt="App Preview" />
        </div></div>
          </Col>
        </Row>
        </Row>
      </Container>
    </section>
  );
}

export default HeroBanner;
