import React from 'react'

import { Info, PersonAdd, HouseCheck, Grid, Speedometer2, CloudDownload, Buildings, StarFill, Award, Map, QuestionCircle } from 'react-bootstrap-icons';

import AccountState from '../pages/AccountState';
import Watchlist from '../componets/Watchlist/Watchlist';
import './MainLayout.css'
import '../componets/Watchlist/watchlist.css'

function SideNav(props) {

  function OnProfilePage() {
    let url = window.location.href
    if (url.indexOf("/profile") > -1 || url.indexOf('/watchlist') > -1) {
      return true
    } else {
      false
    }
  }

  function hideSidebarWatchlist() {
    let currUrl = window.location.href
    if (currUrl.indexOf("watchlist") > -1) {
      return true
    }
    return false
  }

  return (
    <nav className="flex-column mb-auto bg-body-tertiary sidebar collapse d-lg-block dark-main-color sidenav" data-bs-theme="dark" style={{height: 100 + '%'}}>
      
      <ul className="nav nav-pills flex-column mb-auto">
      <li>
        <a href="/" className="nav-link text-white">
          <Speedometer2 size={16} />
          <span className="ms-2">Dashboard</span>
        </a>
      </li>
      <li>
        <a href="/rankings" className="nav-link text-white">
          <Award size={16} />
          <span className="ms-2">Rankings</span>
        </a>
      </li>
      <li>
        <a href="/watchlist" className="nav-link text-white">
          <StarFill size={16} />
          <span className="ms-2">Watchlist Deals</span>
        </a>
      </li>
      <li>
        <a href="/downloads" className="nav-link text-white">
          <CloudDownload size={16} />
          <span className="ms-2">Downloads</span>
        </a>
      </li>
      <li>
        <a href="/premium" className="nav-link text-white">
          <Buildings size={16} />
          <span className="ms-2">Premium</span>
        </a>
      </li>
      <li>
      <li>
        <a href="/profile" className="nav-link text-white">
          <PersonAdd size={16} />
          <span className="ms-2">Profile</span>
        </a>
      </li>
        <a href="/about" className="nav-link text-white">
          <QuestionCircle size={16} />
          <span className="ms-2">About</span>
        </a>
      </li>
    </ul>
    <div hidden={OnProfilePage()} className="sidebar-container">
      <AccountState className="sidebar-account-state"></AccountState>
      <div hidden={hideSidebarWatchlist()}>
        <Watchlist mode={"sidebar"} watchlistItems={props.watchlistItems}></Watchlist>
      </div>
    </div>
  </nav>
  );
}

export default SideNav;
