import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import {isMapNonEmpty, numberWithCommas} from '../../shared/common';

import { Col, Container, Row } from 'react-bootstrap';

let BASE_URL = "https://www.google.com/search?tbm=isch&q="
BASE_URL = "https://www.bing.com/images/search?q="

function PhotoCards(props) {
  const [selectedGeo, setSelectedGeo] = useState({});
  const [url, setUrl] = useState("")
  // https://www.google.com/search?tbm=isch&q=hennepin-county-mn-landscapes

  useEffect(() => {
    if (isMapNonEmpty(props.selectedGeo) && props.selectedGeo.state !== undefined) {
      
      if (props.geoModeTab === "county") {
        setSelectedGeo(props.selectedGeo)
        let urlSuffix = props.selectedGeo.county + '-' + props.selectedGeo.state + '-landscape'
        
        let url = BASE_URL + urlSuffix
        setUrl(url)
      } else if (isMapNonEmpty(props.selectedGeo) && props.geoModeTab === "city") {
        setSelectedGeo(props.selectedGeo)
        let urlSuffix = props.selectedGeo.city_name + '-' + props.selectedGeo.state + '-landscape'
          let url = BASE_URL + urlSuffix
          setUrl(url)
      }
    }

  }, [props.selectedGeo]);


  return (
    <div key={'photo-iframe-'+JSON.stringify(selectedGeo)} className="iframe-outer-container" hidden={props.selectedGeo == null || (selectedGeo.county == null && selectedGeo.city_name == null)}>
    <div className="iframe-container">
      <iframe className={"iframe-actual-photos"} width={"90%"} height="900px" src={url}></iframe>
      </div>
      </div>
  );
}

export default memo(PhotoCards);