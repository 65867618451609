import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import {isMapNonEmpty, numberWithCommas} from '../../shared/common';

import { Col, Container, Row } from 'react-bootstrap';

function CommunityCards(props) {
  const [selectedGeo, setSelectedGeo] = useState({});
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (isMapNonEmpty(props.selectedGeo) && props.selectedGeo.state !== undefined) {
      
      if (props.geoModeTab === "county") {
        setSelectedGeo(props.selectedGeo)
        let urlSuffix = props.selectedGeo.county + '-' + props.selectedGeo.state
        
        let url = "https://www.zillow.com/" + urlSuffix
        setUrl(url)
      } else if (isMapNonEmpty(props.selectedGeo) && props.geoModeTab === "city") {
        let urlSuffix = props.selectedGeo.city_name + '-' + props.selectedGeo.state
        //console.log('url suffix: ' + urlSuffix)
          let url = "https://www.zillow.com/" + urlSuffix
          setUrl(url)
      }
    }

  }, [props.selectedGeo]);


  return (
    <div className="iframe-outer-container" key={"listings-"+JSON.stringify(props.selectedGeo)} hidden={props.selectedGeo == null || (selectedGeo.county == null && selectedGeo.city_name == null)}>
    <div className="iframe-container">
      <iframe className={"iframe-actual"} width={"90%"} height="900px" src={url}></iframe>
      </div>
      </div>
  );
}

export default memo(CommunityCards);