import { memo, useContext} from 'react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppContext from '../../state/app-context';
import { DEFAULT_MAP_KEY, getColorMapsToUse, removeNonNumeric, shadeColor } from '../../shared/common';

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";

function MapKey(props) {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();
  let override = props.override

  function redirectToProfile() {
    return (
      navigate("/profile", { replace: false })
    )
  }

  function getDecoration(range, whichOne) {
    if (range == DEFAULT_MAP_KEY) {
      return
    }

    let top = parseFloat(removeNonNumeric(range["upper"]))
    let bottom = parseFloat(removeNonNumeric(range["bottom"]))

    let upperSymbol = ""
    let bottomSymbol = ""
    if (top < bottom) {
      upperSymbol = " <"
      bottomSymbol = " >"
    }
    if (top > bottom) {
      upperSymbol = " >"
      bottomSymbol = " <"
    }

    if (whichOne == "upper") {
      return upperSymbol
    } else if (whichOne == "bottom") {
      return bottomSymbol
    }
    
  }

  if (override) {
    let colors = getColorMapsToUse(appCtx)["normal"]

    let numColors = colors.length
    let upper = colors[numColors-1]
    let bottom = colors[0]
    let bottomMid = colors[parseInt(numColors / 4)]
    let upperMid = colors[parseInt(numColors / 4)*2+1]

    //bottomMid = "#ffffff"
    //upperMid = "#ffffff"
    //upper = "#ffffff"
    //bottom = "#ffffff"

    return (
      <div key={"map-color-key-"+colors[colors.length-1]} className="card card-key dark-main-color mb-3">
          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <div>
                <i style={{color:upper}} className={"bi good-indicators bi-square-fill"}><i>{" Best"}</i></i>
              </div>
              <div>
                <i style={{color:upperMid}} className={"bi good-indicators bi-square-fill"}><i>{" Good"}</i></i>
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <div>
                <i style={{color:bottomMid}} className={"bi good-indicators bi-square-fill"}><i>{" Bad"}</i></i>
              </div>
              <div>
                <i style={{color:bottom}} className={"bi good-indicators bi-square-fill"}><i style={{color:shadeColor(bottom, 100)}}>{" Worst"}</i></i>
              </div>
            </Col>
          </Row>
        </div>
    )
  } else {
    return (
      <div key={"map-color-key-"+appCtx.mapKeyRange["upper"]} className="card card-key dark-main-color mb-3">
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div>
              <i style={{color:appCtx.mapKeyRange["upper_color"]}} className={"bi good-indicators bi-square-fill"}><i style={{color:"gray"}}> {getDecoration(appCtx.mapKeyRange, "upper") + appCtx.mapKeyRange["upper"]}</i></i>
            </div>
            <div>
              <i style={{color:appCtx.mapKeyRange["upperMid_color"]}} className={"bi good-indicators bi-square-fill"}><i style={{color:"gray", marginLeft: "5px"}}> {" " +appCtx.mapKeyRange["upperMid"]}</i></i>
            </div>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div>
              <i style={{color:appCtx.mapKeyRange["bottomMid_color"]}} className={"bi good-indicators bi-square-fill"}><i style={{color:"gray", marginLeft: "5px"}}> {" " +appCtx.mapKeyRange["bottomMid"]}</i></i>
            </div>
            <div>
              <i style={{color:appCtx.mapKeyRange["bottom_color"]}} className={"bi good-indicators bi-square-fill"}><i style={{color:"gray"}}>{getDecoration(appCtx.mapKeyRange, "bottom")+appCtx.mapKeyRange["bottom"]}</i></i>
            </div>
          </Col>
        </Row>
        <a onClick={()=> redirectToProfile()} className='slider-text mapkey-link' >Alternative colors in profile settings</a>
      </div>
    )
  }

    
}

export default memo(MapKey);
