import React, { useState, useEffect } from "react";
import './Stripe.css';
import '../../pages/ProfilePage.css'
import { Col, Row } from "react-bootstrap";
import crosstabGif from '../../assets/crosstabs_gif.gif'
import corsstabPng from '../../assets/website_screenshot_crosstabs.png'
import corsstabStrPng from '../../assets/website_screenshot_crosstabs_str.png'
import crossTabLand from '../../assets/website_screenshot_crosstabs_land.png'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.

function CrossTabHype(props) {
  return (
  <div>
    <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
      <Col xs={12} sm={12} md={12} lg={6}>
          <p className="stripe-text centered"> Active Listings by Number of Beds/Baths/Sqft</p>
          <img className="thumbnail" src={corsstabPng} alt="loading..." />
        </Col>
        <Col className="stripe-return-box" xs={12} sm={12} md={6} lg={6}>
          <h4 className="stripe-text">
            Be the most knowledgable negotiator </h4>
            <div className='bullets-spacer'></div>
          <p className="bullets-left">View averages by square feet, number of beds, number of bathrooms</p>
          <p className="bullets-left">All the datapoints found throughout the site but customized per subset</p>
          <p className="bullets-left">Know exactly what to bid on a house. See exactly what homes with similar specs are listed for and what they actually sold for.</p>
          <p className="bullets-left">We got whatever data you're looking for, whether its Active Listings, Sold Listings, Rentals and Short Term Rentals (Airbnbs)</p>
          <p className="bullets-left">This is how you find comps. More powerful than zillow, easier to use than the offical realtor apps.</p>
        </Col>
      </Row>
      <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
      <Col xs={12} sm={12} md={12} lg={6}>
          <p className="stripe-text centered"> Short Term Listings by Bedrooms/Beds/Sleeps/Ammenities</p>
          <img className="thumbnail" src={corsstabStrPng} alt="loading..." />
        </Col>
        <Col className="stripe-return-box" xs={12} sm={12} md={6} lg={6}>
        <h4 className="stripe-text">
            Essential for Real Estate Investors </h4>
            <div className='bullets-spacer'></div>
          <p className="bullets-left">Looking to own an airbnb or short term rental? This feature is your best friend.</p>
          <p className="bullets-left">Quickly check what the occupancy rates and revenue projections are for comparable homes.</p>
          <p className="bullets-left">Know your exact profit margin before you invest.</p>
          <p className="bullets-left">Ex. Find out how much revenue Airbnb's with 4 bedrooms generate in Summit County, CO</p>
          <p className="bullets-left">Ex. How much more often do airbnb's book if they Allows Pets? And if it has a Hot Tub. Or a Mountain View? We have it all.</p>
        </Col>
      </Row>
      <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
      <Col xs={12} sm={12} md={12} lg={6}>
          <p className="stripe-text centered"> Land Listings by Acres</p>
          <img className="thumbnail" src={crossTabLand} alt="loading..." />
        </Col>
        <Col className="stripe-return-box" xs={12} sm={12} md={6} lg={6}>
        <h4 className="stripe-text">
            Find Land, Create your own Destiny </h4>
            <div className='bullets-spacer-smaller'></div>
            <p className="bullets-left">Why limit yourself to houses, buy some land and do whatever you want with it. Land aint going nowhere.</p>
            <p className="bullets-left">Sort by acre, find the best price per acre, price drops, number of listings etc. Find value everyone else is missing.</p>
        </Col>
      </Row>
    </div>
    )
}
export default CrossTabHype;
