import React, { useContext } from 'react';
import CryptoJS from 'crypto-js';
import { currentlyLoggedIn, getUsername, getUrlPrefix } from '../shared/common';

const prefix = "http://127.0.0.1:8000/v1/thermostat/"

function decryptObject(responseObj) {
    let key = process.env.REACT_APP_ENCRYPTION_KEY
    let iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);
    key = CryptoJS.enc.Utf8.parse(key);
    let decrypted =  CryptoJS.AES.decrypt(responseObj.data, key, { iv: iv, mode: CryptoJS.mode.CBC});
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    let parsable = decrypted.replaceAll("\"", '')
    parsable = parsable.replace(/'/g, '"');
    return parsable
}

function decryptObjectDecimal(responseObj) {
    let parsable = decryptObject(responseObj)
    let removeDecimal = parsable.replaceAll('Decimal(', '');
    let removeParen = removeDecimal.replaceAll(')', '');
    let removeNone = removeParen.replaceAll('None', '{}');
    return removeNone.replaceAll(/'/g, '"');
}

export async function downloadCsv(appCtx, geoMode, mapType, date, mapMode) {

    try {
        const url = getUrlPrefix() + 'csv/download/' + geoMode + '/' + mapMode + "/" + mapType + '/' + date
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/csv',
                'hh-device-id': appCtx.deviceId,
                'hh-access-token': appCtx.token
            }
          })
        let responseObj = await response.json();
        let key = process.env.REACT_APP_ENCRYPTION_KEY
        let iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);
        key = CryptoJS.enc.Utf8.parse(key);
        let decrypted =  CryptoJS.AES.decrypt(responseObj.data, key, { iv: iv, mode: CryptoJS.mode.CBC});
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);

        return decrypted
        
    } catch(error) {
        console.log('error caught: ' + JSON.stringify(error))
        return [];
    }
}

export async function getMortgageRates(appCtx, day) {

    try {
        const url = getUrlPrefix() + 'listings/rates/' + day
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })
        return await response.json();
    } catch(error) {
        return [];
    }
}

export async function getValidCSVDates(appCtx, dates) {

    try {
        const url = getUrlPrefix() + 'sums/dates'
        const response = await fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
            body: JSON.stringify({
                "dates": dates
               })
          })
        return await response.json();
    } catch(error) {
        return [];
    }
}

export async function getStateHeatmap(appCtx, date, state, mapType, geoMode) {

    if (state == undefined || state == null || state == "") {
        return { "meta": 400}
    }

    try {
        const url = getUrlPrefix() + 'sums/csv/states/' + state + '/' + geoMode + '/' + mapType + '/' + date
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })
        let responseObj = await response.json();
        let parsable = decryptObjectDecimal(responseObj)
        return { "meta": 200, "data": JSON.parse(parsable) }

        //let parsable = decryptObjectDecimal(responseObj["csv"])
        //let bounds = responseObj["bounds"]
        //return { "meta": 200, "data": JSON.parse(parsable), "bounds": bounds }


    } catch(error) {
        console.log('error: ' + JSON.stringify(error))
        return { "meta": 500}
    }
}

export async function getAllStatesHeatmap(appCtx, mapType, date) {

    try {
        const url = getUrlPrefix() + 'sums/states/csv/' + mapType + '/' + date
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })
          let responseObj = await response.json();
          
        let parsable = decryptObjectDecimal(responseObj)
        return { "meta": 200, "data": JSON.parse(parsable) }

    } catch(error) {
        console.log('error: ' + JSON.stringify(error))
        return { "meta": 500 };
    }
}

export async function getHeatmap(appCtx, type, date, geoMode) {
    if (type === undefined || date === undefined || date == "") {
        return { "meta": 400 };
    }

    try {
        const url = getUrlPrefix() + 'sums/csv/' + geoMode + '/' + type + '/' + date
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })
          let responseObj = await response.json();
          let parsable = decryptObjectDecimal(responseObj)
            return { "meta": 200, "data": JSON.parse(parsable) }

          //let data = responseObj["data"]
          //let parsable = decryptObjectDecimal({"data": data["csv"]})
          //let bounds = data["bounds"]
          //return { "meta": 200, "data": JSON.parse(parsable), "bounds": bounds }

    } catch (error) {
        console.log('error: ' + error.message)
        return { "meta": 500 };
    }
}

export async function getLeaderboard(appCtx, type, geoMode, state, sliderDate, mapModeTab) {

    // if state we need the state prefix
    // Ex. AL_median_price
    // Ex. median_price
    let toUse = ""
    if (state == null || state == "USA" || state == "" || mapModeTab === "national") {
        toUse = ""
    } else {
        if (state.label !== undefined && state.label !== "" && state.label !== "USA") {
            toUse = state.label + "_"
        } else {
            toUse = ""
        }
    }

    type = toUse + type

    try {
        let url = getUrlPrefix() + 'leaderboard/' + type + '/' + geoMode + '/' + sliderDate

        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })
        let responseObj = await response.json();
        let fixed = decryptObjectDecimal(responseObj)
        return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getGeoSums(appCtx, geoId, state, geoMode, sliderDate, backupId, isValidDate) {
    if (backupId !== "") {
        geoId = geoId + "+" + backupId
    }

    try {
        let url = getUrlPrefix() + 'sums/' + geoMode + '/' + geoId + '/' + sliderDate
        if (isValidDate) {
            url = getUrlPrefix() + 'sums/' + geoMode + '/' + geoId + '/' + sliderDate + '/v'
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token,
                        'hh-state': state
            },
          })
        let responseObj = await response.json();
        let fixed = decryptObjectDecimal(responseObj)
        return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getCountySubsets(appCtx, countyId, type, sliderDate) {

    if (sliderDate == undefined || sliderDate == null) {
        sliderDate = "today"
    }

    try {
        const url = getUrlPrefix() + 'subsets/' + type + '/county/' + countyId + '/' + sliderDate
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })
          let responseObj = await response.json();
          let fixed = decryptObjectDecimal(responseObj)
          return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getNationalBenchmarks(appCtx, sliderDate) {

    try {
        const url = getUrlPrefix() + 'sums/benchmarks/'+ sliderDate
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })
          
          let responseObj = await response.json();
          let fixed = decryptObjectDecimal(responseObj)
          return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getStateBenchmarks(appCtx, state, sliderDate) {

    try {
        const url = getUrlPrefix() + 'sums/benchmarks/' + state + '/' + sliderDate
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
          })

          let responseObj = await response.json();
          let fixed = decryptObjectDecimal(responseObj)

          return { "meta": 200, "data": { "data": JSON.parse(fixed) }}
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getBackfillData(appCtx, geoId, dates, geoMode) {

    try {
        const url = getUrlPrefix() + 'sums/backfill/' + geoMode + '/' + geoId
        const response = await fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
            body: JSON.stringify({
                "dates": dates
               })
          })
        let responseObj = await response.json();
        let fixed = decryptObjectDecimal(responseObj)

        return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getNationalBenchmarksBackfill(appCtx, dates) {

    try {
        const url = getUrlPrefix() + 'sums/benchmarks/backfill'
        const response = await fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
            body: JSON.stringify({
                "dates": dates
               })
          })

        let responseObj = await response.json();
        let fixed = decryptObjectDecimal(responseObj)
        return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getStateBenchmarksBackfill(appCtx, state, dates) {

    try {
        const url = getUrlPrefix() + 'sums/benchmarks/backfill/'+ state
        const response = await fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token
            },
            body: JSON.stringify({
                "dates": dates
               })
          })
          
          let responseObj = await response.json();
          let fixed = decryptObjectDecimal(responseObj)
          return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getListingDeals(appCtx, geoId, currSort) {
    if (!currentlyLoggedIn) {
        return { "meta": 400 }
    }

    try {
        const url = getUrlPrefix() + 'listings/deals/'+ geoId + "/" + currSort
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token,
                        'hh-username': getUsername(appCtx)
            },
          })

          let responseObj = await response.json();
          let fixed = decryptObjectDecimal(responseObj)

          return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function updateWatchlist(appCtx, status, currGeo) {
    if (!currentlyLoggedIn) {
        return { "meta": 400 }
    }

    let cityId = currGeo["city_id"]
    let countyId = currGeo["county_id"]
    let fips = currGeo["fips"]
    let mode = "county"
    let geoId = ""
    if (cityId !== undefined && cityId !== null) {
        mode = "city"
        geoId = cityId
    } else {
        geoId = countyId
    }

    try {
        const url = getUrlPrefix() + 'user/watchlist/'+ status + "/" + geoId + "/" + fips
        const response = await fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token,
                        'hh-username': getUsername(appCtx)
            },
          })

          return { "meta": 200 }
    } catch(error) {
        return { "meta": 500 };
    }
}

export async function getWatchlistGeos(appCtx) {
    if (!currentlyLoggedIn) {
        return { "meta": 400 }
    }

    try {
        const url = getUrlPrefix() + 'user/watchlist/geos'
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'hh-device-id': appCtx.deviceId,
                        'hh-access-token': appCtx.token,
                        'hh-username': getUsername(appCtx)
            },
          })
          
          let responseObj = await response.json();
          let fixed = decryptObjectDecimal(responseObj)
          return { "meta": 200, "data": JSON.parse(fixed) }
    } catch(error) {
        return { "meta": 500 };
    }
}