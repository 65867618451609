import { useState, useEffect, useContext, memo} from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import {CHART_COLORS, getAttrLabel, getCardTitle, getColorScale, getFontColor, grabPopulation, isMapNonEmpty, numberWithCommas, shouldHideHeader } from '../../shared/common';
import AppContext from '../../state/app-context';

import { Col, Container, Row } from 'react-bootstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartvsTableToggle from '../ChartvsTableToggle';
import StrPeriodComboBox from '../Controls/StrPeriodComboBox';
import Indicator from './Indicator';
import { ControlPointSharp } from '@mui/icons-material';

let sampleCountyData = { "str_county_booking_summary": {}, "county_prop_sums_total": { "avg_price": "", "median_price": ""}, "county_sold_sums_total": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}
let sampleStateData = { "data": { "benchmark_str": {}, "state": "", "data": { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}}}
let sampleNationalData = {"benchmark_str": {}, "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}

const sampleChartData = {
  labels: ["County", "State", "National"],
  datasets: [
    {
      data: ['33', '70', '60'],
      backgroundColor: [CHART_COLORS.lightBlueColor, CHART_COLORS.blueColor, CHART_COLORS.darkBlueColor],
    }
  ]
};

const graphs = {
  "occupancy_rate_3m": sampleChartData,
  "occupancy_rate_12m": sampleChartData,
  "avg_price_per_night_12m": sampleChartData,
  "num_listings": sampleChartData,
  "est_rev": sampleChartData,
  "max_rev": sampleChartData
}


function getOptions(newLabel) {
  let minRoto = 0

  let options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    responsive: true,
    scales: {
      y: {  
        ticks: {
          color: "white",
        }
      },
      x: {  
        ticks: {
          color: "white",
          maxRotation: 90,
          minRotation: minRoto
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: newLabel,
        padding: {
          left: 10,
          right: 5,
          bottom: 10,
          top: 5
        },
        color: "white",
        font: {
          size: 14,
          weight: 'bold',
        },
        align: "center"
      }
    }
  }
  return options
}

// this only functions in county - as short term rentals dont really abide by city limits, best to be grouped via county
function StrCards(props) {
  const appCtx = useContext(AppContext);
  const [benchmarks, setBenchmarks] = useState(sampleNationalData)
  const [stateBenchmarks, setStateBenchmarks] = useState(sampleStateData)
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});
  const [countyData, setCountyData] = useState(sampleCountyData);
  const [chartData, setChartData] = useState(graphs)
  const [selectedTab, setSelectedTab] = useState("table")
  const [strPeriod, setStrPeriod] = useState({ label: "12 Months", id:"12m"})
  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {
    if (isMapNonEmpty(props.selectedCounty) && props.selectedCounty.county_id !== selectedCounty.county_id) {
      setLoadingData(true)
      setSelectedCounty(props.selectedCounty)
    }

  }, [props.selectedCounty]);

  useEffect(() => {
    if (isMapNonEmpty(props.countyData)) {
      setLoadingData(false)
      setCountyData(props.countyData)
      setupCharts(countyData, stateBenchmarks, benchmarks)
    }

  }, [props.countyData]);

  useEffect(() => {
    if (isMapNonEmpty(props.stateBenchmarks)) {
      setStateBenchmarks(props.stateBenchmarks)
      setupCharts(countyData, stateBenchmarks, benchmarks)
    }

  }, [props.stateBenchmarks]);

  useEffect(() => {
    if (isMapNonEmpty(props.benchmarks)) {
      setBenchmarks(props.benchmarks)
      setupCharts(countyData, stateBenchmarks, benchmarks)
    }

  }, [props.benchmarks]);

  function setupChartData2(attr, countyData, stateBenchmarks, benchmarks, color, withCommas) {
    let countyAmt = 0
    let stateAmt = 0
    let nationalAmt = 0

    if (attr === "max_rev") {
      let countyPPN = grabStrStat("avg_price_per_night_12m", countyData, "str_county_booking_summary", true)
      let statePPN = grabStrStat("avg_price_per_night_12m", stateBenchmarks.data, "benchmark_str", true)
      let nationalPPN = grabStrStat("avg_price_per_night_12m", benchmarks, "benchmark_str", true)

      countyAmt = (countyPPN * 365)
      stateAmt = (statePPN * 365)
      nationalAmt = (nationalPPN * 365)

      // recompute on the fly, other numbers are off
    } else if (attr === "est_rev") {
      let countyOR_3m = grabStrStat("occupancy_rate_3m", countyData, "str_county_booking_summary", false) * .01
      let stateOR_3m = grabStrStat("occupancy_rate_3m", stateBenchmarks.data, "benchmark_str", false) * .01
      let nationalOR_3m = grabStrStat("occupancy_rate_3m", benchmarks, "benchmark_str", false) * .01

      let countyOR_12m = grabStrStat("occupancy_rate_12m", countyData, "str_county_booking_summary", false) * .01
      let stateOR_12m = grabStrStat("occupancy_rate_12m", stateBenchmarks.data, "benchmark_str", false) * .01
      let nationalOR_12m = grabStrStat("occupancy_rate_12m", benchmarks, "benchmark_str", false) * .01

      let countyOR = countyOR_3m + countyOR_12m / 2
      let stateOR = stateOR_3m + stateOR_12m / 2
      let nationalOR = nationalOR_3m + nationalOR_12m / 2

      let countyPPN = grabStrStat("avg_price_per_night_12m", countyData, "str_county_booking_summary", true)
      let statePPN = grabStrStat("avg_price_per_night_12m", stateBenchmarks.data, "benchmark_str", true)
      let nationalPPN = grabStrStat("avg_price_per_night_12m", benchmarks, "benchmark_str", true)

      countyAmt = (countyOR * countyPPN * 365)
      stateAmt = (stateOR * statePPN * 365)
      nationalAmt = (nationalOR * nationalPPN * 365)

    } else {
      countyAmt = grabStrStat(attr, countyData, "str_county_booking_summary", withCommas)
      stateAmt = grabStrStat(attr, stateBenchmarks.data, "benchmark_str", withCommas)
      nationalAmt = grabStrStat(attr, benchmarks, "benchmark_str", withCommas)
    }

    const data = {
      labels: ["County", selectedCounty.state, "National"],
      datasets: [
        {
          data: [countyAmt, stateAmt, nationalAmt],
          borderColor: [CHART_COLORS.lightBlueColor, CHART_COLORS.blueColor, CHART_COLORS.darkBlueColor],
          backgroundColor: [CHART_COLORS.lightBlueTransparent, CHART_COLORS.blueTransparent, , CHART_COLORS.darkBlueTransparent]
        }
      ]
    };
    return data
  }

  function setupCharts(countyData, stateBenchmarks, benchmarks) {
    if (isMapNonEmpty(countyData) && countyData !== sampleCountyData
      && isMapNonEmpty(stateBenchmarks) && stateBenchmarks !== sampleStateData
      && isMapNonEmpty(benchmarks) && benchmarks !== sampleNationalData) {

      let or3mData = setupChartData2("occupancy_rate_3m", countyData, stateBenchmarks, benchmarks, "blue", false)
      let or12mData = setupChartData2("occupancy_rate_12m", countyData, stateBenchmarks, benchmarks, "blue", false)
      let nightlyAvgData = setupChartData2("avg_price_per_night_12m", countyData, stateBenchmarks, benchmarks, "red", true)
      let numListingsData = setupChartData2("num_listings", countyData, stateBenchmarks, benchmarks, "green", true)
      let estRevData = setupChartData2("est_rev", countyData, stateBenchmarks, benchmarks, "green", true)
      let maxRevData = setupChartData2("max_rev", countyData, stateBenchmarks, benchmarks, "blue", true)
      
      let allData = {}
      allData["occupancy_rate_3m"] = or3mData
      allData["occupancy_rate_12m"] = or12mData
      allData["avg_price_per_night_12m"] = nightlyAvgData
      allData["est_rev"] = estRevData
      allData["max_rev"] = maxRevData
      allData["num_listings"] = numListingsData

      setChartData(allData)
    }
  }

function grabStrStat(statName, dataSrc, subsetSrc, withCommas) {
  if (dataSrc != undefined && dataSrc[subsetSrc] != undefined) {
    let value = dataSrc[subsetSrc][statName]
    if (value !== undefined) {
      if (withCommas) {
        return numberWithCommas(value)
      } else {
        value = value * 100
        return value.toFixed(0)
        //return value
      }
    }
  }
  return 0
}

function getAttrValues(label, attr, from, months) {
  let response = ""
  let responseBare = ""

  if (from !== undefined) {

    if (label == "avg_price_per_night") {
      responseBare = parseInt(from[attr])
      response = "$" + numberWithCommas(responseBare)
    } else if (label == "occupancy_rate") {
      responseBare = parseInt(Math.round((from[attr]*100), 2))
      response = responseBare + "%"

    } else if (label == "est_revenue") {
      let orKey = "occupancy_rate_" + months
      let ppNKey = "avg_price_per_night_" + months
      responseBare = parseInt(Math.round((from[orKey] * from[ppNKey] * 365), 2))
      response = "$" + numberWithCommas(responseBare)

    } else if (label == "possible_rev") {
      let ppNKey = "avg_price_per_night_" + months
      responseBare = parseInt(Math.round((from[ppNKey] * 365), 2))
      response = "$" + numberWithCommas(responseBare)
    } else if (label == "num_listings") {
      responseBare = parseInt(from[label])
      response = "" + (responseBare)
    } else if (label == "listings_per_pop" && selectedCounty.fips !== null) {
      let numListings = parseInt(from["num_listings"])
      let pop = grabPopulation(props.populationMap, selectedCounty.fips)
      responseBare = Math.round(((numListings / pop.population) * 100000), 2)
      response = "" + (responseBare)
    }
  }

  if (Number.isNaN(responseBare)) {
    return { str: "-", num: responseBare}
  }

  return { str: response, num: responseBare}
}

function StatBubble(props) {
  let index = props.index
  let hideHeader = shouldHideHeader(index)
  let months = props.period.id
  let periodSuffix = "_" + months
  let attr1 = props.attr1 + periodSuffix
  let attr2 = props.attr2 + periodSuffix

  let attr1Label = getAttrLabel(props.attr1)
  let attr2Label = getAttrLabel(props.attr2)

  let countyValue1 = getAttrValues(props.attr1, attr1, countyData.str_county_booking_summary, months)
  let countyValue2 = getAttrValues(props.attr2, attr2, countyData.str_county_booking_summary, months)

  let stateValue1 = getAttrValues(props.attr1, attr1, stateBenchmarks.data.benchmark_str, months)
  let stateValue2 = getAttrValues(props.attr2, attr2, stateBenchmarks.data.benchmark_str, months)

  let nationalValue1 = getAttrValues(props.attr1, attr1, benchmarks.benchmark_str, months)
  let nationalValue2 = getAttrValues(props.attr2, attr2, benchmarks.benchmark_str, months)

  let firstClass = "card card-background mb-3"
  let secondClass = "card card-background-alt mb-3"

  if (selectedCounty.county !== null && countyData !== null && countyData.str_county_booking_summary !== undefined) {
    let bgColor = undefined
    if (isMapNonEmpty(selectedCounty.county)) {
      bgColor = getColorScale(selectedCounty, props.mapType, appCtx)
    }

    return (
      <Container>
        <Row className="mt-2">
          <Col xs={12} sm={12} md={4} lg={4}>
            <div className={firstClass}>
              <div hidden={hideHeader} style={{color:getFontColor(selectedCounty, bgColor), backgroundColor:bgColor}} className="card-header">{getCardTitle("county", selectedCounty)}      ({props.sliderDate})</div>
              <div className="card-body">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div hidden={hideHeader}><h5  className="card-subheader">Airbnb's and VRBOs</h5>
                    <hr className="separator" /></div>
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{countyValue1.str}
                      <Indicator desired={props.desired} number={countyValue1.num} stateAvg={stateValue1.num} nationalAvg={nationalValue1.num} ></Indicator>
                    </p>
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{countyValue2.str}
                      <Indicator desired={props.desired} number={countyValue2.num} stateAvg={stateValue2.num} nationalAvg={nationalValue2.num} ></Indicator>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <div className={secondClass}>
              <div hidden={hideHeader} className="card-header ch-alt">State Averages: {stateBenchmarks.state}      ({props.sliderDate})</div>
              <div className="card-body">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div hidden={hideHeader}><h5  className="card-subheader">Airbnb's and VRBOs</h5>
                    <hr className="separator" /></div>
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{stateValue1.str}
                    </p>
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{stateValue2.str}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <div className={firstClass}>
              <div hidden={hideHeader} className="card-header ch-normal">National Averages      ({props.sliderDate})</div>
              <div className="card-body">
              <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div hidden={hideHeader}><h5  className="card-subheader">Airbnb's and VRBOs</h5>
                    <hr className="separator" /></div>
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{nationalValue1.str}
                    </p>
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{nationalValue2.str}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

  return (
    <Container key={"strs-"+JSON.stringify(selectedCounty)} hidden={selectedCounty == null || selectedCounty.county == null}>
      <div hidden={loadingData == true}>
        <Row>
          <Col xs={4} sm={4} md={4} lg={2}>
            <Box p={1} display="block-inline">
              <ChartvsTableToggle selectedTab={selectedTab} setSelectedTab={setSelectedTab}></ChartvsTableToggle>
            </Box>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3} hidden={selectedTab === "chart"}>
            <Box className="spacer left-on-lg">
              <StrPeriodComboBox setStrPeriod={setStrPeriod}></StrPeriodComboBox>
            </Box>
          </Col>
        </Row>
          <Row>
              <Col hidden={selectedTab === "table"} md={12} lg={6}>
                <Bar className="chart-centered" key={"or-bar-6m"} data={chartData["occupancy_rate_3m"]} options={getOptions("Avg Occupancy Rate (next 3 months)")}></Bar>
              </Col>
              <Col hidden={selectedTab === "table"} md={12} lg={6}>
                <Bar className="chart-centered" key={"or-bar-6m"} data={chartData["occupancy_rate_12m"]} options={getOptions("Avg Occupancy Rate (next 12 months)")}></Bar>
              </Col>
            </Row>
            <Row>
              <Col hidden={selectedTab === "table"} md={12} lg={6}>
                <Bar className="chart-centered" key={"est-rev-bar"} data={chartData["est_rev"]} options={getOptions("Estimated Annual Revenue")}></Bar>
              </Col>
              <Col hidden={selectedTab === "table"} md={12} lg={6}>
                <Bar className="chart-centered" key={"max-rev-bar"} data={chartData["max_rev"]} options={getOptions("Maximum Annual Revenue")}></Bar>
              </Col>
            </Row>
            <Row>
              <Col hidden={selectedTab === "table"} md={12} lg={6}>
                <Bar className="chart-centered" key={"avg-ppn-bar"} data={chartData["avg_price_per_night_12m"]} options={getOptions("Avg Price Per Night")}></Bar>
              </Col>
              <Col hidden={selectedTab === "table"} md={12} lg={6}>
                <Bar className="chart-centered" key={"num-listings-bar"} data={chartData["num_listings"]} options={getOptions("Number of Listings")}></Bar>
              </Col>
            </Row>
          
            <Col hidden={selectedTab === "chart"}>
              <StatBubble mapType={props.mapType} sliderDate={props.sliderDate} desired={"higher"} score={props.score} period={strPeriod} attr1={"occupancy_rate"} attr2={"avg_price_per_night"} index={0}></StatBubble>
              <StatBubble mapType={props.mapType} sliderDate={props.sliderDate} desired={"higher"} score={props.score} period={strPeriod} attr1={"est_revenue"} attr2={"possible_rev"} index={1}></StatBubble>
              <StatBubble mapType={props.mapType} sliderDate={props.sliderDate} desired={"lower"} score={props.score} period={strPeriod} attr1={"num_listings"} attr2={"listings_per_pop"} index={2}></StatBubble>
            </Col>
          </div>
          <Row hidden={loadingData == false}>
            <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
          </Row>
      </Container>
  );
}

export default memo(StrCards);