
import React, { useState, useEffect, memo, useContext } from 'react';

import MapTypeComboBox from '../componets/Controls/MapTypeComboBox';
import MapCategoryComboBox from '../componets/Controls/MapCategoryComboBox';
import CountyComboBox from '../componets/Controls/CountyComboBox';
import DateSlider from "../componets/Controls/DateSlider"
import Grid from '@mui/material/Unstable_Grid2';
import { SCORES_MAP, cityIdToCountyInfo, getCountyInfoFromGeo, setupUrlCheckpoint, countyIdToProp, currentlyLoggedIn, formatDate, getAnalyticsName, isCategeoryPaywalled, isGeoIdCity, isMapNonEmpty, isMobile, setupMapKey, getStateId, replaceHyphens, convertToUserFacing, STATE_ABBREV_TO_FULL, generateRankingTitle } from '../shared/common';
import { getHeatmap, getMortgageRates, getValidCSVDates } from "../service/thermostatservice"
import { Container, Row, Col } from 'react-bootstrap';
import CurrentMap from '../componets/Map/CurrentMap';
import StateComboBox from '../componets/Controls/StateComboBox';
import CardTabs from '../componets/Cards/CardTabs';
import NationalvsStateToggle from '../componets/Controls/NationalvsStateToggle';
import CountyVsCityToggle from '../componets/Controls/CountyVsCityToggle';
import CityComboBox from '../componets/Controls/CityComboBox';
import PopulationSlider from '../componets/Controls/PopulationSlider';
import { Typography } from '@mui/material';
import AppContext from '../state/app-context';
import HelmetTags from '../state/helmet';
import './DashboardPage.css';
import MapKey from '../componets/Map/MapKey';
import MapInfo from '../componets/Map/MapInfo';
import ReactGA from "react-ga4"
import MonthRadioButtons from '../componets/Controls/MonthRadioButtons';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';

const PAGE = "map"

function DashboardPage(props) {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();

  let { mapModeP, geoModeP, mapCategoryP, mapTypeP, stateP, geoIdP, geoNameP } = useParams();
  const [paramGeoId, setParamGeoId] = useState(geoIdP !== undefined? geoIdP: null)

  const [geoContent, setGeoContent] = useState(isMapNonEmpty(appCtx.selectedGeo)? appCtx.selectedGeo: {})
  const [selectedScore, setSelectedScore] = useState({});

  const [mapType, setMapType] = useState(mapTypeP !== undefined? mapTypeP: "median_price");
  const [mapCategory, setMapCategory] = useState(mapCategoryP !== undefined? mapCategoryP: "active");
  const [numMonths, setNumMonths] = useState(mapCategory == "short_term_rental"? "avg": "1m")
  const [typedCounty, setTypedCounty] = useState({})
  const [typedState, setTypedState] = useState(stateP !== undefined? { label: stateP, val: getStateId(stateP) }: {"label": "USA", "val": ""})
  const [csvMap, setCsvMap] = useState({ "county": null, "city": null})
  const [latestDates, setLatestDates] = useState({})
  const [sliderDate, setSliderDate] = useState('')
  const [mapData, setMapData] = useState({})
  const [dataHasArrived, setDataHasArrived] = useState("")
  const [countyCsvIndex, setCountyCsvIndex] = useState({})
  const [cityCsvIndex, setCityCsvIndex] = useState({})
  const [foundDate, setFoundDate] = useState(null)
  const [mapModeTab, setMapModeTab] = useState(mapModeP !== undefined? mapModeP: "national")
  const [geoModeTab, setGeoModeTab] = useState(geoModeP !== undefined? geoModeP: "county")
  const [citiesList, setCitiesList] = useState([])
  const [popStop, setPopStop] = useState(20000)
  const [mortgageRates, setMortgageRates] = useState({"rate15": "", "rate30": ""})

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);

  async function getRates() {
    if (mortgageRates["rate15"] == "") {
      let todayFormatted = formatDate(new Date())
      let response = await getMortgageRates(appCtx, todayFormatted)
      if (response["meta"] == 200 && response["data"] !== undefined) {
        const data = response["data"]
        let rate15 = data["rate15"]
        let rate30 = data["rate30"]
        setMortgageRates({"rate15": rate15, "rate30": rate30})
      }
    }
  }

  function grabDates(startDate) {
    // go back every 15 days, check -+1 from date and count that
    // go back 3 months for now

    let currDate = new Date()

    // fullName = "/" + folderName + "/" + fileName + ".csv"
    let todayFormatted = formatDate(currDate)
    let yesterdayFormatted = formatDate(new Date(new Date().setDate(currDate.getDate() - 1)));

    let datesToCheck = []
    let datesArray = []
    let datesMap = {}
    //datesArray.push(todayFormatted)
    //datesArray.push(yesterdayFormatted)
    //datesMap[todayFormatted] = { "label": "today", "anchor": todayFormatted}
    //datesMap[yesterdayFormatted] = { "label": "today", "anchor": yesterdayFormatted}

    for (let i=1; i < 9; i++) {
      let currMonth = formatDate(new Date(new Date().setDate(currDate.getDate() - 30*i)));
      let dayBefore = formatDate(new Date(new Date().setDate(currDate.getDate() - (30*i) + 1)))
      let dayAfter = formatDate(new Date(new Date().setDate(currDate.getDate() - (30*i) - 1)))

      let suffix = " months ago"
      if (i == 1) {
        suffix = " month ago"
      }
      let text = "" + i + suffix

      datesArray.push(currMonth)
      datesArray.push(dayBefore)
      datesArray.push(dayAfter)

      datesMap[currMonth] = { "label": text, "anchor": currMonth}
      datesMap[dayBefore] = { "label": text, "anchor": currMonth}
      datesMap[dayAfter] = { "label": text, "anchor": currMonth}


    }
    return {"datesMap" : datesMap, "datesArray": datesArray}
  }

  async function getCsvHistory() {
    if (appCtx.token == undefined || appCtx.token == null || appCtx == "") {
      return
    }
    let date = new Date();
    let startDate = formatDate(date)
    if (csvMap["county"] !== null && csvMap["city"] !== null) {
      return
    }

    let datesResp = grabDates(startDate)
    let datesArray = datesResp.datesArray
    let datesMap = datesResp.datesMap

    if (csvMap["county"] == null || Object.keys(csvMap["county"]).length < 1) {
      csvMap["county"] = {}
      csvMap["city"] = {} // temp so this doesnt run more than once

      const response = await getValidCSVDates(appCtx, datesArray)

      // todo this should have city and county in response

      if (response.meta == 200 && response.data !== undefined) {
        const data = response["data"]
        let county = data["county"]
        let city = data["city"]
        let countyDates = county["dates"]
        let cityDates = city["dates"]

        let countyObj = setupSliderDates(countyDates, datesMap, county["latest_date"])
        let latestCountyDate = countyObj["latestDate"]
        let countyCombo = countyObj["comboMap"]

        let cityObj = setupSliderDates(cityDates, datesMap, city["latest_date"])
        let latestCityDate = cityObj["latestDate"]
        let cityCombo = cityObj["comboMap"]

        let csvMapObj = {
          "county": countyCombo,
          "city": cityCombo
        }

        setLatestDates({"county": latestCountyDate, "city": latestCityDate })

        setCsvMap(csvMapObj)

        if (geoModeTab === "city") {
          setSliderDate(latestCityDate)
        } else if (geoModeTab === "county") {
          setSliderDate(latestCountyDate)
        }

      } else {
        // ERROR HANDLING
        // if token is invalid, code is 401,
        if (response.meta == 401) {
          window.location.reload(); // this will create a guest account and redo everything
        }
      }
    }
  }

  function removeYear(withYear) {
    let sub = withYear.substring(withYear.indexOf('-') + 1)
    return sub
  }

  function setupSliderDates(dates, datesMap, latestDateObj) {
    let latestDate = latestDateObj["updated_at"]["S"]
    let date = new Date();
    let today = formatDate(date)
    let currYear = today.substring(0, 4)
    let map = {}
    let anchors = {}
    for (let d in dates) {
      // make sure we only have one date for each anchor point in datesMap
      let date = dates[d]

      let mapDate = datesMap[date]
      if (mapDate !== undefined && anchors[mapDate.anchor] === undefined) {
        let text = mapDate.label
        anchors[mapDate.anchor] = date
        let withoutYear = removeYear(date)
        map[date] = { "value": date, "label": withoutYear, "text": text }
      }

    }

    map[latestDate] = { "value": latestDate, "label": "today", "text": "today" }

    return { "latestDate": latestDate, "comboMap": map }
  }

  async function getCityMapData(date, scoreType, geoType) {
    cityCsvIndex[date] = {}
    if (scoreType === undefined || scoreType === null) {
      return
    }

    try {
      const response = await getHeatmap(appCtx, scoreType, date, geoType)
      if (response.meta == 200 && response.data !== undefined) {
        const data = response.data
        //let bounds = response.bounds
        let usedDate = data["date"]
        setFoundDate(usedDate)
        const valueArray = Object.values(data)

        setMapData(valueArray)
        setPopStop(popStop)
        cityCsvIndex[date][scoreType] = valueArray // TODO: might want to map the desired date with the actual date
      }
    } catch (error) {
       console.log('caught exception: ' + JSON.stringify(error))
    }
  }

  async function getCountyMapData(date, scoreType, geoType) {
    countyCsvIndex[date] = {}
    if (scoreType === undefined || scoreType === null) {
      return
    }

    // dont fetch if paywalled
    if (isCategeoryPaywalled(appCtx, mapCategory)) {
      return
    }

    // find specific month
    if (mapCategory == "short_term_rental" && numMonths !== "avg") {
      scoreType = mapType + "_" + numMonths + "_score"
    }

    const response = await getHeatmap(appCtx, scoreType, date, geoType)
    if (response.meta == 200 && response.data !== undefined) {
      const data = response.data
      
      let usedDate = data["date"]
      setFoundDate(usedDate)

      // past data comes in a big clump, save that clump and access innnards as needed
      const valueArray = Object.values(data)
      setMapData(valueArray)

      if (mapCategory == "past" && mapType !== "past_trend") {
        let pastKey = "past_diff_"+numMonths + "_score"
        countyCsvIndex[date][pastKey] = valueArray

      } else if (mapCategory == "short_term_rental") {
        let pastKey = mapType + "_" + numMonths + "_score"
        countyCsvIndex[date][pastKey] = valueArray

      } else {
        countyCsvIndex[date][scoreType] = valueArray // TODO: might want to map the desired date with the actual date
      }
    }
  }

  function fetchOrSetCities(type, date) {
    if (csvMap["city"] == null) {
      getCsvHistory()
      getRates()
    }

    if (geoModeTab == "city") {
      let typeFormatted = SCORES_MAP[type]

      let pastKey = ""
      if (mapCategory == "past" && type !== "past_trend") {
        pastKey = "past_diff_"+numMonths
        typeFormatted = pastKey + "_score"
      }

      if (cityCsvIndex[date] !== undefined && cityCsvIndex[date][typeFormatted] !== undefined) {
        setDataHasArrived(type) // reset map (fixes case where map is reused (past))
        let dataToSet = cityCsvIndex[date][typeFormatted]
        setMapData(dataToSet)
      } else {
        getCityMapData(date, typeFormatted, geoModeTab)
      }
    }
  }

  function fetchOrSetNationalData(type, date, geoModeTab) {
    if (csvMap["county"] == null) {
      getCsvHistory()
      getRates()
    } else {
      let typeFormatted = SCORES_MAP[type]

      // past ojects come in a big clump
      let pastKey = ""
      if (mapCategory == "past" && type !== "past_trend") {
        pastKey = "past_diff_"+numMonths
        typeFormatted = pastKey + "_score"
      }

      if (geoModeTab == "county") {
        if (countyCsvIndex[date] !== undefined && countyCsvIndex[date][typeFormatted] !== undefined) {
          setDataHasArrived(type) // reset map (fixes case where map is reused (past))
          setMapData(countyCsvIndex[date][typeFormatted])
        } else {
          getCountyMapData(date, typeFormatted, geoModeTab)
        } 

      } else if (geoModeTab == "city") {
        fetchOrSetCities(type, date)
      }
    }
  }

  function fetchOrSetMapData(type, date, mapModeTab, geoModeTab) {

    if (mapModeTab == "national") {
      fetchOrSetNationalData(type, date, geoModeTab)
    } else if (mapModeTab == "state") {
      fetchOrSetCities(type, date)
    }
  }

  function displayStateComboBox(modeTab) {
    if (modeTab === "national") {
      return true
    } else if (modeTab == "state") {
      return false
    }
  }

  function displayPopulationSlider(geoMode) {
    if (geoMode === "city") {
      return false
    } else {
      return true
    }
  }

  function getGeoComboBox(geoModeTab, geoContent) {
    if (geoModeTab == "county") {
      return (
        <CountyComboBox typedState={typedState} geoContent={geoContent} setTypedCounty={setGeoContent}></CountyComboBox>
      )
    } else if (geoModeTab == "city") {
      return (
        <CityComboBox typedState={typedState} geoContent={geoContent} citiesList={citiesList} setTypedCity={setGeoContent}></CityComboBox>
      )
    }
  }

  function getDateComponet() {
    if (mapCategory == "past" || mapCategory == "short_term_rental") {
      return (
        <MonthRadioButtons mapType={mapType} isRankings={false} numMonths={numMonths} setNumMonths={setNumMonths} mapCategory={mapCategory}></MonthRadioButtons>
      )
    } else {
      return (
        <DateSlider className="date-slider" hidden={isMobile()} key={'card_slider_dates'} geoModeTab={geoModeTab} csvMap={csvMap[geoModeTab]} setSliderDate={setSliderDate} sliderDate={sliderDate}></DateSlider>
      )
    }
  }

  // Run the query any time the selectors change.
  useEffect(() => {
    if (appCtx.isAuthenticated) {
      fetchOrSetMapData(mapType, sliderDate, mapModeTab, geoModeTab)
    }

    let newUrl = setupUrlCheckpoint(PAGE, geoModeTab, mapModeTab, mapCategory, mapType, geoContent, typedState)
    navigate(newUrl, { replace: true });

    if (mapCategory == "short_term_rental" && numMonths == "1m") {
      setNumMonths("avg")
    }

  }, [mapType, sliderDate, appCtx.isAuthenticated, numMonths, mapCategory]);

  useEffect(() => {
    if (appCtx.isAuthenticated) {
      fetchOrSetMapData(mapType, sliderDate, mapModeTab, geoModeTab)
    }
    

    if (typedState.label != geoContent["state"] && mapModeTab == "national") {
      setTypedState({"label": "USA", "val": ""})
    }

    // just flipped to state mode, unselect geo
    if (mapModeTab == "state" && geoModeTab == "county") {
      setGeoContent({})
    }

    let newUrl = setupUrlCheckpoint(PAGE, geoModeTab, mapModeTab, mapCategory, mapType, geoContent, typedState)
    navigate(newUrl, { replace: true });

  }, [mapModeTab]);

  useEffect(() => {

    if (typedState.label != geoContent["state"]) {
      setGeoContent({})
    }

    let newUrl = setupUrlCheckpoint(PAGE, geoModeTab, mapModeTab, mapCategory, mapType, geoContent, typedState)
    navigate(newUrl, { replace: true });

  }, [typedState]);
  

  useEffect(() => {
    if (appCtx.isAuthenticated) {

      if (geoModeTab === "county") {
        setSliderDate(latestDates["county"])
      } else if (geoModeTab === "city") {
        setSliderDate(latestDates["city"])
      }

      fetchOrSetMapData(mapType, sliderDate, mapModeTab, geoModeTab)

      let newUrl = setupUrlCheckpoint(PAGE, geoModeTab, mapModeTab, mapCategory, mapType, geoContent, typedState)
      navigate(newUrl, { replace: true });

    }
  }, [geoModeTab]);

  useEffect(() => {
    let countyObj;
    if ((geoIdP !== undefined && (appCtx.selectedGeo == null || appCtx.selectedGeo != null && appCtx.selectedGeo["geo_id"] !== geoIdP)) ||
      (geoIdP !== undefined && !isMapNonEmpty(appCtx.selectedGeo))) {
      countyObj = getCountyInfoFromGeo(geoIdP)
      if (countyObj !== null && countyObj !== undefined) {
        countyObj["geo_id"] = geoIdP
          appCtx.setSelectedGeo(countyObj)
      }
    }  

  }, [paramGeoId]);

  useEffect(() => {
    if (isMapNonEmpty(geoContent) && (appCtx.selectedGeo == null || (appCtx.selectedGeo !== null && geoContent["fips"] !== appCtx.selectedGeo["fips"]))) {

      let newUrl = setupUrlCheckpoint(PAGE, geoModeTab, mapModeTab, mapCategory, mapType, geoContent, typedState)
      navigate(newUrl, { replace: true });

    }
  }, [geoContent]);

  useEffect(() => {
    if (appCtx.selectedGeo !== null) {

      if (!isMapNonEmpty(geoContent) || (appCtx.selectedGeo == null || (appCtx.selectedGeo !== null && appCtx.selectedGeo["fips"] !== geoContent["fips"]))) {

        let geoId = appCtx.selectedGeo["geo_id"]
        let fips = appCtx.selectedGeo["fips"]

        // city mode
        if (geoModeTab == "city") {
          let countyInfo = cityIdToCountyInfo(geoId)
          let geoObj = {
            "city_id": geoId,
            "city_name": countyInfo["city_name"],
            "county": countyInfo["county"],
            "county_id": countyInfo["county_id"],
            "fips": fips,
            "state": countyInfo["state"],
            "score": geoContent.score,
            "value": geoContent.value
          }
          setGeoModeTab("city")
          setGeoContent(geoObj)

        } else {
          // county mode
          let geoObj = {
            "county": countyIdToProp(geoId, "county"),
            "county_id": geoId,
            "fips": fips,
            "state": countyIdToProp(geoId, "state"),
            "score": geoContent.score,
            "value": geoContent.value
          }
          setGeoContent(geoObj)

        }

      }
    }
  }, [appCtx.selectedGeo]);

  function getRateClasses(rateStr) {
    let classes = "mortgage-text "
    rateStr = rateStr.replace('%', '')
    let rate = parseFloat(rateStr)
    rate = rate * .01

    if (rate > .06) {
      classes += "rate-worst"
    } else if (rate > .05) {
      classes += 'rate-mid'
    } else if (rate > .04) {
      classes += 'rate-good'
    } else { 
      classes += 'rate-best'
    }
    return classes
  }

  function getReplacement(oldName, plural) {
    if (oldName == "national") {
      if (!plural) {
        return " National "
      } else {
        return "All United States "
      }
    }
    if (oldName == "state") {
      if (isMapNonEmpty(typedState) && typedState.label !== "" && typedState.label !== undefined) {
        return " in " + STATE_ABBREV_TO_FULL[typedState.label] +','
      } else {
        return " States "
      }
    }
    if (oldName == "states") {
      return "States"
    }
    if (oldName == "city") {
      if (plural) {
        return "Cities"
      } else {
        return "City"
      }
    }
    if (oldName == "county") {
      if (plural) {
        return "Counties"
      } else {
        return "County"
      }
    }
  }

  function createMetaTitle() {
    let title = ""

    if (isMapNonEmpty(geoContent)) {

      if (geoModeTab =="city" && geoContent["city_name"] != undefined) {
        title = title + " " + replaceHyphens(geoContent["city_name"])
      } else if (geoContent["county"] !== undefined) {
        title = title + " " + replaceHyphens(geoContent["county"])
      }

      if (geoContent["state"] !== undefined && geoContent["state"] != "") {
        title = title + ", " + geoContent["state"]
      }
      if (title !== "") {
        title = title + ": "
      }
    }
    title = title + convertToUserFacing(mapType)

    title = title + " of Homes" + getReplacement(mapModeTab, false) +" " + getReplacement(geoModeTab, false) + " Averages"
    return title
  }

  function createMetaDesc() {
    let desc = ""
    let title = ""
    if (geoModeTab =="city") {
      title = title + " " + replaceHyphens(geoContent["city_name"])
    } else {
      title = title + " " + replaceHyphens(geoContent["county"])
    }

    if (geoContent["state"] !== undefined && geoContent["state"] != "") {
      title = title + ", " + geoContent["state"]
    }
    desc = "Compare "+ convertToUserFacing(mapType)+" of Homes in" + title + " to " + getReplacement(mapModeTab, true) + getReplacement(geoModeTab, true) + ". " + convertToUserFacing(mapCategory) + " gathered from the real estate market daily. Political, Climate Change, Economic, Job Market, Rent, Short Term Rental and Community data all available for free at Hiddenhousing.org"

    return desc
  }

// TODO: add a toggle to switch between state mode and national mode from dashboard.. 
// also add state combobox here too
// TODO: add content inside of drawer into cards.. make those modes toggle-able
  return (
    <div>
      <div>
      <HelmetTags key={geoContent["fips"] + "-"+mapType + "-" + geoModeTab} title={createMetaTitle()} description={createMetaDesc()}></HelmetTags>

        <Row className="maprow">
          <Col xs={12} sm={12} md={12} lg={9}>
            <Row>
            <h2 className="text-centered dark-primary mt-4">{generateRankingTitle(geoModeTab, mapCategory, mapType, numMonths)}<span className='text-centered ranking-title'>{convertToUserFacing(mapType)}</span></h2>
          </Row>
            <CurrentMap key={'map-'+mapModeTab} popStop={popStop} setCitiesList={setCitiesList} sliderDate={sliderDate}
              setTypedState={setTypedState} typedState={typedState} geoModeTab={geoModeTab} mapModeTab={mapModeTab}
              mapData={mapData} setSelectedScore={setSelectedScore} setMapType={setMapType} mapType={mapType} numMonths={numMonths}
              geoContent={geoContent} setGeoContent={setGeoContent} mapCategory={mapCategory} dataHasArrived={dataHasArrived}/>
          </Col>

          <Col xs={12} sm={12} md={12} lg={3}>
            <Col lg={12} md={12} sm={12} xs={12} className="mapkey-top" key={"mapkey-1"}>
              <MapKey></MapKey>
              <Col lg={12} md={12} sm={12} xs={12} className="mortgagerates-top">
                <Row display="inline-block">
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <span>
                      <p className='mortgage-text'>30yr Rate:</p>
                      <p className={getRateClasses(mortgageRates["rate30"])}>{mortgageRates["rate30"]}</p>
                    </span>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <span>
                        <p className='mortgage-text'>15yr Rate:</p>
                        <p className={getRateClasses(mortgageRates["rate15"])}>{mortgageRates["rate15"]}</p>
                      </span>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Grid marginTop="4%" container className="center" columnSpacing={5} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid marginLeft="5%" marginRight="5%" className="center" xs={12} sm={12} md={12} minWidth="300px" maxWidth="500px" >
                <MapCategoryComboBox currItem={mapCategory} geoModeTab={geoModeTab} typedState={typedState} mapModeTab={mapModeTab} setMapCategory={setMapCategory}></MapCategoryComboBox>
              </Grid>
              <Grid marginLeft="5%" marginRight="5%" className="center" xs={12} sm={12} md={12} minWidth="300px" maxWidth="500px" >
                <MapTypeComboBox mapType={mapType} mapCategory={mapCategory} geoModeTab={geoModeTab} typedState={typedState} mapModeTab={mapModeTab} setMapType={setMapType}></MapTypeComboBox>
              </Grid>
              <Grid marginLeft="5%" marginRight="5%" xs={12} sm={12} md={12} minWidth="300px" maxWidth="500px">
                {getGeoComboBox(geoModeTab, geoContent)}
              </Grid>
              <Grid marginLeft="10%" marginRight="10%" xs={12} sm={12} md={12} lg={12} minWidth="300px" maxWidth="400px">
                <CountyVsCityToggle geoModeTab={geoModeTab} setGeoModeTab={setGeoModeTab}></CountyVsCityToggle>
              </Grid>
              <Grid marginLeft="10%" marginRight="10%" xs={12} sm={12} md={12} lg={12} minWidth="300px" maxWidth="400px">
                <NationalvsStateToggle startToggle={mapModeTab} setMapModeTab={setMapModeTab}></NationalvsStateToggle>
              </Grid>
              <Grid className="popslider" xs={12} sm={12} md={12} lg={12} minWidth="300px" maxWidth="500px" height={geoModeTab === "county" ? 0 : "auto"} hidden={displayPopulationSlider(geoModeTab)}>
                <PopulationSlider key={'slider_population'} popStop={popStop} setPopStop={setPopStop}></PopulationSlider>
              </Grid>
              <Grid className="statecombo" xs={12} sm={12} md={12} lg={12} minWidth="300px" maxWidth="500px" height={mapModeTab === "national" ? 0 : "auto"} hidden={displayStateComboBox(mapModeTab)}>
                <StateComboBox width="10%" display="inline" typedState={typedState} setTypedState={setTypedState} ></StateComboBox>
              </Grid>
              <Row className="mortgagerates">
                <Col lg={6} md={6} sm={6} xs={6}>
                  <span>
                    <p className='mortgage-text'>30yr Rate:</p>
                    <p className={getRateClasses(mortgageRates["rate30"])}>{mortgageRates["rate30"]}</p>
                  </span>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6}>
                <span>
                    <p className='mortgage-text'>15yr Rate:</p>
                    <p className={getRateClasses(mortgageRates["rate15"])}>{mortgageRates["rate15"]}</p>
                  </span>
                  </Col>
              </Row>
              <div className="map-info" hidden={isMobile(appCtx) || mapType != "all"}>
                <MapInfo></MapInfo>
              </div>
            </Grid>
          </Col>
        </Row>
        <Row className="map-bottom">
          <Col className="map-bottom-col date-slider-container" lg={8} md={12} sm={12} xs={12}>
            {getDateComponet()}
          </Col>
          <Col lg={3} md={0} sm={0} xs={0} className="mapkey-main" key={"mapkey-1"}>
            <MapKey></MapKey>
          </Col>
        </Row>
        <Row>
          <div className="cards-container">
            <CardTabs sliderDate={sliderDate} mapModeTab={mapModeTab} mapType={mapType} score={selectedScore} geoModeTab={geoModeTab} geoContent={geoContent} mortgageRates={mortgageRates}></CardTabs>
          </div>
        </Row>
      </div>
    </div>
  );
}
export default memo(DashboardPage);