import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { height } from '@mui/system';
import { BsBarChartFill, BsTable } from 'react-icons/bs';
import { useEffect } from 'react';

export default function ChartvsTableToggle(props) {
  const [active, setActive] = React.useState('table');

  const handleChange = (event, newValue) => {
    setActive(newValue)
    props.setSelectedTab(newValue);
  };

  useEffect(() => {

    if (props.selectedTab !== undefined) {
      props.setSelectedTab(props.selectedTab)
      setActive(props.selectedTab)
    }
  }, [props.selectedTab]);

  return (
    <ToggleButtonGroup
      className="dark-font-color spacer controls"
      aria-label="Small sizes"
      size="small"
      value={active}
      exclusive={true}
      onChange={handleChange}
    >
      <ToggleButton value="chart">
      <BsBarChartFill></BsBarChartFill>
      </ToggleButton>
      <ToggleButton value="table">
      <BsTable></BsTable>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}