import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import {numberWithCommas, getCardTitle, getAttrLabel, shouldHideHeader, removeNonNumericExceptDot, removeNonNumericExceptDotMinus, CHART_COLORS, randomColor, getPropertyTax, isMapNonEmpty } from '../../shared/common';

import { Col, Container, Row } from 'react-bootstrap';
import Indicator from './Indicator';
import { Doughnut } from 'react-chartjs-2';
import GaugeChart from 'react-gauge-chart'

function getOptions(newLabel) {

   let options = {
     responsive: true,
     maintainAspectRatio: false,
     plugins: {
       legend: {
         labels: {
           color: "white", 
           font: {
             size: 12
           }
         }
       },
       title: {
         display: true,
         text: newLabel,
         padding: {
           left: 10,
           right: 5,
           bottom: 10,
           top: 5
         },
         color: "white",
         font: {
           size: 14,
           weight: 'bold',
         },
         align: "center"
       }
     }
   }
 
   return options
 }

 const sampleChartData = {
   labels: ["Job A", "Job B", "Job C"],
   datasets: [
     {
       data: ['10', '30', '20'],
       backgroundColor: [CHART_COLORS.blueColor, CHART_COLORS.lightBlueColor, CHART_COLORS.darkBlueColor],
     }
   ]
 };

 const graphs = {
   "jobs": sampleChartData,
   "overall": { "county": {"num": .5, "str": "50", "color": "#90ee90" }},
   "housing": { "county": {"num": .5, "str": "50", "color": "#90ee90"}},
   "grocery": { "county": {"num": .5, "str": "50", "color": "#90ee90"}},
   "transportation": { "county": {"num": .5, "str": "50", "color": "#90ee90"}},
   "health": { "county": {"num": .5, "str": "50", "color": "#90ee90"}},
   "utilities": { "county": {"num": .5, "str": "50", "color": "#90ee90"}},
 }

 let firstState ={"state": false, "national": false, "county": false}

function EconomyCards(props) {
  const [economyData, setEconomyData] = useState(null)
  const [benchmarks, setBenchmarks] = useState(null)
  const [stateBenchmarks, setStateBenchmarks] = useState(null)
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});
  const [chartData, setChartData] = useState(graphs)
  const [colors, setColors] = useState(null)
  const [updateKey, setUpdateKey] = useState({"key": "", "county": ""})
  const [dataState, setDataState] = useState(firstState)
  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {   
   if (isMapNonEmpty(props.selectedCounty) && props.selectedCounty.county_id != null && props.selectedCounty.county_id !== selectedCounty.county_id) {
      setLoadingData(true)
      setSelectedCounty(props.selectedCounty)

      if (JSON.stringify(chartData) != JSON.stringify(graphs)) {
         setDataState(firstState)
      }
   }

 }, [props.selectedCounty]);

  useEffect(() => {
   
   if (isMapNonEmpty(props.countyData) && isMapNonEmpty(props.countyData["economy"]) && isMapNonEmpty(props.countyData["cost_of_living"])) {
      // we only care about the weather type so lets only save that part

      let countyMap = {}
      countyMap = props.countyData["economy"]
      countyMap["cost_of_living"] = props.countyData["cost_of_living"]
      setLoadingData(false)
      setEconomyData(countyMap)
      dataState["county"] = true

      if (dataState["county"] && dataState["state"] && dataState["national"]) {
         setupCharts(countyMap, stateBenchmarks, benchmarks)
      }
   }

 }, [props.countyData]);

 useEffect(() => {
   
   if (isMapNonEmpty(props.benchmarks) && isMapNonEmpty(props.benchmarks.benchmark_community)) {
      // we only care about the weather type so lets only save that part
      let communityData = props.benchmarks.benchmark_community
      let benchData = {}
      benchData["economy"] = communityData["economy"]
      benchData["cost_of_living"] = communityData["cost_of_living"]

      setBenchmarks(benchData)
      dataState["national"] = true

      if (dataState["county"] && dataState["state"] && dataState["national"]) {
         setupCharts(economyData, stateBenchmarks, benchData)
      }
   }

 }, [props.benchmarks]);

 useEffect(() => {

   if (isMapNonEmpty(props.stateBenchmarks) && isMapNonEmpty(props.stateBenchmarks.data) 
   && isMapNonEmpty(props.stateBenchmarks.data.benchmark_community) && isMapNonEmpty(props.stateBenchmarks.data.benchmark_community.economy)) {
      // we only care about the weather type so lets only save that part

      let stateCommunity = props.stateBenchmarks.data.benchmark_community
      let stateData = {}
      stateData.state = props.stateBenchmarks.data["state"]
      stateData["cost_of_living"] = stateCommunity["cost_of_living"]
      stateData["economy"] = stateCommunity["economy"]

      setStateBenchmarks(stateData)
      dataState["state"] = true

      if (dataState["county"] && dataState["state"] && dataState["national"]) {
         setupCharts(economyData, stateData, benchmarks)
      }

   }

 }, [props.stateBenchmarks]);

 function checkAndSet(toCheck, toSet, attr, backup) {
   if (toCheck == undefined || toCheck == null) {
     toSet[attr] = backup
   } else {
     toSet[attr] = toCheck
   }
   return toSet
 }

 function setupCharts(countyData, stateBenchmarks, nationalBenchmarks) {

   if (isMapNonEmpty(countyData)
     && isMapNonEmpty(stateBenchmarks)
     && isMapNonEmpty(nationalBenchmarks)
     && isMapNonEmpty(selectedCounty) && selectedCounty.county !== null) {

     let jobs = setupChartData("jobs", countyData, stateBenchmarks, nationalBenchmarks)
     let overallCOL = setupChartData("overall", countyData, stateBenchmarks, nationalBenchmarks)
     let housingCOL = setupChartData("housing", countyData, stateBenchmarks, nationalBenchmarks)
     let groceryCOL = setupChartData("grocery", countyData, stateBenchmarks, nationalBenchmarks)
     let transportationCOL = setupChartData("transportation", countyData, stateBenchmarks, nationalBenchmarks)
     let healthCOL = setupChartData("health", countyData, stateBenchmarks, nationalBenchmarks)
     let utilitiesCOL = setupChartData("utilities", countyData, stateBenchmarks, nationalBenchmarks)

     let allData = {}
     allData = checkAndSet(jobs, allData, "jobs", sampleChartData)
     allData = checkAndSet(overallCOL, allData, "overall", graphs["overall"])
     allData = checkAndSet(housingCOL, allData, "housing", graphs["housing"])
     allData = checkAndSet(groceryCOL, allData, "grocery", graphs["grocery"])
     allData = checkAndSet(transportationCOL, allData, "transportation", graphs["transportation"])
     allData = checkAndSet(healthCOL, allData, "health", graphs["health"])
     allData = checkAndSet(utilitiesCOL, allData, "utilities", graphs["utilities"])

     setChartData(allData)
     if (updateKey.county !== selectedCounty.county) {
      setUpdateKey({"key":Date.now(), "county": selectedCounty.county })
     }
   }
 }

 function getGaugeValue(startValue) {
   return parseFloat((parseFloat(startValue - 100) + 50) / 100.0).toFixed(2);
 }

 function getNeedleColor(value) {
   let color = ""
   value = parseInt(value)
   if (value > 115) {
      color = "#FF0000"
   } else if (value < 85) {
      color = "#90ee90"
   } else {
      color = "#FFE000"
   }
   return color
 }

 function setupChartData(attr, dataToUse, stateBenchmarks, nationalBenchmarks) {

   if (attr === "jobs" && dataToUse[attr] !== undefined) {
      let jobsData = dataToUse[attr]
      let jobs = Object.keys(jobsData)
      let jobsArray = []
      let borders = []
      let backgrounds = []
      let colorObj = {}
      for (let item in jobs) {
         let job = jobs[item]
         let value = getAttrValues(job, dataToUse[attr], "value")
         jobsArray.push(value)

         if (colors == null) {
            let randomColors = randomColor(.4)
            borders.push(randomColors.opaque)
            backgrounds.push(randomColors.transparent)
         }
      }

      if (colors == null) {
         colorObj["backgrounds"] = backgrounds
         colorObj["borders"] = borders
         colorObj["county"] = selectedCounty.county
         setColors(colorObj)

       } else {
         colorObj = colors
       }

     const data = {
       labels: jobs,
       datasets: [
         {
           data: jobsArray,
           borderColor: colorObj["borders"],
           backgroundColor: colorObj["backgrounds"]
         }
       ]
     };
     return data
   }
   if ((attr === "overall" || attr === "housing" || attr == "grocery" || attr == "transportation" || attr == "health" || attr == "utilities")
      && dataToUse["cost_of_living"][attr] !== undefined) {
      let county = getAttrValues(attr, dataToUse, "cost_of_living")
      let state = getAttrValues(attr, stateBenchmarks, "cost_of_living")
      let national = getAttrValues(attr, nationalBenchmarks, "cost_of_living")

      let countyPercent = getGaugeValue(county)
      let statePercent = getGaugeValue(state)
      let nationalPercent = getGaugeValue(national)

      let countyColor = getNeedleColor(county)
      let stateColor = getNeedleColor(state)
      let nationalColor = getNeedleColor(national)

      return {
         "county": { "num": countyPercent, "str": county, "color": countyColor },
         "state": { "num": statePercent, "str": state, "color": stateColor },
         "national": { "num": nationalPercent, "str": national, "color": nationalColor }
      }
   }
 }

 function getAttrValues(attr, from, prefix) {
   if (from === undefined || from == null) {
     return
   }

   // submap
   if (prefix === "economy" || prefix === "cost_of_living" || prefix == "county") {
      from = from[prefix]
   }

   if (from[attr] === undefined || from[attr] === null) {
         return { "str": "", "num": -1 }
   }

   if (attr == "color" || attr == "num" || attr == "str") {
      if (attr == "num") {
         let temp = from[attr]
         if (temp > 1.0) {
            temp = 1.0
         }
         if (temp < 0.0) {
            temp = 0.0
         }
         return temp
      }
      
      return from[attr]
   }

   if (prefix == "value") {
      return removeNonNumericExceptDot(from[attr])
   }

   // gauges
   if (attr == "overall" || attr == "housing" || attr == "transportation" || attr == "grocery" || attr == "health" || attr == "utilities") {
      return removeNonNumericExceptDot(from[attr])
   }

   let responseBare = 0
   let response = ""
   if (attr == "household_income" || attr == "family_median_income") {
      let temp = removeNonNumericExceptDot(from[attr])
      responseBare = parseInt(temp)
      response = "$" + numberWithCommas(responseBare)
   }

   if (attr == "income_tax" || attr == "sales_taxes" || attr == "unemployment_rate" || attr == "recent_job_growth" || attr == "future_job_growth") {
      let temp = removeNonNumericExceptDotMinus(from[attr])
      responseBare = parseFloat(temp)
      response = Math.round(temp, 2) + "%"
   }

   return { str: response, num: responseBare}
 }

 function getAttrDesired(attr) {
   if (attr == "unemployement_rate" || attr == "sales_taxes" || attr == "income_tax") {
      return "lower"
    }
    return "higher"
 }
 
function StatBubble(props) {
   if (economyData === null || economyData === undefined) {
      return
   }

   let index = props.index
   let hideHeader = shouldHideHeader(index)
   let attr1Array = props.attr1

   let attrs = []

   for (let i=0; i< attr1Array.length; i++) {
      let attr = attr1Array[i]
      let newObj = {}
      let bigObj = {}
      newObj["county"] = getAttrValues(attr, economyData, null)
      newObj["label"] = getAttrLabel(attr)
      newObj["state"] = getAttrValues(attr, stateBenchmarks, "economy")
      newObj["national"] = getAttrValues(attr, benchmarks, "economy")
      newObj["desired"] = getAttrDesired(attr)

      bigObj["attr1"] = newObj

      attrs.push(bigObj)
   }

   let firstClass = "card card-background mb-3"

   if (selectedCounty !== null && selectedCounty.county !== null && economyData !== null && stateBenchmarks !== null && benchmarks !== null) {
     return (
       <Container>
         <Row className="mt-2">
            <div className={firstClass}>
            <div hidden={hideHeader} className="card-header">{getCardTitle("county", selectedCounty) + " - Economic Data"}</div>
            <div className="card-body">
               <Row>
               <Col xs={3} sm={3} md={5} lg={5} className="spacer">
               </Col>
               <Col xs={3} sm={3} md={3} lg={3} className="spacer">
                  <div hidden={hideHeader}><h5  className="card-subheader">County</h5>
                  <hr className="separator" /></div>
               </Col>
               <Col xs={3} sm={3} md={2} lg={2}>
                  <div hidden={hideHeader}><h5  className="card-subheader">State</h5>
                  <hr className="separator" /></div>
               </Col>
               <Col xs={3} sm={3} md={2} lg={2}>
                  <div hidden={hideHeader}><h5  className="card-subheader">National</h5>
                  <hr className="separator" /></div>
               </Col>
               </Row>
               {attrs.map((row) => (
               <Row className="mt-2 mb-2">
                  <Col xs={3} sm={3} md={5} lg={5}>
                     <h6 className="card-title">{row["attr1"]["label"]}</h6>
                  </Col>
                  <Col xs={3} sm={3} md={2} lg={3} className="spacer">
                     <p className="card-text">{row["attr1"]["county"].str}
                        <Indicator desired={row["attr1"]["desired"]} number={row["attr1"]["county"].num} stateAvg={row["attr1"]["state"].num} nationalAvg={row["attr1"]["national"].num} ></Indicator>
                     </p>
                  </Col>
                  <Col xs={3} sm={3} md={2} lg={2}>
                     <p className="card-text">{row["attr1"]["state"].str}</p>
                  </Col>
                  <Col xs={3} sm={3} md={2} lg={2}>
                     <p className="card-text">{row["attr1"]["national"].str}</p>
                  </Col>
               </Row>
               ))}
               <Row className="mt-2 mb-2">
                  <Col xs={3} sm={3} md={5} lg={5}>
                     <h6 className="card-title">{"Property Tax"}</h6>
                  </Col>
                  <Col xs={3} sm={3} md={2} lg={3} className="spacer">
                     <p className="card-text">{""}
                     </p>
                  </Col>
                  <Col xs={3} sm={3} md={2} lg={2}>
                     <p className="card-text">{parseFloat(getPropertyTax(selectedCounty["state"]))+"%"}
                     <Indicator desired={parseFloat(getPropertyTax(selectedCounty["state"]))} stateAvg={1.1} nationalAvg={1.1} ></Indicator>

                     </p>
                  </Col>
                  <Col xs={3} sm={3} md={2} lg={2}>
                     <p className="card-text">{"1.1%"}</p>
                  </Col>
               </Row>
            </div>
            </div>
         </Row>
       </Container>
     )
   }
 }

  return (
      <Container key={'economy-card-'+JSON.stringify(selectedCounty)} hidden={selectedCounty == null}>
         <div hidden={loadingData == true}>
         <Row>
            <Col xs={6} sm={4} md={2} lg={2}>
               <GaugeChart key={'gauge1-'+updateKey.key} id="gauge-chart1"
                  nrOfLevels={3}
                  needleColor={getAttrValues("color", chartData["overall"], "county")}
                  hideText={true}
                  percent={getAttrValues("num", chartData["overall"], "county")}  />
                  <p className="text-centered gauge-text">Cost of Living: Overall</p>
                  <p className="text-centered">{getAttrValues("str", chartData["overall"], "county")}</p>
            </Col>
            <Col xs={6} sm={4} md={2} lg={2}>
               <GaugeChart key={'gauge2-'+updateKey.key} id="gauge-chart2"
                  nrOfLevels={3}
                  hideText={true}
                  needleColor={getAttrValues("color", chartData["housing"], "county")}
                  percent={getAttrValues("num", chartData["housing"], "county")}  />
                  <p className="text-centered gauge-text">Cost of Living: Housing</p>
                  <p className="text-centered">{getAttrValues("str", chartData["housing"], "county")}</p>
            </Col>
            <Col xs={6} sm={4} md={2} lg={2}>
               <GaugeChart key={'gauge3-'+updateKey.key} id="gauge-chart3"
                  nrOfLevels={3}
                  needleColor={getAttrValues("color", chartData["grocery"], "county")}
                  hideText={true}
                  percent={getAttrValues("num", chartData["grocery"], "county")}  />
                  <p className="text-centered gauge-text">Cost of Living: Grocery</p>
                  <p className="text-centered">{getAttrValues("str", chartData["grocery"], "county")}</p>
            </Col>
            <Col xs={6} sm={4} md={2} lg={2}>
               <GaugeChart key={'gauge4-'+updateKey.key} id="gauge-chart4"
                  nrOfLevels={3}
                  hideText={true}
                  needleColor={getAttrValues("color", chartData["health"], "county")}
                  percent={getAttrValues("num", chartData["health"], "county")}  />
                  <p className="text-centered gauge-text">Cost of Living: Health</p>
                  <p className="text-centered">{getAttrValues("str", chartData["health"], "county")}</p>
            </Col>
            <Col xs={6} sm={4} md={2} lg={2}>
               <GaugeChart key={'gauge5-'+updateKey.key} id="gauge-chart5"
                  nrOfLevels={3}
                  hideText={true}
                  needleColor={getAttrValues("color", chartData["transportation"], "county")}
                  percent={getAttrValues("num", chartData["transportation"], "county")}  />
                  <p className="text-centered gauge-text">Cost of Living: Transportation</p>
                  <p className="text-centered">{getAttrValues("str", chartData["transportation"], "county")}</p>
            </Col>
            <Col xs={6} sm={4} md={2} lg={2}>
               <GaugeChart key={'gauge6-'+updateKey.key} id="gauge-chart6"
                  nrOfLevels={3}
                  hideText={true}
                  needleColor={getAttrValues("color", chartData["utilities"], "county")}
                  percent={getAttrValues("num", chartData["utilities"], "county")}  />
                  <p className="text-centered gauge-text">Cost of Living: Utilities</p>
                  <p className="text-centered">{getAttrValues("str", chartData["utilities"], "county")}</p>
            </Col>
         </Row>
         <Row>
            <Col md={12} lg={6}>
               <StatBubble key={'economy-data-'+JSON.stringify(dataState)} attr1={["household_income", "family_median_income", "unemployment_rate", "income_tax", "sales_taxes", "recent_job_growth", "future_job_growth" ]} index={0}></StatBubble>
            </Col>
            <Col md={12} lg={6}>
               <Doughnut key={'jobspie-'+updateKey.key} className="chart-centered" data={chartData["jobs"]} options={getOptions("Jobs")}></Doughnut>
            </Col>
         </Row>
         </div>
         <Row hidden={loadingData == false}>
            <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
         </Row>
      </Container>
  );
}

export default EconomyCards;