import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { csv } from "d3-fetch";

import { PropaneSharp } from "@mui/icons-material";
import { Paper } from "@mui/material";
import ReactGA from "react-ga4"

const months = [
  { label: "3 Months", id: "3m" },
  { label: "6 Months", id: "6m" },
  { label: "9 Months", id: "9m" },
  { label: "12 Months", id: "12m" }
]


const StrPeriodComboBox = (props) => {
  const [comboboxItems, setComboboxItems] = useState([]);
  const [selected, setSelected] = useState("12 Months")

  const onChanged = (newValue) => {
    if (newValue != null) {
      setSelected(newValue.label)
      props.setStrPeriod(newValue)

      ReactGA.event({
        category: 'Combobox.Changed',
        action: 'StrPeriodCombobox Changed',
        label: 'strperiodcombobox.changed.'+newValue.label
      });
    }
  };

  if (comboboxItems.length == 0) {
    const dataArray = []
      for (let i in months) {
        const month = months[i]
        dataArray.push({ label: month.label, id: month.id })
      }
    setComboboxItems(dataArray)
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-states-str"
      options={comboboxItems}
      className="combobox-dark"
      value={{label : selected}}
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#2d2d30", color: "white" }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" InputProps={{ style: { fontSize: 8 } }} {...params} label="In the Next" size="small" />}
    />
  );
}

export default memo(StrPeriodComboBox);