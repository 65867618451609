import React, { useState, useEffect, memo } from "react";
import { numberWithCommas, replaceHyphens, convertToUserFacing, getGeoTitle, isMapNonEmpty, getCountyInfoFromGeo } from '../shared/common';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';

import { fontSize } from "@mui/system";

//import * as React from 'react';
import ReactGA from "react-ga4"
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1e1e1e',
    color: theme.palette.common.white,
    fontSize: 11,
    height: 10
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
  '&.headRow': {
    backgroundColor: '#1e1e1e',
    color: theme.palette.common.white,
    fontSize: 11,
    height: 10,
    fontWeight: 'bold'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#36363a",
    color: "white"
  },
  '&:nth-of-type(even)': {
    backgroundColor: "#2d2d30",
    color: "white"
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&.headRow': {
    backgroundColor: '#3e3e42',
    color: "white",
    fontSize: 11,
    height: 10,
    fontWeight: 'bold'
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ ml: 2.5, flexShrink: 0 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const LeaderboardTable  = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardType, setLeaderboardType] = useState("")
  const [selectedRow, setSelectedRow] = useState(null)

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leaderboardData.length) : 0;


  useEffect(() => {
    if (isMapNonEmpty(props.leaderboardData)) {
      setLeaderboardData(props.leaderboardData)
    }

  }, [props.leaderboardData]);

  useEffect(() => {

    if (props.leaderboardType !== null && props.leaderboardType !== undefined) {
      setLeaderboardType(props.leaderboardType)
    }

  }, [props.leaderboardType]);

  function rankingClicked(obj) {
    // grab city_id or county_id from row
    // unit_id
    let selected = getCountyInfoFromGeo(obj["unit_id"])
    props.setSelectedGeo(selected)
    setSelectedRow(obj["unit_id"])

    ReactGA.event({
      category: 'Rankings.Click',
      action: 'Rankings Item Click',
      label: 'rankings.item.click.'+leaderboardType,
    });
  }


  // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getRowClass(row) {
    if (selectedRow == row["unit_id"]) {
      return "leaderboard-row-selected"
    } else {
      return "leaderboard-row"
    }
  }

  function getTableValue(value, mapType, mapCategory) {
    if (mapCategory.indexOf("past") > -1) {
      if (mapType.indexOf("trend") > - 1) {
        return numberWithCommas(value)
      } else {
        return numberWithCommas(value)
      }
    } else {
      return numberWithCommas(value)
    }
  }

  return (
    <div>
      <div className="spacer"></div>
    <TableContainer key={'ranking-table-inner-'+leaderboardType} component={Paper} sx={{maxWidth:'100%'}}>
      <Table size="small" aria-label="a dense table">
      <TableHead>
        <StyledTableRow>
          <StyledTableCell align="left">Rank</StyledTableCell>
          <StyledTableCell align="left">Place</StyledTableCell>
          <StyledTableCell align="left">Value</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? leaderboardData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : leaderboardData
          ).map((row, index) => (
            <StyledTableRow className={getRowClass(row)} key={index+"_"+leaderboardType+"_"+row.unit_id} onClick={() => rankingClicked(row)}>
            <StyledTableCell component="th" scope="row">{page * rowsPerPage+index+1+"."}</StyledTableCell>
            <StyledTableCell component="th" scope="row">{getGeoTitle(row.unit_id)}</StyledTableCell>
            <StyledTableCell align="left">{getTableValue(row.value, props.mapType, props.mapCategory)}</StyledTableCell>
          </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <StyledTableRow style={{ height: 53 * emptyRows }}>
              <StyledTableCell colSpan={6} />
            </StyledTableRow>
          )}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <TablePagination
              sx={{
                '.MuiTablePagination-toolbar': {
                  maxWidth:'100%',
                  height: '35px',
                },
              }}
              rowsPerPageOptions={[25, 100, { label: 'All', value: -1 }]}
              colSpan={4}
              count={leaderboardData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </div>
  );
}

export default memo(LeaderboardTable);