import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from "@mui/material";
import { CATEGORY_SUBSET_MAP, getAttrLabelShort } from "../../shared/common";
import "./watchlist.css";
import ReactGA from "react-ga4"

const SubsetsCombobox = (props) => {
  const [mapItems, setMapItems] = useState([]);
  const [selected, setSelected] = useState("all")

  const dataArray = []
  const dataMap = {} 

  function getSubsetName(type) {
    return CATEGORY_SUBSET_MAP[props.mapCategory][type]
  }


  const onChanged = (newValue) => {
    if (newValue !== null) {
      props.setCurrSort(newValue.type)
      setSelected(getSubsetName(newValue.type))
      ReactGA.event({
        category: 'Subsets.Combobox.Change',
        action: 'Subsets Combobox Change (Downloads)',
        label: 'subsets.combobox.change.'+getAttrLabelShort(newValue.type),
      });
    }
  };

  useEffect(() => {
    const loadValues = async (dataArray) => {

      let subsetTypes = CATEGORY_SUBSET_MAP[props.mapCategory]

      for (let subtype in subsetTypes) {
        let niceName = subsetTypes[subtype]

        if (niceName !== undefined) {
          dataArray.push({ label: niceName, type: subtype})
          dataMap[subtype] = niceName
        }
      }
      if (dataArray.length > 0) {
        setMapItems(dataArray)
      }
    }


    loadValues(dataArray)

  }, [props.mapCategory]);

  return (
    <Autocomplete
      disablePortal
      size="small"
      id="combo-box-listings"
      value={{label : selected}}
      defaultValue={props.currSort}
      options={mapItems}
      disableClearable
      className="combobox-dark watchlist-combo"
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white", border: "0px", fontSize: 10 }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" {...params} InputProps={{ ...params.InputProps, style: { fontSize: 10} }} size="small" />}
    />
  );
}

export default memo(SubsetsCombobox);