import { useState, useEffect, useContext, memo} from 'react';
import AppContext from '../../state/app-context';
import * as React from 'react';
import {getColorScale, numberWithCommas, getAttrLabel, getCardTitle, grabPopulation, CHART_COLORS, shouldHideHeader, removeNonNumeric, isMapNonEmpty, getFontColor} from '../../shared/common';
import { getCountySubsets } from "../../service/thermostatservice"
import { Bubble } from "react-chartjs-2"; 
import Chart from 'chart.js/auto';

import { Col, Container, Row } from 'react-bootstrap';
import ChartvsTableToggle from '../ChartvsTableToggle';
import { Box } from '@mui/material';
import Indicator from './Indicator';

let sampleCountyData = { "county_rent_sums_total": { "avg_price": "", "median_price": "", "num_listings": "", "listings_per_pop": ""}, "county_sold_sums_total": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}
let sampleStateData = { "state": "", "data": { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}}
let sampleNationalData = { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}

let options = {
  responsive: true,
  scales: {
    y: {  
      ticks: {
        color: "white",
      }
    },
    x: {  
      ticks: {
        color: "white",
      }
    }
  },
  plugins: {
    legend: {
      labels: {
        color: "white", 
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: 'Rent Bed/Bath Crosstabs',
      padding: {
        left: 10,
        right: 5,
        bottom: 10,
        top: 5
      },
      color: "white",
      font: {
        size: 14,
        weight: 'bold',
      },
      align: "center"
    }
  }
}

let options2 = {
  responsive: true,
  scales: {
    y: {  
      ticks: {
        color: "white",
      }
    },
    x: {  
      ticks: {
        color: "white",
      }
    }
  },
  plugins: {
    legend: {
      labels: {
        color: "white", 
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: 'Rent Sqft Crosstabs',
      padding: {
        left: 10,
        right: 5,
        bottom: 10,
        top: 5
      },
      color: "white",
      font: {
        size: 14,
        weight: 'bold',
      },
      align: "center"
    }
  }
}

let sampleChartData = {
  labels: ["whateva"],
  datasets: [
    {
      label: 'dataset 1',
      data: [{ x: 350000, y: 10, r: 15}]
    }
  ]
};

function RentCards(props) {
  const appCtx = useContext(AppContext);
  const [benchmarks, setBenchmarks] = useState(sampleNationalData)
  const [stateBenchmarks, setStateBenchmarks] = useState(sampleStateData)
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});
  const [rentData, setRentData] = useState(sampleCountyData);
  const [selectedTab, setSelectedTab] = useState("table")
  const [rentSubsetData, setRentSubsetData] = useState({})
  const [selectedRentSubsetData, setSelectedRentSubsetData] = useState({})
  const [chartData, setChartData] = useState(sampleChartData)
  const [sqftData, setSqftData] = useState(sampleChartData)
  const [population, setPopulation] = useState("")
  const [populationFips, setPopulationFips] = useState("")
  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {

    if (isMapNonEmpty(props.selectedCounty) && props.selectedCounty.county_id !== selectedCounty.county_id) {
        setSelectedCounty(props.selectedCounty)
        setLoadingData(true)
    }

  }, [props.selectedCounty]);

  useEffect(() => {

    if (isMapNonEmpty(props.rentData) && isMapNonEmpty(props.rentData.county_rent_sums_total)) {
        setLoadingData(false)
        setRentData(props.rentData)
        //fetchCountySubsetData(props.selectedCounty)
    }

  }, [props.rentData]);

  useEffect(() => {
    if (isMapNonEmpty(props.benchmarks)) {
        setBenchmarks(props.benchmarks)
        //fetchCountySubsetData(props.selectedCounty)
    }

  }, [props.benchmarks]);

  useEffect(() => {
    if (isMapNonEmpty(props.stateBenchmarks)) {
        setStateBenchmarks(props.stateBenchmarks)
        //fetchCountySubsetData(props.selectedCounty)
    }

  }, [props.stateBenchmarks]);

  function filterSubsets(subsetData) {
    let result = {}
    for (let i in subsetData) {
      let item = subsetData[i]
      let filterBy = item["filter_by"]
      let divider = filterBy.indexOf('_')
      let type = filterBy.substring(0, divider)
      let numType = filterBy.substring(divider+1, filterBy.length)

      let numListings = item["num_listings"]
      let medianPrice = item["median_price"]
      let totalNumListings = parseInt(rentData.county_rent_sums_total.num_listings)

      /*let perPop = ((parseInt(numListings) / population) * 100000).toFixed(2)
      if (perPop < 1) {
        perPop = 1
      } else if (perPop > 15) {
        perPop = 15
      }*/
      let perPop = (((totalNumListings - numListings) / totalNumListings) * 20).toFixed(2)

      if (result[type] === undefined) {
        result[type] = []
      }

      let resultObj = {
        "x": removeNonNumeric(numType),
        "y": medianPrice,
        "r": perPop
      }

      result[type].push(resultObj)
    }

    return result
  }

  function setupChart(subsetData) {

    let subsetMap = filterSubsets(subsetData)

    let datasetsObj = {}
    datasetsObj["data"] = subsetMap["bed"]
    datasetsObj["backgroundColor"] = CHART_COLORS.redTransparent
    datasetsObj["borderColor"] = CHART_COLORS.redColor
    datasetsObj["label"] = "Number of Beds, Rent Price, % of Listings (normalized)"

    let datasetsObj2 = {}
    datasetsObj2["data"] = subsetMap["bath"]
    datasetsObj2["backgroundColor"] = CHART_COLORS.blueTransparent
    datasetsObj2["borderColor"] = CHART_COLORS.blueColor
    datasetsObj2["label"] = "Number of Baths, Rent Price, % of Listings (normalized)"

    const data = {
      labels: ["Rents by Number of Beds"],
      datasets: [datasetsObj, datasetsObj2]
    }

    setChartData(data)

    let datasetsObj3 = {}
    datasetsObj3["data"] = subsetMap["sqft"]
    datasetsObj3["backgroundColor"] = CHART_COLORS.greenTransparent
    datasetsObj3["borderColor"] = CHART_COLORS.greenColor
    datasetsObj3["label"] = "Sqft, Rent Price, % of Listings (normalized)"

    const data2 = {
      labels: ["Rents by Sqft"],
      datasets: [datasetsObj3]
    }
    setSqftData(data2)
}

  const fetchCountySubsetData = async (county) => {
    const type = "rent"
    const countyId = county.county_id
    if (countyId === undefined) {
      return
    }

    // already cached
    if (rentSubsetData[countyId] !== undefined)  {
      setSelectedRentSubsetData(rentSubsetData[countyId])
      setupChart(rentSubsetData[countyId])
  
    } else if (rentSubsetData[countyId] === undefined) {
      /*getCountySubsets(appCtx, countyId, "rent").then((responseObj) => {
  
        const data = responseObj.data
        if (data !== undefined && responseObj.meta === 200 && Object.keys(data).length !== 0) {
          rentSubsetData[countyId] = data
          setSelectedRentSubsetData(data)
          setupChart(data)
        }
      })*/
    }

    
  }


function grabStat(statName, dataSrc, subsetSrc, withCommas) {
  if (dataSrc !== undefined && dataSrc[subsetSrc] !== undefined) {
    let value = dataSrc[subsetSrc][statName]
    if (value !== undefined) {
      if (withCommas) {
        return numberWithCommas(value)
      } else {
        return value
      }
    }
  }
  return 0
}

function getAttrValues(label, attr, from) {
  if (from === undefined) {
    return
  }
  let response = ""
  let responseBare = ""
  if (from[attr] !== undefined || from[label] !== undefined) {

    if (label == "median_price" || label == "avg_price") {
      responseBare = parseInt(from[attr])
      response = "$" + numberWithCommas(responseBare)

    }
    if (label == "num_listings") {
      responseBare = parseInt(from[attr])
      response = numberWithCommas(responseBare)
    }
    
  }

  if (label == "listings_per_pop") {

    if (from[attr] === undefined && selectedCounty.fips !== populationFips) {
      responseBare = parseInt(from["num_listings"])
      let pop = grabPopulation(props.populationMap, selectedCounty.fips)
      if (pop !== undefined && pop.population !== undefined) {
        setPopulation(pop.population)
        setPopulationFips(selectedCounty.fips)
        let per_pop = ((responseBare / pop.population) * 100000).toFixed(2)
        response = numberWithCommas(per_pop)
      }
    } else if (selectedCounty.fips === populationFips) {
      responseBare = parseInt(from["num_listings"])
      let per_pop = ((responseBare / population) * 100000).toFixed(2)
      response = numberWithCommas(per_pop)
    } else {
      responseBare = parseFloat(from[attr])
      response = numberWithCommas(responseBare)
    }
  }

  return { str: response, num: responseBare}
}

function StatBubble(props) {

  let index = props.index
  let hideHeader = shouldHideHeader(index)
  let attr1 = props.attr1
  let attr2 = props.attr2

  let attr1Label = getAttrLabel(props.attr1)
  let attr2Label = getAttrLabel(props.attr2)

  let countyValue1 = getAttrValues(props.attr1, attr1, rentData.county_rent_sums_total)
  let countyValue2 = getAttrValues(props.attr2, attr2, rentData.county_rent_sums_total)

  let stateValue1 = getAttrValues(props.attr1, attr1, stateBenchmarks.data.benchmark_rent)
  let stateValue2 = getAttrValues(props.attr2, attr2, stateBenchmarks.data.benchmark_rent)

  let nationalValue1 = getAttrValues(props.attr1, attr1, benchmarks.benchmark_rent)
  let nationalValue2 = getAttrValues(props.attr2, attr2, benchmarks.benchmark_rent)

  let firstClass = "card card-background mb-3"
  let secondClass = "card card-background-alt mb-3"

  if (nationalValue1 === undefined || nationalValue2 === undefined || stateValue1 === undefined || stateValue2 == undefined
    || countyValue1 === undefined || countyValue2 === undefined) {
    return
  }

  if (selectedCounty.county !== null && rentData !== null && rentData.county_rent_sums_total !== undefined) {
    let bgColor = undefined
    if (isMapNonEmpty(selectedCounty.county)) {
      bgColor = getColorScale(selectedCounty, props.mapType, appCtx)
    }
    return (
      <Container>
        <Row className="mt-2">
          <Col md={6} lg={4}>
            <div className={firstClass}>
              <div style={{color:getFontColor(selectedCounty, bgColor), backgroundColor:bgColor}} hidden={hideHeader} className="card-header">{getCardTitle("county", selectedCounty)}      ({props.sliderDate})</div>
              <div className="card-body">
                <Row>
                  <div hidden={hideHeader}><h5  className="card-subheader text-centered">Rent</h5>
                  <hr className="separator" /></div>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{countyValue1.str}
                      <Indicator desired={props.desired} number={countyValue1.num} stateAvg={stateValue1.num} nationalAvg={nationalValue1.num} ></Indicator>
                    </p>
                    </Col>
                    <Col md={6} lg={6}>
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{countyValue2.str}
                      <Indicator desired={props.desired} number={countyValue2.num} stateAvg={stateValue2.num} nationalAvg={nationalValue2.num} ></Indicator>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className={secondClass}>
              <div hidden={hideHeader} className="card-header ch-alt">State Averages: {stateBenchmarks.state}      ({props.sliderDate})</div>
              <div className="card-body">
                <Row>
                  <div hidden={hideHeader}><h5  className="card-subheader text-centered">Rent</h5>
                  <hr className="separator" /></div>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{stateValue1.str}</p>
                  </Col>
                  <Col md={6} lg={6}>
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{stateValue2.str}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <div className={firstClass}>
              <div hidden={hideHeader} className="card-header ch-normal">National Averages      ({props.sliderDate})</div>
              <div className="card-body">
              <Row>
              <div hidden={hideHeader}><h5  className="card-subheader text-centered">Rent</h5>
                <hr className="separator" /></div>
                  <Col md={6} lg={6} className="spacer">
                    <h6 className="card-title">{attr1Label}</h6>
                    <p className="card-text">{nationalValue1.str}
                    </p>
                  </Col>
                  <Col md={6} lg={6}>
                    <h6 className="card-title">{attr2Label}</h6>
                    <p className="card-text">{nationalValue2.str}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

/*
<Row>
        <Col md={3} lg={1}>
          <Box p={1} display="block-inline">
            <ChartvsTableToggle selectedTab={selectedTab} setSelectedTab={setSelectedTab}></ChartvsTableToggle>
          </Box>
        </Col>
      </Row>
*/

  return (
    <Container key={"rents-"+JSON.stringify(selectedCounty)} hidden={selectedCounty == null || selectedCounty.county == null}>
      <div hidden={loadingData == true}>
        <Row>
          <Col md={12} lg={12}>
            <Container hidden={selectedTab === "table"}>
              <Row className="mt-2">
                <Col md={1} lg={1}></Col>
                <Col md={9} lg={9}>
                  <Bubble className="chart-centered" data={chartData} options={options}></Bubble>
                  <Bubble className="chart-centered" data={sqftData} options={options2}></Bubble>
                </Col>
              </Row>
            </Container>
            <Col hidden={selectedTab === "chart"}>
              <StatBubble mapType={props.mapType} sliderDate={props.sliderDate} desired={"lower"} score={props.score} attr1={"median_price"} attr2={"avg_price"} index={0}></StatBubble>
              <StatBubble mapType={props.mapType} sliderDate={props.sliderDate} desired={"higher"} score={props.score} attr1={"num_listings"} attr2={"listings_per_pop"} index={1}></StatBubble>
            </Col>
        </Col>
      </Row>
      </div>
      <Row hidden={loadingData == false}>
        <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
      </Row>

      </Container>
  );
}

export default memo(RentCards);