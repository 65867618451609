import { useState, useEffect, memo} from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import {getCardTitle, isMapNonEmpty, numberWithCommas, removeNonNumericExceptDot, safelyGrab } from '../../shared/common';
import { Radar } from "react-chartjs-2"; 
import Chart from 'chart.js/auto';

import { Col, Container, Row } from 'react-bootstrap';

let sampleCountyData = { "date":"2023-05-11","county_id":"","fire":"0","flood":"0","wind":"0","heat":"0" }
let sampleStateData = { "state": "", "data": { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}}
let sampleNationalData = { "benchmark_active": { "avg_price": "", "median_price": ""}, "benchmark_sold": { "avg_price": "", "median_price": "", "avg_days_on_market": "", "median_days_on_market": "", "num_listings": "", "listings_per_pop": ""}}
let sampleChartData = {
    datasets: [{
        data: [0, 0, 0, 0],
        label: 'Climate Risks (next 30 years)',
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
          'rgb(201, 203, 207)',
          'rgb(54, 162, 235)'
        ],
    }],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
        'Red',
        'Yellow',
        'Blue',
        'Green'
    ]
}

let options = {
    scale: {
      beginAtZero: true,
      max: 100,
      min: 0,
      stepSize: 20,
      backgroundColor: "transparent"
    },
    scales: {
      r: {
        pointLabels: {
          color: "white",
          font: {
            size: 14,
            weight: 'bold'
          }
        },
        ticks: {
          color: 'white',
          backdropColor: 'transparent'
        },
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Climate Risks',
        padding: {
          left: 10,
          right: 5,
          bottom: 10,
          top: 5
        },
        color: "white",
        font: {
          size: 14,
          weight: 'bold',
        },
        align: "center"
      },
      legend: {
        labels: {
          color: "white", 
          font: {
            size: 12
          }
        }
      }
      
    }
}

/*
scales: {
    y: {  
      ticks: {
        color: "white",
      }
    },
    x: {  
      ticks: {
        color: "white",
      }
    }
  },
  plugins: {
    legend: {
      labels: {
        color: "white", 
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: 'Rent Sqft Crosstabs',
      padding: {
        left: 10,
        right: 5,
        bottom: 10,
        top: 5
      },
      color: "white",
      font: {
        size: 14,
        weight: 'bold',
      },
      align: "center"
    }
  }
*/

function ClimateCards(props) {
  const [selectedCounty, setSelectedCounty] = useState({ county: null, county_id: null, state: null, fips: null});
  const [countyData, setCountyData] = useState(sampleCountyData);
  const [chartData, setChartData] = useState(sampleChartData)
  const [loadingData, setLoadingData] = useState(null)

  function setupRadial(climateData, stateBenchmarks, benchmarks, whichChart) {

    let color = 'rgba(255, 99, 132, 0.2)'
    let hoverColor = 'rgba(255, 99, 132)'

    let stateColor = 'rgb(75, 192, 192, 0.2)'
    let stateHoverColor = 'rgb(75, 192, 192)'

    let nationalColor = 'rgb(54, 162, 235, 0.2)'
    let nationalHoverColor = 'rgb(54, 162, 235)'

    const data = {
      labels: [
        'Fire',
        'Heat',
        'Drought',
        'Flood',
        'Storm'
      ],
      datasets: [{
        label: getCardTitle("county", selectedCounty),
        data: [
          removeNonNumericExceptDot(safelyGrab(climateData, "fire_score")),
          removeNonNumericExceptDot(safelyGrab(climateData, "heat_score")),
          removeNonNumericExceptDot(safelyGrab(climateData, "drought_score")),
          removeNonNumericExceptDot(safelyGrab(climateData, "flood_score")),
          removeNonNumericExceptDot(safelyGrab(climateData, "storm_score"))],
        backgroundColor: color,
        borderColor: hoverColor,
        pointBackgroundColor: hoverColor,
        fill: true
        //pointBorderColor: '#fff',
        //pointHoverBackgroundColor: '#fff',
        //pointHoverBorderColor: hoverColor
      },
      {
        label: selectedCounty.state + ' State Averages',
        data: [
          removeNonNumericExceptDot(safelyGrab(stateBenchmarks, "fire_score")),
          removeNonNumericExceptDot(safelyGrab(stateBenchmarks, "heat_score")),
          removeNonNumericExceptDot(safelyGrab(stateBenchmarks, "drought_score")),
          removeNonNumericExceptDot(safelyGrab(stateBenchmarks, "flood_score")),
          removeNonNumericExceptDot(safelyGrab(stateBenchmarks, "storm_score"))],
        backgroundColor: stateColor,
        borderColor: stateHoverColor,
        pointBackgroundColor: stateHoverColor,
        fill: true
        //pointBorderColor: '#fff',
        //pointHoverBackgroundColor: '#fff',
        //pointHoverBorderColor: hoverColor
      },
      {
        label: 'National Averages',
        data: [
          removeNonNumericExceptDot(safelyGrab(benchmarks, "fire_score")),
          removeNonNumericExceptDot(safelyGrab(benchmarks, "heat_score")),
          removeNonNumericExceptDot(safelyGrab(benchmarks, "drought_score")),
          removeNonNumericExceptDot(safelyGrab(benchmarks, "flood_score")),
          removeNonNumericExceptDot(safelyGrab(benchmarks, "storm_score"))],
        backgroundColor: nationalColor,
        borderColor: nationalHoverColor,
        pointBackgroundColor: nationalHoverColor,
        fill: true
        //pointBorderColor: '#fff',
        //pointHoverBackgroundColor: '#fff',
        //pointHoverBorderColor: hoverColor
      }]
    };
    if (whichChart == "county") {
      setChartData(data)
    } /*else if (whichChart == "state") {
      setStateChartData(data)
    } else if (whichChart == "national") {
      setNationalChartData(data)
    }*/

  }

  useEffect(() => {
    if (isMapNonEmpty(props.selectedCounty) && props.selectedCounty.county_id !== selectedCounty.county_id) {
      setLoadingData(true)
      setSelectedCounty(props.selectedCounty)
    }
 
  }, [props.selectedCounty]);

  useEffect(() => {
    if (props.climateRisks !== undefined && Object.keys(props.climateRisks).length > 0
      && props.stateBenchmarks !== undefined && props.stateBenchmarks.data !== undefined && props.stateBenchmarks.data.benchmark_climate !== undefined
      && props.benchmarks !== undefined && props.benchmarks.benchmark_climate !== undefined) {
        
      setLoadingData(false)
      setCountyData(props.climateRisks)
      //setStateBenchmarks(props.stateBenchmarks.data.benchmark_climate)
      //setNationalChartData(props.benchmarks.benchmark_climate)
      setupRadial(props.climateRisks, props.stateBenchmarks.data.benchmark_climate, props.benchmarks.benchmark_climate,  "county")
    }

  }, [props.climateRisks, props.stateBenchmarks, props.benchmarks]);

  function getAttrValues(attr, from) {
    if (from === undefined) {
      return
    }

    if (from[attr] !== undefined) {
      return from[attr]
    }
  }

  function getLabel(attr) {
    if (attr == "storm") {
      return "Storm"
    }
    if (attr == "fire") {
      return "Fire"
    }
    if (attr == "drought") {
      return "Drought"
    }
    if (attr == "heat") {
      return "Heat"
    }
    if (attr == "flood") {
      return "Flood"
    }
    if (attr == "annual_rain") {
      return "Annual rain"
    }
    if (attr =="num_storms") {
      return "Extreme Storms"
    }
    if (attr == "storm_dump") {
      return "Amount of rainfall per storm"
    }
    if (attr == "num_storms_2050") {
      return "2050: Storms"
    }
    if (attr == "storm_dump_2050") {
      return "2050: Amount of rainfall per storm"
    }
    if (attr == "annual_rain_2050") {
      return "2050: Annual rain"
    }
    if (attr == "num_fire_risk_days") {
      return "Extreme Fire Risk Days"
    }
    if (attr == "fire_risk_level") {
      return "Fire Risk Level"
    }
    if (attr == "burn_chance") {
      return "Burn Chance"
    }
    if (attr == "water_stress") {
      return "Water Stress"
    }
    if (attr == "percent_diff_from_state") {
      return "% Diff from State Avg"
    }
    if (attr == "hot_days_per_year") {
      return "Extreme heat days per year"
    }
    if (attr == "heat_index") {
      return "Extreme heat days reach this temperature"
    }
    if (attr == "storm_index") {
      return "Extreme storms rain this amount"
    }
    if (attr == "intensity") {
      return "Fire Intensity"
    }
    if (attr == "score") {
      return "Score"
    }
    if (attr == "chance_of_flood") {
      return "Chance of Flood"
    }
    if (attr == "num_flooding_days_2050") {
      return "Flood days in 2050"
    }
    if (attr == "flood_intensity") {
      return "Flood Intensity"
    }
    if (attr == "fema") {
      return "FEMA Info"
    }
  }

  function sortDetails(details, type) {
    let year = new Date().getFullYear()
    if (type == "storm") {
      return [ 
        { "attr": getLabel("score"), "both": countyData["storm_score"] + " / 100"  },
        { "attr": getLabel("storm_index"), "both": details["storm_index"] },
        { "attr": "", "present": year, "2050": "2050" }, 
        { "attr": "", "present": "", "2050": "" }, 
        { "attr": getLabel("annual_rain"), "present": details["annual_rain"], "2050": details["annual_rain_2050"] },
        { "attr": getLabel("num_storms"), "present": details["num_storms"],"2050": details["num_storms_2050"] },
        { "attr": getLabel("storm_dump"), "present": details["storm_dump"], "2050": details["storm_dump_2050"]},
        { "attr": " ", "both": " " }]
    }
    if (type == "fire") {
      if (details["num_fire_risk_days"] == undefined) {
        return [
          { "attr": getLabel("score"), "both": countyData["fire_score"] + " / 100"  },
          { "attr": getLabel("intensity"), "both": details["intensity"] }]
      }
      return [
        { "attr": getLabel("score"), "both": countyData["fire_score"] + " / 100"  },
        { "attr": getLabel("fire_risk_level"), "both": details["fire_risk_level"] },
        { "attr": getLabel("burn_chance"), "both": details["burn_chance"] },
        { "attr": "", "present": year, "2050": "2050" }, 
        { "attr": getLabel("num_fire_risk_days"), "present": details["num_fire_risk_days"], "2050": details["num_fire_risk_days_2050"] },
        { "attr": getLabel("intensity"), "both": details["intensity"] },
        ]
    }
    if (type == "drought") {
      return [
        { "attr": getLabel("score"), "both": countyData["drought_score"] + " / 100"  },
        { "attr": getLabel("percent_diff_from_state"), "both": details["percent_diff_from_state"] },
        { "attr": "", "present": year, "2050": "2050" }, 
        { "attr": "", "present": "", "2050": "" }, 
        { "attr": getLabel("water_stress"), "present": details["water_stress"], "2050": details["water_stress_2050"] },
        ]
    }
    if (type == "heat") {
      return [
        { "attr": getLabel("score"), "both": countyData["heat_score"] + " / 100" },
        { "attr": getLabel("heat_index"), "both": details["heat_index"] },
        { "attr": "", "present": year, "2050": "2050" }, 
        { "attr": "", "present": "", "": "2050" }, 
        { "attr": getLabel("hot_days_per_year"), "present": details["hot_days_per_year"], "2050": details["hot_days_per_year_2050"] },
        ]
    }
    if (type == "flood") {
      return [
        { "attr": getLabel("score"), "both": countyData["flood_score"] + " / 100" },
        { "attr": getLabel("chance_of_flood"), "both": details["chance_of_flood"] },
        { "attr": getLabel("num_flooding_days_2050"), "both": details["num_flooding_days_2050"]},
        { "attr": getLabel("flood_intensity"), "both": details["flood_intensity"] },
        { "attr": getLabel("fema"), "both": details["fema"] },
      ]
    }
  }

  function determineClasses(type, type2, type3) {
    let plainClass = "card card-background mb-3"
    let fireClass = "card card-background-fire mb-3"
    let heatClass = "card card-background-heat mb-3"
    let droughtClass = "card card-background-drought mb-3"
    let stormClass = "card card-background-storm mb-3"
    let floodClass = "card card-background-flood mb-3"
  
    let firstClass = plainClass
    let secondClass = plainClass
    let thirdClass = plainClass

    let headerClass = "card-header"
    let secondHeader = "card-header"
    let thirdHeader = "card-header"

    if (type == "heat") {
      firstClass = heatClass
      headerClass = "card-header card-header-heat"
    } else if (type == "fire") {
      firstClass = fireClass
      headerClass = "card-header card-header-fire"
    } else if (type == "drought") {
      firstClass = droughtClass
      headerClass = "card-header card-header-drought"
    } else if (type == "storm") {
      firstClass = stormClass
      headerClass = "card-header card-header-storm"
    } else if (type == "flood") {
      firstClass = floodClass
      headerClass = "card-header card-header-flood"
    }

    if (type2 == "heat") {
      secondClass = heatClass
      secondHeader = "card-header card-header-heat"
    } else if (type2 == "fire") {
      secondClass = fireClass
      secondHeader = "card-header card-header-fire"
    } else if (type2 == "drought") {
      secondClass = droughtClass
      secondHeader = "card-header card-header-drought"
    } else if (type2 == "storm") {
      secondClass = stormClass
      secondHeader = "card-header card-header-storm"
    } else if (type2 == "flood") {
      secondClass = floodClass
      secondHeader = "card-header card-header-flood"
    }
    
    if (type3 != null) {
      if (type3 == "heat") {
        thirdClass = heatClass
        thirdHeader = "card-header card-header-heat"
      } else if (type3 == "fire") {
        thirdClass = fireClass
        thirdHeader = "card-header card-header-fire"
      } else if (type3 == "drought") {
        thirdClass = droughtClass
        thirdHeader = "card-header card-header-drought"
      } else if (type3 == "storm") {
        thirdClass = stormClass
        thirdHeader = "card-header card-header-storm"
      } else if (type3 == "flood") {
        thirdClass = floodClass
        thirdHeader = "card-header card-header-flood"
      }
    }
    return {body1: firstClass, body2: secondClass, header1: headerClass, header2: secondHeader, header3: thirdHeader, body3: thirdClass }
  }
  
  function RiskBubble(props) {

  let index = props.index
  let hideHeader = index !== 0

  let type = props.type
  let type2 = props.type2
  let type3 = props.type3

  let details = type + "_details"
  let details2 = type2 + "_details"
  let details3 = type3 + "_details"

  let subMap = getAttrValues(details, countyData)
  let subMap2 = getAttrValues(details2, countyData)
  let subMap3 = getAttrValues(details3, countyData) // optional

  let hide3 = true
  if (subMap3 === undefined) {
    subMap3 = {}
    hide3 = true
  } else {
    hide3 = false
  }


  if (subMap === undefined || subMap === null || subMap2 == null || subMap2 === undefined) {
    return
  }

  let detailData = []
  let keys = Object.keys(subMap)
  for (let k in keys) {
    let key = keys[k]
    let details = subMap[key]
    detailData.push({ "key": key, "value": details })
  }
  detailData = sortDetails(subMap, type)

  let detailData2 = []
  let keys2 = Object.keys(subMap2)
  for (let k in keys2) {
    let key = keys2[k]
    let details = subMap2[key]
    detailData2.push({ "key": key, "value": details })
  }
  detailData2 = sortDetails(subMap2, type2)

  let detailData3 = []
  let keys3 = Object.keys(subMap3)
  for (let k in keys3) {
    let key = keys3[k]
    let details = subMap2[key]
    detailData3.push({ "key": key, "value": details })
  }
  detailData3 = sortDetails(subMap3, type3)
  if (detailData3 == undefined) {
    detailData3 = []
  }

  let classes = determineClasses(type, type2, type3)

  if (Object.keys(detailData).length > 0) {
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div className={classes.body1}>
              <div className={classes.header1}>{getLabel(type) + " Details"}</div>
              <div className="card-body">
                {detailData.map((row) => (
                  <Container>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <p className="card-title">{row.attr}</p>
                    </Col>
                    <Col hidden={row["both"] === undefined} xs={6} sm={6} md={6} lg={6}>
                      <p className="card-text">{row["both"]}</p>
                    </Col>
                    <Col hidden={row["both"] !== undefined} xs={3} sm={3} md={3} lg={3}>
                      <p className="card-text">{row["present"]}</p>
                    </Col>
                    <Col hidden={row["both"] !== undefined} xs={3} sm={3} md={3} lg={3}>
                      <p className="card-text">{row["2050"]}</p>
                    </Col>
                  </Row>
              </Container>
              ))}
              </div>
            </div>
            <div hidden={hide3} className={classes.body3}>
                <div className={classes.header3}>{getLabel(type3) + " Details"}</div>
                <div className="card-body">
                  {detailData3.map((row) => (
                    <Container>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <p className="card-title">{row.attr}</p>
                      </Col>
                      <Col hidden={row["both"] === undefined} xs={6} sm={6} md={6} lg={6}>
                        <p className="card-text">{row["both"]}</p>
                      </Col>
                      <Col hidden={row["both"] !== undefined} xs={3} sm={3} md={3} lg={3}>
                        <p className="card-text">{row["present"]}</p>
                      </Col>
                      <Col hidden={row["both"] !== undefined} xs={3} sm={3} md={3} lg={3}>
                        <p className="card-text">{row["2050"]}</p>
                      </Col>
                    </Row>
                </Container>
                ))}
                </div>
              </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
          <div hidden={!props.link} className={"card card-background card-background-green"}>
              <a target="_blank" className="card-body" href={countyData.link}> See Full Climate Risk Report</a>
            </div>
            <div className={classes.body2}>
              <div  className={classes.header2}>{getLabel(type2) + " Details"}</div>
              <div className="card-body">
                {detailData2.map((row) => (
                  <Container>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <p className="card-title">{row.attr}</p>
                    </Col>
                    <Col hidden={row["both"] === undefined} xs={6} sm={6} md={6} lg={6}>
                      <p className="card-text">{row["both"]}</p>
                    </Col>
                    <Col hidden={row["both"] !== undefined} xs={3} sm={3} md={3} lg={3}>
                      <p className="card-text">{row["present"]}</p>
                    </Col>
                    <Col hidden={row["both"] !== undefined} cs={3} sm={3} md={3} lg={3}>
                      <p className="card-text">{row["2050"]}</p>
                    </Col>
                  </Row>
              </Container>
              ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

  return (
    <Container key={"climate-"+JSON.stringify(selectedCounty)} hidden={selectedCounty == null || selectedCounty.county == null}>
      <div hidden={loadingData == true}>
      <Row className="mt-2">
      <Col md={12} lg={5}>
          <Radar options={options} id='county-risks-radar' data={chartData} />

        </Col>
        <Col md={12} lg={7}>
          <RiskBubble type={"fire"} type2={"storm"} link={true}></RiskBubble>
          <RiskBubble type={"drought"} type2={"heat"} type3={"flood"} link={false}></RiskBubble>
        </Col>
      </Row>
      </div>
      <Row hidden={loadingData == false}>
      <div key={"loader-statbubbles"} className="lds-ellipsis loader-centered"><div></div><div></div><div></div><div></div></div>
    </Row>
    </Container>
  );
}

export default memo(ClimateCards);