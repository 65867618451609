import React, { useState, useEffect, memo } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { numberWithCommas } from '../../shared/common';
import { Typography } from '@mui/material';
import ReactGA from "react-ga4"

function formatStopText(_, value) {
  return `${value}`;
}

const populationList = [{ "label": "10k", "value": "10000"}, { "label": "20k", "value": "20000" }, { "label": "50k", "value": "50000"}, { "label": "100k", "value": "100000"}, { "label": "200k", "value": "200000"}]

const PopulationSlider = (props) => {
    const [sliderStops, setSliderStops] = React.useState(populationList);
    const [currPop, setCurrPop] = React.useState(10000)

  function valueLabelFormat(value) {
      return numberWithCommas(value)
    }

  function selectionChanged(_, popStop) {
      if (popStop !== undefined && popStop !== props.popStop) {
        console.log('set slider pop: ' + JSON.stringify(popStop))
          props.setPopStop(popStop)

          ReactGA.event({
            category: 'Slider.Changed',
            action: 'PopSlider Changed',
            label: 'popslider.changed.'+popStop
          });
      }
  }

  function sliderChanged(_, value) {
    setCurrPop(numberWithCommas(value))
  }

  return (
    <Box sx={{ maxWidth: "500px", minWidth:"200px" }}>
      <Typography className='dark-primary' textAlign={"center"} id="non-linear-slider" gutterBottom>Min city population: {currPop}</Typography>
      <Slider
        className='dark-primary control'
        size="small" 
        defaultValue={10000}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={formatStopText}
        valueLabelDisplay="auto"
        min={10000}
        max={200000}
        onChange={sliderChanged}
        onChangeCommitted={selectionChanged}
      />
    </Box>
  );
}

export default memo(PopulationSlider);