
import React, { useState, useEffect, useContext, memo } from 'react';
import AppContext from '../../state/app-context';
import Box from '@mui/material/Box';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { getColorScale, getMapColors, SCORES_MAP, isCategeoryPaywalled, numberWithCommas, getStateCoords, getStateColorScale, fipsToCountyId, parseClimateDataV2, countyIdToFips, getAnalyticsName, calculateWeedScore, isMapNonEmpty } from '../../shared/common';
import { getAllStatesHeatmap, getStateHeatmap } from "../../service/thermostatservice";

import countiesMap from "../../input/usacounties.json";
import statesMap from "../../input/geographics.json";
import citiesMap from "../../input/USA_Major_Cities.json"
import stateIds from "../../input/allstates.json"
import { Button } from '@mui/material';
import { useAsyncValue } from 'react-router-dom';
import ReactGA from "react-ga4"

const coordMap = {}

const defaultZoom = { 
  center: [0,0],
  zoom: 1,
  bypass: false }

const defaultC = [0,0]
const defaultZ = 1
const defaultScale = 1400

const hoverOn =
  {
    opacity: ".5",
    outline: "none",
    fill: "#1e1e1e",
  }

const hoverOff =
  {
    outline: "none",
  }

const MODES = {national: "national", state: "state"}

function formatDate (date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance')
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

function StateMapChart(props) {
  const appCtx = useContext(AppContext);
  const [stateIdMap, setStateIdMap] = useState({})
  const [reload, setReload] = useState(Date.now())
  const [data, setData] = useState([]);
  const [scale, setScale] = useState(defaultScale)
  const [zoom, setZoom] = useState(defaultZ)
  const [center, setCenter] = useState(defaultC)
  const [context, setContext] = useState({ "typedState": null, "mapMode": MODES.national, "mapType": null})
  const [size, setSize] = useState({ "width": 1200, "height": 780})
  const [mapMode, setMapMode] = useState({ mode: props.mapModeTab, state: props.typedState})
  const [nationalData, setNationalData] = useState([])
  const [nationalTypeMap, setNationalTypeMap] = useState({})
  const [statesTypeMap, setStatesTypeMap] = useState({})
  const [selectedGeo, setSelectedGeo] = useState(isMapNonEmpty(appCtx.selectedGeo)? appCtx.selectedGeo: null)
  const [geoModeTab, setGeoModeTab] = useState("")
  const [mapData, setMapData] = useState({})
  const [dataHasArrived, setDataHasArrived] = useState("")

  function setTooltip(label, curr) {
    props.setTooltipContent({ "label": label, "data": curr });
  }

  const getGeography = () => {
    if (mapMode.mode === MODES.state && props.geoModeTab === "county") {
      return countiesMap
    } else if (mapMode.mode !== MODES.state && props.geoModeTab == "county") {
      return statesMap
    } else if (props.geoModeTab == "city") {
      return statesMap
    } else {
      return countiesMap
    }
  }

  const setToCountryMode = () => {
    setReload(Date.now())
    props.setTypedState({"label": "USA", "val": ""})
    setMapMode({ mode: MODES.national, state: "USA" })
    setData(nationalData)
    setSelectedGeo(null)
    setDataHasArrived(props.mapType)
    //props.setGeoContent(null)
    //setSelectedGeo(null)
    setScale(defaultScale)
    setZoom(defaultZ)
    setCenter([0,0])
    setContext({"geoMode": geoModeTab, "sliderDate": props.sliderDate, "typedState": "USA", "mapMode": MODES.national, "mapType": props.mapType, "locked": false})
  }

  function setToNationalMode() {
    setMapMode({ mode: MODES.national, state: "USA" })
    setData(nationalData)
    //setSelectedGeo(null)
    
    //setDataHasArrived(props.mapType)
  }

  const getStateData = async (date, abbrev, geoMode, coords) => {
    if (abbrev === undefined || abbrev === null) {
      context["locked"] = false
      return
    }

    let mapType = SCORES_MAP[props.mapType]

    // past data comes in a big clump, save that clump and access innnards as needed
    if (props.mapCategory == "past" && props.mapType !== "past_trend") {
      mapType = "past_diff_"+props.numMonths + "_score"
    }

    if (statesTypeMap[date] == undefined) {
      statesTypeMap[date] = {}
    }

    if (statesTypeMap[date][geoMode] == undefined) {
      statesTypeMap[date][geoMode] = {}
    }

    if (statesTypeMap[date][geoMode][abbrev] === undefined) {
      statesTypeMap[date][geoMode][abbrev] = {}
    }

    // first call - setup
    if (statesTypeMap[date][geoMode][abbrev][mapType] === undefined || statesTypeMap[date][geoMode][abbrev][mapType].length < 1) {
      statesTypeMap[date][geoMode][abbrev][mapType] = []
    } else {
      // do we have it cached
      setData(statesTypeMap[date][geoMode][abbrev][mapType])
      setDataHasArrived(props.mapType)
      setContext({"geoMode": geoMode, "sliderDate": date, "typedState": abbrev, "mapMode": MODES.state, "mapType": mapType, "locked": false})
      setMapMode({ mode: MODES.state, state: abbrev})
      if (coords !== null) {
        setCenter([coords.x, coords.y])
        setZoom((coords.zoom / 2))
        setScale(2500)
      }
      return
    }

    // dont fetch if paywalled
    if (isCategeoryPaywalled(appCtx, props.mapCategory)) {
      //setDataHasArrived(props.mapType)
      setContext({ "geoMode": geoMode, "sliderDate": date, "typedState": abbrev, "mapMode": MODES.state, "mapType": mapType, "locked": false})
      return
    }

    // fetch if we dont have already
    const response = await getStateHeatmap(appCtx, date, abbrev, mapType, geoMode)

    if (response.meta == 200 && response.data !== undefined) {
      const data = response.data
      let valueArray = Object.values(data)

      let mapType = SCORES_MAP[props.mapType]

      // past data comes in a big clump, save that clump and access innnards as needed

      if (props.mapCategory == "past" && props.mapType != "past_trend") {
        mapType = "past_diff_"+props.numMonths + "_score"
      }

      statesTypeMap[date][geoMode][abbrev][mapType] = valueArray
      setData(valueArray)
      setDataHasArrived(props.mapType)
      setContext({ "geoMode": geoMode, "sliderDate": date, "typedState": abbrev, "mapMode": MODES.state, "mapType": mapType, "locked": false})
      setMapMode({ mode: MODES.state, state: abbrev})
      if (coords !== null) {
        setCenter([coords.x, coords.y])
        setZoom((coords.zoom / 2))
        setScale(2500)
      }

      
    } else {
      context["locked"] = false
    }
  }

  const handleCityClick = (curr) => {
    // add county to curr
    if (curr.county_id === undefined && curr.fips !== undefined) {
      let countyInfo = fipsToCountyId(curr.fips)
      if (countyInfo !== null && countyInfo !== undefined) {
        curr.county = countyInfo.county
        curr.state = countyInfo.state
        curr.county_id = countyInfo.county_id
      }
    }
    props.setGeoContent(curr)
    setSelectedGeo(curr)

    ReactGA.event({
      category: 'Map.Clicks',
      action: 'State City Click',
      label: 'state.city.' + curr.city_id,
    });
  };

  const handleGeographyClick = (geography, curr) => {

    context["locked"] = true

    if (mapMode.mode === MODES.state && curr !== undefined) {

      // curr: {"county_id":"2045","type":"all","score":"5.67","fips":"37077","date":"2023-04-25","value":"0","county":"","state":""}
      let countyObj = { score: curr.score, value: curr.value, county_id: curr.county_id, county: geography.properties.name + "-County", state: mapMode.state }
      let fipsResp = countyIdToFips(curr.county_id)
      countyObj.fips = fipsResp.fips
      //curr.fips = fipsResp.fips
      props.setGeoContent(countyObj)
      setSelectedGeo(curr)

      ReactGA.event({
        category: 'Map.Clicks',
        action: 'State County Click',
        label: 'state.county.' + curr.county_id,
      });

      context["locked"] = false

    } else if (mapMode.mode === MODES.national) {
 
      const abbrev = geography.properties.abbr
      const stateId = geography.id
      const stateObj = { label: abbrev, val: stateId }
      //setMapMode({ "mode": MODES.state, "state": abbrev})
      props.setTypedState(stateObj)

      const coords = getStateCoords(abbrev)

      //setZoom((coords.zoom / 3) * 2)
      //setCenter([coords.x, coords.y])

      getStateData(props.sliderDate, abbrev, props.geoModeTab, coords)

      ReactGA.event({
        category: 'Map.Clicks',
        action: 'State Click',
        label: 'state.' + stateId,
      });
    }
  };

  function setupStatesMap() {
    if (Object.keys(stateIdMap).length < 1) {
      for (let s in stateIds) {
        const stateObj = stateIds[s]
        const abbr = stateObj["id"]
        const id = stateObj["val"]
        stateIdMap[abbr] = id
      }
    }
  }

  function dissectStaticMaps(mapType, data, date) {
    let score, value = null

    let stateMap = nationalTypeMap[date]
    if (!isMapNonEmpty(stateMap)) {
      stateMap = {}
    }
    for (let i =0; i < data.length; i++) {
      let entry = data[i]
      let state = entry["state"]

      if (mapType == "weed") {

        let weedScore = calculateWeedScore(entry["status"], entry["medical"], entry["decriminalized"])
        score = weedScore["status"]
        value = weedScore["score"]
      
      } else if(mapType == "abortion") {

        let status = entry["status"]
        let numWeeks = entry["num_weeks"]
        let numWeeksStr = entry["legal_until"]

        if (numWeeksStr == "never") {
          numWeeksStr = "Illegal"
        }
        if (numWeeksStr == "no gestational limit") {
          numWeeksStr = "No Limits"
        }
        if (numWeeksStr == "gestational limit") {
          numWeeksStr = "Legal with limits"
        }

        value = parseInt(numWeeks)
        score = status + " (" + numWeeksStr + ")"
      
      } else if (mapType == "lgbtq") {
       value = entry["equality_index"]
       score = entry["legal_index"] 
      }

      let newEntry = {
        "score": score,
        "value": value,
        "state_id":stateIdMap[state],
        "state": state,
      }

      if (stateMap[mapType] == undefined) {
        stateMap[mapType] = []
      }
      stateMap[mapType].push(newEntry)
    }
    nationalTypeMap[date] = stateMap
    setNationalData(stateMap[mapType])
  }

  function dissectDynamicMaps(mapType, data, date) {
    // go through and sort it so its
      // map[type] = [{"state": "AL, "state_id": 01, "score": "12312", "value": "2132"}, {..} ... ]
      // then upon combobox change we just load whichever array is at map[type] into national data
      const stateMap = {}
      for (let i =0; i < data.length; i++) {
        let entry = data[i]
        let type = entry["type"]
        let state = entry["county_id"]
        let v = entry["value"]
        let score = entry["score"]

        if (type === "all" || type === "avg_days_on_market" || type === "median_days_on_market") {
          if (score > 10000) {
            score = score / 10000
          }
        } else if (type === "avg_price_drop") {
          score = score / 10
        } else if (type === "climate_risk_factors") {
          score = parseClimateDataV2(score)
        }

        let newEntry = {
          "score": score,
          "value": v,
          "state_id":stateIdMap[state],
          "state": state,
        }

        if (stateMap[type] == undefined) {
          stateMap[type] = []
        }
        stateMap[type].push(newEntry)
      }
      nationalTypeMap[date] = stateMap
      setNationalData(stateMap[mapType])
  }

  async function fetchNationalMapData(date, mapType) {

    // fetch national data for first time and cache it in a map

    if (date == undefined || date == "") {
      date = "today"
    }

    let scoreType = SCORES_MAP[mapType]

    const response = await getAllStatesHeatmap(appCtx, scoreType, date)

    if (response.meta == 200 && response.data !== undefined) {
      let data = response.data

      if (mapType == "weed" || mapType == "abortion" || mapType == "lgbtq") {
        // dissect differently
        dissectStaticMaps(mapType, data, date)
      } else {
        // existing
        dissectDynamicMaps(mapType, data, date)
      }
    }
  }

  async function grabNationalData(date, mapType) {
      // already fetched once and saved, just set
      if (isMapNonEmpty(nationalTypeMap) && isMapNonEmpty(nationalTypeMap[date]) && isMapNonEmpty(nationalTypeMap[date][mapType])) {
        setNationalData(nationalTypeMap[date][mapType])
        context["locked"] = false
      } else {
        await fetchNationalMapData(date, mapType)
        context["locked"] = false
    }
  }

  async function reloadMap(sliderDate, state, mapMode, mapType, geoModeTab) {

    // set to national mode if national map selected in combobox - National Mode
    if (mapMode.mode == MODES.national || (state !== null && (state.label === "USA" || state.label == ""))) {
      context["locked"] = true
      setToNationalMode()
      //props.setTypedState({"label": "", "val": ""})

      // setup if needed
      setupStatesMap()
      await grabNationalData(sliderDate, mapType)
      setContext({ "sliderDate": sliderDate, "typedState": state, "mapMode": mapMode.mode, "mapType": mapType, "locked": false})

    } else if (state !== null && state.val !== "") {
      grabStateData(sliderDate, state, geoModeTab)
    }
  }

  function grabStateData(sliderDate, state, geoModeTab) {
    context["locked"] = true

    //if (state.label == "") {
    //  state.label = "USA"
    //}
      // problem is somewher around here... normally states mode is USA as state label, but when switching map types it somehow turns to ""
      setMapMode({ mode: MODES.state, state: state.label})
      props.setTypedState(state)
      
      const coords = getStateCoords(state.label)
      // un comment if we like the state zoom
    /*if (coords !== null) {
        setZoom((coords.zoom / 3) * 2)
        setCenter([coords.x, coords.y])
      }*/
      getStateData(sliderDate, state.label, geoModeTab, coords)
    }

  useEffect(() => {

    if (context.locked == true && context.mapType !== null) {
      return
    }

    if (context.sliderDate == props.sliderDate && context.typedState.val == props.typedState.val && context.mapType == props.mapType) {
      return
    }

    reloadMap(props.sliderDate, props.typedState, mapMode, props.mapType, props.geoModeTab)

  }, [props.sliderDate, props.mapType, props.numMonths, props.mapCategory]);

  useEffect(() => {

    if (context.locked == true) {
      return
    }

    // if the selected state is changed to national we should 
    if (props.typedState.label === "USA" || props.typedState.label == "") {
        //if (center !== null && center !== undefined && center[0] != defaultC[0]) {
          //setCenter(defaultC)
        //}
    } else {

      grabStateData(props.sliderDate, props.typedState, props.geoModeTab)
    }

  }, [props.typedState.label]);

  useEffect(() => {
    if (props.geoModeTab !== null && props.geoModeTab !== undefined && geoModeTab !== props.geoModeTab) {
      setGeoModeTab(props.geoModeTab)
      reloadMap(props.sliderDate, props.typedState, mapMode, props.mapType, props.geoModeTab)
    }

  }, [props.geoModeTab])

  useEffect(() => {

    if (props.mapData !== undefined && props.mapData != null && Object.keys(props.mapData).length > 0) {
      setMapData(props.mapData)
    }

  }, [props.mapData]);

  useEffect(() => {

    if (isMapNonEmpty(props.geoContent !== undefined)) {
      setSelectedGeo(props.geoContent)
   }

  }, [props.geoContent]);

  useEffect(() => {
    if (appCtx.selectedGeo !== null) {

      if (!isMapNonEmpty(selectedGeo) || (appCtx.selectedGeo == null || (appCtx.selectedGeo !== null && appCtx.selectedGeo["fips"] !== selectedGeo["fips"]))) {
        setSelectedGeo(appCtx.selectedGeo)
      }
    }
  }, [appCtx.selectedGeo]);

  function setupTooltip(curr, mapMode, geo, geoMode) {
    // statics
    if (props.mapType === "abortion" || props.mapType === "weed" || props.mapType === "lgbtq") {
      setTooltip(geo.properties.name + ": " + curr.score);

    } else {

      if (geoMode == "county") {
        if (mapMode.mode === MODES.state) {
          if (props.mapCategory == "past" && props.mapType !== "past_trend") {
            let scoreObj = curr["score"]
            if (scoreObj[props.mapType] != undefined) {
              let score = scoreObj[props.mapType]
              setTooltip(geo.properties.name + ": " + score + "%", curr)
            }
          }
          else if (props.mapType === "all") {
            setTooltip(geo.properties.name + ": " + numberWithCommas(parseInt(curr.score)), curr);

          } else if (props.mapType == "str_or") {
            setTooltip(geo.properties.name + ": " + numberWithCommas(parseInt(curr.value*100) + "%"), curr);

          } else if (props.mapType == "climate_risk_factors") {
            setTooltip(geo.properties.name + ": " + parseClimateDataV2(curr.value), curr);

          } else if (props.mapType == "past_trend") {
            setTooltip(geo.properties.name + ": " + numberWithCommas(parseInt(curr.score)), curr);
          
          } else if (props.mapType === "cost_of_living" && curr.score != undefined && curr.score.overall) {
            setTooltip(geo.properties.name  + ": " + numberWithCommas(parseInt(curr.score.overall)), curr);

          } else if (props.mapType === "violent_crime" && curr.score != undefined && curr.score.violent_crime_rate) {
            setTooltip(geo.properties.name  + ": " + numberWithCommas(parseFloat(curr.score.violent_crime_rate)), curr);

          } else if (props.mapType === "property_crime" && curr.score != undefined && curr.score.property_crime_rate) {
            setTooltip(geo.properties.name  + ": " + numberWithCommas(parseFloat(curr.score.property_crime_rate)), curr);

          } else if (props.mapType === "sunny_days" && curr.score != undefined && curr.score.sunny_days) {
            setTooltip(geo.properties.name  + ": " + numberWithCommas(parseInt(curr.score.sunny_days)), curr);

          } else if (props.mapType === "snow" && curr.score != undefined && curr.score.snowfall) {
            setTooltip(geo.properties.name  + ": " + numberWithCommas(parseFloat(curr.score.snowfall)), curr);

          } else if (props.mapType === "rain" && curr.score != undefined && curr.score.rainfall) {
            setTooltip(geo.properties.name  + ": " + numberWithCommas(parseFloat(curr.score.rainfall)), curr);

          } else if (props.mapType === "elevation" && curr.score != undefined && curr.score.elevation) {
            setTooltip(geo.properties.name  + ": " + numberWithCommas(parseInt(curr.score.elevation)), curr);

          } else {
            setTooltip(geo.properties.name + ": " + numberWithCommas(parseInt(curr.value)), curr);
          }
        } else if (mapMode.mode === MODES.national) {
          setTooltip(curr.state + ' score: ' + numberWithCommas(parseInt(curr.score)), curr);
          if (props.mapType === "all") {
            setTooltip(curr.state + ' score: ' + numberWithCommas(parseInt(curr.score)), curr);
          } else if (props.mapType == "str_or") {
            setTooltip(curr.state + ': ' + numberWithCommas(parseInt(curr.value*100) + "%"), curr);
          } else if (props.mapType == "climate_risk_factors") {
            setTooltip(curr.state + ': ' + parseClimateDataV2(curr.value), curr);
          } else if (props.mapType == "past_trend") {
            setTooltip(curr.state + ': ' + numberWithCommas(parseInt(curr.score)), curr);
          } else {
            setTooltip(curr.state + ': ' + numberWithCommas(parseInt(curr.value)), curr);
          }
        } 
      } else if (geoMode == "city") {
        if (props.mapType === "all" || props.mapType == "past_trend") {
          setTooltip(curr.city_name + ', ' + curr.state + ': ' + curr.score, curr);

        } else if (props.mapCategory == "past" && props.mapType !== "past_trend") {
            let scoreObj = curr["score"]
            if (scoreObj[props.mapType] != undefined) {
              let score = scoreObj[props.mapType]
              setTooltip(curr.city_name + ": " + score + "%", curr)
            }
        } else {
          setTooltip(curr.city_name + ', ' + curr.state + ': ' + numberWithCommas(parseInt(curr.value)), curr);
        }
      }
    }
  }

  function isStateMode(mapMode) {
    return (mapMode.mode === MODES.state && props.typedState !== null && props.typedState !== undefined
       && props.typedState.label !== "USA" && props.typedState.label !== "")
  }

  function assignColorScale(mode, curr) {
    if (isStateMode(mode)) {
      return getMapColors(false, curr, props.mapType, dataHasArrived, appCtx, "state", props.mapCategory)
    } else {
      return getStateColorScale(curr, props.mapType, appCtx, "national", props.mapCategory)
    }
  }

  function addCitiesGeo(geo, geoModeTab) {
    if (mapData === undefined || Object.keys(mapData).length == 0) {
      return
    }

    let showCity = false
    let cityName = geo.properties.ST + "_" + geo.properties.NAME
    let curr = mapData.find(s => s.city_id === cityName);
    let population = geo.properties.POPULATION
    let popLimit = (props.popStop !== undefined) ? props.popStop: 10000
    let selected = ""
  
    if (curr != null && selectedGeo != null) {
      selected = curr.city_id === selectedGeo.city_id;
    }

    if (parseInt(population) >= parseInt(popLimit)) {
      showCity = true
    }
    

    let render = true
    if (isStateMode(mapMode)) {
      // only render if matches state
      render = geo.properties.ST === props.typedState.label
    }

    if (render && (curr !== undefined && curr !== null && showCity)) {
      return (
        <Geography
          key={geo.rsmKey}
          geography={geo}
          stroke="#FFF"
          strokeWidth={.25}
          onClick={() => {
            if (curr != null) {
              handleCityClick(curr)
            }
          }}
          onMouseEnter={() => {
            if (curr != null) {
              setupTooltip(curr, mapMode, geo, "city")
            }
          }}
          onMouseLeave={() => {
            setTooltip("", curr);
          }}
          style={{
            default: {
              outline: 'none'
            },
            hover: {
              opacity: ".5",
              outline: "none",
              fill: "#1e1e1e",
            },
            pressed: {
              opacity: ".5",
              outline: "none",
              fill: "#F53",
            },
          }}
          fill={selected ? "#202639" : curr ? assignColorScale(mapMode, curr)  : "#D3D3D3"}
        ></Geography>
      );
    }
  }

  function addCitiesMap(geoMode) {
    if (geoMode == "county") {
      return
    } else if (geoMode == "city") {
      return (
          <Geographies key={"geo-cities-overlay-"+geoMode+props.popStop } geography={citiesMap} >
            {({ geographies }) =>
              geographies.map(geo => {
                return addCitiesGeo(geo, props.geoModeTab)
              })
            }
          </Geographies>
      )
    }
  }

  function getHover(mode) {
    if (mode == MODES.national || (mode == MODES.state && props.geoModeTab === "county")) {
      return hoverOn;
    } else {
      return hoverOff
    }
  }

  function doesFipsMatch(curr, selectedGeoFips) {
    if (curr != null && selectedGeoFips != null) {
      if (curr.fips == selectedGeoFips) {
        return true
      }
      if (curr.fips == "0" + selectedGeoFips) {
        return true
      }
    }
    return false
  }

  function addMainMap() {
    return (
        <Geographies key={"geo-"+mapMode.mode } geography={getGeography()} >
          {({ geographies }) =>
            geographies.map(geo => {
              let curr = null
              let render = true
              let fips = ""
              let state_id = ""
              fips = geo.id
              state_id = fips.slice(0, 2)
              let selected = ""

              if (isStateMode(mapMode)) {
                // state mode
                render = state_id === props.typedState.val
                curr = data.find(s =>  s.fips === geo.id);

                if (curr != null && selectedGeo != null) {
                  selected = doesFipsMatch(curr, selectedGeo.fips);
                  if (selected && doesFipsMatch(curr, props.geoContent.fips)) {
                    props.setGeoContent(curr) // set to curr so we can get the score and value, lines up with cards
                  }
                }

              } else if (nationalData != undefined && nationalData != null && nationalData.length > 0) {
                // national mode
                curr = nationalData.find(s =>  s.state_id === state_id );
              }

              if (render) {
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke="#121212"
                    strokeWidth={.25}
                    onClick={() => {handleGeographyClick(geo, curr)}}
                    onMouseEnter={() => {
                      if (curr != null) {
                        setupTooltip(curr, mapMode, geo, "county")
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltip("", curr);
                    }}
                    style={{
                      default: {
                        outline: 'none'
                      },
                      hover: getHover(mapMode.mode),
                      pressed: {
                        opacity: ".5",
                        outline: "none",
                        fill: "#2d2d30",
                      },
                    }}
                    fill={selected ? "#1e1e1e" : curr ? assignColorScale(mapMode, curr)  : "#D3D3D3"}
                  ></Geography>
                );
              };
            })
          }
        </Geographies>
    )
  }

  return (
    <div>
      <Button hidden={mapMode.mode === MODES.national} className="showallstates" onClick={()=> setToCountryMode()}>Show all States</Button>

      <Box alignItems="center" display="inline">
      <div data-tip="">
        <ComposableMap className="map-wrapper" width={size.width} height={size.height} projection="geoAlbersUsa" projectionConfig={{ scale: scale }}>
          <ZoomableGroup key={"zg-statemap-"+reload} center={center} zoom={zoom}>
            {addMainMap()}
            {addCitiesMap(props.geoModeTab)}
          </ZoomableGroup>
        </ComposableMap>
      </div>
      </Box>
    </div>
  );
}

export default memo(StateMapChart);