import React, { useState, useEffect, memo, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './DownloadsPage.css';
import { CATEGORY_SUBSET_MAP, formatDate, getAnalyticsName, isInScope, isMobile, SCORES_MAP } from '../shared/common';
import AppContext from '../state/app-context';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const dayjs = require('dayjs')
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import MapTypeComboBox from '../componets/Controls/MapTypeComboBox';
import { Box, Button } from '@mui/material';
import { downloadCsv } from "../service/thermostatservice"
import StateComboBox from '../componets/Controls/StateComboBox';
import CountyVsCityToggle from '../componets/Controls/CountyVsCityToggle';
import NationalvsStateToggle from '../componets/Controls/NationalvsStateToggle';
import ReactGA from "react-ga4"

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";
import MapCategoryComboBox from '../componets/Controls/MapCategoryComboBox';
import { Box2 } from 'react-bootstrap-icons';
import MonthRadioButtons from '../componets/Controls/MonthRadioButtons';
import ListingDealsCombobox from '../componets/Watchlist/ListingDealsCombobox';
import SubsetsCombobox from '../componets/Watchlist/SubsetsCombobox';


function DownloadsPage() {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();
  const [mapType, setMapType] = useState("median_price");
  const [typedState, setTypedState] = useState({"label": "USA", "val": ""});
  const [selectedDate, setSelectedDate] = useState(null)
  const [geoModeTab, setGeoModeTab] = useState("county")
  const [mapModeTab, setMapModeTab] = useState("national")
  const [mapCategory, setMapCategory] = useState("active")
  const [numMonths, setNumMonths] = useState(mapCategory == "short_term_rental"? "avg": "1m")
  const [currSort, setCurrSort] = useState("all");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);

  function downloadCsvClicked() {
    let day = null
    if (selectedDate == "" || selectedDate == undefined || selectedDate == null) {
      day = formatDate(new Date())
      setSelectedDate(dayjs(formatDate(new Date())))
    } else {
      day = formatDate(new Date(selectedDate))
    }
    callDownloadCsv(mapType, day)
  }

  function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

  async function callDownloadCsv(mapType, date) {
    let mapModeKey = mapModeTab
    if (mapModeTab !== "national" && typedState.label !== "USA") {
      mapModeKey = typedState.label
    }


    let scoreName = SCORES_MAP[mapType]

    if (mapCategory == "past" && mapType !== "past_trend") {
      scoreName = "past_diff_" + numMonths + "_score"
      mapType = "past_diff_" + numMonths
    }

    if (currSort != "all") {
      let sortToUse = currSort
      sortToUse = sortToUse.replace("<", "")
      sortToUse = sortToUse.replace(">", "")
      scoreName = mapCategory + "_" + currSort + ""
      mapModeKey = "subsets"
    }

    if (mapCategory == "short_term_rental") {
      scoreName = "str_" + currSort
    }

    ReactGA.event({
      category: 'Download.CSV.Clicked',
      action: 'Download CSV Clicked',
      label: 'download.csv.clicked'
    });

    let csvResp = await downloadCsv(appCtx, geoModeTab, scoreName, [date], mapModeKey)

    let geoMode = geoModeTab
    if (mapModeKey == "state") {
      mapType = "all"
      geoMode = ""
    }

    ReactGA.event({
      category: 'Downloaded.CSV',
      action: 'Downloaded CSV ' +  mapType + '.' + mapModeKey,
      label: 'downloaded.csv.'+mapType +'.' + mapModeKey + '.' + date
    });

    downloadFile(csvResp, mapType, mapModeKey, geoMode, date, scoreName)
  }

  const downloadFile = (response, mapType, mapModeKey, geoModeTab, date, scoreName) => {
    let scoreToUse = mapType
    if (mapModeKey == "subsets") {
      scoreToUse = scoreName
    }

    let filename = date + '_' + mapModeKey + "_" +geoModeTab + "_" + scoreToUse + '.csv'

    const url = window.URL.createObjectURL(new Blob([response]))

    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  function displayStateComboBox(modeTab) {
    if (modeTab === "national") {
      return "hidden"
    } else if (modeTab == "state") {
      return "visible"
    }
  }

  function viewSubOptions() {
    return (
      navigate("/premium", { replace: false })
    )
  }

  function doesHaveSubsets(mapCat) {
    if (geoModeTab == "city" || mapModeTab !== "national") {
      return false
    }
    if (CATEGORY_SUBSET_MAP[mapCat] == undefined) {
      return false
    } else {
      return true
    }
  }

  return (
    <Container className="downloads-datepicker">
      <Row>
        <Col lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker 
              label="Download data for date" 
              value={selectedDate}
              format="YYYY-MM-DD-MM"
              slotProps={{
                actionBar: {
                  actions: ['today'],
                }
              }}
              defaultValue={dayjs(formatDate(new Date()))}
              minDate={dayjs(formatDate(new Date("2023-04-20")))}
              disableFuture={true}
              onChange={(newValue) => setSelectedDate(formatDate(new Date(newValue)))} >
            </StaticDatePicker>
        </LocalizationProvider>
      </Col>
      <Col lg={3}>
      <Box marginTop="5%" marginLeft="5%" marginRight="5%" className="center" >
        <MapCategoryComboBox className="rankings-maptype" currItem={mapCategory} typedState={typedState} mapModeTab={"national"} geoModeTab={geoModeTab} setMapCategory={setMapCategory}></MapCategoryComboBox>
        </Box>
        <Box marginTop="5%" marginLeft="5%" marginRight="5%" className="center" >
          <MapTypeComboBox mapType={mapType} mapCategory={mapCategory} geoModeTab={geoModeTab} typedState={typedState} mapModeTab={mapModeTab} setMapType={setMapType}></MapTypeComboBox>
        </Box>
        <Box marginTop="5%" marginLeft="5%" marginRight="5%" className="center" hidden={mapCategory != "past"}>
            <MonthRadioButtons setNumMonths={setNumMonths} mapCategory={mapCategory}></MonthRadioButtons>
        </Box>
        <Box marginTop="5%" marginLeft="5%" marginRight="5%" className="center" >
          <CountyVsCityToggle setGeoModeTab={setGeoModeTab}></CountyVsCityToggle>
        </Box>
        <Box marginTop="5%" marginLeft="5%" marginRight="5%" className="center" >
          <NationalvsStateToggle startToggle={"national"} setMapModeTab={setMapModeTab}></NationalvsStateToggle>
        </Box>
        <Box marginTop="5%" marginLeft="5%" marginRight="5%" className="center" height={mapModeTab === "national" ? 0 : "auto"} visibility={displayStateComboBox(mapModeTab)}>
          <StateComboBox width="10%" display="inline" typedState={typedState} setTypedState={setTypedState} ></StateComboBox>
        </Box>
        <Box marginTop="5%" marginLeft="5%" marginRight="5%" className="center" hidden={!doesHaveSubsets(mapCategory)} >
        <p className="dark-primary">Filter by</p>
        <SubsetsCombobox mapCategory={mapCategory} currSort={currSort} setCurrSort={setCurrSort}></SubsetsCombobox>
        </Box>
        <Box marginTop="5%" className="download-button">
          <Button className="download-button" disabled={!isInScope(appCtx, "tycoon")} onClick={() => downloadCsvClicked()}>Download CSV</Button>
        </Box>
        <div hidden={isInScope(appCtx, "tycoon")}>
          <p className="disabled-text"> To Download you must subscribe, that's what pays for the whole dang operation</p>
          <Button className="profile-sub-button" variant="contained" color="success" onClick={()=> viewSubOptions()}>
            Humor Me
          </Button>
        </div>
      </Col>
    </Row>
    </Container>
  );
}

export default DownloadsPage;