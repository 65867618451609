import React, { useState, useEffect, useContext } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { createStripeSession } from "../../service/stripeservice"
import AppContext from './../../state/app-context'
import ReactGA from "react-ga4"

const appearance = {
  theme: 'night',
  labels: 'floating'
};

const StripeCheckoutForm = (props) => {
  const [clientSecret, setClientSecret] = useState('');
  const appCtx = useContext(AppContext);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + '/stripe-checkout-start' });
  }, []);

  async function startStripe() {
    let currUrl = window.location.href
    let index = currUrl.indexOf("#")
    if (index == -1) {
      return
    }
    let subLevel = currUrl.substring(index+1)

    let response = await createStripeSession(appCtx, subLevel)
    if (response !== undefined && response.data !== undefined) {
      setClientSecret(response.data.clientSecret)
    }
  }

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
   startStripe()
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={props.stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

export default StripeCheckoutForm;