import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { csv } from "d3-fetch";

import counties from "../../input/all_counties_fips.json";
import { Paper } from "@mui/material";
import ReactGA from "react-ga4"
import { isMapNonEmpty } from "../../shared/common";

const filterOptions = createFilterOptions({
  limit: 50
});

const CountyComboBox = (props) => {
  const [comboboxItems, setComboboxItems] = useState([]);
  const [selected, setSelected] = useState("")
  const [allCounties, setAllCounties] = useState([])
  const [stateMap, setStateMap] = useState({})
  const [initialized, setInitialized] = useState(false)

  // overkill but this fixes a crashing bug when swapping out comboboxitems depending on states vs national vs solo
  if (initialized === false && comboboxItems !== undefined && comboboxItems !== null && Object.keys(comboboxItems) == 0) {
    setInitialized(true)
    loadCounties()
  }

  const onChanged = (newValue) => {
    if (newValue != null) {
      setSelected(newValue.label)
      props.setTypedCounty(newValue)

      ReactGA.event({
        category: 'Combobox.Changed',
        action: 'CountyCombobox Typed ',
        label: 'countycombobox.typed.'+newValue.state + '.' +newValue.label
      });
    }
  };

  function loadCounties() {
    const dataArray = []
    const dataMap = {}
    for (let i in counties) {
      const county = counties[i]
      let county_id = county['county_id']
      let state = county['state']
      let origName = county.county
      if (origName !== undefined && dataMap[county.fips] == undefined) {
        let newName = origName.replace('-', ' ') + ', ' + county.state
        dataArray.push({ label: newName, county_id: county_id, county: newName, state: state, fips: county.fips})
        dataMap[county.fips] = county
        if (stateMap[state] === undefined) {
          stateMap[state] = []
        }
        stateMap[state].push({ label: newName, county_id: county_id, county: newName, state: state, fips: county.fips})
      }
    }
    setComboboxItems(dataArray)
    setAllCounties(dataArray)
  }

  useEffect(() => {
    if (isMapNonEmpty(props.geoContent) && props.geoContent.county !== "" && isMapNonEmpty(props.geoContent.county)) {
      let text = props.geoContent.county
      text = text.replace('-', ' ')
      if (props.geoContent.county.indexOf(', ' + props.geoContent.state) == -1) {
        text = text + ', ' + props.geoContent.state
      }
      setSelected(text)
    }

  }, [props.geoContent]);

  useEffect(() => {
    if (props.typedState !== undefined && props.typedState !== null) {
      let tempState = props.typedState.label

      if (tempState !== "" && stateMap[tempState] != undefined) {
        console.log('sorting counties by state')
        setComboboxItems(stateMap[tempState])
      } else {
        setComboboxItems(allCounties)
      }
    }

  }, [props.typedState]);


  return (
    <Autocomplete
      disablePortal
      id="combo-box-counties"
      options={comboboxItems}
      filterOptions={filterOptions}
      value={{label : selected}}
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white" }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField InputProps={{ style: { fontSize: 8 } }} {...params} label="County" size="small" />}
    />
  );
}

export default memo(CountyComboBox);