import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import "./LinksPage.css"
import ReactGA from "react-ga4"

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import { STATE_ABBREV_TO_FULL, getCityLinkMap, getCountyLinkMap } from '../shared/common';
import HelmetTags from '../state/helmet';

const LINK_START = window.location.origin + "/map/"


function LinksPage() {
  const navigate = useNavigate();
  let { geoModeP, stateP } = useParams();
  const STATE_ABBREVS = Object.keys(STATE_ABBREV_TO_FULL)


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);

  function getTextFromLink(link) {
    // get the 
    let lastSlash = link.lastIndexOf('/')
    let name = link.substring(lastSlash+1)
    return name
  }

  function createLink(geoType, state) {
    let domain = window.location.origin;
    return domain + "/links/"+state+"/" + geoType;
  }

  function createMetaTitle(geoP, stateP) {
    let geoTitle = ""
    if (geoP == "county") {
      geoTitle = "Counties"
    } else if (geoP == "city") {
      geoTitle = "Cities"
    }
    let state = STATE_ABBREV_TO_FULL[stateP]
    if (state == undefined) {
      return "Discover the Most Affordable Home Prices in America by " + geoP
    }
    return "Discover the Most Affordable Home Prices in "+ state + " by " + geoP
  }

  function createMetaDesc(geoP, stateP) {
    let geoTitle = ""
    let state = STATE_ABBREV_TO_FULL[stateP]
    if (geoP == "county") {
      geoTitle = "counties"
    } else if (geoP == "city") {
      geoTitle = "cities"
    }
    let title = "Discover home prices and other housing statistics for all the " + geoTitle + " in " + state

    return title
  }

  function LinksWrapper() {
    let links = []
    let geoTitle = "Counties in ";
    let mapToUse = null
      if (geoModeP == "county") {
        mapToUse = getCountyLinkMap()
      } else {
        mapToUse = getCityLinkMap()
        geoTitle = "Citites in ";
      }

    if (stateP == "USA") {
      // choose a random state
      const random = Math.floor(Math.random() * STATE_ABBREVS.length);
      stateP = STATE_ABBREVS[random]
    } 

    links.push(<div class="state-header">{geoTitle + STATE_ABBREV_TO_FULL[stateP]}</div>)
    let stateLinks = mapToUse[stateP]
    for (let l in stateLinks) {
      let link = stateLinks[l]
      links.push(<div><a href={link}>{getTextFromLink(link)}</a></div>)
    }

      return (
        <Container >
          <Row hidden={geoModeP == "city"} class="state-header"> Browse All Counties by State</Row>
          <div hidden={geoModeP == "city"} className='state-list'> 
            {STATE_ABBREVS.map((state) => (
              <a className="state-item" href={createLink("county", state)}>{state + " "}</a>
            ))}
          </div>

          <Row hidden={geoModeP == "county"} class="state-header"> Browse All Cities by State</Row>
          <div hidden={geoModeP == "county"} className='state-list'> 
            {STATE_ABBREVS.map((state) => (
              <a className="state-item" href={createLink("city", state)}>{state + " "}</a>
            ))}
          </div>

        <Row className="link-item">
          {links}
      </Row>
      </Container>
      )
  }


  return (
    <div>
      <HelmetTags key={"link-page-"+stateP} title={createMetaTitle(geoModeP, stateP)} description={createMetaDesc(geoModeP, stateP)}></HelmetTags>
    <Container className="link-wrapper">
      <Row>
        <LinksWrapper></LinksWrapper>
      </Row>
    </Container>
    </div>
  );
}

export default LinksPage;