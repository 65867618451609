import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';
import { ASC, DESC, SORT_ORDER, determineSortOrder } from '../../shared/common';
import './Controls.css'

export default function SortButton(props) {
  const [current, setCurrent] = React.useState(ASC);

  const handleChange = () => {
    if (current == ASC) {
      setCurrent(DESC)
      props.setSortOrder(DESC);
    } else if (current == DESC) {
      setCurrent(ASC)
      props.setSortOrder(ASC);
    } else {
      setCurrent(determineSortOrder(props.mapType))
    }
    
  };

  useEffect(() => {

    if (props.mapType !== undefined) {
      setCurrent(determineSortOrder(props.mapType))
    }
  }, [props.mapType]);

  if (current == ASC) {
    return (
      <Button
        className="dark-font-color spacer controls"
        aria-label="Small sizes"
        size="small"
        onClick={handleChange}
      >
        <span>
          <ArrowUp></ArrowUp>
        </span>
      </Button>
    );

  } else {

    return (
      <Button
        className="dark-font-color spacer sort-button"
        aria-label="Small sizes"
        size="small"
        onClick={handleChange}
      >
        <span>
          <ArrowDown></ArrowDown>
        </span>
      </Button>
    );
  }

  
}