import { memo} from 'react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

function MapInfo() {

    return (
      <div key="map-info-obj" className="card card-key dark-main-color map-info">
        <Row>
        <div>
          <p className="map-info-row center-obj"> Total Score = region's weighted avg of buyer friendly signals, compared to other regions.</p>
          </div>
        </Row>
      </div>
    )
}

export default memo(MapInfo);
