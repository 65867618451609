import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from "@mui/material";
import { getAttrLabelShort } from "../../shared/common";
import "./watchlist.css";
import ReactGA from "react-ga4"

const WatchlistSortComboBox = (props) => {
  const [mapItems, setMapItems] = useState([]);
  const [selected, setSelected] = useState("Median Price")

  const dataArray = []
  const dataMap = {} 
  const SCORE_TYPES = [
  { "type": "median_price", "name": "Median Price" },
  { "type": "num_listings", "name": "Number of Listings" },
  { "type": "num_price_drops", "name": "Number of Price Drops" }
]


  const onChanged = (newValue) => {
    if (newValue !== null) {
      props.setCurrSort(newValue.type)
      setSelected(getAttrLabelShort(newValue.type))
      ReactGA.event({
        category: 'Watchlist.SortCombobox.Change',
        action: 'Watchlist Sort Combobox Change',
        label: 'watchlist.sortCombobox.change.'+getAttrLabelShort(newValue.type),
      });
    }
  };

  useEffect(() => {
    const loadValues = async (dataArray) => {

      for (let i =0; i < SCORE_TYPES.length; i++) {
        const subtype = SCORE_TYPES[i]
        const mapType = subtype["type"]
        const name = subtype["name"]
        
        if (name !== undefined && mapType !== undefined && dataMap[mapType] === undefined) {
          dataArray.push({ label: name, type: mapType})
          dataMap[mapType] = name
        }
      }

      if (dataArray.length > 0) {
        setMapItems(dataArray)
      }
    }

    if (mapItems.length < 1) {
      loadValues(dataArray)
      .catch(console.error);
    }
    

  }, []);

  return (
    <Autocomplete
      disablePortal
      size="small"
      id="combo-box-watchlist"
      value={{label : selected}}
      defaultValue={props.currSort}
      options={mapItems}
      disableClearable
      className="combobox-dark watchlist-combo"
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white", border: "0px", fontSize: 10 }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" {...params} InputProps={{ ...params.InputProps, style: { fontSize: 10} }} size="small" />}
    />
  );
}

export default memo(WatchlistSortComboBox);