import React, { useState, useEffect, memo, useContext } from 'react';
import AppContext from '../../state/app-context';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import { isInScope } from '../../shared/common';
import './Controls.css';
import ReactGA from "react-ga4"

function formatStopText(value) {
  return `${value}`;
}

const DateSlider = (props) => {
  const appCtx = useContext(AppContext);
  const [sliderStops, setSliderStops] = useState([{"label": "today", "text": "today", "value": new Date().getTime()}]);
  const [currentSpot, setCurrentSpot] = useState("")

  useEffect(() => {
    if (props.csvMap !== undefined && props.csvMap !== null) {

      if (sliderStops.length !== Object.keys(props.csvMap).length) {
          setupSliderStops(props.csvMap)
      }
    }
  }, [props.csvMap]);

    

  function formatDate(value) {
    let formatted = value.replace(/(^|-)0+/g, "$1");
    return formatted.replace('-', '/')
  }

  function valueLabelFormat(value) {
    // paywall
    if (!isInScope(appCtx, "landlord")) {
        let resp = 'subscribe to use'
        return resp
    } else {
      if (sliderStops[value] !== undefined && sliderStops[value].label !== undefined) {
          let resp = 'fetching ' + sliderStops[value].text
          return resp
      }
    }
    }

  function selectedDateChanged(_, value) {
    if (!isInScope(appCtx, "landlord")) {
      setCurrentSpot(sliderStops[sliderStops.length -1].value)
    } else {
      let currDate = sliderStops[value]
      setCurrentSpot(sliderStops[value])
      if (currDate !== undefined && currDate.actual !== undefined && currDate.actual !== props.sliderDate) {
          props.setSliderDate(currDate.actual)
      }

      ReactGA.event({
        category: 'Slider.Changed',
        action: 'DateSlider Changed ',
        label: 'dateslider.changed.'+currDate.actual
      });
    }
  }

  function setupSliderStops(csvMap) {
      let parsed = []
      //parsed.push({"label": "today", "value": new Date().getTime()})
      for (const [key, value] of Object.entries(csvMap)) {
          let text = csvMap[key].text
          let label = value.label
          parsed.push({ "label": formatDate(label), "actual": key, "text": text, "value": new Date(key).getTime()})
      }

      // sort
      parsed.sort((a, b) => a.label - b.label);

      for (let i=0; i < parsed.length; i++) {
          parsed[i].value = i
      }

      if (parsed.length > 0) {
          setSliderStops(parsed)
          setCurrentSpot(sliderStops[sliderStops.length -1].value)
      }

  }

  function getMin() {
    let isDisabled = !isInScope(appCtx, "landlord")
    if (isDisabled) {
      return sliderStops[sliderStops.length -1].value
    } else {
      return sliderStops[0].value
    }
  }

  return (
    <Box sx={{ maxWidth: "800px", minWidth:"200px" }}>
      <Slider
        className="dark-primary"
        size="small" 
        defaultValue={sliderStops[sliderStops.length -1].value}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={formatStopText}
        step={1}
        valueLabelDisplay="auto"
        min={sliderStops[0].value}
        disabled={!isInScope(appCtx, "landlord")}
        max={sliderStops[sliderStops.length -1].value}
        marks={sliderStops}
        onChangeCommitted={selectedDateChanged}
      />
      <p className='slider-text' hidden={isInScope(appCtx, "landlord")}>date slider unlocked with subscription</p>
      <p className='slider-text' >*listings priced over 3 million are not included</p>
    </Box>
  );
}

export default memo(DateSlider);