import { getProperMapMode, CATEGORY_SUBTYPES, GEO_MAP_TYPES, GEO_MODE_TYPES, MAP_MODES_TO_LIST, CATEGORIES_PER_TYPE, getCountyMap, STATE_ABBREV_TO_FULL, getCityMap } from './common';

const hostBaseURL = 'https://www.hiddenhousing.org/map';
const untrackedUrlsList = [];
const options = { compact: true, ignoreComment: true, spaces: 4 };
let totalData = 0;

function traverseTheRest(urls, mapMode, geoMode, typedState, files, citiesList) {
    let domain = "https://www.hiddenhousing.org/map"
    let rankingDomain = "https://www.hiddenhousing.org/rankings"
    let countyMap = getCountyMap()
    let cityMap = citiesList

    let mapModeCat = getProperMapMode(mapMode, geoMode, typedState)
    let categories = CATEGORIES_PER_TYPE[mapModeCat]

    for (let c in categories) {
        let category = categories[c]
        let sublist = CATEGORY_SUBTYPES[category]
        sublist = filterSublist(sublist, mapMode, geoMode, typedState)
        if (urls[mapMode][geoMode][category] == undefined) {
          urls[mapMode][geoMode][category] = {}
        }

        //console.log('category: ' + category + ' geoMode: ' + geoMode + ' mapMode: ' + mapMode + ' typedState: ' + typedState.label)

        for (let mt in sublist) {
            let mapType = sublist[mt]
            let url = domain + '/' + mapMode + '/' + geoMode + '/' + category + '/' + mapType + '/' + typedState.label
            let rankingUrl = rankingDomain + '/' + mapMode + '/' + geoMode + '/' + category + '/' + mapType + '/' + typedState.label

            //console.log('rankingUrl: ' + rankingUrl)

            if (urls[mapMode][geoMode][category][mapType] == undefined) {
              urls[mapMode][geoMode][category][mapType] = {}
            }

            if (urls[mapMode][geoMode][category][mapType][typedState.label] == undefined) {
              urls[mapMode][geoMode][category][mapType][typedState.label] = []
            }

            urls[mapMode][geoMode][category][mapType][typedState.label].push(url)
            urls[mapMode][geoMode][category][mapType][typedState.label].push(rankingUrl)

            if (geoMode == "county") {
                for (let c in countyMap) {
                    let county = countyMap[c]
                    let countyId = county["county_id"]
                    let countyState = county["state"]
                    if (mapMode == "state" && countyState != typedState.label) {
                      continue
                    }

                    let name = county["county"].replaceAll(' ', '-')
                    let url = domain + '/' + mapMode + '/' + geoMode + '/' + category + '/' + mapType + '/' + typedState.label + "/" + countyId + '/' + name
                    //url = encodeURI(url)
                    urls[mapMode][geoMode][category][mapType][typedState.label].push(url)
                }
            } else if (geoMode == "city") {
                for (let c in cityMap) {
                    let city = cityMap[c]
                    let cityState = city["state"]
                    if (mapMode == "state" && cityState != typedState.label) {
                      continue
                    }
                    let cityId = city["city_id"].replaceAll(' ', '-')
                    let name = city["city_name"].replaceAll(' ', '-')
                    let url = domain + '/' + mapMode + '/' + geoMode + '/' + category + '/' + mapType + '/'+ typedState.label + "/" + cityId + '/' + name
                    //url = encodeURI(url)
                    urls[mapMode][geoMode][category][mapType][typedState.label].push(url)
                }
            }
            
            let filename = mapMode + '-' + geoMode + "-" + typedState.label + '-' + category + '-'+ mapType
            //console.log('adding filename: ' + filename)
            //files.push("Sitemap: "+"https://www.hiddenhousing.org/sitemaps/" +filename + ".txt")
            files.push("<sitemap>\n    <loc>"+"https://www.hiddenhousing.org/sitemaps/" +filename + ".txt"+ "</loc></sitemap>")
            //downloadFile(urls[mapMode][geoMode][category][mapType][typedState.label], filename)
        }
    }
    return urls
}

function filterSublist(subList, mapModeTab, geoModeTab, typedState) {
    let geoMode = geoModeTab
    let mapMode = mapModeTab

    // if state mode, subdivide into either national state (states) or state solo
    if (mapModeTab === "state") {
      if (typedState == undefined || typedState == null || typedState.label == undefined
        || typedState.label == null || typedState.label === "USA" || typedState.label == "") {
          mapMode = "states"
      } else {
        mapMode = "solo_state"
      }
    }

    let geoModeMap = MAP_MODES_TO_LIST[geoMode]
    let mapModeMap = MAP_MODES_TO_LIST[mapMode]
    let mapToUse = mapModeMap

    // CITY
    if (geoModeMap !== undefined && geoMode == "city") {
      mapToUse = geoModeMap
    }

    let itemsToReturn = []
    for (let i in subList) {
      let item = subList[i]
      let keep = mapToUse[item]
      if (keep !== undefined && keep == "Y") {
        itemsToReturn.push(item)
      }
    }

    return itemsToReturn

  }

export function createSiteMap(citiesList) {
    let urls = {}
    let files = []
    
    for (let mapMode in GEO_MAP_TYPES) {
        if (urls[mapMode] == undefined) {
          urls[mapMode] = {}
        }
        for (let geoMode in GEO_MODE_TYPES) {
          if (urls[mapMode][geoMode] == undefined) {
              urls[mapMode][geoMode] = {}
          }

            // for state we need to traverse all states
            if (mapMode == "state") {
                for (let state in STATE_ABBREV_TO_FULL) {
                    urls = traverseTheRest(urls, mapMode, geoMode, { label: state }, files, citiesList)
                }
            } else {
                urls = traverseTheRest(urls, mapMode, geoMode, { label: "USA" }, files, citiesList)
            }
        }
    }
    //console.log('files: ' + files.toString().replaceAll(',', "\n"))
}

const downloadFile = (array, name) => {
    let list = array.toString().replaceAll(",", "\n")
    let filename = name+'.txt'

    const url = window.URL.createObjectURL(new Blob([list]))

    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
