import React, { useState, useEffect, useContext } from "react";
import './Stripe.css';
import {
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from "react-router-dom";
import { getStripeSession } from "../../service/stripeservice";
import AppContext from './../../state/app-context'
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import HypeFlow from "./HypeFlow";
import ReactGA from "react-ga4"

const StripeReturn = () => {
  const appCtx = useContext(AppContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [scope, setScope] = useState('serf')

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + '/stripe-checkout-return' });
  }, []);

  async function getStripe() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    console.log('calling get stripe session from stripereturn')
    let response = await getStripeSession(appCtx, sessionId)
    if (response !== undefined && response.data !== undefined) {
      let data = response.data
      setStatus(data.status);
      setCustomerEmail(data.customer_email);

      let refresh = data["refresh_token"]
      let scope = data["scope"]
      let token = data["access_token"]
      setScope(scope)
      appCtx.setRefreshToken(refresh)
      appCtx.setScope(scope)
      appCtx.setToken(token)
      localStorage.setItem("hh-user-rt", refresh)
      localStorage.setItem('hh-scope', scope)
      localStorage.setItem('hh-access-token', token)

      ReactGA.event({
        category: 'Account.Subscribed',
        action: 'Account Subscribed ' + scope,
        label: 'account.subscribe.'+scope + '.' + appCtx.deviceId
      });
    }

  }

  function returnToMain() {
    console.log('clicked account create')
    return (
      navigate("/", { replace: false })
    )
  }

  useEffect(() => {
    if (appCtx.token !== null && appCtx.token !== undefined) {
      getStripe()
    }

  }, [appCtx.token]);

  if (status === 'open') {
    return (
      <Navigate to="/stripe-checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <Container>
        <Row className="profile-header" lg={12} sm={12} md={12} xs={12}>
          <Col className="stripe-return-box" xs={12} sm={12} md={12} lg={12}>
            <p className="stripe-text">
              HAZZAH you're subscribed! A confirmation email will be sent to {customerEmail}.
              If you have any questions, please email <a href="mailto:hiddenhousing@outlook.com">hiddenhousing@outlook.com</a>.
            </p>
            <Button className="return-button" variant="contained" color="success" onClick={()=> returnToMain()}>
              Return to Main
            </Button>
            <p className="stripe-text">
              As part of your subscription you now have access to all sorts of the below goodies:
            </p>
          </Col>
        </Row>
        <HypeFlow scope={scope}></HypeFlow>
      </Container>
    )
  } else {
    return (
      <div>
        <div className="lds-ellipsis middle"><div></div><div></div><div></div><div></div></div>
      </div>
    )
  }

  return null;
}


export default StripeReturn;