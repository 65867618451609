import React, { useState, useEffect, useContext, memo } from "react";
import { useNavigate } from 'react-router-dom';
import { ZoomableGroup, ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import { csv } from "d3-fetch";
import { cityIdToCountyInfo , getMapColors, SCORES_MAP, getStateCoords, parseClimateDataV2Type, parseClimateDataV2, fipsToCountyId, getColorScale, getAnalyticsName, isMapNonEmpty } from '../../shared/common';
import AppContext from '../../state/app-context';

import countiesMap from "../../input/usacounties.json";
import citiesMap from "../../input/USA_Major_Cities.json"
import statesMap from "../../input/geographics.json"
import { Box, Button } from "@mui/material";
import ReactGA from "react-ga4"

const notFoundArray = []

const defaultC = [0,0]
const defaultZ = 1

const defaultScale = 1400

const MapChart = (props) => {
  const appCtx = useContext(AppContext);
  const [scale, setScale] = useState(defaultScale)
  const [reload, setReload] = useState(Date.now())
  const [zoom, setZoom] = useState(1)
  const [center, setCenter] = useState([0,0])
  const [data, setData] = useState([]);
  const [selectedGeo, setSelectedGeo] = useState(isMapNonEmpty(appCtx.selectedGeo)? appCtx.selectedGeo: null)
  const [mapType, setMapType] = useState(props.mapType)
  const [mapCategory, setMapCategory] = useState(props.mapCategory)
  const [dataHasArrived, setDataHasArrived] = useState("")

  const setTooltipContent = props.setTooltipContent
  const setGeoContent = props.setGeoContent
  const navigate = useNavigate()

  function numberWithCommas(x) {
    if (x !== undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  function zoomToGeo(curr, withScale) {
    const coords = getStateCoords(curr.state)
    if (withScale) {
      setScale(2500)
    }
    setCenter([coords.x, coords.y])
    let zoom = coords.zoom / 2
    if (zoom == 1) {
      zoom = 2
    }
    setZoom(zoom)
  }

  const handleClick = (curr, geoModeTab) => {

    if (geoModeTab == "county") {
      setGeoContent(curr)
      setSelectedGeo(curr.fips)
      zoomToGeo(curr, false)

      ReactGA.event({
        category: 'Map.Clicks',
        action: 'National County Click',
        label: 'national.county.' + curr.county_id,
      });

    } else if (geoModeTab == "city") {
      if (curr.county_id === undefined && curr.fips !== undefined) {
        let countyInfo = fipsToCountyId(curr.fips)
        if (countyInfo !== null && countyInfo !== undefined) {
          curr.county = countyInfo.county
          curr.state = countyInfo.state
          curr.county_id = countyInfo.county_id
        } else {
          console.log('empty county info on fips: ' + curr.fips)
        }
      }
      setGeoContent(curr)
      setSelectedGeo(curr.city_id)
      zoomToGeo(curr, true)

      ReactGA.event({
        category: 'Map.Clicks',
        action: 'National City Click',
        label: 'national.city.' + curr.city_id,
      });
    }
  };

  const getGeography = (geoModeTab) => {
    if (geoModeTab === "county") {
      return countiesMap
    } else if (geoModeTab === "city") {
      return citiesMap
    } else if (geoModeTab === "states") {
      return statesMap
    } else {
      return countiesMap
    }
  }

  useEffect(() => {
    if (props.mapData !== null && props.mapData.length > 0) {
      setData(props.mapData)
      setDataHasArrived(props.mapType)
    }

  }, [props.mapData]);

  useEffect(() => {

    if (props.mapType !== null && props.mapType !== undefined && props.mapType !== mapType) {
      setMapType(props.mapType)
    }

  }, [props.mapType]);

  useEffect(() => {

    if (props.mapCategory !== null && props.mapCategory !== undefined) {
      setMapCategory(props.mapCategory)
    }

  }, [props.mapCategory]);

  useEffect(() => {

    if (props.dataHasArrived !== null && props.dataHasArrived !== undefined) {
      setDataHasArrived(props.dataHasArrived)
    }

  }, [props.dataHasArrived]);

  useEffect(() => {

    if (isMapNonEmpty(props.geoContent) && props.geoModeTab === "county") {
      setSelectedGeo(props.geoContent.fips)
      zoomToGeo(props.geoContent, false)

    } else if (isMapNonEmpty(props.geoContent) && props.geoModeTab === "city") {
      setSelectedGeo(props.geoContent.city_id)
      zoomToGeo(props.geoContent, true)
    }

  }, [props.geoContent]);

  function setupTooltip(curr, geoModeTab) {
    if (geoModeTab == "county") {
      if (mapType === "all" || mapType == "past_trend") {
        setTooltipContent({"label": curr.county + ' score: ' + curr.score, "data": curr});

      } else if (mapType.indexOf("climate") > -1) {
        if (mapType === "climate_risk_factors") {
          setTooltipContent({"label": curr.county  + ": " + parseClimateDataV2(curr.value), "data": curr});
        } else {
          setTooltipContent({"label": curr.county  + ": " + parseClimateDataV2Type(curr.value, mapType), "data": curr});
        }

      } else if (mapCategory == "past" && mapType !== "past_trend") {
        let scoreObj = curr["score"]
        if (scoreObj[mapType] != undefined) {
          let score = scoreObj[mapType]
          setTooltipContent({"label": curr.county + ": " + score + "%"})
        }

      } else if (mapType === "cost_of_living" && curr.score != undefined && curr.score.overall) {
        setTooltipContent({"label": curr.county  + ": " + curr.score.overall, "data": curr});

      } else if (mapType === "violent_crime" && curr.score != undefined && curr.score.violent_crime_rate) {
        setTooltipContent({"label": curr.county  + ": " + curr.score.violent_crime_rate, "data": curr});

      } else if (mapType === "property_crime" && curr.score != undefined && curr.score.property_crime_rate) {
        setTooltipContent({"label": curr.county  + ": " + curr.score.property_crime_rate, "data": curr});

      } else if (mapType === "sunny_days" && curr.score != undefined && curr.score.sunny_days) {
        setTooltipContent({"label": curr.county  + ": " + curr.score.sunny_days, "data": curr});

      } else if (mapType === "snow" && curr.score != undefined && curr.score.snowfall) {
        setTooltipContent({"label": curr.county  + ": " + curr.score.snowfall, "data": curr});

      } else if (mapType === "rain" && curr.score != undefined && curr.score.rainfall) {
        setTooltipContent({"label": curr.county  + ": " + curr.score.rainfall, "data": curr});

      } else if (mapType === "elevation" && curr.score != undefined && curr.score.elevation) {
        setTooltipContent({"label": curr.county  + ": " + numberWithCommas(parseInt(curr.score.elevation)), "data": curr});

      } else {
        setTooltipContent({"label": curr.county  + ": " + numberWithCommas(curr.value), "data": curr});
      }
    } else if (geoModeTab == "city") {
      if (mapType === "all" || mapType == "past_trend") {
        setTooltipContent({"label": curr.city_name + ', ' + curr.state + ': ' + curr.score, "data": curr});
      } 
      else if (mapCategory == "past" && mapType !== "past_trend") {
        let scoreObj = curr["score"]
        if (scoreObj[mapType] != undefined) {
          let score = scoreObj[mapType]
          setTooltipContent({"label": curr.city_name + ": " + score + "%"})
        }
      
    } else {
        setTooltipContent({"label": curr.city_name + ', ' + curr.state + ': ' + numberWithCommas(parseInt(curr.value)), "data": curr});
      }
    }
  }

  function addBackgroundGeo(geoModeTab) {
    if (geoModeTab == "county") {
      return
    } else if (geoModeTab == "city") {
      return (
      <Geographies key='below-map' geography={getGeography("states")} >
        {({ geographies }) =>
          geographies.map(geo => {
          
          //let curr = data.find(s =>  s.fips === geo.id);
          return (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              stroke="#121212"
              strokeWidth={.25}
              style={{
                default: {
                  outline: 'none'
                },
                hover: {
                  outline: "none",
                },
                pressed: {
                  outline: "none",
                },
              }}
              fill={"#D3D3D3"}
            ></Geography>
          );
        })
      }
      </Geographies>
    )}
  }

  function doesFipsMatch(curr, selectedGeoFips, geoModeTab) {
    if (curr != null && selectedGeoFips != null) {
      if (geoModeTab === "county"){
        if (curr.fips == selectedGeoFips) {
          return true
        }
        if (curr.fips == "0" + selectedGeoFips) {
          return true
        }
      }
    }
    return false
  }

  const zoomOut = () => {
    // reset selected county
    setSelectedGeo(null)

    setReload(Date.now())
    setScale(defaultScale)
    setZoom(1)
    setCenter([0,0])
  }

  function addGeography(geographies, geoModeTab) {
    // s: {"city_id":"MO_Lee's Summit","score":"4.08","fips":"29095","value":"","city_name":"Lee's Summit","state":"MO"}
    // geo: {"type":"Feature","properties":{"FID":387,"NAME":"Olathe","CLASS":"city","ST":"KS","STFIPS":"20","PLACEFIPS":"2052575","CAPITAL":" ","POP_CLASS":8,"POPULATION":138786,"POP2010":125872,"WHITE":104559,"BLACK":6703,"AMERI_ES":545,"ASIAN":5137,"HAWN_PI":83,"HISPANIC":12794,"OTHER":5116,"MULT_RACE":3729,"MALES":62358,"FEMALES":63514,"AGE_UNDER5":11167,"AGE_5_9":11080,"AGE_10_14":9976,"AGE_15_19":8450,"AGE_20_24":6582,"AGE_25_34":20053,"AGE_35_44":20380,"AGE_45_54":17322,"AGE_55_64":11820,"AGE_65_74":5006,"AGE_75_84":2716,"AGE_85_UP":1320,"MED_AGE":33,"MED_AGE_M":32.2,"MED_AGE_F":33.7,"HOUSEHOLDS":44507,"AVE_HH_SZ":2.8,"HSEHLD_1_M":5178,"HSEHLD_1_F":5915,"MARHH_CHD":15153,"MARHH_NO_C":11958,"MHH_CHILD":1241,"FHH_CHILD":3110,"FAMILIES":33274,"AVE_FAM_SZ":3.24,"HSE_UNITS":46851,"VACANT":2344,"OWNER_OCC":32348,"RENTER_OCC":12159},"geometry":{"type":"Point","coordinates":[-94.818750457,38.8843548620001]},"rsmKey":"geo-386","svgPath":"M210.79448931319916,103.50506742763383m0,4.5a4.5,4.5 0 1,1 0,-9a4.5,4.5 0 1,1 0,9z"}
    let citiesList = {}
    let showCity = false
    let selected = false
    let strokeWidth = .25

    let toReturn = geographies.map(geo => {
      showCity = false
      let curr = data.find(s =>  s.fips === geo.id);

      selected = doesFipsMatch(curr, selectedGeo, geoModeTab)
      if (selected && doesFipsMatch(curr, props.geoContent.fips, geoModeTab)) {
        setGeoContent(curr) // set to curr so we can get the score and value, lines up with cards
        appCtx.setSelectedGeo(appCtx.selectedGeo) // force update
      }

      if (geoModeTab === "city") {
        let population = geo.properties.POPULATION
        let popLimit = (props.popStop !== undefined) ? props.popStop: 10000
        strokeWidth = .1

        let cityName = geo.properties.ST + "_" + geo.properties.NAME
        curr = data.find(s => s.city_id === cityName);
        selected = curr != null && curr.city_id === selectedGeo;
        if (selected == true) {
          if (curr.city_id == props.geoContent.city_id) {
            if (curr.county_id == undefined) {
              let countyInfo = cityIdToCountyInfo(curr.city_id)
              let geoObj = {
                "city_id": curr.city_id,
                "city_name": countyInfo["city_name"],
                "county": countyInfo["county"],
                "county_id": countyInfo["county_id"],
                "fips": countyInfo["fips"],
                "state": countyInfo["state"],
                "score": curr.score,
                "value": curr.value
              }
              setGeoContent(geoObj) // set to curr so we can get the score and value, lines up with cards
            }
          }
        }
        let fips = curr != null ? curr.fips : null

        if (parseInt(population) >= parseInt(popLimit) && citiesList[cityName] == undefined) {
          citiesList[cityName] = { "city_id": cityName, "label": geo.properties.NAME +', ' + geo.properties.ST , "city_name": geo.properties.NAME, "state": geo.properties.ST, "population": population, "fips": fips }
          showCity = true
        }
      }
      if ((curr !== undefined && curr !== null && showCity) || geoModeTab == "county") {
        return (
          <Geography
            key={geo.rsmKey}
            geography={geo}
            stroke="#121212"
            strokeWidth={strokeWidth}
            onClick={() => {
              if (curr != null) {
                handleClick(curr, geoModeTab)
              }
            }}
            onMouseEnter={() => {
              if (curr != null) {
                setupTooltip(curr, geoModeTab)
              }
            }}
            onMouseLeave={() => {
              setTooltipContent({label: "", data: curr});
            }}
            style={{
              default: {
                outline: 'none'
              },
              hover: {
                opacity: ".5",
                outline: "none",
                fill: "#1e1e1e",
              },
              pressed: {
                opacity: ".5",
                outline: "none",
                fill: "#2d2d30",
              },
            }}
            fill={getMapColors(selected, curr, mapType, dataHasArrived, appCtx, geoModeTab, mapCategory)}
          ></Geography>
        );
      }
    })
    // save city list for the combobox TODO: remove values that dont have large enough population on the slider
    if (geoModeTab == "city") {
      const unsorted = Object.values(citiesList)
      const sortedArray = unsorted.sort(function(a,b) {
        if (a.city_id < b.city_id) { return -1; }
        else { return 1; }
      });
      props.setCitiesList(sortedArray)
    }
    return toReturn
  }

  function getDynamicMap(geoMode) {
    if (geoMode == "county") {
      return (
        <ComposableMap className="map-wrapper" height={260} width={400} projection="geoAlbersUsa" projectionConfig={{ scale: 450, rotate: [0, 0, 0], center: [20, 61] }}>
          <ZoomableGroup key={"zg-map-"+reload} center={center} zoom={zoom}>
            <Geographies key={'geo-county-map'} geography={getGeography(props.geoModeTab)} >
              {({ geographies }) =>
                addGeography(geographies, props.geoModeTab)
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      )

    } else if (geoMode == "city") {
      return (
        <ComposableMap className="map-wrapper" width={1200} height={780} projection="geoAlbersUsa" projectionConfig={{ scale: scale }}>
            <ZoomableGroup key={"zg-city-"+reload} center={center} zoom={zoom}>
            {addBackgroundGeo(geoMode)}
            <Geographies key={'geo-city-map-pop'+props.popStop} geography={getGeography(geoMode)} >
              {({ geographies }) =>
                addGeography(geographies, geoMode)
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        )
    }
  }

  return (

      <div data-tip="">
          <Button hidden={zoom==1} className="zoomout" onClick={()=> zoomOut()}>Reset Map</Button>
        {getDynamicMap(props.geoModeTab)}
      </div>

  );
};

export default memo(MapChart);
