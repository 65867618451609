import React, { useState, useEffect, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { csv } from "d3-fetch";
import states from "../../input/allstates.json";
import { PropaneSharp } from "@mui/icons-material";
import { Paper } from "@mui/material";
import ReactGA from "react-ga4"

const filterOptions = createFilterOptions({
  limit: 50
});

function formatDate (date) {  
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance')
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
const StateComboBox = (props) => {
  const [comboboxItems, setComboboxItems] = useState([]);
  const [selected, setSelected] = useState("")

  const onChanged = (newValue) => {
    if (newValue != null) {
      setSelected(newValue.label)
      props.setTypedState(newValue)

      ReactGA.event({
        category: 'Combobox.Changed',
        action: 'StateCombobox Typed ',
        label: 'statecombobox.typed.'+newValue.label
      });
    }
  };

  if (comboboxItems.length == 0) {
    const dataArray = []
      for (let i in states) {
        const state = states[i]
        dataArray.push({ label: state.id, val: state.val })
      }
    setComboboxItems(dataArray)
  }

  useEffect(() => {
    setSelected(props.typedState.label)

  }, [props.typedState]);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-states"
      options={comboboxItems}
      className="combobox-dark"
      filterOptions={filterOptions}
      defaultValue={"USA"}
      value={selected}
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white" }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" InputProps={{ style: { fontSize: 8 } }} {...params} label="State" size="small" />}
    />
  );
}

export default memo(StateComboBox);