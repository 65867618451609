import { memo} from 'react';
import * as React from 'react';
import "./styles.css";

function Indicator(props) {

  let override = props.image

  let numberF = parseFloat(props.number)
  let stateF = parseFloat(props.stateAvg)
  let nationalF = parseFloat(props.nationalAvg)

  if (numberF == 0.0) {
    return
  }

  let points = 0
  let arrow = "bi-arrow-down-circle-fill"
  let arrowPrefix = "bi good-indicators "
  let color = ""
  let diff = 0
  if (props.desired === "lower") {
    if (numberF < nationalF) {
      points += 1
      diff = (nationalF - numberF) / nationalF
    }
    if (numberF < stateF) {
      points += 1
      diff = (stateF - numberF) / stateF
    }

    if (points >= 2) {
      arrow = "bi-arrow-down-circle-fill"
      color = " strong-indicator"
    } else if (points >= 1) {
      arrow = "bi-arrow-down-circle-fill"
      color = " weak-indicator"
    }

  } else if (props.desired === "higher") {
    if (numberF > nationalF) {
      points += 1
      diff = (numberF - nationalF) / nationalF
    }
    if (numberF > stateF) {
      points += 1
      diff = (numberF - stateF) / stateF
    }

    if (points >= 2) {
      arrow = "bi-arrow-up-circle-fill"
      color = " strong-indicator"
    } else if (points >= 1) {
      arrow = "bi-arrow-up-circle-fill"
      color = " weak-indicator"
    }
  }
  let percent = ""
  if (diff > 0) {
    percent = "   " + String(Math.round((diff*100), 2)) + "%"
  }

  if (override !== undefined && override !== null) {
    arrow = override
    percent = ""
  }

  let classes = arrowPrefix+arrow+color
  if (points > 0) {
    return (
      <span>
        <i className={classes}>{percent}</i>
      </span>
    )
  }
}

export default memo(Indicator);
