import React, { useState, useEffect, useContext, memo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { csv } from "d3-fetch";
import { Paper } from "@mui/material";
import AppContext from '../../state/app-context';
import { convertToUserFacing, isInScope, CATEGORY_SUBTYPES, MAP_MODES_TO_LIST, PAYWALLED, NATIONAL_COUNTIES_LIST, isMapTypeInExistingList } from "../../shared/common";
import ReactGA from "react-ga4"

/*
119,"{'1m': {'median_price': '27.0', 'avg_days_on_market': '-2.0', 'avg_price_drop': '4.0', 'listings_per_pop': '-23.0', 'price_drops_per_pop': '16.0', 'num_listings': '-23.0', 'avg_price_change': '13.0'}, '3m': {'median_price': '29.0', 'avg_days_on_market': '84.0', 'avg_price_drop': '7.0', 'listings_per_pop': '-19.0', 'price_drops_per_pop': '6.0', 'num_listings': '-19.0', 'avg_price_change': '-29.0'}, '6m': {'median_price': '14.0', 'avg_days_on_market': '258.0', 'avg_price_drop': '16.0', 'listings_per_pop': '138.0', 'price_drops_per_pop': '6.0', 'num_listings': '138.0', 'avg_price_change': '44.0'}, 'total': '4.39'}",01001,"{'1m': {'median_price': '69251', 'avg_days_on_market': '-3', 'avg_price_drop': '629', 'listings_per_pop': '-211', 'price_drops_per_pop': '12', 'num_listings': '-124', 'avg_price_change': '2141'}, '3m': {'median_price': '74806', 'avg_days_on_market': '62', 'avg_price_drop': '1108', 'listings_per_pop': '-162', 'price_drops_per_pop': '5', 'num_listings': '-95', 'avg_price_change': '-7370'}, '6m': {'median_price': '40000.0', 'avg_days_on_market': '98', 'avg_price_drop': '2300', 'listings_per_pop': '412', 'price_drops_per_pop': '5', 'num_listings': '242', 'avg_price_change': '5544'}, 'total': '6.23'}",Autauga-County,AL

*/

function formatDate (date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance')
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

const MapTypeComboBox = (props) => {
  const appCtx = useContext(AppContext);
  const [mapItems, setMapItems] = useState(generateComboList(props.mapCategory))
  const [selectedItem, setSelectedItem] = useState(props.mapType != undefined? { label: convertToUserFacing(props.mapType), type: props.mapType } : mapItems[0])
  const [state, setState] = useState({ "geoModeTab": "", "mapModeTab": "", "typedState": ""})

  const onChanged = (newValue) => {
    if (newValue !== null) {
      setSelectedItem(newValue)
      props.setMapType(newValue.type)

      ReactGA.event({
        category: 'Combobox.Changed',
        action: 'MapTypeCombobox Typed ',
        label: 'maptypecombobox.typed.'+newValue.state + '.' +newValue.label
      });
    }
  };

  function generateComboList(subType) {
    let subList = CATEGORY_SUBTYPES[subType]
    if (subList !== undefined && subList !== null) {
      let comboItems = []
      for (let i in subList) {
        let item = subList[i]
        let newItem = {
          "type": item, "label": convertToUserFacing(item)
        }
        comboItems.push(newItem)
      }
      return comboItems
    }
    return []
  }
  
  function filterSublist(subList) {
    let geoMode = props.geoModeTab
    let mapMode = props.mapModeTab

    // if state mode, subdivide into either national state (states) or state solo
    if (props.mapModeTab === "state") {
      if (props.typedState == undefined || props.typedState == null || props.typedState.label == undefined
        || props.typedState.label == null || props.typedState.label === "USA" || props.typedState.label == "") {
          mapMode = "states"
      } else {
        mapMode = "solo_state"
      }
    }

    let geoModeMap = MAP_MODES_TO_LIST[geoMode]
    let mapModeMap = MAP_MODES_TO_LIST[mapMode]
    let mapToUse = mapModeMap

    // CITY
    if (geoModeMap !== undefined && geoMode == "city") {
      mapToUse = geoModeMap
    }

    let itemsToReturn = []
    for (let i in subList) {
      let item = subList[i]
      let type = item.type
      let keep = mapToUse[type]
      if (keep !== undefined && keep == "Y") {
        itemsToReturn.push(item)
      }
    }
    //setCurrList(mapToUse)
    return itemsToReturn

  }
/*
  useEffect(() => {
    if (props.mapCategory !== undefined) {
      let newSublist = generateComboList(props.mapCategory)
      let filtered = filterSublist(newSublist)
      if (filtered.length > 0) {
        setMapItems(filtered)

        if (props.mapType == undefined && !isMapTypeInExistingList(currList, props.mapType))) {
          setSelectedItem(filtered[0])
          console.log('setting selected item to 0')
          props.setMapType(filtered[0].type)
        }
      } else {
        setMapItems([])
        setSelectedItem("No maps types available for this map configuration (toggle to national or click a state)")
      }
    }

  }, [props.mapCategory]);*/

  useEffect(() => {


    if (props.mapModeTab !== undefined && props.mapModeTab !== null && props.geoModeTab !== null && props.mapType !== undefined && props.mapCategory !== undefined) {
      // ensure something has actually changed
      if (props.mapModeTab !== state.mapModeTab || props.geoModeTab !== state.geoModeTab || props.typedState.label != state.typedState.label || props.mapCategory !== state.mapCategory) {
        let resetList = false
        if (state.mapModeTab !== props.mapModeTab) {
          state["mapModeTab"] = props.mapModeTab
          resetList = true
        }

        if (state.geoModeTab !== props.geoModeTab) {
          state["mapModeTab"] = props.geoModeTab
          resetList = true
        }

        if (state.typedState.label !== props.typedState.label && props.typedState.label !== "USA" && props.typedState.label !== "") {
          state["typedState"] = props.typedState
          resetList = true
        }

        if (state.mapCategory != props.mapCategory) {
          state["mapCategory"] = props.mapCategory
          resetList = true
        }

        if (resetList) {
          let newSublist = generateComboList(props.mapCategory)
          let filtered = filterSublist(newSublist)
          if (filtered.length > 0) {
            setMapItems(filtered)

            // if mapType is not in the list then reset to start of the new list
            if (!isMapTypeInExistingList(filtered, props.mapType)) {
              setSelectedItem(filtered[0])
              props.setMapType(filtered[0].type)
            }
          }
      }

      }
    }

  }, [props.mapModeTab, props.typedState, props.geoModeTab, props.mapCategory]);

  function getOptionText(option) {
    let text = option.label
    if (disableIfApplicable(option) == true) {
      text = text = " (Requires Subscription)"
      return text
    } else {
      return text
    }
  }

  function disableIfApplicable(option) {
    let currType = option["type"]

    let scope = PAYWALLED[currType]
    

    if (scope !== undefined) {
      return !isInScope(appCtx, scope)
    }

    return false
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-map"
      value={getOptionText(selectedItem)}
      getItemValue={(item) => item.label}
      className="combobox-dark"
      options={mapItems}
      getOptionDisabled={(option) => disableIfApplicable(option)}
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#3e3e42", color: "white" }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        onChanged(newValue);
      }}
      renderInput={(params) => 
        <TextField className="combobox-dark" InputProps={{ style: { fontSize: 6 } }} {...params} label="Map Type" size="small" />}
    />
  );
}

export default memo(MapTypeComboBox);