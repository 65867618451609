
import React, { useState, useEffect, memo, useContext } from 'react';

import { isMapNonEmpty, isMobile, getAnalyticsName } from '../shared/common';
import { getHeatmap, getValidCSVDates } from "../service/thermostatservice"
import { Container, Row, Col } from 'react-bootstrap';
import CardTabs from '../componets/Cards/CardTabs';
import AppContext from '../state/app-context';
import './DashboardPage.css';
import Watchlist from '../componets/Watchlist/Watchlist';
import ListingDeals from '../componets/Watchlist/ListingDeals';
import ReactGA from "react-ga4"

function WatchlistPage() {
  const appCtx = useContext(AppContext);
  const [selectedScore, setSelectedScore] = useState({});
  const [geoContent, setGeoContent] = useState({})
  const [mapType, setMapType] = useState("all");
  const [mapModeTab, setMapModeTab] = useState( isMobile() ? "state" : "national")
  const [geoModeTab, setGeoModeTab] = useState("county")

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.host + window.location.pathname });
  }, []);
  
  function getWatchlistItems(map) {
    if (!isMapNonEmpty(map)) {
      return []
    } else {
      return Object.values(map)
    }
  }


  useEffect(() => {
    if (appCtx.selectedGeo !== null) {

    }
  }, [appCtx.selectedGeo]);


  return (
    <div>
      <Container>
        <Row className="maprow-watchlist">
          <Col xs={12} sm={12} md={12} lg={5}>
            <Watchlist setGeoContent={setGeoContent} mode={"page"} watchlistItems={getWatchlistItems(appCtx.watchlistItems)}></Watchlist>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7}>
            <ListingDeals selectedGeo={appCtx.selectedGeo}></ListingDeals>
          </Col>
        </Row>
        <Row className="map-bottom-watchlist">
        </Row>
      </Container>
    </div>
  );
}
export default memo(WatchlistPage);