import { getUrlPrefix } from '../shared/common';

export async function createStripeSession(appCtx, subLevel) {

    let username = ""
    if (appCtx.user !== null && appCtx.user.username !== null && appCtx.user !== undefined && appCtx.user.username !== undefined){
        username = appCtx.user.username
    }

    try {
        const url = getUrlPrefix() + 'stripe/create-checkout-session'
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'hh-device-id': appCtx.deviceId,
                'hh-access-token': appCtx.token,
                'hh-username': username,
                'sub_id': subLevel
            },
          })
        return await response.json();

    } catch(error) {
        return [];
    }
}

export async function getStripeSession(appCtx, sessionId) {

    let username = ""
    if (appCtx.user !== null && appCtx.user.username !== null && appCtx.user !== undefined && appCtx.user.username !== undefined){
        username = appCtx.user.username
    }

    try {
        const url = getUrlPrefix() + 'stripe/session-status/'+sessionId
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'hh-device-id': appCtx.deviceId,
                'hh-username': username,
                'hh-access-token': appCtx.token
            },
          })
        return await response.json();

    } catch(error) {
        return [];
    }
}